/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const Sidebar = (props) => {
  let {
    locationData,
    assetData,
    changeLocation,
    changeAsset,
    handleSubmitData,
    selectedLocation,
    selectedAsset,
  } = props;

  const validationSchema = Yup.object().shape({
    location: Yup.object().shape({
      value: Yup.string().required("Location is required"),
    }),
    site: Yup.object().shape({
      value: Yup.string().required("Asset is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      location: selectedLocation,
      site: selectedAsset,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        location: selectedLocation,
        site: selectedAsset,
      };
      props.handleSubmitData(data);
    },
  });

  const handleLocationChange = (selectedLocation) => {
    localStorage.removeItem("asset");
    props.changeLocation(selectedLocation);
  };
   // Update formik values when props change
    useEffect(() => {
      formik.setErrors({
        location: "",
        site: "",
      });
      formik.setFieldValue("location", selectedLocation);
      formik.setFieldValue("site", selectedAsset);
    
      
    }, [selectedLocation, selectedAsset]);

  return (
    <>
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
              data-tooltip-id="tooltip"
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip
              id="tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              style={{
                position: "absolute",
                marginLeft: "-10px",
                padding: "10% 20% 10% 20%",
              }}
            />
          </li>
        </ul>
      </div>

      <Card className="filterCard submitbtnWpr">
        <Card.Body>
          <Form className="">
            <Row>
              <Form.Group
                className="form-group col-md-3 col-lg-3"
                controlId=""
              >
                <Form.Label>Location *</Form.Label>
                <Select
                  options={locationData}
                  placeholder="Select Location"
                  onChange={(e) => {
                    handleLocationChange(e);
                    formik.setFieldValue("site", "");
                    formik.setFieldTouched("location", true);
                    formik.setFieldValue("location", e);
                  }}
                  value={formik.values.location}
                  onBlur={() => {
                    formik.setFieldTouched("location", true);
                  }}
                />
                {formik.errors.location && formik.touched.location && (
                  <div className="error-message text-danger">
                    {formik.errors.location.value}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group col-md-3 col-lg-3"
                controlId=""
              >
                <Form.Label>Asset *</Form.Label>
                <Select
                  options={assetData}
                  placeholder="Select Asset"
                  value={formik.values.site}
                  onChange={(e) => {
                    formik.setFieldValue("parameter", []);
                    changeAsset(e);
                    formik.setFieldTouched("site", true);
                    formik.setFieldValue("site", e);
                  }}
                  onBlur={() => {
                    // formik.handleBlur('site');
                    formik.setFieldTouched("site", true);
                  }}
                />
                {formik.errors.site && formik.touched.site && (
                  <div className="error-message text-danger">
                    {formik.errors.site.value}
                  </div>
                )}
              </Form.Group>
              <div className="col-md-2 col-lg-2 d-flex float-right">
                <Form.Group className="col-auto">
                  <button
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </Form.Group>
              </div>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default Sidebar;
