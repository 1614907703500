import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from "js-cookie";
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import AWS from 'aws-sdk';
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";


const userRole = Cookies.get("role"); 
// Enable custom date format parsing in dayjs
dayjs.extend(customParseFormat);
// AWS S3 Configuration
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

function Historicaldata() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [requestdate, setRequested] = useState(new Date());
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [fileList, setFileList] = useState([]); // State for table data
  const [isModalVisible, setModalVisible] = useState(false); // Manage modal visibility
  const [modalRequestId, setModalRequestId] = useState(""); // Store Request ID
  const [modalMessage, setModalMessage] = useState(""); // Store modal message
  const [selectedRow, setSelectedRow] = useState(null);
    const [usernameRole, setnameUserRole] = useState("");
  


  const handleModalOpen = (requestId) => {
    setModalRequestId(requestId);
    setModalMessage("Your Request is under process");
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };


  const handleDateChange = (setter) => (date) => {
    setter(date);
  };
  // Fetch the subdomain from localStorage
  const subdomain = localStorage.getItem('subdomain');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page refresh
  
    // Generate a unique requestId (or fetch it from an API if needed)
    const generatedRequestId = `REQ${Date.now()}`;
  
    // Construct the folder path using the generated requestId
    const folderPath = `${subdomain}/${generatedRequestId}/`;
  
    // Extract the requestId from the folder path
    const requestId = extractRequestIdFromPath(folderPath); // This ensures requestId is initialized
  
    // Call the API integration functions
    await fetchHistoricalData();
    await fetchRequests();
  
    // Open the modal with the extracted requestId
    handleModalOpen(requestId);
  };
  const extractRequestIdFromPath = (folderPath) => {
    const parts = folderPath.split('/'); // Split the folder path by '/'
    return parts[1]; // Extract the second part (requestId) from the path
  };
  
  
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
      const name = tokenData.name;
            const role = Cookies.get("role");
            setnameUserRole(`${name} | ${role}`);
    }

    // if (solution === null) {
    //   setSolution(localStorage.getItem("solution"));
    //   update_solution(localStorage.getItem("solution"));
    // }
  }, []);


  useEffect(() => {
    if (subdomain) {
      fetchS3Data();
    }
  }, [subdomain]);
  useEffect(() => {
    if (token) {
      fetchRequests();
    }
  }, [token]);


  const fetchHistoricalData = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 1;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/historical_data/customer_requests_for_historical_data`,
            {
              access_token: token,
              user_log_id: userLogId,
              start_date: format(fromDate, "yyyy-MM-dd HH:mm:ss"), // Format date as required
              end_date: format(toDate, "yyyy-MM-dd HH:mm:ss"),
              status: 'Pending',
              created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                requestId: item.requestId,
                fromDate: item.fromDate,
                toDate: item.toDate,
                status: item.status,
              }))
              .sort((a, b) => a.requestId.localeCompare(b.requestId)); // Sort data by request ID

            if (response.data.data.length === 0) {
              // Show message if no data is found
              // showFlashMessage("No historical data found.");
            }
            // setFileList(data);
            success = true;
          } else {
            // Show message if data fetch fails
            // showFlashMessage("Failed to fetch historical data.");
          }
        }
      } catch (error) {
        console.error("Error fetching historical data:", error);
      }
      attempts++;
    }

    if (!success) {
      // showFlashMessage("Unable to fetch historical data after multiple attempts.");
    }
  };



  const fetchRequests = async () => {
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 1;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/historical_data/fetch_requests_for_historical_data`,
          {
            access_token: token,
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success === true) {

          const data = response.data.data.map((item) => {
            // Parse dates using dayjs
            const fromDate = dayjs(item.start_date, "DD-MM-YYYY HH:mm:ss").isValid()
              ? dayjs(item.start_date, "DD-MM-YYYY HH:mm:ss").format("DD-MMM-YYYY")
              : "Invalid Date";

            const toDate = dayjs(item.end_date, "DD-MM-YYYY HH:mm:ss").isValid()
              ? dayjs(item.end_date, "DD-MM-YYYY HH:mm:ss").format("DD-MMM-YYYY")
              : "Invalid Date";

            return {
              created_at: item.created_at || "-",// Provide a fallback value
              requestId: item.request_id, // Map `request_id` to `requestId`
              requestBy: item.username,  // Use `username` for `Request By`
              fromDate,                  // Formatted `start_date`
              toDate,
              // Formatted `end_date`
              status: item.status,       // Use `status` directly
              collectionProgress: item.collectionProgress || [],
            };
          });
           // Sort by `created_at` in descending order (newest first)
      const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setFileList(sortedData)//ate with the mapped data
          success = true;
        } else {
          console.error("Failed to fetch requests");
        }
      } catch (error) {
        console.error("Error fetching requests:", error);
      }
      attempts++;
    }
  };




  const fetchS3Data = async () => {
    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Prefix: `${subdomain}/`,
      };

      const data = await s3.listObjectsV2(params).promise();


    } catch (error) {
      console.error('Error fetching data from S3:', error);
    }
  };
 
  const updateStatusOnly = (updatedData) => {
    setFileList((prevFileList) =>
      prevFileList.map((row) => {
        const updatedRow = updatedData.find(
          (item) => item.requestId === row.requestId
        );
        return updatedRow ? { ...row, status: updatedRow.status } : row;
      })
    );
  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        let attempts = 0;
        const maxAttempts = 1;
        let success = false;
  
        while (attempts < maxAttempts && !success) {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/historical_data/fetch_requests_for_historical_data`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
  
          if (response.status === 200 && response.data.success) {
            const updatedData = response.data.data.map((item) => ({
              requestId: item.request_id,
              status: item.status,
            }));
            updateStatusOnly(updatedData); // Update only the `status` column
            success = true;
          }
          attempts++;
        }
      } catch (error) {
        console.error("Error fetching updated statuses:", error);
      }
    }, 100000);// Fetch every 10 min
  
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [token, userLogId]);

   // ✅ Function to Download CSV Data with Correct File Size
   const csvdownloadData = async (row, fileSize) => {
    if (!token || !row) {
      console.warn("Missing token or row data", { token, row });
      return;
    }
  
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/historical_data/csv_download_data`,
        {
          access_token: token,
          user_log_id: userLogId,
          requestedId: row.requestId,
          requestBy: row.requestBy,
          start_date: row.fromDate,
          end_date: row.toDate,
          fileSize,
        }
      );
  
      if (response.status === 200 && response.data.success) {
      } else {
        console.error("Failed to fetch CSV data");
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  
  
  const handleDownloadFolder = async (row) => {
    try {
      if (!row) {
        console.error("Selected row is undefined!");
        return;
      }
  
      // Define the folder path
      const folderPath = `${subdomain}/${row.requestId}/`;
  
      // List all objects in the folder
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Prefix: folderPath,
      };
  
      const data = await s3.listObjectsV2(params).promise();
  
      if (!data.Contents || data.Contents.length === 0) {
        alert("No files found in the folder.");
        return;
      }
  
      // ✅ Calculate Total File Size in Bytes
      let fileSize = data.Contents.reduce((acc, file) => acc + (file.Size || 0), 0);
  
      // ✅ Call csvdownloadData with correct row and fileSize
      await csvdownloadData(row, fileSize);
  
      // ✅ Create ZIP file for download
      const JSZip = require("jszip");
      const zip = new JSZip();
  
      for (const item of data.Contents) {
        const fileKey = item.Key;
  
        const fileData = await s3
          .getObject({ Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME, Key: fileKey })
          .promise();
  
        const relativePath = fileKey.replace(folderPath, "");
        zip.file(relativePath, fileData.Body);
      }
  
      // Generate ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });
  
      // Trigger browser download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = `${row.requestId}.zip`;
      link.click();
    } catch (error) {
      console.error("Error downloading folder:", error);
      alert("An error occurred while downloading the folder.");
    }
  };
  



  return (

    <div className="historicalPage content-wrapper ">
      {/* <header className="content">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Historical Data</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Historical Data</li>
              </ol>
            </div>
          </div>
        </div>
      </header> */}

      <section className="content">
        <div className="container-fluid">
          {/* Filters Section */}
          <div className="row ">
            <div className="col-md-12 col-lg-12">
              <div className="historicalpg mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">My Account</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Historical Data</a>
                    </li>
                    <p className="float-right">{usernameRole}</p>
                    {/* <li className="breadcrumb-item ">
                        <a href="#">Historicaldata</a>
                      </li> */}
                  </ol>
                </div>
                <div className="mdlContent card-body">
                  <div className="card">
                    <div className="card-body">
                      <form className="hstForm form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mr-2">
                          <label htmlFor="fromDate" className="mr-2">
                            From Date
                          </label>
                          <DatePicker
                            selected={fromDate}
                            onChange={handleDateChange(setFromDate)}
                            className="form-control"
                            id="fromDate"
                            dateFormat="dd-MMM-yyyy"
                          />
                        </div>
                        <div className="form-group mr-2">
                          <label htmlFor="toDate" className="mr-2">
                            To Date
                          </label>
                          <DatePicker
                            selected={toDate}
                            onChange={handleDateChange(setToDate)}
                            className="form-control"
                            id="toDate"
                            dateFormat="dd-MMM-yyyy"
                          />
                        </div>
                        {/* <div className="form-group mr-2">
  <label htmlFor="toDate" className="mr-2">
    Requested Date
  </label>
  <DatePicker
    selected={requestdate} // Default date is the current date
    onChange={handleDateChange(setRequested)} // Update state on date change
    className="form-control"
    id="requestdate"
    dateFormat="dd-MMM-yyyy" // Format the displayed date
    minDate={new Date(new Date().setDate(new Date().getDate() + 14))}
    maxDate={new Date()} // Prevent selection of dates after today
  />
</div> */}

                        <Button type="submit" className="btn btn-primary" >
                          Submit
                        </Button>
                      </form>
                    </div>
                  </div>

                  {isModalVisible && (
                    <div
                      className="requestIdModal modal fade show"
                      id="requestPop"
                      role="dialog"
                      aria-labelledby="requestPop"
                      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content text-center">
                          <div className="modal-header">
                            <h5 id="myModalLabel">Request Id: {modalRequestId}</h5>
                          </div>
                          <div className="modal-body">
                            <p>{modalMessage}</p>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleModalClose}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Table Section */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        {/* <div className="card-header">
                  <h3 className="card-title">Historical Data Table</h3>
                </div> */}

                        <div className="card-body">
                          <DataTable
                            columns={[
                              {
                                name: "Requested Date",
                                selector: (row) => row.created_at ? row.created_at : "Not Available",
                                sortable: true,
                              },
                              ...(userRole !== "tester" ? [ // ✅ Hide "Requested By" column for testers
                                {
                                  name: "Requested By",
                                  selector: (row) => row.requestBy,
                                  sortable: true,
                                }
                              ] : []),
                              {
                                name: "From Date",
                                selector: (row) => row.fromDate,
                                sortable: true,
                              },
                              {
                                name: "To Date",
                                selector: (row) => row.toDate,
                                sortable: true,
                              },
                              {
                                name: 'Status',
                                cell: (row) => (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '8px' }}>{row.status}</span>
                                    {row.status === 'In progress' && (
                                      <i className="fa fa-spinner fa-spin" style={{ color: '#FFD700' }} aria-hidden="true"></i>
                                    )}
                                    {row.status === 'Completed' && (
                                      <i className="fa fa-check-circle" style={{ color: '#88BF48' }} aria-hidden="true"></i>
                                    )}
                                    {row.status === 'Pending' && (
                                      <i className="fa fa-clock-o" style={{ color: '#FF6873' }} aria-hidden="true"></i>
                                    )}
                                  </div>
                                ),
                                sortable: true, 
                              },
                              {
                                name: "Download",
                                cell: (row) => (
                                  <Button
                                    variant="link"
                                    onClick={() => handleDownloadFolder(row)}
                                    disabled={row.status === "Pending" || row.status === "In progress"}
                                  >
                                    <i className="fa fa-download"></i>
                                  </Button>
                                ),
                              }
                              
                            ]}
                            data={fileList}
                            pagination
                            highlightOnHover
                            className="table table-hover dtr-inline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Historicaldata;
