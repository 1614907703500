import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { CardHeader, ToggleButton } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";


function Drum() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drumCards, setDrumCards] = useState([]);
  const [DrumTable,setDrumTable] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const location = useLocation();
  const rowData = location.state; 

  const service_order_Id = rowData?.id._id;
  const serviceOrderNo = rowData?.id.service_order_no;  

 // getting Token
 useEffect(() => {
  const elementsToRemove = document.querySelectorAll(".sidebarRight");
  elementsToRemove.forEach((element) => {
    element.remove();
  });
  const tkData = Cookies.get("tkData");
  if (tkData) {
    const tokenData = JSON.parse(tkData);
    setToken(tokenData.token);
    setUserLogId(tokenData.userLogId);
  }
}, []);

// Function to show flash message with a delay
const showFlashMessage = (message, variant = "danger") => {
  const newAlert = {
    message: message,
    variant: variant,
    id: new Date().getTime(),
  };

  setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
};

useEffect(() => {
  if (alerts.length > 0) {
    const timeoutId = setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 2000);

    return () => clearTimeout(timeoutId);
  }
}, [alerts]);

  // truncate to two decimal limt
  const truncateToTwoDigits = (value) => {
    if (!isNaN(value)) {
        return Math.trunc(value * 100) / 100;
    }
    return value;
};

//////// drum cards api /////////
const getDrumCards = async () => {
  if (!token) {
    // Return early if token is not available
    return;
  }

  let attempts = 0;
  const maxAttempts = 3;
  let success = false;

  while (attempts < maxAttempts && !success) {
    try {
      if (token !== null && token !== undefined && token !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/drums/view_drums_by_service_order_no`,
          {
            access_token: token,
            user_log_id: userLogId,
            service_order_id:service_order_Id
          }
        );
        if (response.status === 200 && response.data.success === true) {
          const data = response.data.data;
          setDrumCards(data)
          success = true;
          setIsLoading(false);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
    attempts++;
  }
  if (!success) {
    setDrumCards([]);
    showFlashMessage("Drum are Not Found in Cards");
    setIsLoading(false);
  }
};

//////// drum table api //////
const getDrumTable = async () => {
  if (!token) {
    // Return early if token is not available
    return;
  }

  let attempts = 0;
  const maxAttempts = 3;
  let success = false;

  while (attempts < maxAttempts && !success) {
    try {
      if (token !== null && token !== undefined && token !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/drums/view_drums`,
          {
            access_token: token,
            user_log_id: userLogId,
            service_order_no:serviceOrderNo
          }
        );
        if (response.status === 200 && response.data.success === true) {
          const data = response.data.data;
          setDrumTable(data)
          success = true;
          setIsLoading(false);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
    attempts++;
  }
  if (!success) {
    setDrumTable([]);
    showFlashMessage("Drum are Not Found in Table");
    setIsLoading(false);
  }
};

useEffect(() => {
  if (token !== null && token !== undefined && token !== "") {
    getDrumCards();
    getDrumTable()
  }
}, [token]);

const ellipsisStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",  
  textOverflow: "ellipsis",
  // maxWidth: "150px",    
};

const orderDetails = [
  { label: "PO No", value: drumCards[0]?.purchase_order_no},
  { label: "Type", value: drumCards[0]?.conductor_type},
  { label: "Diameter (m)", value: truncateToTwoDigits(drumCards[0]?.conductor_diameter_mm)},
  { label: "Length (m)", value: truncateToTwoDigits(drumCards[0]?.conductor_length_m)},
  { label: "Weight (kg)", value:truncateToTwoDigits(drumCards[0]?.total_weight)},
  { label: "Line", value:drumCards[0]?.line_number},
  { label: "Drums", value: drumCards[0]?.number_of_drums},
];

const columns = [
  { name: "Drum Sr. Number", 
    selector: (row) => row.drum_serial_number, 
    cell: (row) => (<div title={row.drum_serial_number} style={ellipsisStyle}>{row.drum_serial_number}</div>),
    sortable: true },
  { name: "Diameter (mm)", 
    selector: (row) => truncateToTwoDigits(row.conductor_diameter_mm), 
    cell: (row) => (<div title={row.conductor_diameter_mm} style={ellipsisStyle}>{row.conductor_diameter_mm}</div>),
    sortable: true },
  { name: "Length (m)", 
    selector: (row) => truncateToTwoDigits(row.drum_length_m),
    cell: (row) => (<div title={row.drum_length_m} style={ellipsisStyle}>{row.drum_length_m}</div>),
     sortable: true },
  { name: "Weight (kg)", 
    selector: (row) => truncateToTwoDigits(row.drum_weight), 
    cell: (row) => (<div title={row.drum_weight} style={ellipsisStyle}>{row.drum_weight}</div>),
    sortable: true },
  { name: "Line Number", 
    selector: (row) => row.location, 
    cell: (row) => (<div title={row.location} style={ellipsisStyle}>{row.location}</div>),
    sortable: true },
  {
    name: "Action",
    cell: (row) => (
      <div>
        <Link to="/cable Drum Report" 
      state={{ 
            id: row, 
            serviceOrderNo: serviceOrderNo, 
            service_order_Id: service_order_Id 
          }}
        >View Report</Link> 
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];

 //// no data available columns ////
 const NoDataComponent = () => (
  <>
    <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.name}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={columns.length}>
            <div className="text-center">
              <p>No Data Available For Drum</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      whiteSpace: "normal", // Custom font weight for the table header
    },
  },
  headRows: {
    style: {
      whiteSpace: "normal",
    },
  },
};
  
  return (
    <>
     {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="trendPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <CardHeader className="breadcrumbHdr">
                    <Breadcrumb>
                      <Breadcrumb.Item href="#">Customer Name</Breadcrumb.Item>
                      <Breadcrumb.Item href="#">Service Order No</Breadcrumb.Item>
                      <Breadcrumb.Item href="#">Purchase Order No</Breadcrumb.Item>
                    </Breadcrumb>
                  </CardHeader>
                  <div className="mdlContent card-body">
                    <div className="row drumCards">
                      {orderDetails.map((detail, index) => (
                        <div key={index} className="col-md-2 col-lg-2">
                          <div className="enrgConsBox mb-3">
                            <div className="inrCardHdr">
                              <h5>{detail.label}</h5>
                            </div>
                            <div className="inrCardBody">
                              <h3>{detail.value}</h3>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="energyWpr card pr-card mb30">
                      <DataTable
                        columns={columns}
                        data={DrumTable}
                        noDataComponent={<NoDataComponent />}
                        customStyles={customStyles}
                        pagination
                        highlightOnHover
                        responsive
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Drum