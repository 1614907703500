import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { CardHeader, ToggleButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger"; // ✅ Import Bootstrap tooltip
import Tooltip from "react-bootstrap/Tooltip";


const User = () => {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [userName, setUserName] = useState("");
  const [usernameRole, setnameUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeAdminsCount, setActiveAdminsCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  
  useEffect(() => {
    if (userData && Array.isArray(userData)) {
      const count = userData.filter(user => user.role === "account_admin" && user.status === "enable").length;
      setActiveAdminsCount(count);
    }
  }, [userData]); // Runs whenever userData updates

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      const name = tokenData.name;
      const role = Cookies.get("role");
      setUserName(role);
      setnameUserRole(`${name} | ${role}`);
    } else {
      console.error("tkData is missing from cookies");
    }
  }, []);


  useEffect(() => {
    if (token) {
      fetchUsers();
    }
  }, [token]);

  // const handleRoleChange = (event, row) => {
  //   const newRole = event.target.value;

  //   setUserData((prevData) =>
  //     prevData.map((item) =>
  //       item.id === row.id
  //         ? { ...item, email_service: item.email_service === "enable" ? "disable" : "enable" }
  //         : item
  //     )
  //   );
  // };

  // const handleEmailServiceToggle = (row) => {
  //   const updatedValue = row.email_service === "enable" ? "disable" : "enable";
  //   console.log("Before Update:", userData);
  //   setUserData((prevData) =>
  //     prevData.map((item) => {
  //       if (item._id === row._id) {
  //         console.log("Updating row:", item);
  //         return { ...item, email_service: updatedValue };
  //       }
  //       return item;
  //     })
  //   );
  //   console.log("After Update:", userData);
  // };
  // const handleSmsServiceToggle = (row) => {
  //   setUserData((prevData) =>
  //     prevData.map((item) =>
  //       item._id === row._id
  //         ? { ...item, sms_service: item.sms_service === "enable" ? "disable" : "enable" }
  //         : item
  //     )
  //   );
  // };

  // const handleWhatsappServiceToggle = (row) => {
  //   setUserData((prevData) =>
  //     prevData.map((item) =>
  //       item._id === row._id
  //         ? { ...item, whatsapp_service: item.whatsapp_service === "enable" ? "disable" : "enable" }
  //         : item
  //     )
  //   );
  // };

  // const handleAssetAllocationToggle = (row) => {
  //   setUserData((prevData) =>
  //     prevData.map((item) =>
  //       item._id === row._id
  //         ? { ...item, asset_allocation: item.asset_allocation === "enabled" ? "disabled" : "enabled" }
  //         : item
  //     )
  //   );
  // };

  // const handleStatusToggle = (row) => {
  //   setUserData((prevData) =>
  //     prevData.map((item) =>
  //       item._id === row._id
  //         ? { ...item, service_status: item.service_status === "enable" ? "disable" : "enable" }
  //         : item
  //     )
  //   );
  // };


  //csv 
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const filteredDataExcel = userData.map((row) => {
      return {
        User: row.user || "-",
        Email: row.email || "-",
        Mobile: row.contact_no || "-",
        Role: row.role || "-",
        EmailService: row.email_service || "-",
        SMSService: row.sms_service || "-",
        WhatsAppService: row.whatsapp_service || "-",
        Status: row.status || "-",
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "User Data" + fileExtension);
  };
  ////// Message Function //////
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);

    // Remove flash message after 3 seconds
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 3000);
  };

  const handleModalConfirm = () => {
    if (selectedRow) {
      // Call handleUserUpdate to handle the status change
      handleUserUpdate(selectedRow, "user_status", "disable");
    }
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };



  const fetchUsers = async () => {
    if (!token) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/fetch_users`,
        {
          access_token: token,
          user_log_id: userLogId,
        }
      );

      if (response.status === 200 && response.data.success === true) {
        const fetchedUsers = response.data.data;
        const loggedInEmail = localStorage.getItem("loggedInEmail"); // ✅ Get logged-in email
        const loggedInUser = fetchedUsers.find(user => user.email === loggedInEmail); // Get logged-in user data

        if (loggedInUser && loggedInUser.role === 'tester') {
          setUserData([loggedInUser]); // Show only logged-in user data
        } else {
          // ✅ Sort so that the logged-in user appears at the top
          const sortedUsers = fetchedUsers.sort((a, b) => {
            if (a.email === loggedInEmail) return -1; // Move logged-in user to top
            if (b.email === loggedInEmail) return 1;
            return 0;
          });
          setUserData(sortedUsers); // Show all users if not a tester
        }
      } else {
        console.error("Failed to fetch users");
        showFlashMessage("Error fetching users", "danger");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      showFlashMessage("Failed to fetch users", "danger");
    }
  };
  const handleUserUpdate = async (row, field, value) => {
    if (!token) {
      showFlashMessage("Error: Unable to update. Token is missing.", "danger");
      return;
    }

    try {
      const payload = {
        access_token: token,
        user_log_id: userLogId,
        userId: row._id,
        role: field === "role" ? value : row.role || "",
        sms: field === "sms_service" ? value : row.sms_service || "",
        whatsapp_service: field === "whatsapp_service" ? value : row.whatsapp_service || "",
        // asset_allocation_status:field ==="asset_allocation" ? value:row.asset_allocation || "",
        historical_data_status: "",
        email: field === "email_service" ? value : row.email_service || "",
        user_status: field === "user_status" ? value : row.user_status || "",
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/update_users`,
        payload
      );

      if (response.status === 200 && response.data.success) {
        setUserData((prevData) =>
          prevData.map((item) =>
            item._id === row._id ? { ...item, [field]: value } : item
          )
        );
        if (field !== "role") {
          const statusText = value === "enable" || value === "enabled" ? "Enabled" : "Disabled";
          showFlashMessage(`${field.replace("_", " ").toUpperCase()} ${statusText} updated successfully!`, "success");
        } else {
          showFlashMessage(`${field.replace("_", " ").toUpperCase()} updated successfully!`, "success");
        }
        fetchUsers();

      } else {
        showFlashMessage(`Failed to update ${field}`, "danger");
        console.error(`Failed to update ${field}:`, response.data);
      }
    } catch (error) {
      showFlashMessage(`Failed to update ${field}`, "danger");
      console.error(`Error updating ${field}:`, error);
    }
  };


  const columns = React.useMemo(() => {
    const role = Cookies.get("role"); // Get the role from cookies
    const loggedInUserEmail = Cookies.get("email");
    const adminCount = userData.filter(user => user.role === "Admin").length;
    const baseColumns = [
      {
        name: "User",
        selector: (row) => row.user,
        sortable: true,
      },
      {
        name: "Email Id",
        selector: (row) => row.email,
        sortable: true,
        cell: (row) => (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${row._id}`}>{row.email}</Tooltip>}
          >
            <span
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "180px", // ✅ Adjust width as needed
                display: "inline-block",
              }}
            >
              {row.email}
            </span>
          </OverlayTrigger>
        ),
      },
      {
        name: "Mobile",
        selector: (row) => row.contact_no,
        sortable: true,
      },
      // {
      //   name: "Role",
      //   cell: (row) => (
      //     <Form.Select
      //       size="sm"
      //       aria-label="Role selection"
      //       value={row.role}
      //       onChange={(e) => handleUserUpdate(row, "role", e.target.value)}
      //     >
      //       <option value="">Select Role</option>
      //       <option value="tester">Tester</option>
      //       <option value="Admin">Admin</option>
      //     </Form.Select>
      //   ),
      // },
      // {
      //   name: "Email (20/100)",
      //   selector: (row) => row.email_service,
      //   cell: (row) => (
      //     <div className="datalabel">
      //       <label className="switchSmall">
      //         <input
      //           type="checkbox"
      //           checked={row.email_service === "enable"}
      //           onChange={() =>
      //             handleUserUpdate(
      //               row,
      //               "email_service",
      //               row.email_service === "enable" ? "disable" : "enable"
      //             )
      //           }
      //         />
      //         <small></small>
      //       </label>
      //     </div>
      //   ),
      // },
      // {
      //   name: "SMS (50/1000)",
      //   selector: (row) => row.sms_service,
      //   cell: (row) => (
      //     <div className="datalabel">
      //       <label className="switchSmall">
      //         <input
      //           type="checkbox"
      //           checked={row.sms_service === "enable"}
      //           onChange={() =>
      //             handleUserUpdate(
      //               row,
      //               "sms_service",
      //               row.sms_service === "enable" ? "disable" : "enable"
      //             )
      //           }
      //         />
      //         <small></small>
      //       </label>
      //     </div>
      //   ),
      // },
      // {
      //   name: "WhatsApp (20/100)",
      //   selector: (row) => row.whatsapp_service,
      //   cell: (row) => (
      //     <div className="datalabel">
      //       <label className="switchSmall">
      //         <input
      //           type="checkbox"
      //           checked={row.whatsapp_service === "enable"}
      //           onChange={() =>
      //             handleUserUpdate(
      //               row,
      //               "whatsapp_service",
      //               row.whatsapp_service === "enable" ? "disable" : "enable"
      //             )
      //           }
      //         />
      //         <small></small>
      //       </label>
      //     </div>
      //   ),
      // },
      // {
      //   name: "Asset Allocation",
      //   selector: (row) => row.asset_allocation,
      //   cell: (row) => (
      //     <div className="datalabel">
      //       <label className="switchSmall">
      //         <input type="checkbox" checked={row.asset_allocation === "enabled"} />
      //         <small></small>
      //       </label>
      //     </div>
      //   ),
      // },
    ];
    // ✅ Only add "Role" column if the user is NOT a tester
    // ✅ Add "Role" column right after "Mobile" (only if role is NOT tester)
    if (role !== "tester") {
      baseColumns.push({
        name: "Role",
        cell: (row) => {
          const isSelf = row.email === loggedInUserEmail; // Check if it's the logged-in user
          const isLastAdmin = row.role === "Admin" && adminCount === 1;
          const canChangeAdminRole = adminCount > 1 && role === "Admin"; // Allow if multiple Admins exist

          return (
            <Form.Select
              size="sm"
              aria-label="Role selection"
              value={row.role}
              onChange={(e) => handleUserUpdate(row, "role", e.target.value)}
              disabled={isSelf || isLastAdmin || (!canChangeAdminRole && row.user === "Admin User")}
            >
              <option value="">Select Role</option>
              <option value="tester">Tester</option>
              <option value="Admin">Admin</option>
            </Form.Select>
          );
        },
      });
    }
    const loggedInEmail = localStorage.getItem("loggedInEmail");
  baseColumns.push(
  {
    name: "Email",
    selector: (row) => row.email_service,
    cell: (row) => {
      const isSelf = row.email === loggedInEmail; // ✅ Check if the row is the logged-in user
      const isTester = role === "tester"; // ✅ Check if the logged-in user is a tester

      return (
        <div className="datalabel">
          <label className="switchSmall">
            <input
              type="checkbox"
              checked={row.email_service === "enable"}
              disabled={isTester && !isSelf} // ✅ Disable if tester is trying to update others
              onChange={() =>
                handleUserUpdate(
                  row,
                  "email_service",
                  row.email_service === "enable" ? "disable" : "enable"
                )
              }
            />
            <small></small>
          </label>
        </div>
      );
    },
  },
  {
    name: "SMS",
    selector: (row) => row.sms_service,
    cell: (row) => {
      const isSelf = row.email === loggedInEmail;
      const isTester = role === "tester";

      return (
        <div className="datalabel">
          <label className="switchSmall">
            <input
              type="checkbox"
              checked={row.sms_service === "enable"}
              disabled={isTester && !isSelf} // ✅ Only allow testers to update themselves
              onChange={() =>
                handleUserUpdate(
                  row,
                  "sms_service",
                  row.sms_service === "enable" ? "disable" : "enable"
                )
              }
            />
            <small></small>
          </label>
        </div>
      );
    },
  },
  {
    name: "WhatsApp",
    selector: (row) => row.whatsapp_service,
    cell: (row) => {
      const isSelf = row.email === loggedInEmail;
      const isTester = role === "tester";

      return (
        <div className="datalabel">
          <label className="switchSmall">
            <input
              type="checkbox"
              checked={row.whatsapp_service === "enable"}
              disabled={isTester && !isSelf} // ✅ Only allow testers to update themselves
              onChange={() =>
                handleUserUpdate(
                  row,
                  "whatsapp_service",
                  row.whatsapp_service === "enable" ? "disable" : "enable"
                )
              }
            />
            <small></small>
          </label>
        </div>
      );
    },
  }
);

  // ✅ Add "Asset Allocation" only if the user is NOT a tester
  // if (role !== "tester") {
  //   baseColumns.push({
  //     name: "Asset Allocation",
  //     selector: (row) => row.asset_allocation,
  //     cell: (row) => {
  //       const loggedInEmail = localStorage.getItem("loggedInEmail"); // ✅ Get logged-in email
  //       const isSelf = row.email === loggedInEmail; // ✅ Check if this row is the logged-in user
  
  //       return (
  //         <div className="datalabel">
  //           <label className="switchSmall">
  //             <input
  //               type="checkbox"
  //               checked={row.asset_allocation === "enable"}
  //               disabled={isSelf} // ✅ Disable for the logged-in user
  //               onChange={() =>
  //                 handleUserUpdate(
  //                   row,
  //                   "asset_allocation",
  //                   row.asset_allocation === "enable" ? "disable" : "enable"
  //                 )
  //               }
  //             />
  //             <small></small>
  //           </label>
  //         </div>
  //       );
  //     },
  //   });
  // }
  
  if (role !== "tester") {
    baseColumns.push({
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        const loggedInEmail = localStorage.getItem("loggedInEmail"); // Get the logged-in user's email
  
        // Prevent the logged-in user from disabling themselves
        const isSelf = row.email === loggedInEmail;
  
        return (
          <Form className="statusTgl">
            <Form.Group className="switch">
              <Form.Label className="switch-light">
                <Form.Control
                  type="checkbox"
                  checked={row.status === "enable"}
                  disabled={isSelf} 
                  onChange={() => {
                    if (row.status === "enable") {
                      setSelectedRow(row);
                      setIsModalVisible(true);
                    }
                  }}
                />
                <span id="toggleParamColor">
                  <span>Enable</span>
                  <span>Disable</span>
                </span>
                <a className="btn btn-primary"></a>
              </Form.Label>
            </Form.Group>
          </Form>
        );
      },
    });
  }
  

    return baseColumns;
  })

  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Users</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };
  return (
    <>  {alerts.map((alert, index) => (
      <Alert
        key={alert.id}
        id={`flash-message-${alert.id}`}
        variant={alert.variant === "success" ? "success" : "danger"}
        onClose={() =>
          setAlerts((prevAlerts) =>
            prevAlerts.filter((a) => a.id !== alert.id)
          )
        }
        dismissible
        style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
      >
        {alert.message}
      </Alert>
    ))}
      {isModalVisible && (
        <div
          className="inactiveModal modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5>Status</h5>
              </div>
              <div className="modal-body">
                <p>Please confirm if you want to Enable or Disable user</p>
                <button
                  type="button"
                  className="btn btn-primary mr10"
                  onClick={handleModalConfirm}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleModalCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="historicalPage content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="historicalpg mdlContent card pr-card mb30">
                <CardHeader className="breadcrumbHdr">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">My Account</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">User</Breadcrumb.Item>
                    <p className="float-right">{usernameRole}</p>
                  </Breadcrumb>
                  </CardHeader>
                  <Card.Body className="mdlContent">
                    <Card className="userTbl">
                      <Card.Body>
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                            </div>
                            {/* <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="form-inline dataTables_filter">
                                <div className="input-group pr-form-group" >
                        <a
                          className="mt10"
                          alt="Download XLS"
                          onClick={exportToCSV}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-file-excel-o"></i>
                        </a>
                                </div>
                                </div>
                            </div> */}
                        </div>
                    
                        <DataTable
                          columns={columns}
                          data={userData.filter((user) => user.status === "enable")}
                          keyField="_id" // Ensure each row has a unique 'id'
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}

                          pagination
                          highlightOnHover
                          responsive
                          progressPending={isLoading} // Show loading spinner
                        />
                      </Card.Body>
                    </Card>
                  </Card.Body>

                  {/* <div
                      className="disableModal modal fade show"
                      id="disablePop"
                      role="dialog"
                      aria-labelledby="disablePop"
                      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content text-center">
                          <div className="modal-header">
                            <h5>Alert Message</h5>
                          </div>
                          <div className="modal-body">
                            <p>Are you sure? You want to disable Asset?</p>
                            <button
                              type="button"
                              className="btn btn-primary mr10"
                            //   onClick={handleModalConfirm}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                            //   onClick={handleModalCancel}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default User;
