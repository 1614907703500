import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import ReactApexChart from "react-apexcharts";
import EnergyDeliveredSkeleton from "../Energy Delivered/EnergyDeliveredSkeleton";
import ResidualLifeSkelton from "./ResidualLifeSkelton";
import { useFormik } from "formik";
import * as Yup from "yup";
// import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";

function ResidualLife() {
  const chartRef = useRef(null);
  const [totalLossOfLifeInHour, setTotalLossOfLifeInHour] = useState("-");
  const [standardBalanceLifeInYear, setStandardBalanceLifeInYear] =
    useState("-");
  const [actualBalanceLifeInYear, setActualBalanceLifeInYear] = useState("-");
  const [chartData, setChartData] = useState([]);
  const [onloadStartDate, setOnloadStartDate] = useState("");
  const [onloadEndDate, setOnloadEndDate] = useState("");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  // URL params
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  // Header state
  const [header, setHeader] = useState({ location: "", asset: "" });
  // Context
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  // Data states
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  // Selected values
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  // Date range states
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(7, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initializeTokenData = () => {
      const tkData = Cookies.get("tkData");

      if (tkData) {
        const { token, userLogId } = JSON.parse(tkData);
        setToken(token);
        setUserLogId(userLogId);
      }
    };

    const initializeSolution = () => {
      if (solution === null) {
        setSolution(localStorage.getItem("solution"));
      }
    };

    initializeTokenData();
    initializeSolution();
  }, []);
  // after authentication and authorization it should load the respected account locations
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);
  // hooking the location change if previously we select the location then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);
  // hooking the asset change if previously we select the asset then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);
  useEffect(() => {
    if (
      onload === true &&
      token !== null &&
      token !== undefined &&
      token !== ""
    ) {
      const data = {
        // location: selectedLocation,
        // site: selectedAsset,
        location: onloadLoc,
        site: onloadAsset,
        iot_sol: "Transformer",
      };
      handleSubmitData(data);
    }
  }, [onload]);
  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
            // }
            // else if (response.status === 200 && response.data.success === false) {
            //   showFlashMessage(response.data.msg);
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };
  // ajax api for all assets depend upon location
  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer",
            }
          );

          setAssetData([]);

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));


            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };
  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];

        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset)
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  function hasDecimal(number) {
    // Compare the rounded version with the original number

    return number !== Math.floor(number);
  }
  function isPropertyPresent(obj, propertyPath) {
    if (!obj || typeof obj !== "object") {
      return "-";
    }

    const properties = propertyPath.split(".");
    let currentObject = obj;

    for (let prop of properties) {
      if (currentObject && currentObject.hasOwnProperty(prop)) {
        currentObject = currentObject[prop];
      } else {
        return "-";
      }
    }
    if (Number.isInteger(currentObject)) {
      return currentObject;
    } else if (typeof currentObject === "number") {
      return parseFloat(currentObject.toFixed(2));
    } else {
      return currentObject;
    }
  }

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    // setSelectedAsset({});
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };
  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };
  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };
  const handleSubmitData = (props) => {
    setTotalLossOfLifeInHour("-");
    setStandardBalanceLifeInYear("-");
    setActualBalanceLifeInYear("-");
    setChartData([]);
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      if (fromDate) {
        data.start_date = convertDateCloneApi(fromDate);
      }

      if (toDate) {
        data.end_date = convertDateCloneApi(toDate);
      }
      try {
        data = Object.assign(
          {},
          data
          // { sensor_parameters: selectedParameter.map((d) => d.value), }
        );
        getResidualLifeData(data);
        // getDatableData(data);
      } catch (error) { }
      // data = (isParam === false) ? Object.assign({}, data, { sensor_parameters: parameter }) : data;
      // alert("done");
    }
  };
  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }
  const fetchData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {


      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }
          if (setData === "setChartData") {
            setData(response.data);
          } else {
            if (response.data.start_date && response.data.end_date) {
              setOnloadStartDate(response.data.start_date);
              setOnloadEndDate(response.data.end_date);
            }
            else {
              setOnloadStartDate("");
              setOnloadEndDate("");
            }
            setData(response.data.data);
            success = true;
          }
        } else {
          setIsLoading(false);
          // showFlashMessage(`Data Not Available For ${errMSG}`);
        }
      } catch (error) {
        // alert(error);
        setIsLoading(false);
        // showFlashMessage(`Data Not Available For ${errMSG}`);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };
  const getResidualLifeData = async (props) => {
    const requestData = props;
    setIsLoading(true);
    if (token !== null && token !== undefined && token !== "") {
      await fetchData(
        "iotrms/residual_life/total_loss_of_life_in_hour",
        setTotalLossOfLifeInHour,
        requestData,
        "Total Loss Of Life (Hour)"
      );
      setIsLoading(false);
      await fetchData(
        "iotrms/residual_life/standard_balance_life_in_year",
        setStandardBalanceLifeInYear,
        requestData,
        "Standard Balance Life (Year)"
      );
      await fetchData(
        "iotrms/residual_life/actual_balance_life_in_year",
        setActualBalanceLifeInYear,
        requestData,
        "Actual Balance Life (Year)"
      );

      await fetchData(
        "iotrms/residual_life/loss_of_life_in_year",
        setChartData,
        requestData,
        "Loss Of Life (Hour)"
      );
    }
  };
  const getName = (data, title) => {
    return data ? data : title;
  };
  const getValue = (data) => {
    return data ? data.toFixed(1) : "-";
  };
  const chartDataArray = chartData || [];
  const chartFormatDate = (dateString) => {
    const dateParts = dateString.split('-');
    
    // Expecting dateString in format YYYY-MM-DD
    const [year, month, day] = dateParts;
  
    // Format the date in "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
  };
  const labels = chartDataArray.map((item) => chartFormatDate(item.created_at));

  const lossOfLifeHoursValues = chartDataArray.map((item) =>
    item.loss_of_life_hours.toFixed(1)
  );
  
  const chartDataMain = {
    options: {
        chart: {
            toolbar: {
                export: {
                    csv: {
                        filename: "Loss Of Life (Hour)",
                        headerCategory: 'Date',
                        categoryFormatter: (value) => value,
                    },
                    png: {
                        filename: "Loss Of Life (Hour)",
                    },
                    svg: {
                        filename: "Loss Of Life (Hour)",
                    }
                }
            },
            animations: {
                enabled: false
            },
            type: "bar",
            height: 350,
            zoom: {
                enabled: true,
            },
        },
        colors: ["#1B75BB"],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: labels,
            title: {
                // text: "Date",
                style: {
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                    fontWeight: "500",
                },
            },
            labels: {
                show: labels.length > 0,
                style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                    fontWeight: "500",
                },
                formatter: (value) => value,
            },
        },
        yaxis: {
            title: {
                text: "Loss of Life (Hour)",
                style: {
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                    fontWeight: "500",
                },
            },
            labels: {
                style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                    fontWeight: "500",
                },
            },
        },
        tooltip: {
            x: {
                format: "dd-MM-yyyy",
            },
            style: {
                fontSize: "14px",
                fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                fontWeight: "500",
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                style: {
                    fontFamily: "Montserrat, sans-serif", // Updated to Montserrat
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
        },
        grid: {
            padding: {
                bottom: 20, // Adjust bottom padding to ensure x-axis title is not cut off
            }
        }
    },
    series: [
        {
            name: "Loss of Life (Hour)",
            data: lossOfLifeHoursValues,
        },
    ],
};

  function formatDate(date) {
     if (!date) return ""; // Handle null/undefined
   
     // Convert dayjs object or string to Date
     const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);
   
     if (isNaN(parsedDate.getTime())) return ""; // Invalid date check
   
     const day = String(parsedDate.getDate()).padStart(2, "0");
     const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
     const year = parsedDate.getFullYear();
     const hours = String(parsedDate.getHours()).padStart(2, "0");
     const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
   
     return `${day}-${month}-${year} ${hours}:${minutes}`;
   }

  //to get lenght of alert 
  const findMaxMessageLength = () => {
    let maxLength = 0;
  
    // Iterate through alerts to find the maximum length
    alerts.forEach(alert => {
      const messageLength = alert.message.length;
      if (messageLength > maxLength) {
        maxLength = messageLength;
      }
    });
    return maxLength;
  };

  return (
    <>
      {isLoading && (
        <ResidualLifeSkelton />
      )}
     
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {onloadStartDate !== ""
                          ? onloadStartDate
                          : formatDate(fromDate)}
                        &nbsp;-&nbsp;
                        <a href="#">
                          {onloadEndDate !== ""
                            ? onloadEndDate
                            : formatDate(toDate)}
                        </a>
                      </a>
                    </li>
                  </ol>
                </div>
                <Sidebar
        locationData={locationData}
        assetData={assetData}
        // parameterData={parameterData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        // changeParameter={changeParameter}
        handleSubmitData={handleSubmitData}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        // selectedParameter={selectedParameter}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        // isParam={isParam}
        // changeIsParam={changeIsParam}
        // handleAddGroup={handleAddGroup}
        // groupData={groupData}
        // getGroupName={getGroupName}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                <div className="mdlContent card-body mt20">
         
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr text-center">
                          <h5>
                            {" "}
                            {getName(
                              totalLossOfLifeInHour["Name"],
                              "Total Loss Of Life (Hour)"
                            )}
                          </h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {getValue(totalLossOfLifeInHour["Value"])}
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr">
                          <h5>
                            {getName(
                              standardBalanceLifeInYear["Name"],
                              "Standard Balance Life (Year)"
                            )}
                          </h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {getValue(standardBalanceLifeInYear["Value"])}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card pr-card">
                        <div className="inrCardHdr">
                          <h5>
                            {getName(
                              actualBalanceLifeInYear["Name"],
                              "Actual Balance Life (Year)"
                            )}
                          </h5>
                        </div>
                        <div className="inrCardBody text-center">
                          <h3 className="mb0">
                            {getValue(actualBalanceLifeInYear["Value"])}
                          </h3>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="card pr-card">
                    <div className="inrCardHdr">
                      <h5>Loss Of Life (Hour)</h5>
                    </div>
                    <div className="inrCardBody">
                      <div className="card-body text-center mt20">
                        <ReactApexChart
                          options={chartDataMain.options}
                          series={chartDataMain.series}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     
    </>
  );
}

export default ResidualLife;
