import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ServiceReport() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [servReport, setServReport] = useState();

  const location = useLocation();
  const service_order_Id = location.state?.id;

  // getting Token
  useEffect(() => {
    const elementsToRemove = document.querySelectorAll(".sidebarRight");
    elementsToRemove.forEach((element) => {
      element.remove();
    });
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  //////// service order api /////////
  const getServiceReport = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/view_service_order_test_report`,
            {
              access_token: token,
              user_log_id: userLogId,
              service_order_Id: service_order_Id,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setServReport(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setServReport([]);
      showFlashMessage("Service Report Not Found");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getServiceReport();
    }
  }, [token]);

  // truncate to two decimal limt
  const truncateToTwoDigits = (value) => {
    if (!isNaN(value)) {
      return Math.trunc(value * 100) / 100;
    }
    return value;
  };

  const downloadPDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF
    const pageWidth = pdf.internal.pageSize.getWidth(); // Get page width
    const pageHeight = pdf.internal.pageSize.getHeight(); // Get page height

    // Capture the header
    const header = document.getElementById("header");
    const headerCanvas = await html2canvas(header, {
      scale: 2, // Increase scale for better quality
      useCORS: true, // Allow cross-origin images
      allowTaint: true, // Allow tainted images
      logging: true, // Enable logging for debugging
    });

    const headerImgData = headerCanvas.toDataURL("image/png");
    const headerHeight = (headerCanvas.height * pageWidth) / headerCanvas.width; // Calculate header height to fit page width

    // Capture the first table
    const firstTable = document.getElementById("first-table");
    const firstTableCanvas = await html2canvas(firstTable, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    });

    const firstTableImgData = firstTableCanvas.toDataURL("image/png");
    const firstTableHeight =
      (firstTableCanvas.height * pageWidth) / firstTableCanvas.width; // Calculate first table height to fit page width

    // Add the header and first table to the first page
    pdf.addImage(headerImgData, "PNG", 0, 0, pageWidth, headerHeight);
    pdf.addImage(
      firstTableImgData,
      "PNG",
      0,
      headerHeight,
      pageWidth,
      firstTableHeight
    );

    // Loop through each drum and capture its content
    for (let i = 0; i < servReport?.[0]?.drum_info.length; i++) {
      const drumElement = document.getElementById(`drum-${i + 1}`);

      // Capture the drum's content as an image
      const drumCanvas = await html2canvas(drumElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
      });

      const drumImgData = drumCanvas.toDataURL("image/png");
      const drumHeight = (drumCanvas.height * pageWidth) / drumCanvas.width; // Calculate drum height to fit page width

      // Add a new page for the drum's content
      pdf.addPage();

      // Add the header to the new page
      pdf.addImage(headerImgData, "PNG", 0, 0, pageWidth, headerHeight);

      // Add the drum's content below the header
      pdf.addImage(drumImgData, "PNG", 0, headerHeight, pageWidth, drumHeight);
    }

    // Save the PDF
    pdf.save("Service Report.pdf");
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="reportPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div id="content-to-download">
              <Row>
                <Col md={12} lg={12}>
                  <Card className="mdlContent pr-card mb30">
                    <Card.Header className="breadcrumbHdr">
                      <Row>
                        <Breadcrumb className="col-md-10">
                          <Breadcrumb.Item>
                            <Link to={`/Cable Service Order`}>
                              Service Order
                            </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item href="#">
                            {servReport?.[0]?.service_order_no}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                        <Col className="switchWpr col-md-2">
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={downloadPDF}
                          >
                            Download PDF
                          </button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body className="mdlContent">
                      <div className="">
                        <table
                          className="table table-bordered dtr-inline"
                          style={{ width: "100%" }}
                        >
                          <tbody>
                            <tr id="header">
                              <td colSpan="4" style={{ padding: "0" }}>
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td
                                      rowSpan="3"
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <img
                                      s  src="assets/images/motware_logo2.png"
                                        alt="oil test Logo"
                                        width="40px"
                                      />
                                    </td>
                                    <td>
                                      <h1 className="reportHead">
                                        {servReport?.[0]?.customerName}
                                      </h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {servReport?.[0]?.address} |{" "}
                                      {servReport?.[0]?.contact_no}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {servReport?.[0]?.service_order_no} |{" "}
                                      {servReport?.[0]?.purchase_order_no}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            <tr id="first-table">
                              <td style={{ padding: "0" }}>
                                <table
                                  className="mt20"
                                  style={{ width: "100%" }}
                                >
                                  <tr>
                                    <th>Type</th>
                                    <th>Total Drums</th>
                                    <th>Diameter (m)</th>
                                    <th>Total Length (m)</th>
                                    <th>Total Weight</th>
                                  </tr>
                                  <tr>
                                    <td>{servReport?.[0]?.conductor_type}</td>
                                    <td>
                                      {truncateToTwoDigits(
                                        servReport?.[0]?.number_of_drums
                                      )}
                                    </td>
                                    <td>
                                      {truncateToTwoDigits(
                                        servReport?.[0]?.conductor_diameter_mm
                                      )}
                                    </td>
                                    <td>
                                      {truncateToTwoDigits(
                                        servReport?.[0]?.conductor_length_m
                                      )}
                                    </td>
                                    <td>
                                      {truncateToTwoDigits(
                                        servReport?.[0]?.total_drum_weight
                                      )}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                            </table>

                            {/* Dynamically render drum info */}
                            {servReport?.[0]?.drum_info.map((drum, index) => (
                              <React.Fragment key={index}>
                              {/* <table style={{width:"100%"}}> */}
                              <table
                          className="table table-bordered dtr-inline"
                          style={{ width: "100%" }}
                          id={`drum-${index + 1}`} 
                        >
                          <tbody>
                                  <tr >
                                    <td style={{ padding: "0" }}>
                                      <div className="row infoTbl mb20">
                                        <div className="col-md-6">
                                          <table
                                            className="mt20"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  colSpan={2}
                                                  style={{
                                                    textAlign: "center",
                                                    background: "#e0e4e9",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {drum.drum_serial_number}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Conductor Type</th>
                                                <td>{drum.conductor_type}</td>
                                              </tr>
                                              <tr>
                                                <th>Conductor Diameter (m)</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.conductor_diameter_mm
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Conductor Length (m)</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.drum_length_m
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Weight (kg)</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.drum_weight
                                                  )}{" "}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="col-md-6">
                                          <table
                                            className="mt20"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  colSpan={2}
                                                  style={{
                                                    textAlign: "center",
                                                    background: "#e0e4e9",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Test Info
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Total Test</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.total_test
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Pass Test</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.pass_count
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Fail Test</th>
                                                <td>
                                                  {truncateToTwoDigits(
                                                    drum.fail_count
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Line Number</th>
                                                <td>{drum.line_number}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {/* Dynamically render test data */}
                                  {drum.test_data.map((test, testIndex) => (
                                    <React.Fragment key={testIndex}>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "10px",
                                          }}
                                        >
                                          <h5>
                                            {testIndex + 1}. {test.test_type}{" "}
                                            <span
                                              className={
                                                test.test_data.test_result ===
                                                "Pass"
                                                  ? "passTest"
                                                  : "failTest"
                                              }
                                            >
                                              {test.test_data.test_result}
                                            </span>
                                          </h5>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "0" }}>
                                          <table
                                            className="mb20"
                                            style={{ width: "100%" }}
                                          >
                                            {/* Dynamically generate table headers */}
                                            <thead>
                                              <tr>
                                                {Object.keys(test.test_data)
                                                  .filter(
                                                    (key) =>
                                                      key !== "test_result" &&
                                                      key !== "test_remark"
                                                  )
                                                  .map((key) => (
                                                    <th key={key}>{key}</th>
                                                  ))}
                                              </tr>
                                            </thead>
                                            {/* Dynamically generate table rows */}
                                            <tbody>
                                              <tr>
                                                {Object.entries(test.test_data)
                                                  .filter(
                                                    ([key]) =>
                                                      key !== "test_result" &&
                                                      key !== "test_remark"
                                                  )
                                                  .map(
                                                    ([key, value], index) => (
                                                      <td key={index}>
                                                        {!isNaN(value)
                                                          ? truncateToTwoDigits(
                                                              value
                                                            )
                                                          : value}
                                                      </td>
                                                    )
                                                  )}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "10px",
                                          }}
                                        >
                                          <h6>Remark:</h6>
                                          <ol>
                                            {test.test_data.test_remark ? (
                                              <li>
                                                {test.test_data.test_remark}
                                              </li>
                                            ) : (
                                              <li>-</li>
                                            )}
                                          </ol>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                        </table>
                                {/* </table> */}
                              </React.Fragment>
                            ))}
                          
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ServiceReport;
