/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./MainContent.css";

const Table = ({ data, fetchTableData, fetchCriticalAssetData, statusFilter, setStatusFilter }) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [criticalChecked, setCriticalChecked] = useState(true);
  // const [statusFilter, setStatusFilter] = useState("All"); // Ensure statusFilter state


  // Preprocess the data before applying filtering
  useEffect(() => {
    fetchTableData();
  }, []);
  const preprocessData = (data) => {
    return data.map((row) => {
      const processedRow = row.map((item, index) => {
        // Convert date strings to the desired format
        if ((index === 0 || index === 6) && typeof item === "string") {
          return formatDateTime(item);
        }
        return item;
      });
      return processedRow;
    });
  };

  useEffect(() => {
    const processedData = preprocessData(data);
    const filteredResult = processedData.filter((row) => {
      const rowValues = row.map((item) =>
        typeof item === "string" ? item.toLowerCase() : item.toString()
      );

      const searchTerm = search.toLowerCase();

      const statusMatch = statusFilter === "All" || row[5].toLowerCase() === statusFilter.toLowerCase();
      return rowValues.some((value) => value.includes(searchTerm)) && statusMatch;

    });
    if (statusFilter === "Active") {
      setAllChecked(false);
      setCriticalChecked(false)
    } else if (statusFilter === "Inactive") {
      setAllChecked(false);
      setCriticalChecked(false)
    } else {
    }
    setFilteredData(filteredResult);
  }, [data, search, statusFilter]);

  const handleAllChange = () => {
    setStatusFilter("All");
    setAllChecked(true);
    setCriticalChecked(false);
    fetchTableData();
  };

  const handleCriticalChange = () => {
    const newCriticalChecked = !criticalChecked;
    setCriticalChecked(newCriticalChecked);
    setAllChecked(!newCriticalChecked);
    if (newCriticalChecked) {
      fetchCriticalAssetData()
      setStatusFilter("All");
    } else {
      fetchTableData();
    }
  };
  // for date formating
  function formatDateTime(dateTimeString) {

    if (typeof dateTimeString === 'string') {
      const data = new Date(new Date(dateTimeString).toISOString());
      const result = `${String(data.getUTCDate()).padStart(2, '0')}-${String(data.getUTCMonth() + 1).padStart(2, '0')}-${data.getUTCFullYear()} ${String(data.getUTCHours()).padStart(2, '0')}:${String(data.getUTCMinutes()).padStart(2, '0')}:${String(data.getUTCSeconds()).padStart(2, '0')}`;

      // const result =
      return result//dateTime.toLocaleString("en-IN", options);
    }
    else {
      console("Invalid", dateTimeString)
      return dateTimeString;
    }
  }


  function removeSlash(dateTimeString) {
    const [day, month, year] = dateTimeString.split('/');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const truncateToTwoDigits = (value) => {
    if (value !== undefined && value !== "") {
      const truncatedValue = parseFloat(Number(value).toFixed(2));
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "-";
  };
  const getStatusColorClass = (status) => {
    if (typeof status === "string") {
      switch (status.toLowerCase()) {
        case "active":
          return "active";
        case "inactive":
          return "inactive";
        default:
          return "";
      }
    }
    return "";
  };
  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  const columns = [
    { name: "Last Updated",
      selector: (row) => parseDateString(row[0]),
     sortable: true, cell: row => <div className="custom-cell">{row[0]}</div>, },
    { name: "Location", selector: (row) => row[1] || "-", sortable: true, cell: row => <div className="custom-cell">{row[1] || "-"}</div> },
    { name: "Asset", selector: (row) => row[2] || "-", sortable: true, cell: row => <div className="custom-cell">{row[2] || "-"}</div> },
    { name: "Avg. Current", selector: (row) => row[3] , sortable: true, cell: row => <div className="custom-cell">{truncateToTwoDigits(row[3])}</div> },
    { name: "Avg. Voltage", selector: (row) => row[4] , sortable: true, cell: row => <div className="custom-cell">{truncateToTwoDigits(row[4]) }</div> },
    { name: "Status", selector: (row) => row[5] || "-", sortable: true, cell: row => <div className={`custom-cell ${getStatusColorClass(row[5])}`}>{row[5] || "-"}</div> },
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const customHeadings = columns.map((column) => column.name);

    const filteredDataExcel = filteredData.map((row) => {
      row = Object.values(row);
      const filteredItem = {};
      row.forEach((item, index) => {
        const heading = customHeadings[index];
        filteredItem[heading] = item;
      });

      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "SummaryData" + fileExtension);
  };

  // for adding all option inside pagination
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  // no data available columns
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        whiteSpace: 'normal',// Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: 'normal',
      },
    }
  };

  return (
    <div className="card pr-card mb30 tableCard">
      <div className="card-header">
        <div className="row align-items-center">
          {/* <div className="col-md-6 col-lg-6 col-xl-6">
            <div className="dataTables_length" id=""></div>
          </div> */}
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="form-inline dataTables_filter">
              <div className="input-group pr-form-group mr10" >
                <label>
                  <input
                    type="checkbox"
                    checked={allChecked}
                    onChange={handleAllChange}
                  />
                  All
                </label>
              </div>
              <div className="input-group pr-form-group mr10" >
                <label>
                  <input
                    type="checkbox"
                    checked={criticalChecked}
                    onChange={handleCriticalChange}
                  />
                  Critical
                </label>
                <div>

                </div>

              </div>
              <div className="mr10">
                <label>
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>

              <div className="input-group pr-form-group" >

              </div>

              <div className="input-group pr-form-group" id="">

                <a>
                  {Object.keys(filteredData).length === 0 ? (
                    <a className="" alt="Download XLS" >
                      <i className="fa fa-file-excel-o" ></i>
                    </a>

                  ) : (
                    <a className="" alt="Download XLS" onClick={exportToCSV}>
                      <i className="fa fa-file-excel-o" ></i>
                    </a>

                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            // className="table table-hover dtr-inline"
            fixedHeader
            // fixedHeaderScrollHeight="450px"
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataComponent />}
            customStyles={customStyles}
          // customStyles={{
          //   headCells: {
          //     style: {
          //       overflowWrap: "break-word",
          //       backgroundColor: '#FFA500'
          //     },
          //   },
          // }}
          />
        </div>
        <div className="row mt20"></div>
      </div>
    </div>
  );
};

export default Table;
