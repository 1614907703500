/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Chart from "react-apexcharts";

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from "moment";
import LineChart from "./LineChart";
// import Table from './Table';
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import DemadGraphSkeleton from "./DemadGraphSkeleton";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
// import { Alert } from "react-bootstrap";

function DemandGraph() {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pielabels, setPielabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [selectedParameter, setSelectedParameter] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [onload, setOnLoad] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [isParam, setIsParam] = useState(false);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOption, setChartOption] = useState({
    colors: [
      "#2A73B9",
      "#8EC5EC",
      "#F15A2A",
      "#F7941E",
      "#39B54A",
      "#D7DF24",
      "#92278F",
      "#EC008D",
      "#FF0000",
      " #FF33CC",
    ],
    chart: {
      toolbar: {
        export: {
          csv: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 15 Minutes",
            headerCategory: 'Date Time',
            categoryFormatter: function (timestamp) {
              let date = new Date(timestamp).toUTCString();
              const originalDate = new Date(date);

              // Add 5 hours and 30 minutes to the original date
              originalDate.setHours(originalDate.getHours() - 5);
              originalDate.setMinutes(originalDate.getMinutes() - 30);

              // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
              const formattedDate =
                ('0' + originalDate.getDate()).slice(-2) + '-' +
                ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
                (originalDate.getFullYear()) + ' ' +
                ('0' + originalDate.getHours()).slice(-2) + ':' +
                ('0' + originalDate.getMinutes()).slice(-2) + ':' +
                ('0' + originalDate.getSeconds()).slice(-2);


              return formattedDate;
            },
          },
          png: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 15 Minutes",
          },
          svg: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 15 Minutes",
          },
        }
      },
      animations: {
        enabled: false
      },


      height: "350px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      style: {
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: '500',

      },
      // fontSize: '14px'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray: 0,
      lineCap: "round",
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      title: {
        text: "Values", // Set your Y-axis title here
        style: {
          fontFamily: 'Montserrat, sans-serif',  // Set font family for Y-axis title
          fontSize: '14px',  // Set font size for Y-axis title
          fontWeight: 500,
        }
      },
      labels: {
        // Use a formatter function to format y-axis labels as integers
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        },
        formatter: function (value) {
          return value; // Convert to integers
        },
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Datetime", // Set your X-axis title here
        style: {
          fontFamily: 'Montserrat',  // Set font family for Y-axis title
          fontSize: '14px',  // Set font size for Y-axis title
          fontWeight: 500,
        }
      },

      labels: {
        // datetimeFormatter: {
        //   day: 'dd-MM-yyyy',
        //   month: "MM-yyyy",
        //   year: 'yyyy',
        //   hour: 'dd-MM-yyyy HH:mm:ss'
        // },
        style: {
          fontFamily: 'Montserrat',  // Set font family for Y-axis title
          fontSize: '14px',  // Set font size for Y-axis title
          fontWeight: 500,
        }
      },
    },
    tooltip: {
      x: {
        format: 'dd-MM-yyyy HH:mm:ss'
      },
      cssClass: 'custom-tooltip',
      style: {
        fontFamily: 'Montserrat, sans-serif',  // Set font family for tooltip
        fontSize: '14px',  // Set font size for tooltip

      }
    },
    title: {
      text: " ",
      align: "left",
    },
  });

  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const [searchText, setSearchText] = useState("");
  const [filterTableData, setFilterTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);

  const [fiftyMinutesData, setFiftyMinutesData] = useState([]);
  const [thirtyMinutesData, setThirtyMinutesData] = useState([]);
  const [loadPercentData, setLoadPercentData] = useState([]);

  const chartOption1 = {
    colors: [
      "#2A73B9",
      "#8EC5EC",
      "#F15A2A",
      "#F7941E",
      "#39B54A",
      "#D7DF24",
      "#92278F",
      "#EC008D",
      "#FF0000",
      " #FF33CC",
    ],
    chart: {
      toolbar: {
        export: {
          csv: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 30 Minutes",
            headerCategory: 'Date Time',
            categoryFormatter: function (timestamp) {
              let date = new Date(timestamp).toUTCString();
              const originalDate = new Date(date);

              // Add 5 hours and 30 minutes to the original date
              originalDate.setHours(originalDate.getHours() - 5);
              originalDate.setMinutes(originalDate.getMinutes() - 30);

              // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
              const formattedDate =
                ('0' + originalDate.getDate()).slice(-2) + '-' +
                ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
                (originalDate.getFullYear()) + ' ' +
                ('0' + originalDate.getHours()).slice(-2) + ':' +
                ('0' + originalDate.getMinutes()).slice(-2) + ':' +
                ('0' + originalDate.getSeconds()).slice(-2);
              return formattedDate;
            },
          },
          png: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 30 Minutes",
          },
          svg: {
            filename: "Average of LV Total Apparent Power ( kVA ) Time Interval 30 Minutes",
          },
        }
      },
      animations: {
        enabled: false,
      },
      height: "350px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      fontFamily: 'Montserrat, sans-serif',  // Set font family for legend
      fontSize: '14px',  // Set font size for legend
      fontWeight: 500,
      // fontSize: '14px'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray: 0,
      lineCap: "round",
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      title: {
        text: "Values", // Set your Y-axis title here
        style: {
          fontFamily: 'Montserrat, sans-serif',  // Set font family for Y-axis title
          fontSize: '14px',  // Set font size for Y-axis title
          fontWeight: 500,
        }
      },
      labels: {
        // Use a formatter function to format y-axis labels as integers
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        },
        formatter: function (value) {
          return value; // Convert to integers
        },
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Datetime", // Set your X-axis title here
        style: {
          fontFamily: 'Montserrat, sans-serif',  // Set font family for Y-axis title
          fontSize: '14px',  // Set font size for Y-axis title
          fontWeight: 500,
        }
      },

      labels: {
        // datetimeFormatter: {
        //   day: 'dd-MM-yyyy',
        //   month: "MM-yyyy",
        //   year: 'yyyy',
        //   hour: 'dd-MM-yyyy HH:mm:ss'
        // },
        style: {
          fontFamily: 'Montserrat, sans-serif',  // Set font family for Y-axis labels
          fontSize: '14px',  // Set font size for Y-axis labels
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd-MM-yyyy HH:mm:ss'
      },
      cssClass: 'custom-tooltip',
      style: {
        fontFamily: 'Montserrat, sans-serif',  // Set font family for tooltip
        fontSize: '14px',  // Set font size for tooltip

      }
    },
    title: {
      text: " ",
      align: "left",
    },
  }

  const chartOption2 = {
    colors: [
      "#2A73B9",
      "#8EC5EC",
      "#F15A2A",
      "#F7941E",
      "#39B54A",
      "#D7DF24",
      "#92278F",
      "#EC008D",
      "#FF0000",
      " #FF33CC",
    ],
    chart: {
      toolbar: {
        export: {
          csv: {
            filename: "Average of Load % 30 Minutes",
            headerCategory: 'Date Time',
            categoryFormatter: function (timestamp) {
              let date = new Date(timestamp).toUTCString();
              const originalDate = new Date(date);

              // Add 5 hours and 30 minutes to the original date
              originalDate.setHours(originalDate.getHours() - 5);
              originalDate.setMinutes(originalDate.getMinutes() - 30);

              // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
              const formattedDate =
                ('0' + originalDate.getDate()).slice(-2) + '-' +
                ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
                (originalDate.getFullYear()) + ' ' +
                ('0' + originalDate.getHours()).slice(-2) + ':' +
                ('0' + originalDate.getMinutes()).slice(-2) + ':' +
                ('0' + originalDate.getSeconds()).slice(-2);
              return formattedDate;
            },
          },
          png: {
            filename: "Average of Load % 30 Minutes",
          },
          svg: {
            filename: "Average of Load % 30 Minutes",
          },
        }
      },
      animations: {
        enabled: false,
      },
      height: "350px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    responsive: [
      { breakpoint: 1367, options: { chart: { height: 270 } } },
    ],
    legend: {
      position: "top",
      horizontalAlign: "center",
      style: {
        fontFamily: 'Montserrat',  // Set font family for X-axis title
        fontSize: '14px',  // Set font size for X-axis title
        fontWeight: 500,
      }
      // fontSize: '14px'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray: 0,
      lineCap: "round",
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      title: {
        text: "Values", // Set your Y-axis title here
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        }
      },
      labels: {
        // Use a formatter function to format y-axis labels as integers
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        },
        formatter: function (value) {
          return value; // Convert to integers
        },
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Datetime", // Set your X-axis title here
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        }
      },

      labels: {
        // datetimeFormatter: {
        //   day: 'dd-MM-yyyy',
        //   month: "MM-yyyy",
        //   year: 'yyyy',
        //   hour: 'dd-MM-yyyy HH:mm:ss'
        // },
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500,
        }
      },
    },
    tooltip: {
      x: {
        format: 'dd-MM-yyyy HH:mm:ss'
      },
      cssClass: 'custom-tooltip',
      style: {
        fontFamily: 'Montserrat, sans-serif',  // Set font family for tooltip
        fontSize: '14px',  // Set font size for tooltip

      }
    },
    title: {
      text: " ",
      align: "left",
    },
  }



  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageParameter();
    }
  }, [parameterData]);

  useEffect(() => {
    if (
      onload === true &&
      token !== null &&
      token !== undefined &&
      token !== ""
    ) {
      let data = {
        location: onloadLoc,
        site: onloadAsset,
        // location: selectedLocation,
        // site: selectedAsset,
        iot_sol: "Transformer",
      };
      handleSubmitData(data);
      // onLoadHandleSubmitData(data);
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
    // alert(solution_id)
    if (solution === null) {
      setSolution(localStorage.getItem("solution"));
      // update_solution(localStorage.getItem("solution"));
    }
  }, []);


  useEffect(() => {
    searchTable();
  }, [searchText]);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        setOnloadLoc(new_location)
        // setSelectedLocation(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];

        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset)
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageParameter = () => {
    if (param_asset && param_location) {
      setSelectedParameter([parameterData[0]]);
      //   setOnLoad(true);
      return;
    } else if (
      localStorage.getItem("parameter") !== undefined &&
      localStorage.getItem("parameter") !== "undefined" &&
      localStorage.getItem("parameter") !== "null" &&
      localStorage.getItem("parameter") !== null
    ) {
      let parameter = localStorage.getItem("parameter");
      parameter = JSON.parse(parameter);
      const flag = parameter.every((item) => {
        return parameterData.some((arr1Item) => arr1Item.value === item.value);
      });

      if (flag && isParam === false) {
        setSelectedParameter(parameter);
        // setOnLoad(true);
      }
    } else {
      if (parameterData.length > 0) {
        const new_parameter = parameterData[0];
        setSelectedParameter([new_parameter]);
        // setOnLoad(true);
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          }
          else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };


  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? '-' : truncatedValue;
    }
    return '';
  };

  const formatChartData = (data, seriesName) => {
    return {
      name: seriesName,
      data: data.map(([timestamp, value]) => ({
        x: new Date(timestamp),
        y: typeof value === "number" ? truncateToOneDigits(value) : null,
      })),
    };
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }



  // const [chartOptions, setChartOptions] = useState({}); // Define chart options state
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();

  const getGraphData = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      setIsLoading(true);
      const data = props;
      if (data.start_date && data.end_date) {
        setNewEndDate(breadcrum_date_converter(data.end_date));
        setNewStartDate(breadcrum_date_converter(data.start_date));
      }
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/demand_graph`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            if (response.data.start_date && response.data.end_date) {
              setNewStartDate(response.data.start_date);
              setNewEndDate(response.data.end_date);
            }

            // setHeader({
            //   location: selectedLocation.label,
            //   title: selectedAsset.label,
            // });
            const {
              lv_total_apparent_power_fifteen_minutes,
              lv_total_apparent_power_thirty_minutes,
              load_percent_thirty_minutes,
            } = response.data.data;
            setIsLoading(false);
            // Set formatted data to state
            setFiftyMinutesData(
              formatChartData(
                lv_total_apparent_power_fifteen_minutes,
                "LV Total Apparent Power (kVA)"
              )
            );

            setThirtyMinutesData(
              formatChartData(
                lv_total_apparent_power_thirty_minutes,
                "LV Total Apparent Power (kVA)."
              )
            );

            setLoadPercentData(
              formatChartData(load_percent_thirty_minutes, "Load Percent")
            );
            // showFlashMessage("Data F");
            success = true;
          } else {
            setFiftyMinutesData(
              formatChartData(
                [],
                "LV Total Apparent Power (kVA)"
              )
            );
            setThirtyMinutesData(
              formatChartData(
                [],
                "LV Total Apparent Power (kVA)."
              )
            );

            setLoadPercentData(
              formatChartData([], "Load Percent")
            );
            setIsLoading(false); // Corrected here
            // showFlashMessage("Data Not Available");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }

    if (!success) {
      setFiftyMinutesData(
        formatChartData(
          [],
          "LV Total Apparent Power (kVA)"
        )
      );
      setThirtyMinutesData(
        formatChartData(
          [],
          "LV Total Apparent Power (kVA)."
        )
      );

      setLoadPercentData(
        formatChartData([], "Load Percent")
      );
      setIsLoading(false);
      showFlashMessage("Data Not Available");
    }
  };

  const searchTable = () => {
    if (searchText === undefined || searchText === null || searchText === "" || !searchText) {
      setFilterTableData(tableData);
    } else if (tableData && tableData.data) {
      const filteredResult = tableData.data.filter((row) => {
        row = Object.values(row);
        const rowValues = row.map((item) =>
          typeof item === "string" ? item.toLowerCase() : item.toString()
        );

        const searchTerm = searchText.toLowerCase();

        return rowValues.some((value) => value.includes(searchTerm));
      });

      setFilterTableData((prevOptions) => ({
        ...prevOptions,
        data: filteredResult,
      }));
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const handleSubmitData = async (props) => {
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    setSelectedLocation(location);
    setSelectedAsset(site);

    setSelectedParameter(parameter);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {

      let loadDataRequest = {
        access_token: token,
        user_log_id: userLogId,
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };

      getGraphData(loadDataRequest)

    }
  };

  const SubmitDataWithDate = (props) => {
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setSelectedParameter(parameter);

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      getGraphData(data);
    }
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // const png = (pngId) => {
  //   const id = `#${pngId}`;
  //   const svg = document.querySelector(`${id} svg`);
  //   svg.style.backgroundColor = "white";

  //   // create a temporary canvas element
  //   const canvas = document.createElement("canvas");
  //   // set the canvas dimensions to match the chart dimensions
  //   canvas.width = svg.width.baseVal.value;
  //   canvas.height = svg.height.baseVal.value;
  //   canvas.backgroundColor = svg.style.backgroundColor;
  //   // get the canvas context
  //   const context = canvas.getContext("2d");
  //   // create a new Image object with the SVG data
  //   const image = new Image();
  //   // wait for the Image to load
  //   image.onload = function () {
  //     // draw the Image onto the canvas
  //     context.drawImage(image, 0, 0);
  //     // convert the canvas to a data URL
  //     const dataURL = canvas.toDataURL("image/jpeg", 2.0);
  //     // create a temporary link element
  //     const link = document.createElement("a");
  //     link.download = "chart.png";
  //     link.href = dataURL;
  //     // trigger a click event on the link element to initiate the download
  //     link.click();
  //     // cleanup the link element
  //     link.remove();
  //   };

  //   image.src =
  //     "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg.outerHTML);
  // };


  function formatDate(date) {
    if (!date) return ""; // Handle null/undefined
  
    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);
  
    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check
  
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }


  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
    // setNewStartDate(formatDate(newFromDate));
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
    // setNewEndDate(formatDate(newToDate));
  };
  return (
    <>
      {isLoading && (
        <DemadGraphSkeleton />
      )}

      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="wrapper">
         <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30">
                    <div className="breadcrumbHdr card-header">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#">{selectedAsset.label}</a>
                        </li>
                        <li className="breadcrumb-item ">
                          <a href="#">{selectedLocation.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">
                            {" "}
                            {newStartDate ? (
                              <a href="#">{newStartDate}</a>
                            ) : (
                              <a href="#">{formatDate(fromDate)}</a>
                            )}
                            &nbsp;-&nbsp;
                            {newEndDate ? (
                              <a href="#">{newEndDate}</a>
                            ) : (
                              <a href="#">{formatDate(toDate)}</a>
                            )}
                          </a>
                        </li>

                      
                      </ol>
                    </div>
                    <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                    <div className="mdlContent card-body"> 
                    
   
                    
                
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>
                            Average of LV Total Apparent Power (kVA) (Time interval:
                            15 mins)
                          </h5>
                        </div>
                        <div className="inrCardBody">
                          <LineChart
                            chartSeries={fiftyMinutesData}
                            chartOption={chartOption}
                          />
                         </div>
                      </div>
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>
                            Average of LV Total Apparent Power (kVA) (Time interval:
                            30 mins)
                          </h5>
                        </div>
                        <div className="inrCardBody">
                           <LineChart
                            chartSeries={thirtyMinutesData}
                            chartOption={chartOption1}
                          />
                        </div>
                      </div>
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Average of Load % (Time interval: 30 mins)</h5>
                        </div>
                        <div className="inrCardBody">
                           <LineChart
                            chartSeries={loadPercentData}
                            chartOption={chartOption2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     
    </>
  );
}

export default DemandGraph;
