/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { CardHeader, ToggleButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Select from "react-select";

const AssetAllocation = () => {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [locAssetData, setLocAssetData] = useState([]);
  const [selectedLocAsset, setSelectedLocAsset] = useState([]);
   const [usernameRole, setnameUserRole] = useState("");

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      const name = tokenData.name;
      const role = Cookies.get("role");
      setUserName(role);
      setnameUserRole(`${name} | ${role}`);
    }
  }, []);

  ////// message //////
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  ///// select users api ////
  const getAllocationsUsers = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/asset_allocation_users`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setUsersData(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("No Data Available For Users");
      setIsLoading(false);
    }
  };

  // Convert usersData to react-select format
  const userOptions = usersData.map((item) => ({
    value: item._id,
    label: item.user,
  }));

  // Add 'All' option to the start of the options list
  const allOption = { value: "all", label: "All" };
  const options = [allOption, ...userOptions];

  // Handle user selection
  const handleSelectChange = (selectedOption) => {
    if (selectedOption.some((option) => option.value === "all")) {
      // If 'All' is selected, select all users
      setSelectedUser(userOptions);
    } else {
      // Otherwise, update selected users based on user selection
      setSelectedUser(selectedOption);
    }
  };

  ////// select location | asset /////////
  const getAllocationAsset = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/asset_allocation_locationAndAssets`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setLocAssetData(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("No Data Available For Assets");
      setIsLoading(false);
    }
  };

  const locAssetOptions = locAssetData.map((item) => ({
    value: item._id,
    label: item.asset,
  }));

  const allLocAssetOption = { value: "all", label: "All" };
  const locAssetAllOptions = [allLocAssetOption, ...locAssetOptions];

  const handleLocChange = (selectedOption) => {
    if (selectedOption.some((option) => option.value === "all")) {
      setSelectedLocAsset(locAssetOptions);
    } else {
      setSelectedLocAsset(selectedOption);
    }
  };

  ///// asset allocation submit ////// 
  const submitAssetAlloc = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

  // Collect selected user and asset IDs
  const userIds = selectedUser.map((user) => user.value);
  const assetIds = selectedLocAsset.map((asset) => asset.value);

  const payload = {
    access_token: token,
    userId: userIds,
    assetId: assetIds,
    user_log_id: userLogId,
  };

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/asset_allocation_submit`,
            payload
          );
          if (response.status === 200 && response.data.success === true) {
            setSelectedUser([]);
            setSelectedLocAsset([]);
            getAssetAllocations()
            success = true;
            setIsLoading(false);
            showFlashMessage("Asset allocation submitted successfully!", "success");
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Not Able to allocat asset","danger");
      setIsLoading(false);
    }
  };

  ///// table api ////
  const getAssetAllocations = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/get_all_asset_allocations`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setAssetData(data);
            success = true;
            setIsLoading(false);
          } else {
            setAssetData([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("No Data Available For Assets");
      setIsLoading(false);
    }
  };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",  
    textOverflow: "ellipsis",
    // maxWidth: "150px",    
  };

  const columns = [
    {
    name: "User",
    selector: (row) => row.user,
    sortable: true,
    cell: (row) => (<div title={row.user} style={ellipsisStyle}>{row.user}</div>),
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      cell: (row) => (<div title={row.category} style={ellipsisStyle}>{row.category}</div>),
    },
    {
      name: "Subaccount",
      selector: (row) => row.subaccount,
      sortable: true,
      cell: (row) => <div title={row.subaccount} style={ellipsisStyle}>{row.subaccount}</div>,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      cell: (row) => <div title={row.location} style={ellipsisStyle}>{row.location}</div>,
    },
    {
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
      cell: (row) => <div title={row.department} style={ellipsisStyle}>{row.department}</div>,
    },
    {
      name: "Asset",
      selector: (row) => row.asset,
      sortable: true,
      cell: (row) => <div title={row.asset} style={ellipsisStyle}>{row.asset}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <Form className="statusTgl">
          <Form.Group className="switch">
            <Form.Label className="switch-light">
              <Form.Control
                type="checkbox"
                checked={row.status === "enable"} // Dynamically set status
                onChange={() => {
                  if (row.status === "enable") {
                    // Open confirmation modal before disabling
                    setSelectedRow(row);
                    setIsModalVisible(true);
                  }
                }}
              />
              <span id="toggleParamColor">
                <span>Enable</span> {/* Enable on the left */}
                <span>Disable</span> {/* Disable on the right */}
              </span>
              <a className="btn btn-primary"></a>
            </Form.Label>
          </Form.Group>
        </Form>
      ),
    },
  ];

  /////// search /////////
  const filteredData = assetData.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  ///// excel config //////
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const filteredDataExcel = assetData.map((row) => {
      const dataRow = {
        User: row.user || "-",
        Category: row.category || "-",
        Subaccount: row.subaccount || "-",
        Location: row.location || "-",
        Department: row.department || "-",
        asset: row.asset || "-",
        Status: row.status || "-",
      };
      return dataRow;
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "Asset Allocation Data" + fileExtension);
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getAssetAllocations();
      getAllocationsUsers();
      getAllocationAsset();
    }
  }, [token]);

  /////// Status ///////
  const handleModalConfirm = () => {
    if (selectedRow) {
      // Call handleUserUpdate to handle the status change
      handleUserUpdate(selectedRow, "status", "disable");
    }
    setIsModalVisible(false);
    setSelectedRow(null);
  };
  
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleUserUpdate = async (row, field, value) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const payload = {
            access_token: token,
            user_log_id: userLogId,
            assetAllocationId: row._id || "",
            status: field === "status" ? value : row.status,
          };
          const response = await axios.patch(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/update_asset_allocation`,
            payload
          );
          if (response.status === 200 && response.data.success) {
            showFlashMessage("Status Updated Successfully", "success");
            success = true;
            setIsLoading(false);
            getAssetAllocations();
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Not Able To Update Status");
      setIsLoading(false);
    }
  };

  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Asset Allocation</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}

      {/* active/inactive button  */}
      {isModalVisible && (
        <div
          className="inactiveModal modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5>Status</h5>
              </div>
              <div className="modal-body">
                <p>Please confirm if you want to {selectedRow?.status === "enable"
                      ? "Disable"
                      : "Enable"} <>{selectedRow?.asset}</> for a {selectedRow?.user}</p>
                <button
                  type="button"
                  className="btn btn-primary mr10"
                  onClick={handleModalConfirm}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleModalCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="historicalPage content-wrapper">
        <section className="content">
          <Container fluid>
            <Row>
              <div className="col-md-12 col-lg-12">
                <div className="historicalpg mdlContent card pr-card mb30">
                <CardHeader className="breadcrumbHdr">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">My Account</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Asset Allocation</Breadcrumb.Item>
                    <p className="float-right">{usernameRole}</p>
                  </Breadcrumb>
                  </CardHeader>
                  <Card.Body className="mdlContent">
                    <Card style={{}} className="assetalloForm">
                      <Card.Body>
                        <Row className="mt10">
                          <div className="col-md-4 col-lg-4">
                            <Form.Label >User | Role</Form.Label>
                            <Select
                              isMulti={true}
                              // closeMenuOnSelect={false}
                              options={options}
                              value={selectedUser}
                              onChange={handleSelectChange}
                              placeholder="Search"
                              isSearchable={true}
                              styles={{
                                menuList: (base) => ({
                                ...base,
                                maxHeight: "150px", // Limit dropdown height
                                overflowY: "auto",  // Enable scrolling
                                }),
                              }}
                            />
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>
                                Location | Asset
                              </Form.Label>
                              <Select
                                isMulti={true}
                                // closeMenuOnSelect={false}
                                options={locAssetAllOptions}
                                value={selectedLocAsset}
                                onChange={handleLocChange}
                                placeholder="Search"
                                isSearchable={true}
                                styles={{
                                menuList: (base) => ({
                                ...base,
                                maxHeight: "150px", // Limit dropdown height
                                overflowY: "auto",  // Enable scrolling
                                }),
                                }}
                              />
                            </Form.Group>
                            <Button
                              onClick={submitAssetAlloc}
                              type="submit"
                              className="btn btn-primary mt5"
                            >
                              Submit
                            </Button>
                          </div>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card style={{}} className="assetAloTbl">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="form-inline dataTables_filter">
                        <label className="mr10">
                          <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </label>
                        <div className="input-group pr-form-group" >
                        <a>
                          {Object.keys(assetData).length === 0 ? (
                            <a className="" alt="Download XLS">
                              <i className="fa fa-file-excel-o"></i>
                            </a>
                          ) : (
                            <a
                              className=""
                              alt="Download XLS"
                              onClick={exportToCSV}
                            >
                              <i className="fa fa-file-excel-o"></i>
                            </a>
                            )}
                          </a>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                      <Card.Body>
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}
                          pagination
                          highlightOnHover
                          responsive
                        />
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default AssetAllocation;
