/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import Cookies from "js-cookie"; // Import the js-cookie library

const Login = (props) => {
  const [isChange, setIsChange] = useState(false);
  const [islogin, setIsLogin] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputOTPValue, setInputOTPValue] = useState("");
  const [loginStatus, setLoginStatus] = useState(null); // null: not attempted, true: successful, false: unsuccessful
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isOTPExpired, setIsOTPExpired] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showCopiedMsg, setShowCopiedMsg] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  // Flash msg
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  //validation
  const [inputEmailValue, setInputEmailValue] = useState("");
  // const [isuserpresent,setisuserpresent]=useState("");
  const showFlashMessage = (message, variant) => {
    setErrorMessage(message);
    setShowError(true);
    setFlashMessageVariant(variant);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  };
  const handleEmailPaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text");
    setInputValue(pastedValue);
    validateEmailOnPaste(pastedValue);
  };
  const validateEmailOnPaste = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      // setEmailError("Email is required");
      setIsEmailValid(false);
    } else if (!emailRegex.test(email)) {
      // setEmailError("Invalid email address");
      setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);
    }
  };
  const validateEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //  const emailRegex= /^[^\s@]+@[^\s@]+\.[^\s@]+\.com$/;
    if (!inputValue) {
      // setEmailError("Email is required");
      setEmailError(''); // Clear any existing error message
      setIsEmailValid(false);
    } else if (!emailRegex.test(inputValue)) {
      // setEmailError("Invalid email address");
      setIsEmailValid(false);
      // } else if (!usersData.includes(inputValue)) {
      //    setEmailError("No Account Found. Please register your email address");
      //   setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);    //kv change
      //  checkRegistered(inputValue);
      // setInputEmailValue(inputValue);
      // await checkRegistered(inputValue);
      // return true;
    }
  };
  const checkRegistered = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_VARIABLE}/iotrms/login/validateuser`, {
        method: "POST",
        body: JSON.stringify({
          email: value,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json"
        }
      })
      const data = await res.json();
      if (res.status === 200) {
        // setisuserpresent(data.isuserpresent);
        if (data.isuserpresent === "Yes") {
          setEmailError("");
          setIsEmailValid(true);
          // getOTP(e);
          setIsLogin(true);
        } else {          
          setEmailError(data.msg);
          // setEmailError("*");
          setIsEmailValid(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (inputValue) {
      validateEmail();
    }
  }, [inputValue]);
  const validateOtp = () => {
    if (!inputOTPValue) {
      setOtpError("OTP is required");
      return false;
    }
    setOtpError("");
    return true;
  };
  const navigate = useNavigate();
  const getOTP = async (e) => {
    e.preventDefault();
    handleResendOTP();
    // Check if the email is valid before proceeding
    if (!validateEmail()) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/iotrms/login/getotp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: inputValue,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        // OTP sent successfully, you can display a success message if needed
        // alert("OTP sent to your email. Please check your inbox or spam folder.");
      } else if (response.status === 500 && data.error === "email_not_found") {
        // Email not found in the database, show an error message
        showFlashMessage(
         data.message
        );
      } else {
        // Handle other error cases
        showFlashMessage(data.message || "An error occurred while sending OTP.");
      }
    } catch (err) {
      console.error(err);

      setShowCopiedMsg(true);
      // setTimeout(() => setShowCopiedMsg(false), 2000); // Reset the message after 2 seconds

      //   setTimeout(function() {
      //     window.location.reload();
      //     // setShowCopiedMsg(false);
      // }, 1000); // 2000 milliseconds = 2 second


      //   window.location.reload();
      //   alert("No Account Found. Please register your email address");
      //   setLoginStatus(false); // Mark the login attempt as unsuccessful
    }
  };

  const expireOtp = async (e) => {
    // e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/iotrms/login/timerfailed`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: inputValue,
          }),
        }
      );
      const data = await response.json();
      if (data) {
        // alert(data.message);
      } else {
        // setIsChange(true);
        // alert(data.message);
      }
      // alert(data.message);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isOTPExpired && islogin) {
      showFlashMessage(
        "OTP has been expired. Please click on Resend button for a new OTP."
      );
    }
    // expireOtp();
  }, [isOTPExpired, islogin]);
  useEffect(() => {
    const storedValue = Cookies.get("inputValue");
    if (storedValue) {
      setInputValue(storedValue);
    }
  }, []);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsEmailValid(false); // Reset email validation when input changes
    if (!value.trim()) {
      // setEmailError("Email is required");
    } else {
      validateEmail();
    }
  };
  const handleInputOTP = (e) => {
    const value = parseInt(e.target.value);
    setInputOTPValue(value);
    validateOtp();
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    navigate(`/change_email?inputValue=${inputValue}`);
  };
  const ChangeEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/api/iot_devices/users/changeemail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: inputValue,
          }),
        }
      );
      const data = await response.json();
      if (data) {
        showFlashMessage(data.message);
      } else {
        setIsChange(true);
        setShowOtpInput(true);
        // Pass the email value as a prop to the OTP page when navigating
        navigate({
          pathname: "/change_email",
          state: { email: inputValue },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VARIABLE}/iotrms/login/sign_in`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: inputValue,
            otp: inputOTPValue,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        // const accountId = data.account_id;
        // const userId = data.user_id;
        
        const name = data.full_name;
        const token = data.token;
        const companyName = data.company_name;
        const userLogId = data.user_log_id
        const subdomain = data.subdomain

        // if (token !==null || "") {    //another code kv..
        if (token !== null && token !== "") {
          const tokenData = {
            token: token,
            name: name,
            companyName: companyName,
            userLogId: userLogId,
            subdomain:subdomain

          };

          // function tokenDataIsValid(token) {
          //   // Add your validation logic here
          //   return token !== null && token !== undefined;
          // }

          // if (tokenData!=={}) {  //ussing aother kv..
          if (Object.keys(tokenData).length !== 0) {
            const expirationTime = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000); // 1 days in milliseconds
            Cookies.set('tkData', JSON.stringify(tokenData), { expires: expirationTime });
            Cookies.set('islogin', 'true', { expires: expirationTime });

            // ✅ Store logged-in email in localStorage
          localStorage.setItem("loggedInEmail", inputValue);  

              // Persist subdomain to localStorage
          localStorage.setItem("subdomain", subdomain);
          
            setLoginStatus(true);
            // You might want to remove one of the reloads here based on your logic.
            window.location.reload();
          } else {
            showFlashMessage('Invalid token data. Cannot log in.');
          }
          // navigate("/summary_page");
          // window.location.reload();
        } else {
          const tokenData = {};

          // if (tokenData === {}) {  another code kv..
          if (Object.keys(tokenData).length === 0) {
            Cookies.set('tkData', tokenData);
            Cookies.set('islogin', 'false');
            setLoginStatus(false);
            // You might want to remove one of the reloads here based on your logic.
            window.location.reload();
          } else {
            showFlashMessage('Invalid token data. Cannot log in.');
          }
          // window.location.reload();
        }
      } else {
        //const data = await response.json();
        if (data && data.message) {
          showFlashMessage(data.message);
        } else {
          // showFlashMessage("Login failed. Please try again later.");
          showFlashMessage("Invalid OTP, Could not Login.");
        }
      }
    } catch (err) {
      console.error("Login error:", err);
      // showFlashMessage("Login failed. Please try again later.");
      showFlashMessage("Invalid OTP, Could not Login.");
    }
  };
  useEffect(() => {
    const storedToken = Cookies.get("tkData");
    if (storedToken) {
      setLoginStatus(true);
    }
  }, []);
  const [timer, setTimer] = useState(120);
  const [showResend, setShowResend] = useState(false);
  useEffect(() => {
    if (timer > 0 && islogin && loginStatus !== false) {
      const countdown = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else if (timer === 0 && islogin) {
      setIsOTPExpired(true);
      setShowResend(true);
      setDisabledLogin(true); // Disable the login button
    }
  }, [timer, islogin, loginStatus]);
  // Calculate minutes and seconds from the timer value
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const startCountdown = () => {
    setTimer(120);
    setShowResend(false);
    // setIsOTPExpired(false);
  };
  const handleResendOTP = () => {
    setInputOTPValue(''); // Clear the OTP input value
    setDisabledLogin(false); // Re-enable the login button
    // Start countdown and fetch OTP
    startCountdown();
    if (!isOTPExpired && islogin) {
      getOTP();
    }
  };
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const handleGetOTPClick = async (e) => {
    e.preventDefault();
    validateEmail();

    // if (isEmailValid) {
    //   // Perform action for getting OTP
    //   // You can call getOTP(e) here
    checkRegistered(inputValue);
    getOTP(e);
    //   setIsLogin(true);
    // } else {
    //   // Handle the case where email is not valid
    // }
  };
  return (
    <>
      {/* // <!-- Main content --> */}
      
      <section className="login-section">
        
        <div className="container-fluid">
          <div className="row align-items-center login-row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 avatar-bg">
              {/* <div className="login-bg-inner"> */}
                <div className="avatar-wrap">
                  {/* <img alt="Motware" className="avatar-img" src={loginLogo}/> */}
                  <img
                    alt="Motware"
                    className="avatar-img"
                    src="assets/images/loginLogo.png"
                  />
                </div>
                <div className="iotImg-wrap">
                  {/* <img src={iotImage} alt="motware gateway" className=""/> */}
                  {/* <img
                    src="assets/images/iot_image.png"
                    alt="motware gateway"
                    className=""
                  /> */}
                </div>
              {/* </div> */}
            </div>
            {/* <!-- /.col --> */}
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 login-form">
              {/* ul tag add for clossing  */}
              {/* {showError && (
                <Alert
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "0px",
                    left: "0px",
                  }}
                  variant={
                    flashMessageVariant === "success" ? "success" : "danger"
                  }
                  onClose={() => setShowError(false)}
                  dismissible
                >
                  {errorMessage}
                </Alert>
              )} */}
              <div className="login-form-inner">
                <div className="login-form-inner-head">
                  {/* <img alt="Motware" className="avatar-img" src="assets/images/loginLogo.png" /> */}
                  <h2>Welcome to Motware</h2>
                </div>
                {showError && (
                  <Alert
                    style={{
                      position: "relative",
                      top: "5px",
                      right: "0px",
                      left: "0px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    variant={
                      flashMessageVariant === "success" ? "success" : "danger"
                    }
                    onClose={() => setShowError(false)}
                    dismissible
                  >
                    {errorMessage}
                  </Alert>
                )}
                <form>
                  <div className="form-row">
                    <div className="col-md-12">
                      {!islogin ? (
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={inputValue}
                            onChange={handleInputChange}
                            // onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Enter"
                            onPaste={handleEmailPaste}
                          />
                          <p style={{ color: "#D0292C", marginTop: "10px", fontSize: "12px" }}>
                            {emailError}
                          </p>
                          {/* {emailError && <p>{emailError}</p>} */}
                        </div>
                      ) : (
                        <>
                          <div className="form-group">
                            <label>Email</label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="test@motware.com"
                                readOnly
                              />
                              {/* <input
                                type="email"
                                className="form-control"
                                defaultValue={inputValue}
                                id=""
                                placeholder="test@motware.com"
                                aria-describedby=""
                                required
                                readOnly
                              /> */}
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="">
                                  {/* <a href="" onClick={ChangeEmail}></a> */}

                                  {/* <Link onClick={handleFormSubmit}>Change</Link> */}
                                  <a href="/">Change</a>
                                </span>
                              </div>
                            </div>
                            {/* {showCopiedMsg && (
                              <h5
                                style={{ color: "#D0292C", marginTop: "10px" }}
                              >
                                No Account Found. Please register your email
                                address
                              </h5>
                            )} */}
                          </div>
                        </>
                      )}
                      <div>
                        {!islogin ? (
                          <div className="form-group">
                            <input
                              type="submit"
                              name=""
                              value="Get OTP"
                              className="btn btn-primary btn-block"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   if (validateEmail()) {
                              //     !isChange ? getOTP(e) : ChangeEmail(e);
                              //     setIsLogin(true);
                              //   } else {
                              //   }
                              // }}
                              onClick={handleGetOTPClick}
                              data-disable-with="Get OTP"
                              disabled={!isEmailValid}

                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   if (isEmailValid) {
                            //     // Perform action for getting OTP
                            //     !isChange ? getOTP(e) : ChangeEmail(e);
                            //     setIsLogin(true);
                            //   } else {
                            //     checkEmailRegistered();
                            //   }
                            // }}
                            // disabled={
                            //   !inputValue || (!isEmailValid && !isRegistered)
                            // }
                            style={{ fontWeight: 500 }}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="form-group">
                              <label>OTP</label>
                              <input
                                type="number"
                                className="form-control number-input"
                                value={inputOTPValue}
                                onChange={handleInputOTP}
                                placeholder="Enter OTP"
                                disabled={!isEmailValid}
                                
                              />
                              <p className="float-right">
                                {showResend ? (
                                  <>
                                    <h5
                                      style={{ color: "#D0292C", marginTop: "10px" }}
                                    >
                                      OTP Expired.
                                    </h5>
                                    {/* <span>OTP Expired. </span> */}
                                    <span onClick={handleResendOTP}>
                                      <Link onClick={getOTP}>Resend OTP</Link>
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {/* ({`00:${timer.toString().padStart(2, "0")}`}
                                    ) */}
                                    {`${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="form-group">
                              <input
                                type="submit"
                                name=""
                                value="Login"
                                className="btn btn-primary btn-block"
                                onClick={login}
                                data-disable-with="Login"
                                disabled={disabledLogin}
                                style={{ fontWeight: 500 }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
