/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import Image from "../../../assets/images/full_screen.png";
import exitImage from "../../../assets/images/full_screen_exit.png";
import { useNavigate } from "react-router-dom";
import Plot from "react-plotly.js";

function Fleet() {
  const [barChartSlides, setBarChartSlides] = useState([]); // State for the chart slides
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeCockpitSlide, setActiveCockpitSlide] = useState(0);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isHovered, setIsHovered] = useState(false); // Track if the carousel is hovered
  const navigate = useNavigate(); // Initialize navigation
  const [viewMode, setViewMode] = useState("24_hour"); // Default to "24_hour"
  const [electricalData, setElectricalData] = useState([]); // State for API response
  const [relaysAndAlerts, setRelaysAndAlerts] = useState([]);
  const [relaysAndAlertsViewMode, setRelaysAndAlertsViewMode] =
    useState("24_hour"); // Default to 24_hour
  const [lastViewMode, setLastViewMode] = useState("24_hour"); // Default to 'real_time'

  useEffect(() => {
    const getAssetCountAndNavigate = async () => {
      if (!token) return; // Exit if no token

      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/total_assets_count`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const assetCount = response.data.data;
            if (assetCount <= 1) {
              navigate("/Transformer TxView");
            } else if (assetCount > 1) {
              navigate("/Transformer Fleet");
            }
            return; // Stop retrying after success
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
        attempts++;
      }

      // Handle failure after maxAttempts
      console.warn("Failed to fetch asset count after maximum attempts.");
    };

    getAssetCountAndNavigate();
  }, [token, navigate]);
  const chunkParameters = (params, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < params.length; i += chunkSize) {
      chunks.push(params.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkTransformersData = (data, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Chunk the parameters for Electrical Parameters Cockpit
  const electricalParameterChunks = chunkParameters(
    electricalData && electricalData.length > 0
      ? Object.keys(electricalData[0]).filter((key) => key !== "asset" && key !== "datetime")
      : [],
    10
  );

  const transformerChunks = chunkTransformersData(electricalData, 6);

  // State to track the switch state
  const [isChecked, setIsChecked] = useState(false);
  // Handle the change of the toggle button
  const changeToggleButton = (checked) => {
    setIsChecked(checked); // Update the switch state
    // Navigate based on the switch state
    if (checked) {
      navigate("/Transformer Fleet"); // Navigate to Fleet View if switch is ON
    } else {
      navigate("/Transformer TxView"); // Navigate to TX View if switch is OFF
    }
  };

  // Handle the view toggle for text display (not the redirection)
  const handleViewToggle = (viewType) => {
    if (viewType === "Fleet") {
      navigate("/Transformer Fleet");
    } else if (viewType === "TX") {
      navigate("/Transformer TxView");
    }
  };

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  ///////// critical/all switch ////////////
  const [isCritical, setIsCritical] = useState(false);
  // Read the initial value from cookies when the component loads
  useEffect(() => {
    const cookieValue = Cookies.get("filterMode");
    if (cookieValue) {
      setIsCritical(cookieValue === "1");
    }
  }, []);

  // Function to handle toggle switch change
  const handleToggleChange = async (checked) => {
    const newValue = !isCritical ? "1" : "0";
    setIsCritical(!isCritical);
    Cookies.set("filterMode", newValue);
    await getLatestAlerts(newValue);
    await getLoadingTemp(newValue);
    await electricalCockpit(newValue);
    await getGraphData(newValue);
    await fetchRelaysAndAlertsList(newValue);
    await getEnergyConsumptionData(newValue);
  };

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  /////////latest alerts ////////
  const [alertHeaders, setAlertHeaders] = useState([]);
  const [alertRows, setAlertRows] = useState([]);

  const getLatestAlerts = async (filterMode) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/fleet_alerts`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              critical_asset: filterMode,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            if (response.data.success === true) {
              setAlertHeaders(response.data.data.headers);
              setAlertRows(response.data.data.data);
              success = true;
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setAlertHeaders([]);
              setAlertRows([]);
              // showFlashMessage("Data Not Available");
              // setLatestAlerts(result)
            }
          } else {
            // setLatestAlerts(result)
            setIsLoading(false);
            setAlertHeaders([]);
            setAlertRows([]);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      showFlashMessage("Data Not Available For Latest Alerts", "danger");
      // setChartSeries(result)
      setAlertHeaders([]);
      setAlertRows([]);
      setIsLoading(false);
    }
  };

  // Define the new sequence order
  const reorderedHeaders = [
    "datetime",
    "location",
    "assset",
    "parameter_name",
    "parameter_value",
    "message",
  ];

  // Header display names
  const headerNames = [
    "Date Time",
    "Location",
    "Asset",
    "Parameter",
    "Value",
    "Alert",
  ];

  // Reorder the rows based on the new sequence
  const reorderedRows = alertRows.map((row) =>
    reorderedHeaders.map((header) => row[alertHeaders.indexOf(header)])
  );

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // Extract date components
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Extract time components
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, 0 becomes 12
    hours = String(hours).padStart(2, "0");

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  //////// box plot chart //////////
  //   const [originalSeries, setOriginalSeries] = useState([
  //     {
  //       name: "Loading (%)",
  //       data: [],
  //     },
  //     {
  //       name: "Winding Temp (°C)",
  //       data: [],
  //     },
  //   ]);
  //   const [chartHeight, setChartHeight] = useState(370); // Default height
  //   const [chartWidth, setChartWidth] = useState("100%")
  //   // Adjust chart height based on window width
  //   useEffect(() => {
  //     const handleResize = () => {
  //       const width = window.innerWidth;
  //       if (width === 1366) {
  //         setChartHeight(170);
  //       } else if (width === 1536) {
  //         setChartHeight(220);
  //       } else if (width === 1600) {
  //         setChartHeight(270);
  //       // } else if (width === 1669) {
  //       //   setChartHeight(270);
  //       } else {
  //         setChartHeight(370);
  //         setChartWidth(1820)
  //       }
  //     };

  //     // Listen for window resize events
  //     window.addEventListener("resize", handleResize);

  //     // Call the function initially to set the correct height
  //     handleResize();

  //     // Cleanup event listener on unmount
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  //   const [options, setOptions] = useState({});
  //   const [series, setSeries] = useState([]);

  //   const [tooltip, setTooltip] = useState({
  //     visible: false,
  //     content: "",
  //     x: 0,
  //     y: 0,
  //   });

  //   // Function to calculate the global min and max across both Loading and Winding series
  //   const calculateGlobalMinMax = () => {
  //     // Combine all y-values from both series
  //     const allValues = [
  //       ...originalSeries[0].data.flatMap((item) => item.y),
  //       ...originalSeries[1].data.flatMap((item) => item.y),
  //     ];

  //     // Get the global min and max values
  //     const globalMin = Math.min(...allValues);
  //     const globalMax = Math.max(...allValues);

  //     // Return the global min and max with ±5 adjustment
  //     return { min: globalMin, max: globalMax };
  //   };

  //   const splitDataIntoChunks = (series, chunkSize) => {
  //     if (!Array.isArray(series)) {
  //       console.error("Invalid series:", series);
  //       return [];
  //     }

  //     return series.map((s) => {
  //       const chunks = [];
  //       if (s?.data?.length > 0) {
  //         for (let i = 0; i < s.data.length; i += chunkSize) {
  //           chunks.push({
  //             name: s.name,
  //             data: s.data.slice(i, i + chunkSize),
  //           });
  //         }
  //       }
  //       return chunks;
  //     });
  //   };

  //   const getLoadingTemp = async (filterMode) => {
  //     if (!token) {
  //       // Return early if token is not available
  //       return;
  //     }
  //     let attempts = 0;
  //     const maxAttempts = 3;
  //     let success = false;
  //     while (attempts < maxAttempts && !success) {
  //       setIsLoading(true);
  //       try {
  //         if (token !== null && token !== undefined && token !== "") {
  //           const response = await axios.post(
  //             `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/loading_utilization_and_temperature_stress`,
  //             {
  //               access_token: token,
  //               user_log_id: userLogId,
  //               iot_sol: "Transformer",
  //               critical_asset: filterMode,
  //             }
  //           );
  //           if (response.status === 200 && response.data.success) {
  //             if (response.data.data.length > 0) {
  //               setOriginalSeries(response.data.data);
  //               success = true;
  //               setIsLoading(false);
  //             } else {
  //               setIsLoading(false);
  //               // showFlashMessage("Data Not Available");
  //               setOriginalSeries([
  //                 {
  //                   name: "Loading (%)",
  //                   data: [],
  //                 },
  //                 {
  //                   name: "Winding Temp (°C)",
  //                   data: [],
  //                 },
  //               ]);
  //             }
  //           } else {
  //             setOriginalSeries([
  //               {
  //                 name: "Loading (%)",
  //                 data: [],
  //               },
  //               {
  //                 name: "Winding Temp (°C)",
  //                 data: [],
  //               },
  //             ]);
  //             setIsLoading(false);
  //             // showFlashMessage("Data Not Available");
  //           }
  //           // setIsLoading(false);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //       attempts++;
  //       setIsLoading(false);
  //     }
  //     if (!success) {
  //       showFlashMessage("Data Not Available For Latest Alerts", "danger");
  //       setOriginalSeries([
  //         {
  //           name: "Loading (%)",
  //           data: [],
  //         },
  //         {
  //           name: "Winding Temp (°C)",
  //           data: [],
  //         },
  //       ]);
  //       setIsLoading(false);
  //     }
  //   };

  //   const chunkSize = 6; // Max transformers per chart
  //   const chunkedSeries = splitDataIntoChunks(series, chunkSize);

  //   const calculateSeriesMinMax = (seriesName) => {
  //     const series = originalSeries.find((item) => item.name === seriesName);
  //     if (!series || !series.data) return { min: 0, max: 0 };

  //     const allValues = series.data.flatMap((item) => item.y);
  //     const min = Math.min(...allValues);
  //     const max = Math.max(...allValues);
  //     return { min, max };
  //   };

  //   useEffect(() => {
  //     const globalRange = calculateGlobalMinMax();
  //     //     setOptions({
  //     //       chart: {
  //     //         type: "boxPlot",
  //     //         toolbar: {
  //     //           tools: {
  //     //             download: false,
  //     //           },
  //     //         },
  //     //         animations: {
  //     //           enabled: false,
  //     //         },
  //     //       },
  //     //       responsive: [
  //     //         {
  //     //           breakpoint: 1921,
  //     //           options: {
  //     //             chart: {
  //     //               height: 380,
  //     //             },
  //     //           },
  //     //         },
  //     //         {
  //     //           breakpoint: 1601,
  //     //           options: {
  //     //             chart: {
  //     //               height: 260,
  //     //             },
  //     //           },
  //     //         },
  //     //         {
  //     //           breakpoint: 1537,
  //     //           options: {
  //     //             chart: {
  //     //               height: 380,
  //     //             },
  //     //           },
  //     //         },
  //     //         {
  //     //           breakpoint: 1367,
  //     //           options: {
  //     //             chart: {
  //     //               height: 190,
  //     //             },
  //     //           },
  //     //         },
  //     //       ],
  //     //       legend: {
  //     //         markers: {
  //     //           fillColors: ["#1C75BD", "#8EC5EC"],
  //     //         },
  //     //         position: "top", // Place the legend on top
  //     //       },
  //     //       xaxis: {
  //     //         type: "category",
  //     //         labels: {
  //     //           show: true,
  //     //           style: {
  //     //             fontWeight: "900px", // Bold labels
  //     //           },
  //     //         },
  //     //         style: { fontWeight: 600 },
  //     //       },
  //     //       yaxis: [
  //     //         {
  //     //           labels: {
  //     //             // formatter: function (value) {
  //     //             //   return value !== null && !isNaN(value) ? value : "";
  //     //             // },
  //     //             style: {
  //     //               color: "#000000",
  //     //               fontFamily: "Montserrat, sans-serif",
  //     //               fontSize: "9px",
  //     //               fontWeight: 500,
  //     //             },
  //     //           },
  //     //           title: {
  //     //             text: "Loading (%)",
  //     //           },
  //     //           min: globalRange.min, // Use global min
  //     //           max: globalRange.max, // Use global max
  //     //           opposite: false, // Left side
  //     //         },
  //     //         {
  //     //           labels: {
  //     //             // formatter: function (value) {
  //     //             //   return value !== null && !isNaN(value) ? value : "";
  //     //             // },
  //     //             style: {
  //     //               color: "#000000",
  //     //               fontFamily: "Montserrat, sans-serif",
  //     //               fontSize: "9px",
  //     //               fontWeight: 500,
  //     //             },
  //     //           },
  //     //           title: {
  //     //             text: "Winding Temp (°C)",
  //     //           },
  //     //           min: globalRange.min, // Use global min
  //     //           max: globalRange.max, // Use global max
  //     //           opposite: true, // Right side
  //     //         },
  //     //       ],
  //     //       title: {
  //     //         text: "",
  //     //       },
  //     //       plotOptions: {
  //     //         boxPlot: {
  //     //           colors: {
  //     //             upper: undefined, // Not used because we will set colors per series
  //     //             lower: undefined,
  //     //           },
  //     //           stroke: {
  //     //             width: 0, // Removes the border between boxes
  //     //           },
  //     //         },
  //     //       },
  //     //       fill: {
  //     //         colors: ["#1C75BD", "#8EC5EC"], // Set specific colors for each series
  //     //       },
  //     //       tooltip: {
  //     //         custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //     //           const seriesName = w.globals.seriesNames[seriesIndex];
  //     //           const color = w.globals.colors[seriesIndex]; // Get the series color
  //     //           const data =
  //     //             w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
  //     //           const [close, low, average, high, open] = data;

  //     //               // Check if any value in the data is null
  //     //     if (data.includes(null)) {
  //     //       return ''; // Return an empty string to prevent showing the tooltip
  //     //     }

  //     //           return `
  //     //           <div style="border: 1px solid #ddd; background: white;">
  //     //  <div style="background-color: #e0e4e9; width: 100%; text-align: center; padding: 5px 0; font-size:0.75rem; font-weight: bold; ">
  //     //           ${seriesName}
  //     //         </div>
  //     //          <div style="padding: 5px; text-align:left; font-size:0.688rem;">
  //     //         <div>Max:<strong> ${open}</strong></div>
  //     //              <div>Q3: <strong> ${high}</strong></div>
  //     //              <div>Median: <strong> ${average}</strong></div>
  //     //              <div>Q1: <strong> ${low}</strong></div>
  //     //              <div>Min: <strong> ${close}</strong></div>
  //     //            </div>
  //     //            </div>
  //     //           `;
  //     //         },
  //     //         cssClass: "custom-tooltip",

  //     //         style: {
  //     //           fontFamily: "Montserrat, sans-serif",
  //     //           fontSize: "12px",
  //     //         },
  //     //       },
  //     //     });
  //     // Update the series
  //     setSeries(originalSeries);
  //   }, [originalSeries]);

  //   const loadingData = originalSeries.find(
  //     (item) => item.name === "Loading (%)"
  //   );
  //   const windingData = originalSeries.find(
  //     (item) => item.name === "Winding Temp (°C)"
  //   );
  //   const loadingY = loadingData.data.map((d) => d.y); // Loading values
  //   const windingY = windingData.data.map((d) => d.y); // Winding temp values

  //   const isSingleLoadingOrWinding =
  //     loadingY.length === 1 || windingY.length === 1; // Correctly defining this variable here

  //   const getPlotDataForChunk = (chunk) => {
  //     const loadingData = originalSeries.find(
  //       (item) => item.name === "Loading (%)"
  //     );
  //     const windingData = originalSeries.find(
  //       (item) => item.name === "Winding Temp (°C)"
  //     );

  //     const plants = chunk.map((d) => d.x); // Transformer names
  //     const loadingY = loadingData.data.map((d) => d.y); // Loading values
  //     const windingY = windingData.data.map((d) => d.y); // Winding temp values

  //     return [
  //       // Grouped Box plot for all Loading (%)
  //       {
  //         y: [].concat(...loadingY), // Flatten all loading data values
  //         x: [].concat(
  //           ...loadingY.map((_, index) =>
  //             Array(loadingY[index].length).fill(plants[index])
  //           )
  //         ), // Flatten the x labels
  //         type: "box",
  //         name: "Loading (%)",
  //         boxmode: "group",
  //         marker: { color: "#1C75BD" }, // Custom color for Loading (%)
  //         legendgroup: "loading", // Group under "Loading"
  //         hoverinfo: "none", // Disable default hover info
  //       },

  //       // Grouped Box plot for all Winding Temp (°C)
  //       {
  //         y: [].concat(...windingY), // Flatten all winding data values
  //         x: [].concat(
  //           ...windingY.map((_, index) =>
  //             Array(windingY[index].length).fill(plants[index])
  //           )
  //         ), // Flatten the x labels
  //         type: "box",
  //         name: "Winding Temp (°C)",
  //         boxmode: "group",
  //         yaxis: "y2",
  //         marker: { color: "#8EC5EC" }, // Custom color for Winding Temp (°C)
  //         legendgroup: "winding", // Group under "Winding"
  //         hoverinfo: "none", // Disable default hover info
  //       },
  //     ];
  //   };

  //   const handleHover = (event) => {
  //     const points = event.points[0];
  //     const transformerData = points.data; // The data of the hovered box (points.data.y)

  //     // Get the index of the hovered box in the array (you need to determine this based on your data structure)
  //     const boxIndex = points.pointIndex; // This will give us the index of the hovered box in the trace

  //     // Each box has 5 values, so the starting index for each transformer is boxIndex * 5
  //     const startIndex = boxIndex * 5;

  //     // Extract 5 values for the specific hovered box (based on the index)
  //     const valuesForBox = transformerData.y.slice(startIndex, startIndex + 5);

  //     const color = points.data.marker.color; // Retrieve the marker color for the legend symbol

  //  // Check if the values are empty or undefined
  //  if (valuesForBox.length === 0 || valuesForBox.some((value) => value === undefined || value === null)) {
  //   setTooltip({ visible: false, content: "", x: 0, y: 0 });
  //   return;
  // }
  //     setTooltip({
  //       visible: true,
  //       content: `
  //         <div style="display: flex; align-items: center;background-color: lightgrey;font-family: 'Montserrat', sans-serif;">
  //           <div style="background-color: lightgrey; border-radius: 2px; padding: 5px 15px;">
  // <span style="  display: inline-block; margin-right: 5px;align-items: center;"></span>
  //  <b>${points.data.name}</b>
  //           </div>
  //         </div>
  // <div style="margin:0 10px">
  //         Max:  <strong>${valuesForBox[4]}</strong>
  //         <br>
  //         Q3:   <strong>${valuesForBox[3]}</strong>
  //          <br>
  //         Median:  <strong>${valuesForBox[2]}</strong>
  //          <br>
  //         Q1: <strong>${valuesForBox[1]}</strong>
  //          <br>
  //         Min: <strong>${valuesForBox[0]}</strong>
  //         </div>
  //         `,
  //       x: event.event.clientX,
  //       y: event.event.clientY,
  //     });
  //   };

  //   const handleUnhover = () => {
  //     setTooltip({ visible: false, content: "", x: 0, y: 0 });
  //   };

  //////// bar chart ////////

  // const [originalSeries, setOriginalSeries] = useState([
  //   {
  //     name: "Loading (%)",
  //     data: [],
  //   },
  //   {
  //     name: "Winding Temp (°C)",
  //     data: [],
  //   },
  // ]);

  // const [series, setSeries] = useState([]);
  // console.log("series",series);

  //   const splitDataIntoChunks = (series, chunkSize) => {
  //   if (!Array.isArray(series)) {
  //     console.error("Invalid series:", series);
  //     return [];
  //   }
  //   return series.map((s) => {
  //     const chunks = [];
  //     if (s?.data?.length > 0) {
  //       for (let i = 0; i < s.data.length; i += chunkSize) {
  //         chunks.push({
  //           name: s.name,
  //           data: s.data.slice(i, i + chunkSize),
  //         });
  //       }
  //     }
  //     return chunks;
  //   });
  // };

  const [barData, setBarData] = useState([]);
  const getLoadingTemp = async (filterMode) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/loading_utilization_and_temperature_stress_bar_graph`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              critical_asset: filterMode,
            }
          );
          if (response.status === 200 && response.data.success) {
            if (response.data.data.length > 0) {
              setBarData(response.data.data);
              success = true;
              setIsLoading(false);
            } else {
              setIsLoading(false);
              // showFlashMessage("Data Not Available");
              setBarData([
                { load_percent: null, winding_temp_lv: null, asset: "no data available" },
              ]);
            }
          } else {
            setBarData([
              { load_percent: null, winding_temp_lv: null, asset: "no data available" },
            ]);
            setIsLoading(false);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      showFlashMessage("Data Not Available For Latest Alerts", "danger");
      setBarData([
        { load_percent: null, winding_temp_lv: null, asset: "no data available" },
      ]);
      setIsLoading(false);
    }
  };

  // const chunkSize = 6; // Max transformers per chart
  // const chunkedSeries = splitDataIntoChunks(series, chunkSize);

  //   useEffect(() => {

  //   setSeries(originalSeries);
  // }, [originalSeries]);

  // const barData = [
  //   { loading: 50, winding: 25, transformer_name: "TX 1" },
  //   { loading: 30, winding: 20, transformer_name: "TX 2" },
  //   { loading: 50, winding: 20, transformer_name: "TX 3" },
  //   { loading: 35, winding: 30, transformer_name: "TX 4" },
  //   { loading: 40, winding: null, transformer_name: "TX 5" },
  //   { loading: 40, winding: 20, transformer_name: "TX 6" },
  //   { loading: 40, winding: 20, transformer_name: "TX 7" },
  // ];

  const splitDataIntoChunks = (data, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Split barData into chunks of 6
  const chunkedBarData = splitDataIntoChunks(barData, 6);

  const getChartOptionsForChunk = (chunk) => {
    const loadingValues = chunk.map((item) => item.load_percent);
    const windingValues = chunk.map((item) => item.winding_temp_lv);
    const transformerNames = chunk.map((item) => item.asset);
  
    return {
      chart: {
        type: "bar",
        height: 400,
      },
      dataLabels: {
        enabled: true,
        style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis labels          
          colors: ["#000"],  
        },
       offsetY: -15,  
      },
      animations: {
        enabled: false,
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // Position data labels on top of the bars
        },
        distributed: false,
      },
    },
      responsive: [
        { breakpoint: 1921, options: { chart: { height: 400 } } },
        { breakpoint: 1601, options: { chart: { height: 280 },
        xaxis: {
          labels: {
            style: { fontSize: "7px" },
          },
        }, } },
        { breakpoint: 1537, options: { chart: { height: 250 } } },
        {
          breakpoint: 1367,
          options: {
            chart: { height: 170 },
            dataLabels: { style: { fontSize: "10px" } },
          },
        },
        { breakpoint: 1281, options: { chart: { height: 240 } } },
      ],
      stroke: {
        width: 2,
      },
      xaxis: {
        categories: transformerNames,
        labels: {
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis labels
            fontSize: '14px',          // Reduce font size of transformer names
            fontWeight: '500',        // Optional: Adjust font weight if needed
          },
          formatter: function (value) {
            // Apply truncation only if screen width is 1366px or below
            if (window.innerWidth <= 1366) {
              const maxLength = 25;
              return value.length > maxLength
                ? value.substring(0, maxLength) + "..."
                : value;
            }
            return value; // Return full label for larger screens
          },
        
        },
        
      },
      yaxis: 
      [
        {
          axisTicks: { show: true },
          // axisBorder: { show: true, color: "#1C75BD" },
          labels: { style: { colors: "black" } },
          title: { text: "Loading (%)", style: { color: "black" } },
          min: 0,
          max: Math.max(...loadingValues) > 100 
          ? Math.ceil(Math.max(...loadingValues) / 20) * 20  // Round up to nearest 20
          : 100, 
          
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for y-axis title
            fontSize: '14px',
            fontWeight: '500',
            color: '#000000',
          },
        
        },
        {
          opposite: true,
          axisTicks: { show: true },
          // axisBorder: { show: true, color: "#8EC5EC" },
          labels: { style: { colors: "black" } },
          title: { text: "Winding Temp (°C)", style: { color: "black" } },
          min: 0,
          max: Math.max(...windingValues) > 100 
          ? Math.ceil(Math.max(...windingValues) / 20) * 20  // Round up to nearest 20
          : 100, 
          //max: Math.max(...windingValues),
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for y-axis title
            fontSize: '14px',
            fontWeight: '500',
          },
          
        },
       
      ],
      
      series: [
        {
          name: "Loading (%)",
          data: loadingValues,
          color: "#1C75BD",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font to series names
            fontSize: '14px',
            fontWeight: '500',
          },
        },
        {
          name: "Winding Temp (°C)",
          data: windingValues,
          color: "#8EC5EC",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font to series names
            fontSize: '14px',
            fontWeight: '500',
          },
        },
      ],
      tooltip: {
        cssClass: "custom-tooltip",
        y: {
          formatter: (val) => val,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            textAlign: "center",
          },
        },
        x: {
          formatter: function (value) {
            return value; // Full label is returned here, no truncation
          },
        },
      },
    };
  };

  // const loadingValues = barData.map((item) => item.loading);
  // const windingValues = barData.map((item) => item.winding);
  // const transformerNames = barData.map((item) => item.transformer_name);

  // const loadingMax = Math.max(...loadingValues);
  // const windingMax = Math.max(...windingValues);

  // const options = {
  //   chart: {
  //     type: "bar",
  //     height: 400,
  //   },
  //   legend: {
  //     position: "top", // Moves the legend to the top
  //     horizontalAlign: "center", // Centers the legend horizontally
  //   },
  //   plotOptions: {
  //     bar: {
  //       distributed: false, // Disable distributed to keep consistent colors
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     width: 2,
  //   },
  //   xaxis: {
  //     categories: transformerNames,
  //   },
  //   yaxis: [
  //     {
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: "#1C75BD",
  //       },
  //       labels: {
  //         style: {
  //           colors: "#1C75BD",
  //         },
  //       },
  //       title: {
  //         text: "Loading (%)",
  //         style: {
  //           color: "#1C75BD",
  //         },
  //       },
  //       min: 0,
  //       max: loadingMax,
  //     },
  //     {
  //       opposite: true,
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: "#8EC5EC",
  //       },
  //       labels: {
  //         style: {
  //           colors: "#8EC5EC",
  //         },
  //       },
  //       title: {
  //         text: "Winding Temp (°C)",
  //         style: {
  //           color: "#8EC5EC",
  //         },
  //       },
  //       min: 0,
  //       max: windingMax,
  //     },
  //   ],
  //   series: [
  //     {
  //       name: "Loading (%)",
  //       data: loadingValues,
  //       color: "#1C75BD", // Consistent color for Loading
  //     },
  //     {
  //       name: "Winding Temp (°C)",
  //       data: windingValues,
  //       color: "#8EC5EC", // Consistent color for Winding
  //     },
  //   ],
  // };

  //////////// line chart///////////////
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  const [chartSeries, setChartSeries] = useState([]);

  const [chartOption, setChartOption] = useState({
    colors: [], 
    chart: {
      toolbar: {
        show: false, // Disables all toolbar icons
      },

      width: "100%",
    },
    responsive: [
      {
        breakpoint: 1921,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 1601,
        options: {
          chart: {
            height: 190,
          },
        },
      },
      {
        breakpoint: 1367,
        options: {
          chart: {
            height: 140,
          },
        },
      },
      {
        breakpoint: 1281,
        options: {
          chart: {
            // height: "100vh",
            height: 150,
          },
        },
      },
    ],
    legend: {
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat, sans-serif", // Set font family for legend
      fontSize: "12px", // Set font size for legend
      fontWeight: 600,
    },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'center',
    //   // fontSize: '14px'
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      // min: minValue,
      // max: maxValue,
      title: {
        text: "Temperature(°C)", // Set your Y-axis title here
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: "13px", // Set font size for Y-axis title
          fontWeight: 700,
        },
      },
      labels: {
        formatter: function (value) {
          if (value !== null && typeof value !== "undefined" && !isNaN(value)) {
            return value.toFixed(2); // Format y-axis labels to 2 decimal places
          } else {
            return ""; // Return an empty string for undefined or NaN values
          }
        },
        style: {
          fontFamily: "Montserrat, sans-serif",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: "datetime",
      // tickAmount: 16,
      labels: {
        // formatter: function (value) {
        //   const hours = Math.floor(value); // Get the hour
        //   return `${hours < 10 ? "0" + hours : hours}:00`; // Add leading zero if single-digit hour
        // },
        datetimeFormatter: {
          hour: "HH:mm",
        },
        style: {
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      x: {
        format: "HH:mm",
      },
      // x: {
      //   formatter: function (value) {
      //     const hours = Math.floor(value); // Get the hour
      //     return `${hours < 10 ? "0" + hours : hours}:00`; // Add leading zero if single-digit hour
      //   },
      // },
      cssClass: "custom-tooltip",
      style: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "12px",
      },
    },
  });

  const transformers =
    chartSeries.length > 0 ? Object.keys(chartSeries[0]) : [];
  const chunkTransformers = (transformers, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < transformers.length; i += chunkSize) {
      chunks.push(transformers.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const slides = chunkTransformers(transformers, 4);

  const getAdjustedYAxisRange = (series) => {
    // Extract Y-values from the series, filter out null, undefined, or non-numeric values
    const allYValues = series
      .flatMap((s) => s.data.map((point) => point[1]))
      .filter((y) => typeof y === "number" && !isNaN(y)); // Exclude null, undefined, and non-numeric values

    if (allYValues.length === 0) {
      // If no valid Y-values are found, return a default range (could be adjusted as per your needs)
      return { min: 0, max: 1 }; // Default range if there are no valid values
    }

    // Calculate the min and max values based on the valid numeric Y-values
    const minY = Math.min(...allYValues) - 0.1; // Adjusting the min value to 0.1 less
    const maxY = Math.max(...allYValues) + 0.1; // Adjusting the max value to 0.1 more

    return { min: minY, max: maxY };
  };

  const getGraphData = async (filterMode) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/temperature_cockpit`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              critical_asset: filterMode,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.length > 0) {
              setChartSeries(response.data.data);
               // Extract Colors from API Response
            const extractedColors = [];
            response.data.data.forEach((transformer) => {
              Object.values(transformer).forEach((parameters) => {
                parameters.forEach((param) => {
                  if (param.color && !extractedColors.includes(param.color)) {
                    extractedColors.push(param.color);
                  }
                });
              });
            });

            // Update chart options with dynamic colors
            setChartOption((prevOptions) => ({
              ...prevOptions,
              colors: extractedColors,
            }));
              success = true;
              setIsLoading(false);
            } else {
              setIsLoading(false);
              // showFlashMessage("Data Not Available");
              // setChartSeries(result)
            }
          } else {
            // setChartSeries(result)
            setIsLoading(false);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      showFlashMessage("Data Not Available For Temperature Cockpit", "danger");
      setChartSeries(chartData);
      setIsLoading(false);
    }
  };
  //////////// line chart ends///////////////

  const electricalCockpit = async (filterMode, currentViewMode) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    const viewModeToUse = currentViewMode || lastViewMode;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/electrical_cockpit`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              critical_asset: filterMode,
              view_mode: viewModeToUse,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            if (response.data.success === true) {
              setElectricalData(response.data.data); // Store the response data in state
              // setChartSeries(response.data.data);
              success = true;
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setElectricalData([]); // Ensure the state is cleared when no data is available
              // showFlashMessage("Data Not Available");
              // setChartSeries(result)
            }
          } else {
            // setChartSeries(result)
            setIsLoading(false);
            setElectricalData([]);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setElectricalData([]);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      showFlashMessage("Data Not Available For electrical Cockpit", "danger");
      // setChartSeries(result)
      setIsLoading(false);
    }
  };

  const fetchRelaysAndAlertsList = async (filterMode, currentViewMode) => {
    const viewModeToUse = currentViewMode || lastViewMode;

    if (!token) {
      console.error("Error: Token is missing.");
      return;
    }
    setIsLoading(true); // Show loading indicator
    setRelaysAndAlerts([]); // Clear existing data to avoid stale rendering
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true); // Set loading state
      try {
        // Make the API call
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/relays_and_alerts_list`,
          {
            access_token: token,
            user_log_id: userLogId,
            iot_sol: "Transformer",
            critical_asset: filterMode,
            view_mode: viewModeToUse, // Add the view_mode parameter
          }
        );

        if (response.status === 200 && response.data.success === true) {
          setRelaysAndAlerts(response.data.data); // Update state with response data
          success = true; // Mark success
        } else {
          console.error("No data available for Relays and Alerts List.");
          setRelaysAndAlerts([]); // Clear state if no data is available
        }
      } catch (error) {
        console.error("Error fetching Relays and Alerts List:", error.message);
        setRelaysAndAlerts([]); // Clear state on error
      } finally {
        setIsLoading(false); // Ensure loading state is reset
        attempts++; // Increment attempts
      }
    }
    if (!success) {
      showFlashMessage(
        "Data Not Available For Relays and Alerts List",
        "danger"
      );
    }
  };
  const [isEnergyConsumptionAvailable, setIsEnergyConsumptionAvailable] =
    useState(true);

  const getEnergyConsumptionData = async (filterMode) => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/benchmark_vs_actual_energy_consumption`,
        {
          access_token: token,
          user_log_id: userLogId,
          iot_sol: "Transformer",
          critical_asset: filterMode,
        }
      );

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;

        // Prepare data for the chart
        const labels = data.map((item) => item.asset);
        const benchmarkValues = data.map((item) =>
          Math.round(item.benchmark_energy_delivered_kwh)
        );
        const actualValues = data.map((item) =>
          Math.round(item.actual_energy_delivered_kwh)
        );
        const calculatedMinValue =
          Math.min(...[...benchmarkValues, ...actualValues]) - 0.1;
        const barMinValue = Math.min(calculatedMinValue, 0); // Prevent negative minimum
        const barMaxValue =
          Math.max(...[...benchmarkValues, ...actualValues]) + 0.1;

        setBarChartSlides([
          {
            options: {
              ...(barChartSlides[0]?.options || {}),
              xaxis: {
                categories: labels,
                labels: {
                  style: { fontSize: "13px", fontFamily: "Montserrat" },
                  formatter: function (value) {
                    // Apply truncation only if screen width is 1366px or below
                    if (window.innerWidth <= 1366) {
                      const maxLength = 25;
                      return value.length > maxLength
                        ? value.substring(0, maxLength) + "..."
                        : value;
                    }
                    return value; // Return full label for larger screens
                  },
                },
              },
              yaxis: {
                min: barMinValue,
                max: Math.ceil(barMaxValue / 10000) * 10000,
                tickAmount: 5, // Divide the axis into 5 segments for better scaling
                labels: {
                  formatter: function (value) {
                    if (
                      value !== null &&
                      typeof value !== "undefined" &&
                      !isNaN(value) &&
                      value === 0
                    ) {
                      return "0"; // Display "0" without "k"
                    }
                    return `${Math.round(value / 1000)}k`; // Divide by 1000, round to nearest integer, and append "k"
                  },
                },
              },
              tooltip: {
                x: {
                  show: true,
                  formatter: function (value) {
                    return value; // Show full label in the tooltip
                  },
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: { position: "top" },
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
                offsetY: 0,
                formatter: function (val) {
                  return Math.round(val); // Display rounded integers
                },
                style: {
                  fontSize: "9px",
                  fontFamily: "Montserrat",
                  colors: ["#000000"],
                },
              },
              stroke: { show: true, width: 2, colors: ["#7BBFE9", "#1B75BB"] },
              responsive: [
                { breakpoint: 1921, options: { chart: { height: 640 } } },
                { breakpoint: 1601, options: { chart: { height: 500 } } },
                { breakpoint: 1537, options: { chart: { height: 550 } } },
                {
                  breakpoint: 1367,
                  options: {
                    chart: { height: 360 },
                    dataLabels: { style: { fontSize: "7px" } },
                  },
                },
              ],
              fill: {
                opacity: 1,
                colors: ["#7BBFE9", "#1B75BB"],
                type: ["solid", "solid"],
              },
              tooltip: {
                cssClass: "custom-tooltip",
                y: {
                  formatter: (val) => val,
                  style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  },
                },
                x: {
                  formatter: function (value) {
                    return value; // Full label is returned here, no truncation
                  },
                },
              },
              legend: {
                position: "top",
                horizontalAlign: "center",
                markers: {
                  fillColors: ["#7BBFE9", "#1B75BB"],
                },
              },
            },
            series: [
              {
                name: "Benchmark",
                data: labels.map((label, index) => ({
                  x: label, // Category
                  y: benchmarkValues[index], // Value
                })),
              },
              {
                name: "Actual",
                data: labels.map((label, index) => ({
                  x: label, // Category
                  y: actualValues[index], // Value
                })),
              },
            ],
          },
        ]);
        setIsEnergyConsumptionAvailable(true); // Data is available
      } else {
        console.error("Error: Data not available for benchmark vs actual.");
        setBarChartSlides([]);
        setIsEnergyConsumptionAvailable(false); // Data not available
      }
    } catch (error) {
      console.error("Error fetching energy consumption data:", error);
      setBarChartSlides([]);
      setIsEnergyConsumptionAvailable(false); // Data not available
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      const initialFilterMode = Cookies.get("filterMode") || "0";
      getEnergyConsumptionData(initialFilterMode);
      getLatestAlerts(initialFilterMode);
      getLoadingTemp(initialFilterMode);
      electricalCockpit(initialFilterMode);
      getGraphData(initialFilterMode);
      fetchRelaysAndAlertsList(initialFilterMode);
    }
  }, [token]);

  ///////////Bar chart/////////
  const chartDatas = {
    chartData: [
      {
        consumption: 50,
        benchmark: 45,
        transformer_name: "TX 1",
      },
      {
        consumption: 300000,
        benchmark: 500000,
        transformer_name: "TX 2",
      },
      {
        consumption: 500000,
        benchmark: 500000,
        transformer_name: "TX 3",
      },
      {
        consumption: 35,
        benchmark: 25,
        transformer_name: "TX 4",
      },
      {
        consumption: 40,
        benchmark: 50,
        transformer_name: "TX 5",
      },
      {
        consumption: 40,
        benchmark: 50,
        transformer_name: "TX 6",
      },
      {
        consumption: 400,
        benchmark: 500,
        transformer_name: "TX 7",
      },
    ],
  };
  const chartDataArray = chartDatas?.chartData || [];

  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      const chunk = array.slice(i, i + size);
      if (chunk.length > 0) {
        chunks.push(chunk); // Only push non-empty chunks
      }
    }
    return chunks;
  };

  const chunkedData = chunkArray(chartDatas.chartData, 6);

  /////////// carousel ///////////
  const [activeBoxSlide, setActiveBoxSlide] = useState(0);
  useEffect(() => {
    const carousel = document.querySelector("#carouselExample");
    if (carousel) {
      // Listen to the slide event to update activeTab when the slide changes
      const handleSlide = (e) => {
        if (e.to >= 0) {
          setActiveTab(e.to);
        } else {
          console.error("Invalid slide index detected:", e.to);
        }
      };
      carousel.addEventListener("slide.bs.carousel", handleSlide);
      // Cleanup the event listener when the component unmounts
      return () =>
        carousel.removeEventListener("slide.bs.carousel", handleSlide);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 0 && !isHovered) {
      const totalSlides = chunkedBarData.length || 0;
      if (totalSlides > 0) {
        const slideInterval = setInterval(() => {
          setActiveBoxSlide((prev) => {
            // const slideCount = slides.length; // Dynamically calculate number of slides
            const nextSlide = (prev + 1) % totalSlides; // Loop through slides
            if (nextSlide === 0) {
              // When it's the last slide, move to the next tab
              const nextTab = activeTab + 1 < 5 ? activeTab + 1 : 0; // Loop through tabs
              setActiveTab(nextTab);
            }
            return nextSlide;
          });
        }, 10000); // Display each slide for 3 seconds
        return () => clearInterval(slideInterval); // Cleanup interval
      }
    }

    // Temperature Cockpit Logic
    if (activeTab === 2 && !isHovered) {
      const slideInterval = setInterval(() => {
        setActiveCockpitSlide((prev) => {
          const slideCount = slides.length; // Dynamically calculate number of slides
          const nextSlide = (prev + 1) % slideCount; // Loop through slides
          if (nextSlide === 0) {
            // When it's the last slide, move to the next tab
            const nextTab = activeTab + 1 < 5 ? activeTab + 1 : 0; // Loop through tabs
            setActiveTab(nextTab);
          }
          return nextSlide;
        });
      }, 10000); // Change slide every 3 seconds
      return () => clearInterval(slideInterval); // Cleanup the interval
    }

    // Generic Auto-Sliding for Other Tabs
    if (activeTab !== 0 && activeTab !== 2 && !isHovered) {
      const tabInterval = setInterval(() => {
        const nextTab =
          activeTab + 1 < (isEnergyConsumptionAvailable ? 5 : 4)
            ? activeTab + 1
            : 0; // Loop through tabs
        setActiveTab(nextTab);
      }, 10000); // Change tab every 5 seconds

      return () => clearInterval(tabInterval); // Cleanup the interval
    }
  }, [activeTab, isHovered, slides, chunkedBarData]); // Include slides as a dependency

  // Handle hover events to pause and resume auto-slide
  const handleMouseEnter = () => {
    setIsHovered(true); // Pause the auto-slide
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Resume the auto-slide
  };
  useEffect(() => {
    if (activeTab === 4 && !isHovered && barChartSlides.length > 0) {
      const totalSlides = Math.ceil(
        barChartSlides.reduce(
          (total, slide) => total + slide.series[0].data.length,
          0
        ) / 6
      ); // Total slides based on chunking

      const slideInterval = setInterval(() => {
        setActiveCockpitSlide((prev) => {
          const nextSlide = (prev + 1) % totalSlides;
          if (nextSlide === 0) {
            const nextTab = activeTab + 1 < 5 ? activeTab + 1 : 0; // Loop to the next tab
            setActiveTab(nextTab);
          }
          return nextSlide;
        });
      }, 10000); // Change slide every 3 seconds

      return () => clearInterval(slideInterval);
    }
  }, [activeTab, isHovered, barChartSlides]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 2) {
      // Reset to the first slide of Temperature Cockpit when the tab is clicked
      setActiveCockpitSlide(0); // Start at Temperature Cockpit 1/3
    }
    if (index === 0) {
      setActiveBoxSlide(0);
    }
    if (index === 4) {
      setActiveCockpitSlide(0); // Reset to the first slide
    }
  };

  ///////// Function to enter fullscreen ///////////
  const [isFullscreen, setIsFullscreen] = useState(false);

  const enterFullScreen = (e) => {
    // e.preventDefault();
    // const handleResize = () => {
    //   const width = window.innerWidth;
    //   if (width === 1366) {
    //     setChartHeight(250);
    //   } else if (width === 1536) {
    //     setChartHeight(170);
    //   } else if (width === 1600) {
    //     setChartHeight(270);
    //   }
    //   else if (width === 1920) {
    //     setChartHeight(450);
    //   } else {
    //     setChartHeight(450);
    //   }
    // };

    // // Listen for window resize events
    // window.addEventListener("resize", handleResize);

    // // Call the function initially to set the correct height
    // handleResize();

    document.documentElement
      .requestFullscreen()
      .then(() => {
        // Remove specific elements
        const elementsToRemove = document.querySelectorAll(
          ".sidebarLeft, .main-sidebar, .sidebarRight"
        );
        elementsToRemove.forEach((element) => {
          element.remove();
        });

        // Reset margin styles for the content-wrapper
        const contentWrapper = document.querySelector(".content-wrapper");
        if (contentWrapper) {
          contentWrapper.style.marginLeft = "0";
          contentWrapper.style.marginRight = "0";
        }
        const mainHeader = document.querySelector(".main-header");
        if (mainHeader) {
          mainHeader.style.marginLeft = "0";
        }

        // Update fullscreen state
        setIsFullscreen(true);
        sessionStorage.setItem("isFullscreen", "true"); // Persist fullscreen state
      })
      .catch((err) => {
        console.error("Failed to enter full-screen mode:", err);
      });
  };

  const exitFullScreen = () => {
    window.location.reload();
    setIsFullscreen(false);
    sessionStorage.removeItem("isFullscreen"); // Remove fullscreen state
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && isFullscreen) {
        exitFullScreen();
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [isFullscreen]);

  useEffect(() => {
    // Check if fullscreen was previously set
    const fullscreenState = sessionStorage.getItem("isFullscreen");
    if (fullscreenState === "true") {
      setIsFullscreen(true);
      enterFullScreen(); // Re-enter fullscreen if necessary
    }
  }, []);

  // Ensure fullscreen is entered when the component is mounted and not in fullscreen already
  useEffect(() => {
    if (isFullscreen && !document.fullscreenElement) {
      sessionStorage.removeItem("isFullscreen"); // Remove fullscreen state
    }
  }, [isFullscreen]);

  const handleViewModeToggle = (checked) => {
    const newMode = checked ? "real_time" : "24_hour"; // Map checked state to mode
    setViewMode(newMode); // Update the view mode state
    setLastViewMode(newMode);
    const filterMode = isCritical ? "1" : "0"; // 0 or 1 for critical asset
    getLatestAlerts(filterMode);
    getGraphData(filterMode);
    electricalCockpit(filterMode, newMode);
    getEnergyConsumptionData(filterMode);
  };

  const handleRelaysAndAlertsViewModeToggle = (checked) => {
    const newModer = checked ? "real_time" : "24_hour"; // Map checked state to mode
    setRelaysAndAlertsViewMode(newModer); // Update the state
    setLastViewMode(newModer);
    // localStorage.setItem("relays_and_alerts_view_mode", newMode); // Persist in localStorage

    const filterMode = isCritical ? "1" : "0"; // Determine filterMode
    fetchRelaysAndAlertsList(filterMode, newModer); // Fetch data for the updated mode
  };

  useEffect(() => {}, [viewMode]);

  useEffect(() => {
    if (activeTab === 3 && !isHovered) {
      // Calculate the total number of slides dynamically
      const totalParams = [
        ...new Set(relaysAndAlerts.flatMap((item) => Object.keys(item))),
      ].filter((key) => key !== "ASSET" && key !== "DATETIME").length;
      const slideCount = Math.ceil(totalParams / 10);

      if (slideCount > 1) {
        const intervalDuration =
          relaysAndAlertsViewMode === "24_hour"
            ? 9000
            : relaysAndAlertsViewMode === "real_time"
            ? 9000
            : 10000; // Default duration

        const slideInterval = setInterval(() => {
          setActiveCockpitSlide((prev) => {
            const nextSlide = (prev + 1) % slideCount;

            // Check if the next tab is Energy Consumption and skip if unavailable
            if (nextSlide === 0) {
              let nextTab = activeTab + 1;
              if (nextTab === 4 && !isEnergyConsumptionAvailable) {
                // Skip Energy Consumption tab if it's unavailable
                nextTab = (nextTab + 1) % 5;
              }
              setActiveTab(nextTab);
            }

            return nextSlide;
          });
        }, intervalDuration);

        return () => clearInterval(slideInterval);
      }
    }
  }, [
    activeTab,
    isHovered,
    relaysAndAlerts,
    relaysAndAlertsViewMode,
    isEnergyConsumptionAvailable,
  ]);

  // Auto-sliding logic for Electrical Parameters Cockpit
  useEffect(() => {
    if (activeTab === 1 && !isHovered) {
      const totalSlides =
        electricalParameterChunks.length * transformerChunks.length;
      const slideInterval = setInterval(
        () => {
          setActiveCockpitSlide((prev) => {
            const nextSlide = (prev + 1) % totalSlides;
            if (nextSlide === 0) {
              setActiveTab(activeTab + 1 < 5 ? activeTab + 1 : 0);
            }
            return nextSlide;
          });
        },
        viewMode === "real_time" ? 9000 : 10000
      ); // Faster sliding for real-time mode
      return () => clearInterval(slideInterval);
    }
  }, [
    activeTab,
    isHovered,
    electricalParameterChunks,
    transformerChunks,
    viewMode,
  ]);

  useEffect(() => {}, [relaysAndAlerts]);

  // const chartHeight = window.innerHeight * 0.4; // 60% of the window height

  return (
    <>
      <div
        className={`wrapper ${isFullscreen ? "fullscreen" : ""}`}
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {alerts.map((alert, index) => (
          <Alert
            key={alert.id}
            id={`flash-message-${alert.id}`}
            variant={alert.variant === "success" ? "success" : "danger"}
            onClose={() =>
              setAlerts((prevAlerts) =>
                prevAlerts.filter((a) => a.id !== alert.id)
              )
            }
            dismissible
            style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
          >
            {alert.message}
          </Alert>
        ))}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30">
                    <div className="topBtnCard card pr-card">
                      <div className="topBtn inrCardBody">
                        <div
                          className="form-group switch float-left"
                          // onChange={getGroupName}
                        >
                          <label className="switch-light">
                            <input
                              type="checkbox"
                              checked={isChecked} // The switch's state reflects isChecked
                              onChange={(e) =>
                                changeToggleButton(e.target.checked)
                              } // Handle toggle change
                              onBlur={() => {}}
                              name="isGroupSwitchOn"
                            />
                            <span id="toggleParamColor">
                              {/* Update text color based on switch state */}
                              <span
                                style={{
                                  color: isChecked ? "black" : "white",
                                }} // Change color based on the switch state
                                onClick={() => handleViewToggle("Fleet")}
                              >
                                Fleet View
                              </span>
                              <span
                                style={{
                                  color: isChecked ? "white" : "black",
                                }} // Change color based on the switch state
                                onClick={() => handleViewToggle("TX")}
                              >
                                TX View
                              </span>
                            </span>
                            <a className="btn btn-primary"></a>
                          </label>
                        </div>
                        <div className="scroll-x">
                          <ul className="form-group items-x">
                            {[
                              "Loading Utilization And Temp Stress",
                              "Electrical Parameters Cockpit",
                              "Temperature Cockpit",
                              "Relays",
                              isEnergyConsumptionAvailable &&
                                "Energy Consumption Benchmark VS Actuals", // Only include if available
                            ]
                              .filter(Boolean) // This filters out falsy values like false or null
                              .map((label, index) => (
                                <li key={index}>
                                  <a
                                    href="#"
                                    className={`btn ${
                                      activeTab === index ? "active" : ""
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent default link behavior
                                      if (
                                        index === 4 &&
                                        barChartSlides.length > 0
                                      ) {
                                        // Ensure Energy Consumption starts with the first slide
                                        setActiveTab(index);
                                        setActiveCockpitSlide(0); // Reset to the first chunked slide
                                      } else {
                                        handleTabClick(index);
                                      }
                                    }}
                                  >
                                    {label}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>

                        <div
                          className="form-group switch float-right"
                          style={{ marginRight: "5px" }}
                        >
                          <label className="switch-light">
                            <input
                              type="checkbox"
                              name="isGroupSwitchOn"
                              checked={isCritical}
                              onChange={(e) =>
                                handleToggleChange(e.target.checked)
                              }
                            />
                            <span id="toggleCritColor">
                              <span
                                style={{
                                  color: isCritical ? "black" : "white",
                                }}
                              >
                                All
                              </span>
                              <span
                                style={{
                                  color: isCritical ? "white" : "black",
                                }}
                              >
                                Critical
                              </span>
                            </span>
                            <a className="btn btn-primary"></a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="fleetWpr mdlContent card-body">
                      <div
                        id="carouselExample"
                        className="carousel slide w-100"
                        data-bs-ride="false" // Disable automatic sliding
                        onMouseEnter={handleMouseEnter} // Pause on hover
                        onMouseLeave={handleMouseLeave} // Resume when hover ends
                      >
                        <div className="carousel-indicators">
                          {[
                            "Loading Utilization",
                            "Electrical Parameters",
                            "Temperature Cockpit",
                            "Relays",
                            isEnergyConsumptionAvailable &&
                              "Energy Consumption Benchmark VS Actuals",
                          ]
                            .filter(Boolean)
                            .map((label, tabIndex) => {
                              const isGroupedTab = tabIndex === 4; // Assuming tabIndex 4 is for Energy Consumption
                              return (
                                <button
                                  key={tabIndex}
                                  type="button"
                                  data-bs-target="#carouselExample"
                                  data-bs-slide-to={tabIndex}
                                  className={
                                    activeTab === tabIndex ? "active" : ""
                                  }
                                  onClick={() => {
                                    setActiveTab(tabIndex);
                                    if (isGroupedTab) {
                                      setActiveCockpitSlide(0); // Reset to the first slide for grouped tabs
                                    }
                                  }}
                                >
                                  {/* {label}
        {isGroupedTab && barChartSlides.length > 1
          ? ` (${activeCockpitSlide + 1}/${barChartSlides.length})`
          : ""} */}
                                </button>
                              );
                            })}
                        </div>

                        {!isFullscreen && (
                          <div className="fullScreenWpr">
                            <a href="#" onClick={enterFullScreen}>
                              <img src={Image} width={"40"} alt="fullScreen" />
                            </a>
                          </div>
                        )}

                        {isFullscreen && (
                          <div className="fullScreenExit">
                            <a href="#" onClick={exitFullScreen}>
                              <img
                                src={exitImage}
                                width={"40"}
                                alt="exit fullScreen"
                              />
                            </a>
                          </div>
                        )}

                        <div className="innerWpr carousel-inner">
                          {chunkedBarData.map((chunk, slideIndex) => (
                            <div
                              key={slideIndex}
                              className={`carousel-item ${
                                activeTab === 0 && activeBoxSlide === slideIndex
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="col-md-12 col-lg-12">
                                <div className="latestArtTbl card pr-card mb30 tableCard">
                                  <div className="inrCardHdr">
                                    <h5>Latest Alerts</h5>
                                  </div>
                                  <div className="inrCardBody">
                                    {reorderedRows.some(
                                      (serie) => serie.length > 0
                                    ) ? (
                                      <div className="table-responsive">
                                        <table
                                          className="table table-hover dtr-inline"
                                          style={{ width: "100%" }}
                                        >
                                          <thead>
                                            <tr>
                                              {headerNames.map(
                                                (header, index) => (
                                                  <th key={index}>{header}</th>
                                                )
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {reorderedRows.map(
                                              (row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                  {row.map(
                                                    (cell, cellIndex) => (
                                                      <td key={cellIndex}>
                                                        {reorderedHeaders[
                                                          cellIndex
                                                        ] === "datetime" ? (
                                                          <a href="#">
                                                            {convertTimestamp(
                                                              cell
                                                            )}
                                                          </a>
                                                        ) : (
                                                          <a href="#">{cell}</a>
                                                        )}
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    ) : (
                                      <p className="text-center">
                                         No Data Available For Latest Alerts
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 col-lg-12">
                                <div className="card pr-card mb30">
                                  <div className="inrCardHdr">
                                    <h5>
                                      Loading Utilization And Temperature Stress
                                      {chunkedBarData.length > 1 &&
                                        ` (${slideIndex + 1}/${
                                          chunkedBarData.length
                                        })`}
                                      {/* Loading Utilization And Temperature Stress ({slideIndex + 1}/
            {Math.ceil(barData.length / 6)}) */}
                                    </h5>
                                  </div>
                                  <div className="card-body">
                                    {barData.length > 0 ? (
                                      <div id="myDiv">
                                        <div
                                          id="componentsCarousel"
                                          className="carousel slide"
                                          data-bs-ride="carousel"
                                        >
                                          <div className="carousel-inner">
                                            <div id="myChart">
                                              <ReactApexChart
                                                options={getChartOptionsForChunk(
                                                  chunk
                                                )}
                                                series={
                                                  getChartOptionsForChunk(chunk)
                                                    .series
                                                }
                                                type="bar"
                                                height={400}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <p className="text-center">
                                       Data is Loading  For Loading
                                        Utilization And Temperature Stress
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {activeTab === 1 && (
                            <div>
                              { electricalParameterChunks.length > 0 &&
                              transformerChunks.length > 0 ? (
                                electricalParameterChunks.map(
                                  (parameterChunk, paramSlideIndex) =>
                                    transformerChunks.map(
                                      (transformerChunk, transSlideIndex) => (
                                        <div
                                          key={`electrical-slide-${paramSlideIndex}-trans-${transSlideIndex}`}
                                          className={`carousel-item ${
                                            activeCockpitSlide ===
                                            paramSlideIndex *
                                              transformerChunks.length +
                                              transSlideIndex
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <div className="col-md-12 col-lg-12">
                                            <div className="card pr-card mb30">
                                              <div className="inrCardHdr">
                                                <h5>
                                                  Electrical Parameters Cockpit
                                                  {electricalParameterChunks.length >
                                                    1 &&
                                                    ` (${paramSlideIndex + 1}/${
                                                      electricalParameterChunks.length
                                                    })`}
                                                  {transformerChunks.length >
                                                    1 &&
                                                    ` (${transSlideIndex + 1}/${
                                                      transformerChunks.length
                                                    })`}
                                                </h5>
                                                <div className="form-group switch float-right">
                                                  <label className="switch-light">
                                                    <input
                                                      type="checkbox"
                                                      name="viewModeSwitch"
                                                      checked={
                                                        viewMode === "real_time"
                                                      }
                                                      onChange={(e) =>
                                                        handleViewModeToggle(
                                                          e.target.checked
                                                        )
                                                      }
                                                    />
                                                    <span id="toggleParamColor">
                                                      <span
                                                        style={{
                                                          color:
                                                            viewMode ===
                                                            "24_hour"
                                                              ? "white"
                                                              : "black",
                                                        }}
                                                      >
                                                        24 Hour
                                                      </span>
                                                      <span
                                                        style={{
                                                          color:
                                                            viewMode ===
                                                            "real_time"
                                                              ? "white"
                                                              : "black",
                                                        }}
                                                      >
                                                        Real Time
                                                      </span>
                                                    </span>
                                                    <a className="btn btn-primary"></a>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="eleParaTbl inrCardBody">
                                                <table
                                                  className="table dtr-inline eleParaTbl"
                                                  style={{ width: "100%" }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th className="text-center td_width">
                                                        Transformer Name
                                                      </th>
                                                      {parameterChunk.map(
                                                        (param) => (
                                                          <th
                                                            key={param}
                                                            className="text-center td_width"
                                                          >
                                                            {/* {param
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          param
                                                            .slice(1)
                                                            .replace("_", " ")} */}
                                                            {param}
                                                          </th>
                                                        )
                                                      )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {transformerChunk.map(
                                                      (transformer, index) => (
                                                        <tr key={index}>
                                                          <td>
                                                            {transformer.asset}
                                                            <br />
                                                            <span style={{fontSize:10,fontWeight:600}}>
                                                            {transformer.datetime}</span>
                                                          </td>
                                                          {parameterChunk.map(
                                                            (param) => (
                                                              <td
                                                                key={param}
                                                                className={
                                                                  transformer[
                                                                    param
                                                                  ]?.color || ""
                                                                }
                                                              >
                                                                <a href="#">
                                                                  {typeof transformer[
                                                                    param
                                                                  ]?.value ===
                                                                  "number"
                                                                    ? Number.isInteger(
                                                                        transformer[
                                                                          param
                                                                        ].value
                                                                      )
                                                                      ? transformer[
                                                                          param
                                                                        ].value
                                                                      : transformer[
                                                                          param
                                                                        ].value.toFixed(
                                                                          2
                                                                        )
                                                                    : transformer[
                                                                        param
                                                                      ]
                                                                        ?.value ===
                                                                        undefined ||
                                                                      transformer[
                                                                        param
                                                                      ]
                                                                        ?.value ===
                                                                        null
                                                                    ? "-"
                                                                    : transformer[
                                                                        param
                                                                      ]?.value}
                                                                </a>
                                                              </td>
                                                            )
                                                          )}
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )
                                )
                              ) : (
                                <div className="col-md-12 col-lg-12">
                                <div className="card pr-card mb30">
                                  <div className="inrCardHdr">
                                    <h5>
                                      Electrical Parameters Cockpit</h5>
                                      <div className="form-group switch float-right">
                                                  <label className="switch-light">
                                                    <input
                                                      type="checkbox"
                                                      name="viewModeSwitch"
                                                      checked={
                                                        viewMode === "real_time"
                                                      }
                                                      onChange={(e) =>
                                                        handleViewModeToggle(
                                                          e.target.checked
                                                        )
                                                      }
                                                    />
                                                    <span id="toggleParamColor">
                                                      <span
                                                        style={{
                                                          color:
                                                            viewMode ===
                                                            "24_hour"
                                                              ? "white"
                                                              : "black",
                                                        }}
                                                      >
                                                        24 Hour
                                                      </span>
                                                      <span
                                                        style={{
                                                          color:
                                                            viewMode ===
                                                            "real_time"
                                                              ? "white"
                                                              : "black",
                                                        }}
                                                      >
                                                        Real Time
                                                      </span>
                                                    </span>
                                                    <a className="btn btn-primary"></a>
                                                  </label>
                                                </div>
                                                </div>
                                                <div className=" inrCardBody">
                                <p className="text-center">
                                            No Data Available For Electrical
                                            Parameters Cockpit
                                          </p> 
                                          </div>
                                          </div>
                                          </div>
                              )}
                            </div>
                          )}
                          {/* <!-- line slide --> */}
                          {activeTab === 2 && (
                            <div>
                              {isLoading ? (
            <div className="col-md-12 col-lg-12">
              <div className="card pr-card mb30">
                <div className="inrCardHdr">
                  <h5>Temperature Cockpit</h5>
                </div>
                <div className="eleParaTbl inrCardBody">
                  <table className="table dtr-inline eleParaTbl" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        {/* Empty headers as it is loading */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="100%" className="text-center">Data Loading For Temperature Cockpit...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) :slides.length > 0 ? (
                                slides.map((slide, slideIndex) => (
                                  <div
                                    key={slideIndex}
                                    className={`carousel-item ${
                                      activeCockpitSlide === slideIndex
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="col-md-12 col-lg-12">
                                      <div className="card pr-card mb30">
                                        <div className="breadcrumbHdr card-header">
                                          <h5>
                                            Temperature Cockpit
                                            {slides.length > 1 &&
                                              ` (${slideIndex + 1}/${
                                                slides.length
                                              })`}
                                          </h5>
                                        </div>
                                        <div className="card-body">
                                          <div className="row align-items-center">
                                            {slide.map(
                                              (
                                                transformer,
                                                transformerIndex
                                              ) => {
                                                const isLastSlide =
                                                  slideIndex ===
                                                  slides.length - 1;
                                                const remainingCharts =
                                                  slide.length;

                                                // Layout adjustment based on the number of charts in the slide
                                                const columnClass =
                                                  remainingCharts === 1
                                                    ? "col-md-12 col-lg-12 col-xl-12" // Full width for 1 chart
                                                    : remainingCharts === 2
                                                    ? "col-md-12 col-lg-12 col-xl-12" // Full width for 2 charts
                                                    : remainingCharts === 3
                                                    ? transformerIndex === 2
                                                      ? "col-md-12 col-lg-12 col-xl-12" // Third chart full width
                                                      : "col-md-6 col-lg-6 col-xl-6" // First two charts half-width
                                                    : "col-md-6 col-lg-6 col-xl-6"; // Default layout for 4 charts (2x2 grid)

                                                const series =
                                                  chartSeries[0][transformer];
                                                const { min, max } =
                                                  getAdjustedYAxisRange(series);

                                                const transformerChartOption = {
                                                  ...chartOption,
                                                  yaxis: {
                                                    ...chartOption.yaxis,
                                                    min,
                                                    max,
                                                  },
                                                  responsive:
                                                    chartOption.responsive.map(
                                                      (breakpoint) => ({
                                                        ...breakpoint,
                                                        options: {
                                                          ...breakpoint.options,
                                                          chart: {
                                                            ...breakpoint
                                                              .options.chart,
                                                            height:
                                                              remainingCharts ===
                                                              1
                                                                ? breakpoint.breakpoint ===
                                                                  1921
                                                                  ? 550
                                                                  : breakpoint.breakpoint ===
                                                                    1601
                                                                  ? 420
                                                                  : breakpoint.breakpoint ===
                                                                    1537
                                                                  ? 550
                                                                  : breakpoint.breakpoint ===
                                                                    1367
                                                                  ? 350
                                                                  : breakpoint
                                                                      .options
                                                                      .chart
                                                                      .height
                                                                : breakpoint
                                                                    .options
                                                                    .chart
                                                                    .height,
                                                          },
                                                        },
                                                      })
                                                    ),
                                                };

                                                return (
                                                  <div
                                                    key={transformerIndex}
                                                    className={columnClass}
                                                  >
                                                    <div
                                                      className={`card pr-card ${
                                                        columnClass ===
                                                        "col-md-12 col-lg-12 col-xl-12"
                                                          ? "full-height"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="inrCardHdr">
                                                        <h5>{transformer}</h5>
                                                      </div>
                                                      <div className="inrCardBody">
                                                        <ReactApexChart
                                                          options={
                                                            transformerChartOption
                                                          }
                                                          series={
                                                            chartSeries[0][
                                                              transformer
                                                            ]
                                                          }
                                                          type="line"
                                                          width="100%"
                                                          height="100%"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-md-12 col-lg-12">
                                  <div className="card pr-card mb30">
                                    <div className="inrCardHdr">
                                      <h5>Temperature Cockpit</h5>
                                    </div>
                                    <div className=" inrCardBody">
                                    <p className="text-center">
                                          
                                          No Data Available For Temperature
                                            Cockpit
                                          </p>
                                        
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {/* <!-- line slide end --> */}

                          {/* <div className="innerWpr carousel-inner"> */}
                            {/* Render the Relays slide */}
                            {activeTab === 3 && (
                              <div>
                                {isLoading ? (
            <div className="col-md-12 col-lg-12">
              <div className="card pr-card mb30">
                <div className="inrCardHdr">
                  <h5>Relays</h5>
                </div>
                <div className="eleParaTbl inrCardBody">
                  <table className="table dtr-inline eleParaTbl" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        {/* Empty headers as it is loading */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="100%" className="text-center">Data Loading For Relays...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) :relaysAndAlerts.length > 0 ? (
                                  chunkParameters(
                                    // Get unique keys from all objects, excluding "ASSET"
                                    [
                                      ...new Set(
                                        relaysAndAlerts.flatMap((item) =>
                                          Object.keys(item)
                                        )
                                      ),
                                    ].filter((key) => key !== "ASSET" && key !== "DATETIME"),
                                    10 // Chunk the parameters into groups of 10
                                  ).map((chunk, slideIndex) => (
                                    <div
                                      key={slideIndex}
                                      className={`carousel-item ${
                                        activeCockpitSlide === slideIndex
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <div className="col-md-12 col-lg-12">
                                        <div className="card pr-card mb30">
                                          <div className="inrCardHdr">
                                            <h5>
                                              {Math.ceil(
                                                [
                                                  ...new Set(
                                                    relaysAndAlerts.flatMap(
                                                      (item) =>
                                                        Object.keys(item)
                                                    )
                                                  ),
                                                ].filter(
                                                  (key) => key !== "ASSET"
                                                  && key !== "DATETIME" ).length / 10
                                              ) > 1
                                                ? `Relays ${
                                                    slideIndex + 1
                                                  }/${Math.ceil(
                                                    [
                                                      ...new Set(
                                                        relaysAndAlerts.flatMap(
                                                          (item) =>
                                                            Object.keys(item)
                                                        )
                                                      ),
                                                    ].filter(
                                                      (key) => key !== "ASSET"
                                                    && key !== "DATETIME").length / 10
                                                  )}`
                                                : "Relays"}
                                            </h5>

                                            <div className="form-group switch float-right">
                                              <label className="switch-light">
                                                <input
                                                  type="checkbox"
                                                  name="relaysAndAlertsViewModeSwitch"
                                                  checked={
                                                    relaysAndAlertsViewMode ===
                                                    "real_time"
                                                  }
                                                  onChange={(e) =>
                                                    handleRelaysAndAlertsViewModeToggle(
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <span id="toggleParamColor">
                                                  <span
                                                    style={{
                                                      color:
                                                        relaysAndAlertsViewMode ===
                                                        "24_hour"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    24 Hour
                                                  </span>
                                                  <span
                                                    style={{
                                                      color:
                                                        relaysAndAlertsViewMode ===
                                                        "real_time"
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                  >
                                                    Real Time
                                                  </span>
                                                </span>
                                                <a className="btn btn-primary"></a>
                                              </label>
                                            </div>
                                          </div>
                                          <div className="eleParaTbl inrCardBody">
                                            <table
                                              className="table dtr-inline eleParaTbl"
                                              style={{ width: "100%" }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-center td_width">
                                                    Transformer Name
                                                  </th>
                                                  {chunk.map((param) => (
                                                    <th
                                                      key={param}
                                                      className="text-center td_width"
                                                    >
                                                      {param}
                                                    </th>
                                                  ))}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {relaysAndAlerts.map(
                                                  (item, index) => (
                                                    <tr key={index}>
                                                      <td>{item.ASSET}
                                                      <br />
                                                      <span style={{fontSize:10,fontWeight:600}}>
                                                      {item.DATETIME}</span>
                                                      </td>
                                                      {chunk.map((param) => (
                                                        <td
                                                          key={param}
                                                          className={
                                                            item[param]
                                                              ?.color || ""
                                                          }
                                                        >
                                                          <a href="#">
                                                            {item[param]
                                                              ?.value ??
                                                              "-"}{" "}
                                                            {/* Display value or "-" */}
                                                          </a>
                                                        </td>
                                                      ))}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  // Show "No Data Available" only for Relays
                                  <div className="col-md-12 col-lg-12">
                                    <div className="card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>
                                          {/* {Math.ceil(
                      [...new Set(relaysAndAlerts.flatMap((item) => Object.keys(item)))]
                        .filter((key) => key !== "ASSET").length / 10
                    ) > 1
                      ? `Relays ${slideIndex + 1}/${Math.ceil(
                          [...new Set(relaysAndAlerts.flatMap((item) => Object.keys(item)))]
                            .filter((key) => key !== "ASSET").length / 10
                        )}`
                      : "Relays"} */}
                                          Relays
                                        </h5>
                                        <div className="form-group switch float-right">
                                          <label className="switch-light">
                                            <input
                                              type="checkbox"
                                              name="relaysAndAlertsViewModeSwitch"
                                              checked={
                                                relaysAndAlertsViewMode ===
                                                "real_time"
                                              }
                                              onChange={(e) =>
                                                handleRelaysAndAlertsViewModeToggle(
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span id="toggleParamColor">
                                              <span
                                                style={{
                                                  color:
                                                    relaysAndAlertsViewMode ===
                                                    "24_hour"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                24 Hour
                                              </span>
                                              <span
                                                style={{
                                                  color:
                                                    relaysAndAlertsViewMode ===
                                                    "real_time"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                Real Time
                                              </span>
                                            </span>
                                            <a className="btn btn-primary"></a>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="inrCardBody">
                                        
                                      <p className="text-center">
                                            No Data Available For Relays
                                            </p>
                                         
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          {/* </div> */}

                          {/* <!-- slide 4 end --> */}

                          {barChartSlides.flatMap((chartData, slideIndex) =>
                            chunkArray(chartData.series[0].data, 6).map(
                              (chunk, chunkIndex) => (
                                <div
                                  key={`${slideIndex}-${chunkIndex}`}
                                  className={`carousel-item ${
                                    activeTab === 4 &&
                                    activeCockpitSlide ===
                                      slideIndex *
                                        Math.ceil(
                                          chartData.series[0].data.length / 6
                                        ) +
                                        chunkIndex
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div className="col-md-12 col-lg-12">
                                    <div className="card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>
                                          Energy Consumption Benchmark VS
                                          Actuals
                                          {barChartSlides.length > 1 &&
                                            ` (${activeCockpitSlide + 1}/${
                                              barChartSlides.length
                                            })`}
                                        </h5>
                                      </div>
                                      <div className="inrCardBody">
                                        <ReactApexChart
                                          options={chartData.options}
                                          series={chartData.series}
                                          type="bar"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={handleSubmitDataOnClick}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
      /> */}
    </>
  );
}

export default Fleet;
