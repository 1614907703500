import React from 'react';
import { Card, Row, Col } from "react-bootstrap";

const InfoBox = ({ title, value }) => {
    return (
      <Col md={4} lg={4}>
        <div className="info-box">
          {/* <span className={`info-box-icon ${iconClass} elevation-1`}></span> */}
          <div className="info-box-content">
            <span className="info-box-text">{title}</span>
          </div>
          <div className="info-box-number">
            <span className="info-box-text">{value}</span>
          </div>
        </div>
      </Col>
    );
  };



export default InfoBox