import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const NameplateSkeleton = () => {
  return (
    <div className="nameplatePg content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mdlContent card pr-card mb30">
                {/* Breadcrumb Skeleton */}
                <div className="nameplateHdr breadcrumbHdr card-header">
                  <div className="row">
                    <div className="col-md-10">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Skeleton variant="text" width={150} height={30} />
                        </li>
                        <li className="breadcrumb-item">
                          <Skeleton variant="text" width={150} height={30} />
                        </li>
                      </ol>
                    </div>
                    {/* <div className="topBtnWpr col-md-2">
                      <div className="form-inline dataTables_filter pr-form-inline float-right">
                        <div className="input-group pr-form-group">
                          <Skeleton variant="rectangular" width={150} height={40} />
                          <Skeleton variant="circular" width={30} height={30} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* Sidebar Skeleton */}
                {/* <div className="sidebarSkeleton">
                  <Skeleton variant="rectangular" width="100%" height={200} />
                </div> */}

                {/* Main Content Skeleton */}
                <div className="mdlContent card-body mt10">
                  {/* Data Cards Skeleton */}
                  <div className="row">
                    {[...Array(3)].map((_, index) => (
                      <div className="col-md-4 col-lg-4" key={index}>
                        <div className="enrgConsBox mb-3">
                          <div className="inrCardHdr">
                            <Skeleton variant="text" width={100} height={30} />
                          </div>
                          <div className="inrCardBody">
                            <Skeleton variant="text" width={150} height={50} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Nameplate Table Skeleton */}
                  {/* <div className="card pr-card mb30">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={150} height={30} />
                    </div>
                    <div className="card-body">
                      <div className="nameplateTbl table table-bordered mt20">
                        <tbody>
                          {[...Array(4)].map((_, index) => (
                            <tr key={index}>
                              <th>
                                <Skeleton variant="text" width={100} height={30} />
                              </th>
                              <td>
                                <Skeleton variant="text" width={150} height={30} />
                              </td>
                              <th>
                                <Skeleton variant="text" width={100} height={30} />
                              </th>
                              <td>
                                <Skeleton variant="text" width={150} height={30} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </div>
                    </div>
                  </div> */}

                  {/* Nameplate Data Table Skeleton */}
                  {/* <div className="card pr-card mb30">
                    <div className="card-body">
                      <div className="table mt20">
                        <thead>
                          <tr>
                            <th>
                              <Skeleton variant="text" width={100} height={30} />
                            </th>
                            {[...Array(3)].map((_, index) => (
                              <th key={index} className="text-center">
                                <Skeleton variant="text" width={100} height={30} />
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(3)].map((_, index) => (
                            <tr key={index}>
                              <th className="text-center">
                                <Skeleton variant="text" width={100} height={30} />
                              </th>
                              {[...Array(3)].map((_, idx) => (
                                <td key={idx} className="text-center">
                                  <Skeleton variant="text" width={100} height={30} />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </div>
                    </div>
                  </div> */}

                  {/* TOD Table Skeleton */}
                  <div className="card pr-card mb30 alerttblCard">
                    <div className="card-header">
                      <Skeleton variant="text" width={100} height={30} />
                      <div className="form-inline pr-form-inline float-right">
                        <div className="input-group pr-form-group">
                          <Skeleton variant="rectangular" width={50} height={30} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <Skeleton variant="rectangular" width="100%" height={200} />
                    </div>
                  </div>

                  {/* Taps Table Skeleton */}
                  <div className="card pr-card mb30 alerttblCard">
                    <div className="card-header">
                      <Skeleton variant="text" width={100} height={30} />
                      <div className="form-inline pr-form-inline float-right">
                        <div className="input-group pr-form-group">
                          <Skeleton variant="rectangular" width={50} height={30} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <Skeleton variant="rectangular" width="100%" height={200} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NameplateSkeleton;