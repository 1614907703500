/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
// import ReactSpeedometer from "react-d3-speedometer";
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import { Alert } from "react-bootstrap";
import moment from "moment";
import PHSkeleton from "./PHSkeleton";

function OtherPh() {
  const chartRef = useRef(null);

  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pielabels, setPielabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [header, setHeader] = useState({ location: "", asset: "" });

  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [assetId, setAssetId] = useState("");
  const [phTable, setPhTable] = useState([]);
  const [gauge, setGauge] = useState([
    {
      // "min_pH": 1,
      // "max_pH": 7,
      // "pH": 8,
      // "created_at": "2023-11-29T10:40:30.000Z"
    },
  ]);
  const [chart, setChart] = useState([{}]);
  const [isChartDataAvailable, setIsChartDataAvailable] = useState(true);

  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        type: "category", // Specify x-axis type as category
        categories: [], // Empty initially until data is fetched
      },
    },
    series: [], // Initialize series array
  });
  const emptyChartData = {
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: "pH",
          style: {
            fontFamily: 'Montserrat',  // Set font family for X-axis title
            fontSize: '14px',  // Set font size for X-axis title
            fontWeight: 500, 
          }
        },
      },

      xaxis: {
        type: "category",
        title: {
          text: "Time",
          style: {
            fontFamily: 'Montserrat',  // Set font family for X-axis title
            fontSize: '14px',  // Set font size for X-axis title
            fontWeight: 500, 
          }
        },
        labels: {
          show: false,
        },
        categories: [],
      },
    },
    series: [],
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) { }
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    setOnloadAsset(props);
    // localStorage.setItem("asset", JSON.stringify(props));
    // setSelectedAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Other",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Other",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const [tableLastDate, setTableLastDate] = useState();

  // Ph Table
  const getPhTable = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token) {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/pH/pH_table`,
            props,
            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          if (response.data.data.length === 0) {
            // showFlashMessage(
            //   "Data Not Available For Summary ( Last 7 Days )",
            //   "danger"
            // );
            // setPhTable();
          }
          setPhTable(response.data.data);
          success = true;
          setTableLastDate(response.data.last_date);
        }
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(
        "Data Not Available For Summary ( Last 7 Days )",
        "danger"
      );
      setPhTable();
    }
  };

  const [minPH, setMinPH] = useState(null);

  // gauge
  const getGauge = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token) {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/pH/pH_gauge`,
            props,

            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          if (response.data.success) {
            setGauge(response.data.data);
            const { min_pH } = response.data.data[0];
            setMinPH(min_pH);
            success = true;
          } else {
            // showFlashMessage("Data Not Available For Last pH recorded", "danger");
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available For Last pH recorded", "danger");
    }
  };

  // meter //

  const value = gauge[0].pH;

  // Define custom segment stops and colors
  const customSegmentStops = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  // const isWithinRange1 = value >= min_pH && value <= max_pH;

  const numbersBetweenMinMax = [];
  const min_pH = gauge[0].min_pH;
  const max_pH = gauge[0].max_pH;

  // Generate numbers between min_pH and max_pH
  for (let i = min_pH + 0; i <= max_pH; i++) {
    numbersBetweenMinMax.push(i);
  }

  const segmentColor = customSegmentStops.map((value) => {
    const green = "#00B050";
    const red = "#BF0000";
    let color = "";

    if (numbersBetweenMinMax.includes(value)) {
      color = green; // Assign green color to numbers between min_pH and max_pH
    } else if (value >= 0 && value <= 14) {
      color = red; // Assign red color to numbers outside that range and not between min_pH and max_pH
      // Perform any operation with the number here if needed
    }

    return color;
  });

  // chart

  const [csvData, setCsvData] = useState([]);

  const getChart = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      setIsLoading(true);
      try {
        if (token) {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/pH/pH_trend`,
            props,
            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          const chartData = response.data.data;
          // [
          //     {
          //         "_id": "6566c7c844dba2a6d8f0af66",
          //         "pH": 7,
          //         "datetime": "2023-11-29 10:40:30",
          //         "min_pH": 6,
          //         "max_pH": 9
          //     }
          // ]
          if (chartData.length === 0) {
            // setIsLoading(false);
            // setIsChartDataAvailable(false);
            // setChartOptions(generateChartOptions());
            // showFlashMessage(
            //   "Data Not Available For pH Status (Last 2 Hours)",
            //   "danger"
            // );
          }
          setCsvData(chartData);
          success = true;
          setIsChartDataAvailable(true);
          setChartOptions(generateChartOptions(chartData));
          setIsLoading(false);
        }
      } catch (error) {
        setIsChartDataAvailable(false);
        console.error("Error fetching chart data:", error);
        setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setIsLoading(false);
      setIsChartDataAvailable(false);
      setChartOptions(generateChartOptions());
      showFlashMessage(
        "Data Not Available For pH Status (Last 2 Hours)",
        "danger"
      );
    }
  };

  useEffect(() => {
    if (csvData && csvData.length > 0) {
      setChartOptions(generateChartOptions(csvData));
    }
  }, [csvData]);

  const png = (pngId) => {
    const id = `${pngId}`;
    const svg = document.querySelector(`${id} svg`);
    svg.style.backgroundColor = "white";

    // create a temporary canvas element
    const canvas = document.createElement("canvas");
    // set the canvas dimensions to match the chart dimensions
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;
    canvas.backgroundColor = svg.style.backgroundColor;
    // get the canvas context
    const context = canvas.getContext("2d");
    // create a new Image object with the SVG data
    const image = new Image();
    // wait for the Image to load
    image.onload = function () {
      // draw the Image onto the canvas
      context.drawImage(image, 0, 0);
      // convert the canvas to a data URL
      const dataURL = canvas.toDataURL("image/jpeg", 2.0);
      // create a temporary link element
      const link = document.createElement("a");
      link.download = "chart.png";
      link.href = dataURL;
      // trigger a click event on the link element to initiate the download
      link.click();
      // cleanup the link element
      link.remove();
    };

    image.src =
      "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg.outerHTML);
  };

  const generateChartOptions = (data) => {
    const chartMin_pH = data && data.length > 0 ? data[0].min_pH : 0;
    const chartMax_pH = data && data.length > 0 ? data[0].max_pH : 14;
    const adjustedTime = moment(data.datetime);
    const time = adjustedTime.toDate();
    const greenData = data.filter(
      (entry) => entry.pH >= chartMin_pH && entry.pH <= chartMax_pH
    );
    const greenSeries = greenData.map((entry) => [entry.datetime, entry.pH]);
    const redData = data.filter(
      (entry) => entry.pH < chartMin_pH || entry.pH > chartMax_pH
    );
    const redSeries = redData.map((entry) => [entry.datetime, entry.pH]);

    // const chartDatas = csvData.map(item => ({
    //     x: item.datetime,
    //     y: item.pH,
    // }));

    const options = {
      series: [
        // {
        //     name: 'pH',
        //     data: chartDatas.map(data => data.y),
        // },
        {
          name: "Healthy",
          data: greenSeries,
          color: "#00B050",
        },
        {
          name: "Unhealthy",
          data: redSeries,
          color: "#BF0000",
        },
      ],
      chart: {
        type: "bar",
        height: 300,
        stacked: true,
        zoom: {
          enabled: false,
        },
        toolbar: {
          export: {
            csv: {
              filename: "pH Status (Last 2 Hours)",
              headerCategory: "Date Time",
              categoryFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                // originalDate.setHours(originalDate.getHours() - 5);
                // originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  ('0' + originalDate.getDate()).slice(-2) + '-' +
                  ('0' + (originalDate.getMonth() + 1)).slice(-2) + '-' +
                  (originalDate.getFullYear()) + ' ' +
                  ('0' + originalDate.getHours()).slice(-2) + ':' +
                  ('0' + originalDate.getMinutes()).slice(-2) + ':' +
                  ('0' + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "pH Status (Last 2 Hours)",
            },
            svg: {
              filename: "pH Status (Last 2 Hours)",
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
    },
      responsive: [
        {
          breakpoint: 1366,
          options: {
            chart: {
              height: 250,
            },
          },
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          connectNulls: true,
          dataLabels: {
            enabled: false, // Set to false to remove data labels on the bars
          },
          // columnWidth: '112%', // Adjusts the width of the bars
          // distributed: true, // Removes the gap between bars
        },
      },
      yaxis: {
        title: {
          text: "pH",
          style: {
            fontFamily: 'Montserrat',  // Set font family for X-axis title
            fontSize: '14px',  // Set font size for X-axis title
            fontWeight: 500, 
          }
        },
        min: 0,
        max: 14,
      },
      xaxis: {
        // categories: chartDatas.map(data => data.x),
        title: {
          text: "Time",
          style: {
            fontFamily: 'Montserrat',  // Set font family for X-axis title
            fontSize: '14px',  // Set font size for X-axis title
            fontWeight: 500, 
          }
        },
        type: "datetime",
        labels: {
          style: {
            fontFamily: 'Montserrat',  // Set font family for X-axis title
            fontSize: '14px',  // Set font size for X-axis title
            fontWeight: 500, 
          },
          formatter: function (value, timestamp) {
            return new Date(timestamp).toLocaleString("en-IN", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              // timeZone: "UTC",
            });
          },
        },
      },
      legend: {
        position: "top",
        onItemClick: {
          toggleDataSeries: false, // This line enables toggling of data series on legend click
        },
        style: {
          fontFamily: 'Montserrat',  // Set font family for X-axis title
          fontSize: '14px',  // Set font size for X-axis title
          fontWeight: 500, 
        }
      },
      tooltip: {
        y: {
          formatter: (val) => val,
          style: {
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
        },
        x: {
          formatter: (value) => moment(value).format("DD-MM-YYYY HH:mm"), // Show both date and time
        },
        style: {
          fontSize: "14px",
          fontFamily: "Montserrat",
          textAlign: "center", // Center-align tooltip text
        },
      },
      fill: {
        opacity: 1,
      },
    };

    return options;
  };
  const handleDownload = () => {
    if (!csvData || csvData.length === 0) {
      console.warn("No data available for download.");
      return;
    }

    // Convert data to CSV format
    const csvContent =
      "Datetime, pH\n" +
      csvData.map((entry) => `${entry.datetime}, ${entry.pH}`).join("\n");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "pH_data.csv");
    document.body.appendChild(link);

    // Trigger a click on the link to initiate the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const handleSubmitData = (props) => {
    const { location, site } = props;

    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;


    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(updatedLocation)
    setOnloadAsset(updatedSite)


    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // location_id: location.value,
        assset_id: site.value,
      };
      getPhTable(data);
      getGauge(data);
      getChart(data);
    }
  };

  const handleSubmitDataOnClick = (props) => {
    const { location, site } = props;

    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;


    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(updatedLocation)
    setOnloadAsset(updatedSite)


    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // location_id: location.value,
        assset_id:  onloadAsset.value,
      };
      getPhTable(data);
      getGauge(data);
      getChart(data);
    }
  };

  // line chart //
  const state = {
    options: {
      chart: {
        id: "area-chart",
      },
      xaxis: {
        categories: [
          "12-11-23",
          "13-12-23",
          "14-12-23",
          "15-12-23",
          "16-12-23",
          "17-12-23",
          "18-12-23",
        ],
      },
      colors: ["#BF0000", "#00B050"],
    },
    series: [
      {
        name: "Series 1",
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      },

      {
        name: "Series 2",
        data: [8, 9, 10, 11, 12, 13, 14],
      },
    ],
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // if (isLoading) {
  //     return (
  //         <div className="loaderWpr">
  //             <div className="loader"></div>
  //         </div>
  //     );
  // }

  // date format for breadcrums
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  const lastDate = phTable.length > 0 ? phTable[phTable.length - 1].date : null;

  return (
    <>
      <div className="wrapper">
        {isLoading && (
          <PHSkeleton/>
        )}
        
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30">
                    <div className="breadcrumbHdr card-header">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#">{selectedAsset.label}</a>
                        </li>
                        <li className="breadcrumb-item ">
                          <a href="#">{selectedLocation.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>{tableLastDate}</a>
                          {/* <a>{lastDate}</a> */}
                          {/* <a href="#"> {formatDate(toDate)}</a> */}
                        </li>
                        {/*   &nbsp;-&nbsp;
                                                <li className="">
                                                    <a href="#"> date</a>
                                                </li> */}
                      </ol>
                    </div>
                    <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={handleSubmitDataOnClick}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
      />
                    <div className="mdlContent card-body">
                      <div className="row">
                        <div className="ph_gauge col-md-2 col-lg-2">
                          <div className="card pr-card mb30">
                            <div className="inrCardHdr">
                              <h5>Last pH recorded</h5>
                            </div>
                            <div className="inrCardBody text-center">
                              <p>
                                {gauge.length > 0 &&
                                  gauge[0].pH !== null &&
                                  gauge[0].pH !== "" &&
                                  gauge[0].pH >= gauge[0].min_pH &&
                                  gauge[0].pH <= gauge[0].max_pH
                                  ? "Healthy"
                                  : gauge[0].pH === undefined ||
                                    gauge[0].pH === null ||
                                    gauge[0].pH === ""
                                    ? "-"
                                    : "Unhealthy"}{" "}
                              </p>
                              <div
                                className={`lastValue ${gauge.length > 0 &&
                                  gauge[0].pH >= gauge[0].min_pH &&
                                  gauge[0].pH <= gauge[0].max_pH
                                  ? "healthy"
                                  : "unhealthy"
                                  }`}
                              >
                                {gauge.length > 0 && gauge[0].pH}
                              </div>
                              <p>
                                {
                                  gauge[0]?.created_at
                                    ? new Date(gauge[0].created_at).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                      timeZone: "UTC",
                                    }).replace(/\//g, '-') +
                                    ' ' +
                                    new Date(gauge[0].created_at).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                      hour12: false,
                                      timeZone: "UTC",
                                    })
                                    : "-"
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ph_bar col-md-10 col-lg-10">
                          <div className="card pr-card mb30">
                            <div className="inrCardHdr">
                              <h5>Summary ( Last 7 Days )</h5>
                            </div>
                            <div className="inrCardBody">
                              <div className="table-responsive">
                                <table className="table phTable table-bordered">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      {phTable.map((item, index) => (
                                        <th key={index}>{item.date}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th>pH Violations</th>
                                      {phTable.map((item, index) => (
                                        <td key={index}>
                                          {item.pH_violations}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <th>Min pH</th>
                                      {phTable.map((item, index) => (
                                        <td key={index}>
                                          {item.min_pH !== null
                                            ? item.min_pH
                                            : "-"}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <th>Max pH</th>
                                      {phTable.map((item, index) => (
                                        <td key={index}>
                                          {item.max_pH !== null
                                            ? item.max_pH
                                            : "-"}
                                        </td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>pH Status (Last 2 Hours)</h5>
                        </div>
                        <div className="inrCardBody">
                          <div className="barChartWpr">
                            {/* Your form or component triggering handleSubmitData */}
                            {/* Render the chart component */}
                            {/* {chart && chart.options && chart.series && chart.options.chart && ( */}
                            <div>
                              {isChartDataAvailable ? (
                                <div id="chart">
                                  <Chart
                                    options={chartOptions}
                                    series={chartOptions.series}
                                    type="bar"
                                    height={
                                      chartOptions.chart
                                        ? chartOptions.chart.height
                                        : 300
                                    } // Providing a default height
                                  />
                                </div>
                              ) : (
                                <Chart
                                  options={emptyChartData.options}
                                  series={emptyChartData.series}
                                  type="bar"
                                  height={300}
                                />
                              )}
                            </div>

                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default OtherPh;
