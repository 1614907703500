import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Chart from "react-apexcharts";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import Sidebar from "./Sidebar";
// import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";



function Moisture() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));

  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const params = new URLSearchParams(useLocation().search);

  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const [onloadLoc, setOnloadLoc] = useState("");
  const [parameterData, setParameterData] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState("");
  const [firstPara, setFirstPara] = useState([]);
  const [isParam, setIsParam] = useState(false);
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onload, setOnLoad] = useState(true);
  const [allParams, setAllParams] = useState(false);
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [chartData, setChartData] = useState({ series: [], options: {}, });
  const [twochartData, setTwochartData] = useState({ series: [], options: {} });
  const [dpchartData, setDpchartData] = useState({ series: [], options: {} });
  const [headerValues, setHeaderValues] = useState({
    oilMoisture: "-",
    bottomOilTemperature: "-",
    humidity: "-"
  });
  const [onloadParameter, setOnloadParameter] = useState("");
  const [stringParameters, setStringParameters] = useState([]);


  const colors = [
    "#2A73B9",
    "#8EC5EC",
    "#F15A2A",
    "#F7941E",
    "#39B54A",
    "#D7DF24",
    "#92278F",
    "#EC008D",
    "#FF0000",
    " #FF33CC",
  ];

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorage.removeItem("asset");
      localStorage.removeItem("location");
      getLocation();
    }
  }, [token]);
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      getLocation();
    }

  }, []);

  useEffect(() => {
    if (token && locationData.length > 0) {
      // console.log("Calling localStorageLocation, locationData is available");
      localStorageLocation();
    }
  }, [token, locationData]);

  useEffect(() => {
    if (token && assetData.length > 0) {
      // console.log("Calling localStorageAsset, assetData is available");
      localStorageAsset();
    }
  }, [token, assetData]);


  useEffect(() => {
    if (token && userLogId && selectedLocation && selectedAsset) {
      // console.log("Calling getGraphData...");
      getGraphData();
      getGraphDataTwo();
      fetchHeaderTitle();
      dpvsExpectedlife();
    } else {
      // console.log("Skipping getGraphData due to missing values", {
      //   token,
      //   userLogId,
      //   selectedLocation,
      //   selectedAsset
      // });
    }
  }, [token, userLogId, selectedLocation, selectedAsset]);
  useEffect(() => {
    if (initializationComplete && onloadLoc && onloadAsset) {
      // console.log("Calling handleSubmitData...");
      let data = {
        fromDate: fromDate,
        toDate: toDate,
        location: onloadLoc,
        site: onloadAsset,
      };

      const storedParameter = JSON.parse(localStorage.getItem("parameter"));
      const updatedParameter = storedParameter || onloadParameter;

      data =
        isParam === false
          ? { ...data, parameter: updatedParameter }
          : data;

      // console.log("Calling handleSubmitData with:", data);
      handleSubmitData(data);
      setOnLoad(false);
    } else {
      // console.log("Skipping handleSubmitData due to missing values", {
      //   initializationComplete,
      //   onloadLoc,
      //   onloadAsset
      // });
    }
  }, [initializationComplete, onloadLoc, onloadAsset]);

  // useEffect(() => {
  //   if (fromDate && toDate) {
  //     getGraphData();
  //   }
  // }, [fromDate, toDate]);


  useEffect(() => {
    if (initializationComplete) {
      let data = {
        fromDate: fromDate,
        toDate: toDate,
        location: onloadLoc,
        site: onloadAsset,
      };
      const storedParameter = JSON.parse(localStorage.getItem("parameter"));
      const updatedParameter = storedParameter || onloadParameter;
      data =
        isParam === false
          ? Object.assign({}, data, { parameter: updatedParameter })
          : data;
      handleSubmitData(data);
      setOnLoad(false); // Set onload to false after handling submit data
    }
  }, [initializationComplete]);

  const localStorageLocation = () => {
    if (!locationData || locationData.length === 0) {
      // console.log("Skipping localStorageLocation because locationData is empty");
      return;
    }

    const storedLocation = JSON.parse(localStorage.getItem("location"));

    if (storedLocation && locationData.some(item => item.value === storedLocation.value)) {
      setSelectedLocation(storedLocation);
      setOnloadLoc(storedLocation);
      getAsset(storedLocation.value);
      return;
    }

    if (locationData.length > 0) {
      setOnloadLoc(locationData[0]);
      setSelectedLocation(locationData[0]);
      getAsset(locationData[0].value);
    }
  };



  const localStorageAsset = () => {
    if (!assetData || assetData.length === 0) {
      // console.log("Skipping localStorageAsset because assetData is empty");
      return;
    }

    const storedAsset = JSON.parse(localStorage.getItem("asset"));

    if (storedAsset && assetData.some(item => item.value === storedAsset.value)) {
      setSelectedAsset(storedAsset);
      setOnloadAsset(storedAsset);
      return;
    }

    if (assetData.length > 0) {
      setOnloadAsset(assetData[0]);
      setSelectedAsset(assetData[0]);
    }
  };


  const changeLocation = (props) => {
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };
  const changeAsset = (props) => {
    setOnloadAsset(props);
  };

  const getLocation = async () => {
    if (!token) {
      // console.log("Skipping getLocation due to missing token");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
        {
          access_token: token,
          user_log_id: userLogId,
          iot_sol: "Transformer",
        }
      );

      if (response.status === 200 && response.data.success) {
        const data = response.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        setLocationData(data);

        if (data.length > 0) {
          // console.log("Location data available, calling localStorageLocation");
          localStorageLocation();
        } else {
          console.log("No location data available");
        }
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };



  const getAsset = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer",
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));
            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
            }
          } else {
            setAssetData([]);
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };




  const getGraphData = async () => {
    
    if (!token) {
      // console.log("Skipping API call due to missing token");
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);

      // 🛠️ Use user-selected dates if available, otherwise default to last 24 hours
      const startDate = fromDate ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss") : moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
      const endDate = toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");


      const payload = {
        access_token: token,
        user_log_id: userLogId,
        start_date: startDate,
        end_date: endDate,
        location_id: selectedLocation.value,
        assset_id: selectedAsset.value,
        iot_sol: "Transformer",
      };

      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/moisture/BDV_Vs_Oil_Moisture`,
            payload
          );

          if (response.status === 200 ) {
            console.log("API Response:", response.data);
            setChartData({
              series: response.data.data.map((series) => ({
                name: series.name,
                data: series.data.map(([timestamp, value]) => [
                  timestamp,
                  value !== null ? value : 0, // Replace null with 0
                ]),
              })).reverse(),

            });
            console.log("Chart Data:", response.data.data);

            success = true;
          }
        } else {
          setChartData({ series: [] }); 
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setChartData({ series: [] }); 
      }
      attempts++;
    }
    setIsLoading(false);
  };
  const getGraphDataTwo = async () => {
    if (!token) {
      // console.log("Skipping API call due to missing token");
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);

      // 🛠️ Use user-selected dates if available, otherwise default to last 24 hours
      const startDate = fromDate ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss") : moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
      const endDate = toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");


      const payload = {
        access_token: token,
        user_log_id: userLogId,
        start_date: startDate,
        end_date: endDate,
        location_id: selectedLocation.value,
        assset_id: selectedAsset.value,
        iot_sol: "Transformer",
      };

      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/moisture/Paper_Moisture_vs_Oil_Moisture`,
            payload
          );

          if (response.status === 200) {

            setTwochartData({
              series: response.data.data.map((series) => ({
                name: series.name,
                // data: series.data.filter(([_, value]) => value !== null),
                data: series.data.map(([timestamp, value]) => [
                  timestamp,
                  value !== null ? value : 0, // Replace null with 0
                ]),
              })).reverse(),

            });
            success = true;
          }
        } else {

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      attempts++;
    }
    setIsLoading(false);
  };
  const dpvsExpectedlife = async () => {
    if (!token) {
      // console.log("Skipping API call due to missing token");
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);

      // 🛠️ Use user-selected dates if available, otherwise default to last 24 hours
      const startDate = fromDate ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss") : moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
      const endDate = toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");


      const payload = {
        access_token: token,
        user_log_id: userLogId,
        start_date: startDate,
        end_date: endDate,
        location_id: selectedLocation.value,
        assset_id: selectedAsset.value,
        iot_sol: "Transformer",
      };

      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/moisture/DP_vs_Expected_life`,
            payload
          );

          if (response.status === 200 ) {

            setDpchartData({
              series: response.data.data.map((series) => ({

                name: series.name,
                // data: series.data.filter(([_, value]) => value !== null),
                data: series.data.map(([timestamp, value]) => [
                  timestamp,
                  value !== null ? value : 0, // Replace null with 0
                ]),
              })).reverse(),

            });
            success = true;
          }
        } else {

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      attempts++;
    }
    setIsLoading(false);
  };

  const handleFallbackTask = () => {
   
    setHeaderValues({
      oilMoisture: "-",
      bottomOilTemperature: "-",
      humidity: "-",
    });
  };

  const fetchHeaderTitle = async () => {
    if (!token) {
      // console.log("Skipping API call for header title due to missing token");
      return;
    }

    const startDate = fromDate ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss") : moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
    const endDate = toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");

    const payload = {
      access_token: token,
      user_log_id: userLogId,
      start_date: startDate,
      end_date: endDate,
      location_id: selectedLocation.value,
      assset_id: selectedAsset.value,
      iot_sol: "Transformer",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/moisture/moisture_parameters`,
        payload
      );

      if (response.status === 200 && response.data.success) {
        const data = response.data.data;
        const formatValue = (value) =>
          value !== undefined && !isNaN(value) ? Number(value).toFixed(2) : "--";

        const oilMoisture = formatValue(data.find(item => item.oil_moisture !== undefined)?.oil_moisture);
        const bottomOilTemperature = formatValue(data.find(item => item.bottom_oil_temperature !== undefined)?.bottom_oil_temperature);
        const humidity = formatValue(data.find(item => item.humidity !== undefined)?.humidity);

        setHeaderValues({ oilMoisture, bottomOilTemperature, humidity });

      } else {
        setHeaderValues([{}])
        handleFallbackTask();
        // console.log("Error fetching header values:");
      }
    } catch (error) {
      console.error("Error fetching header values:", error);
    }
  };
  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const breadcrum_to_api = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [day, month, year] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    return myDate;
  };
  const handleSubmitData = (props) => {
    // console.log("Received data from Sidebar:", props); 
    if (!props.location || !props.site) {
      console.error("Missing location or asset. Cannot proceed.");
      return;
    }

    setSelectedLocation(props.location);
    setSelectedAsset(props.site);

    if (token) {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: props.location.value,
        assset_id: props.site.value,
      };
      // console.log("Calling getGraphData with:", data);
      getGraphData();
      getGraphDataTwo();
      fetchHeaderTitle();
      dpvsExpectedlife();
    }
  };


  const SubmitDataWithDate = (props) => {
    const { fromDate, toDate, location, site, parameter, group } = props;

    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    localStorage.setItem("parameter", JSON.stringify(parameter));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);
    setSelectedParameter(parameter);
    setAllParams(false);

    if (props.fromDate && props.toDate) {
      setNewStartDate(
        breadcrum_date_converter(convertDateCloneApi(props.fromDate))
      );
      setNewEndDate(
        breadcrum_date_converter(convertDateCloneApi(props.toDate))
      );
    }

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
      };
      getGraphData(data);
      getGraphDataTwo(data);
      fetchHeaderTitle(data);
      dpvsExpectedlife(data);
    }
  };
  const [alerts, setAlerts] = React.useState([]);
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };





 function formatDate(date) {
    if (!date) return ""; // Handle null/undefined
  
    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);
  
    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check
  
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };







  // BDV vs Oil chart start
  // const [chartData, setChartData] = useState({
  //   series: [
  //     {
  //       name: "BVD (kv)",
  //       data: [22, 24, 28, 30, 27, 26, 25],
  //     },
  //     {
  //       name: "Oil Moisture (PPM)",
  //       data: [65, 60, 70, 75, 72, 68, 66],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "line",
  //       // height: 250,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     // title: {
  //     //   text: "BDV VS Oil Moisture",
  //     //   align: "center",
  //     //   style: {
  //     //     fontFamily: 'Montserrat, sans-serif', 
  //     //     fontSize: "15px",
  //     //     fontWeight: "bold",
  //     //     color: "#000",
  //     //   },
  //     // },
  //     responsive: [
  //       { breakpoint: 1921, options: { chart: { height: 270 } } },
  //       {
  //         breakpoint: 1601, options: {
  //           chart: { height: 230 },
  //           // title: { style: { fontSize: "12px"} } 
  //         }
  //       },
  //       { breakpoint: 1367, options: { chart: { height: 160 } } },
  //     ],
  //     stroke: {
  //       width: [3, 3], // Line thickness
  //       curve: "smooth", // Smooth curve
  //     },
  //     colors: ["#FF5733", "#33B5FF"], // Different colors for lines
  //     dataLabels: {
  //       enabled: false, // Hide data labels
  //     },
  //     xaxis: {
  //       categories: ["2025-1-1 12:45", "2025-1-2 15:05", "2025-1-3 02:14", "2025-1-4 10:40", "2025-1-5 18:45", "2025-1-6 22:50", "2025-1-7 11:05"],
  //     },
  //     yaxis: [
  //       {
  //         title: {
  //           text: "BVD (kv)",
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontSize: '14px',
  //           fontWeight: '500',
  //           color: '#000000',
  //         },
  //         min: 20,
  //         max: 35,
  //       },
  //       {
  //         opposite: true, // Align second Y-axis to the right
  //         title: {
  //           text: "Oil Moisture (PPM)",
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontSize: '14px',
  //           fontWeight: '500',
  //           color: '#000000',
  //         },
  //         min: 50,
  //         max: 80,
  //       },
  //     ],
  //     tooltip: {
  //       shared: true, // Show both Y-axis values on hover
  //       intersect: false,
  //     },
  //     legend: {
  //       position: "top",
  //     },
  //   },
  // });


  // paper vs Oil chart start
  // const [paperOilchartData, paperOilsetChartData] = useState({
  //   series: [
  //     {
  //       name: "Paper Moisture (%)",
  //       data: [22, 24, 28, 30, 27, 26, 25],
  //     },
  //     {
  //       name: "Oil Moisture (PPM)",
  //       data: [65, 60, 70, 75, 72, 68, 66],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "line",
  //       // height: 250,
  //       toolbar: {
  //         show: false, // Hides zoom, download, and other toolbar icons
  //       },
  //     },

  //     responsive: [
  //       { breakpoint: 1921, options: { chart: { height: 270 } } },
  //       { breakpoint: 1601, options: { chart: { height: 230 } } },
  //       { breakpoint: 1367, options: { chart: { height: 160 } } },
  //     ],
  //     stroke: {
  //       width: [3, 3], // Line thickness
  //       curve: "smooth", // Smooth curve
  //     },
  //     colors: ["#FF5733", "#33B5FF"], // Different colors for lines
  //     dataLabels: {
  //       enabled: false, // Hide data labels
  //     },
  //     xaxis: {
  //       categories: ["2025-1-1 12:45", "2025-1-2 15:05", "2025-1-3 02:14", "2025-1-4 10:40", "2025-1-5 18:45", "2025-1-6 22:50", "2025-1-7 11:05"],
  //     },
  //     yaxis: [
  //       {
  //         title: {
  //           text: "Paper Moisture (%)",
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontSize: '14px',
  //           fontWeight: '500',
  //           color: '#000000',
  //         },
  //         min: 20,
  //         max: 35,
  //       },
  //       {
  //         opposite: true, // Align second Y-axis to the right
  //         title: {
  //           text: "Oil Moisture (PPM)",
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontSize: '14px',
  //           fontWeight: '500',
  //           color: '#000000',
  //         },
  //         min: 50,
  //         max: 80,
  //       },
  //     ],
  //     tooltip: {
  //       shared: true, // Show both Y-axis values on hover
  //       intersect: false,
  //     },
  //     legend: {
  //       position: "top",
  //     },
  //   },
  // });
  const downloadCSV = (data, filename, type) => {
    if (!data || data.series.length === 0) {
      alert("No data available to download.");
      return;
    }
  
    let csvContent = "data:text/csv;charset=utf-8,";
  
    // Determine column names based on type
    if (type === "bdv") {
      csvContent += "Datetime,Oil Moisture Value,BDV Value\n";
    } else if (type === "paper") {
      csvContent += "Datetime,Oil Moisture Value,Paper Moisture Value\n";
    } else if (type === "dp") {
      csvContent += "Datetime,Data\n"; // Only two headers for DP vs Expected Life
    }
  
    const formattedData = {};
  
    data.series.forEach((series) => {
      series.data.forEach(([timestamp, value]) => {
        const formattedDate = moment(timestamp).format("YYYY-MM-DD HH:mm");
  
        if (!formattedData[formattedDate]) {
          formattedData[formattedDate] = { oilMoisture: null, otherValue: null, dpValue: null, expectedLife: null };
        }
  
        if (series.name.toLowerCase().includes("oil moisture")) {
          formattedData[formattedDate].oilMoisture = value ?? 0;
        } else if (type === "bdv" || type === "paper") {
          formattedData[formattedDate].otherValue = value ?? 0;
        } else if (type === "dp") {
          if (series.name.toLowerCase().includes("dp")) {
            formattedData[formattedDate].dpValue = value ?? 0;
          } else {
            formattedData[formattedDate].expectedLife = value ?? 0;
          }
        }
      });
    });
  
    Object.entries(formattedData).forEach(([datetime, values]) => {
      if (type === "dp") {
        // For DP vs Expected Life, only include datetime and data
        csvContent += `${datetime},${values.dpValue ?? ""}\n`;
      } else {
        csvContent += `${datetime},${values.oilMoisture ?? ""},${values.otherValue ?? ""}\n`;
      }
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}


      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <Row>
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                    
                      <li className="breadcrumb-item">
                        <a href="#">{selectedAsset.label}</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <a href="#">{selectedLocation.label}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">
                          {" "}
                          {newStartDate ? (
                            <a href="#">{newStartDate}</a>
                          ) : (
                            <a href="#">{formatDate(fromDate)}</a>
                          )}
                          &nbsp;-&nbsp;
                          {newEndDate ? (
                            <a href="#">{newEndDate}</a>
                          ) : (
                            <a href="#">{formatDate(toDate)}</a>
                          )}
                        </a>
                      </li>
                    </ol>
                  </div>
                  <Sidebar
        // validateGroupSelection={validateGroupSelection}
        // setOnLoad={setOnLoad}
        locationData={locationData}
        assetData={assetData}
        parameterData={parameterData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        //  changeParameter={changeParameter}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        selectedParameter={selectedParameter}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        isParam={isParam}
        // changeIsParam={changeIsParam}
        // handleAddGroup={handleAddGroup}  
        // groupData={groupData}
        // getGroupName={getGroupName}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                  <div className="mdlContent card-body">
                        {/* <div className="dlySmryPg mdlContent card-body">
                    
    
              
                 </div> */}
                    <Row>
                      <div className="col-md-4 col-lg-4">
                        <div className="card pr-card">
                          <div className="inrCardHdr">
                            <h5>Oil Moisture (PPM)</h5>
                          </div>
                          <div className="inrCardBody text-center">
                            <h3 className="mb0">{headerValues.oilMoisture}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="card pr-card">
                          <div className="inrCardHdr">
                            <h5>Bottom Oil Temperature (<sup>o</sup>C)</h5>
                          </div>
                          <div className="inrCardBody text-center">
                            <h3 className="mb0">{headerValues.bottomOilTemperature}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="card pr-card">
                          <div className="inrCardHdr">
                            <h5>Humidity (%)</h5>
                          </div>
                          <div className="inrCardBody text-center">
                            <h3 className="mb0">{headerValues.humidity}</h3>
                          </div>
                        </div>
                      </div>
                    </Row>
                

                    <div className="inrCardHdr">
                      <h5>Moisture Analysis   </h5>
                    </div>
                    <div className="card pr-card">
                      {/* <div className="inrCardHdr">
                        <h5>Oil Moisture vs BDV    </h5>
                      </div> */}
                      <div className="inrCardBody text-center">
                        <div className="">
                          {/* {!isLoading && chartData.series.length > 0 && (
                            <a alt="Download CSV" className="csvTwo" onClick={() => downloadCSV(chartData, "Oil_Moisture_vs_BDV.csv", "bdv")}> </a>
                          )} */}
                          {isLoading ? (
                            <h5>Loading data...</h5>
                          ) : chartData.series.length === 0 ? (
                            <h5>No data available For Oil Moisture vs BDV</h5>
                          ) : (
                            <Chart options={{
                              chart: { type: "line",
                                toolbar: {
                                  tools: {
                                    selection: false,  // ❌ Remove selection
                                    zoom: false,       // ❌ Remove zoom
                                    zoomin: false,     // ❌ Remove zoom-in
                                    zoomout: false,    // ❌ Remove zoom-out
                                    pan: false,        // ❌ Remove panning
                                    reset: false,      // ❌ Remove reset zoom
                                  },
                                  autoSelected: "menu",
                                export: {
                                csv: {
                                  filename: "Oil Moisture vs BDV",
                                  headerCategory: "Date Time",
                                  categoryFormatter: function (timestamp) {
                                    let date = new Date(timestamp).toUTCString();
                                    const originalDate = new Date(date);
                      
                                    // Add 5 hours and 30 minutes to the original date
                                    originalDate.setHours(originalDate.getHours() - 5);
                                    originalDate.setMinutes(originalDate.getMinutes() - 30);
                      
                                    // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                                    const formattedDate =
                                      ("0" + originalDate.getDate()).slice(-2) +
                                      "-" +
                                      ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                                      "-" +
                                      originalDate.getFullYear() +
                                      " " +
                                      ("0" + originalDate.getHours()).slice(-2) +
                                      ":" +
                                      ("0" + originalDate.getMinutes()).slice(-2) +
                                      ":" +
                                      ("0" + originalDate.getSeconds()).slice(-2);
                                    return formattedDate;
                                  },
                                },
                                png: {
                                  filename: "Oil Moisture vs BDV",
                                },
                                svg: {
                                  filename: "Oil Moisture vs BDV",
                                },
                              }, },},
                              responsive: [
                                { breakpoint: 1921, options: { chart: { height: 270 } } },
                                { breakpoint: 1601, options: { chart: { height: 230 } } },
                                { breakpoint: 1367, options: { chart: { height: 160 } } },
                              ],

                              colors: ["#F7941E", "#2A73B9",],
                              stroke: { curve: "smooth", width: [3, 3] },
                              dataLabels: {
                                enabled: false, // Hide data labels
                              },
                              xaxis: {
                                type: "datetime",
                                title: {
                                  // text: "Datetime", // Set your X-axis title here
                                  style: {
                                    fontFamily: "Montserrat, sans-serif", // Set font family for X-axis title
                                    fontSize: "10px", // Set font size for X-axis title
                                    fontWeight: 500,
                                  },
                                },

                                labels: {
                                  datetimeFormatter: {
                                    day: "yyyy/MM/dd",
                                    month: "yyyy/MM",
                                    year: "yyyy",
                                    hour: "yyyy/MM/dd HH:mm",
                                  },
                                  style: {
                                    fontFamily: "Montserrat, sans-serif", // Set font family for X-axis title
                                    fontSize: "10px", // Set font size for X-axis title
                                    fontWeight: 500,
                                  },
                                },
                              },
                              yaxis: [
                                {
                                  title: { text: "Oil Moisture (PPM)" },
                                  labels: {
                                    formatter: value => Number(value).toFixed(2) // Format Y-axis values to 2 decimal places
                                  }
                                },
                                {
                                  opposite: true,
                                  title: { text: "BDV (kV)" },
                                  labels: {
                                    formatter: value => Number(value).toFixed(2) // Format Y-axis values to 2 decimal places
                                  }
                                },
                              ],

                              tooltip: {
                                enabled: true,
                                shared: true, // ✅ Ensure multiple Y-axis values appear
                                intersect: false,
                                followCursor: true, // ✅ Tooltip follows the cursor for better visibility
                                x: {
                                  format: "dd-MM-yyyy HH:mm:ss",
                                },
                                cssClass: "custom-tooltip",
                                style: {
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: "10px",
                                },
                              },

                              legend: {
                                position: "top",
                              },
                            }}
                              series={chartData.series} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="card pr-card">


                          <div className="inrCardBody text-center">



                            {/* {!isLoading && twochartData.series.length > 0 && (
                              <a alt="Download CSV" className="csvTwo" onClick={() => downloadCSV(twochartData, "Oil_Moisture_vs_Paper.csv", "paper")}> </a>
                            )} */}
                            {isLoading ? (
                              <h5>Loading data...</h5>
                            ) : twochartData.series.length === 0 ? (
                              <h5>No data available For Oil Moisture vs Paper Moisture</h5>
                            ) : (
                              <Chart options={{
                                chart: { type: "line",
                                  toolbar: {
                                    tools: {
                                      selection: false,  // ❌ Remove selection
                                      zoom: false,       // ❌ Remove zoom
                                      zoomin: false,     // ❌ Remove zoom-in
                                      zoomout: false,    // ❌ Remove zoom-out
                                      pan: false,        // ❌ Remove panning
                                      reset: false,      // ❌ Remove reset zoom
                                    },
                                    autoSelected: "menu", // ✅ Keep only the menu
                                    export: {
                                      csv: {
                                        filename: "Oil Moisture vs Paper Moisture",
                                        headerCategory: "Date Time",
                                        categoryFormatter: function (timestamp) {
                                          let date = new Date(timestamp).toUTCString();
                                          const originalDate = new Date(date);
                            
                                          // Add 5 hours and 30 minutes to the original date
                                          originalDate.setHours(originalDate.getHours() - 5);
                                          originalDate.setMinutes(originalDate.getMinutes() - 30);
                            
                                          // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                                          const formattedDate =
                                            ("0" + originalDate.getDate()).slice(-2) +
                                            "-" +
                                            ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                                            "-" +
                                            originalDate.getFullYear() +
                                            " " +
                                            ("0" + originalDate.getHours()).slice(-2) +
                                            ":" +
                                            ("0" + originalDate.getMinutes()).slice(-2) +
                                            ":" +
                                            ("0" + originalDate.getSeconds()).slice(-2);
                                          return formattedDate;
                                        },
                                      },
                                      png: {
                                        filename: "Oil Moisture vs Paper Moisture",
                                      },
                                      svg: {
                                        filename: "Oil Moisture vs Paper Moisture",
                                      },
                                    },
                                  }, },
                                responsive: [
                                  { breakpoint: 1921, options: { chart: { height: 270 } } },
                                  { breakpoint: 1601, options: { chart: { height: 230 } } },
                                  { breakpoint: 1367, options: { chart: { height: 160 } } },
                                ],

                                colors: ["#F7941E", "#FF0000"],
                                stroke: { curve: "smooth", width: [3, 3] },
                                dataLabels: {
                                  enabled: false, // Hide data labels
                                },
                                xaxis: {
                                  type: "datetime",
                                  title: {
                                    // text: "Datetime", // Set your X-axis title here
                                    style: {
                                      fontFamily: "Montserrat, sans-serif", // Set font family for X-axis title
                                      fontSize: "10px", // Set font size for X-axis title
                                      fontWeight: 500,
                                    },
                                  },

                                  labels: {
                                    datetimeFormatter: {
                                      day: "yyyy/MM/dd",
                                      month: "yyyy/MM",
                                      year: "yyyy",
                                      hour: "yyyy/MM/dd HH:mm",
                                    },
                                    style: {
                                      fontFamily: "Montserrat, sans-serif", // Set font family for X-axis title
                                      fontSize: "10px", // Set font size for X-axis title
                                      fontWeight: 500,
                                    },
                                  },
                                },
                                yaxis: [

                                  {
                                    title: { text: "Oil Moisture (PPM)" }, labels: {
                                      formatter: value => Number(value).toFixed(2) // Format Y-axis values to 2 decimal places
                                    }
                                  },

                                  {
                                    opposite: true,
                                    title: { text: "Paper Moisture (%)" }, labels: {
                                      formatter: value => Number(value).toFixed(2) // Format Y-axis values to 2 decimal places
                                    }
                                  },


                                ],
                                tooltip: {
                                  x: {
                                    format: "dd-MM-yyyy HH:mm:ss",
                                  },
                                  cssClass: "custom-tooltip",
                                  style: {
                                    fontFamily: "Montserrat, sans-serif", // Set font family for tooltip
                                    fontSize: "10px", // Set font size for tooltip
                                  },

                                },
                                legend: {
                                  position: "top",
                                },
                              }}
                                series={twochartData.series} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div className="card pr-card">
                          <div className="text-center">
                           {/* {dpchartData.series.length === 0 ? (
                              <h5></h5>
                            ):(<h5 className="text-center">DP vs Expected Life    </h5>)}  */}
                          </div>

                          <div className="inrCardBody text-center">
                            {/* {!isLoading && dpchartData.series.length > 0 && (
                              <a alt="Download CSV" className="csvTwo" onClick={() => downloadCSV(dpchartData, "DP_vs_Expected_life.csv", "dp")}> </a>
                            )} */}
                            {isLoading ? (
                              <h5>Loading data...</h5>
                            ) : dpchartData.series.length === 0 ? (
                              <h5>No data available for DP vs Expected Life</h5>
                            ) : (
                              <Chart
                                options={{
                                  chart: { type: "line", toolbar: {
                                    tools: {
                                      selection: false,  // ❌ Remove selection
                                      zoom: false,       // ❌ Remove zoom
                                      zoomin: false,     // ❌ Remove zoom-in
                                      zoomout: false,    // ❌ Remove zoom-out
                                      pan: false,        // ❌ Remove panning
                                      reset: false,      // ❌ Remove reset zoom
                                    },
                                    autoSelected: "menu",
                                     export: {
                                    csv: {
                                      filename: "DP vs Expected life",
                                      headerCategory: "Date Time",
                                      categoryFormatter: function (timestamp) {
                                        let date = new Date(timestamp).toUTCString();
                                        const originalDate = new Date(date);
                          
                                        // Add 5 hours and 30 minutes to the original date
                                        originalDate.setHours(originalDate.getHours() - 5);
                                        originalDate.setMinutes(originalDate.getMinutes() - 30);
                          
                                        // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                                        const formattedDate =
                                          ("0" + originalDate.getDate()).slice(-2) +
                                          "-" +
                                          ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                                          "-" +
                                          originalDate.getFullYear() +
                                          " " +
                                          ("0" + originalDate.getHours()).slice(-2) +
                                          ":" +
                                          ("0" + originalDate.getMinutes()).slice(-2) +
                                          ":" +
                                          ("0" + originalDate.getSeconds()).slice(-2);
                                        return formattedDate;
                                      },
                                    },
                                    png: {
                                      filename: "DP vs Expected Life",
                                      show:false
                                    },
                                    svg: {
                                      filename: "DP vs Expected Life",
                                      show:false
                                    },
                                  }, },},
                                  responsive: [
                                    { breakpoint: 1921, options: { chart: { height: 270 } } },
                                    { breakpoint: 1601, options: { chart: { height: 230 } } },
                                    { breakpoint: 1367, options: { chart: { height: 160 } } },
                                  ],
                                  colors: ["#8EC5EC", "#FFBF00"], // Yellow for actual, Red for limit
                                  stroke: {
                                    curve: "smooth",  
                                    width: [4, 4],
                                    dashArray: [0, 5] // Solid for actual, Dashed for limit
                                  },
                                  dataLabels: { enabled: false },
                                  xaxis: {
                                    type: "datetime",
                                    // title: { text: "Date Time", }, // Expected Life (Hours)
                                    labels: {
                                      show: false,
                                      datetimeFormatter: {
                                        day: "yyyy/MM/dd",
                                        month: "yyyy/MM",
                                        year: "yyyy",
                                        hour: "yyyy/MM/dd HH:mm",
                                      },
                                      style: { fontSize: "10px", fontWeight: 500, fontFamily: "Montserrat, sans-serif" },
                                    },
                                    labels: {
                                      datetimeFormatter: {
                                        day: "yyyy/MM/dd",
                                        month: "yyyy/MM",
                                        year: "yyyy",
                                        hour: " HH:mm",
                                      },
                                      style: {
                                        fontFamily: "Montserrat, sans-serif", // Set font family for X-axis title
                                        fontSize: "10px", // Set font size for X-axis title
                                        fontWeight: 500,
                                      },
                                    },
                                  },
                                  yaxis: {
                                    title: { text: "DP" },
                                    labels: { formatter: value => Number(value).toFixed(2) },
                                    min: 0,
                                    max: Math.max(
                                      ...(dpchartData?.series?.[0]?.data?.map(([_, value]) => value) || [0]) 
                                    ) + 100,

                                  },
                                  annotations: {
                                    yaxis: [
                                      {
                                        y: dpchartData?.series?.[0]?.DP_limit || 300, // Default to 300 if undefined
                                        borderColor: "red",
                                        strokeDashArray: 5, // Dashed line for the limit
                                        label: {
                                                 text: `DP Threshold: ${dpchartData?.series?.[0]?.DP_limit || 300}`,
                                          style: {
                                            background: "red",
                                            color: "black",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                            padding: 5
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  tooltip: {
                                    x: { format: "dd-MM-yyyy HH:mm:ss" },
                                    cssClass: "custom-tooltip",
                                    style: { fontSize: "10px", fontFamily: "Montserrat, sans-serif" },
                                  },
                                  legend: {
                                    show: true,
                                    showForSingleSeries: true, // Ensure legend is shown even for a single series
                                    position: "top",
                                    labels: {
                                      useSeriesColors: true, // Use series colors for legend labels
                                    },
                                  },
                                }}
                                series={dpchartData.series}
                              />

                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
     
    </>
  )
}

export default Moisture
