import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const HotspotSkeleton = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="mdlContent card pr-card mb30">
            {/* Breadcrumb Skeleton */}
            <div className="breadcrumbHdr card-header">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={150} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={150} height={30} />
                </li>
                <li className="breadcrumb-item">
                  <Skeleton variant="text" width={300} height={30} />
                </li>
              </ol>
            </div>

            <div className="mdlContent card-body mt20">
              <div className="row">
                {/* Max Of Hotspot Skeleton */}
                <div className="col-md-4 col-lg-4">
                  <div className="card pr-card">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody text-center">
                      <Skeleton variant="text" width={50} height={20} />
                    </div>
                  </div>
                </div>

                {/* Hotspot Count Skeleton */}
                <div className="col-md-4 col-lg-4">
                  <div className="card pr-card">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody text-center">
                      <Skeleton variant="text" width={50} height={50} />
                    </div>
                  </div>
                </div>

                {/* Hotspot Time Skeleton */}
                <div className="col-md-4 col-lg-4">
                  <div className="card pr-card">
                    <div className="inrCardHdr">
                      <Skeleton variant="text" width={200} height={30} />
                    </div>
                    <div className="inrCardBody text-center">
                      <Skeleton variant="text" width={50} height={50} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Hotspot Chart Skeleton */}
              <div className="hotspotChart card pr-card">
                <div className="inrCardBody">
                  <Skeleton variant="rectangular" width="100%" height={150} />
                </div>
              </div>

              {/* Search & Export Section Skeleton */}
              <div className="card pr-card mt-2">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <Skeleton variant="text" width={150} height={30} />
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <div className="form-inline dataTables_filter">
                        <div className="mr10">
                          <Skeleton variant="rectangular" width={50} height={30} />
                        </div>
                        <div className="input-group pr-form-group">
                          <Skeleton variant="rectangular" width={50} height={30} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Data Table Skeleton */}
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={400} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotspotSkeleton;
