import React, { useContext, useEffect, useState } from "react";
// import ReactSpeedometer from "react-d3-speedometer";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import PowerFactorSkeleton from "./PowerFactorSkeleton";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
// import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";


function PowerFactor(props) {
  const arrowvalue = "";
  const customSegmentStops = [-1, 1];
  const color = "#00B050";

  // const segmentColor = color

  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);
  const [average_lv_factor, setAverage_lv_factor] = useState("-");
  const [average_hv_factor, setAverage_hv_factor] = useState("-");
  const [lv_dates, setLvDates] = useState("");
  const [hv_dates, setHvDates] = useState("");
  const [hvPowerFactor, setHvPowerFactor] = useState({});
  const [lvPowerFactor, setLvPowerFactor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [leadOrLagHv, setLeadOrLagHv] = useState();
  const [leadOrLagLv, setLeadOrLagLv] = useState();

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (solution === null) {
      setSolution(localStorage.getItem("solution"));
    }
  }, []);

  // after authentication and authorization it should load the respected account locations
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  // hooking the location change if previously we select the location then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  // hooking the asset change if previously we select the asset then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (
      onload === true &&
      token !== null &&
      token !== undefined &&
      token !== ""
    ) {
      const data = {
        location: onloadLoc,
        site: onloadAsset,
        // location: selectedLocation,
        // site: selectedAsset
      };
      onLoadHandleSubmitData(data);
    }
  }, [onload]);

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const average_hv_power_factor = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          setIsLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/power_factor/average_hv_power_factor`,
            props
            // {
            //     access_token: token,
            //     user_log_id: userLogId,
            //     iot_sol: "Transformer",
            //     location_id: location.value,
            //     assset_id: site.value
            // }
          );

          if (response.status === 200 && response.data.success === true) {
            setAverage_hv_factor(checkHvAverage(response.data));
            setHvDates(checkDates(response.data));
            success = true;
            setIsLoading(false);
          } else {
            setAverage_hv_factor(checkHvAverage([]));
            setHvDates("-");
            setIsLoading(false);
            // showFlashMessage("Data Not Available For Average HV Power Factor", "danger");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAverage_hv_factor(checkHvAverage([]));
      setHvDates("-");
      setIsLoading(false);
      showFlashMessage(
        "Data Not Available For Average HV Power Factor",
        "danger"
      );
    }
  };

  const average_lv_power_factor = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          if (props.start_date && props.end_date) {
            setNewStartDate(breadcrum_date_converter(props.start_date));
            setNewEndDate(breadcrum_date_converter(props.end_date));
          }
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/power_factor/average_lv_power_factor`,
            props
          );

          if (response.status === 200 && response.data.success === true) {
            setAverage_lv_factor(checkLvAverage(response.data));
            setLvDates(checkDates(response.data));
            setNewStartDate(response.data.start_date);
            setNewEndDate(response.data.end_date);
            success = true;
            if (response.data.start_date && response.data.end_date) {
              setNewStartDate(response.data.start_date);
              setNewEndDate(response.data.end_date);
            }
            // setFromDate(new Date(moment(response.data.start_date, "DD-MM-YYYY HH:mm")));
            // setToDate(new Date(moment(response.data.end_date, "DD-MM-YYYY HH:mm")));
          } else {
            setAverage_lv_factor(checkLvAverage([]));
            setLvDates("-");
            // showFlashMessage("Data Not Available For Average LV Power Factor", "danger");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAverage_lv_factor(checkLvAverage([]));
      setLvDates("-");
      showFlashMessage(
        "Data Not Available For Average LV Power Factor",
        "danger"
      );
    }
  };

  const hv_power_factor = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/power_factor/hv_power_factor`,
            props
          );

          if (response.status === 200 && response.data.success === true) {
            setHvPowerFactor(response.data.data);
            success = true;
          } else {
            setHvPowerFactor({
              max_hv_rn: "-",
              max_hv_yn: "-",
              max_hv_bn: "-",
              min_hv_rn: "-",
              min_hv_yn: "-",
              min_hv_bn: "-",
            });
            // showFlashMessage("Data Not Available For HV Power Factor", "danger");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setHvPowerFactor({
        max_hv_rn: "-",
        max_hv_yn: "-",
        max_hv_bn: "-",
        min_hv_rn: "-",
        min_hv_yn: "-",
        min_hv_bn: "-",
      });
      // showFlashMessage("Data Not Available For HV Power Factor", "danger");
    }
  };

  const lv_power_factor = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/power_factor/lv_power_factor`,
            props
          );

          if (response.status === 200 && response.data.success === true) {
            // showFlashMessage('Data successfully updated', 'success');
            setLvPowerFactor(response.data.data);
            success = true;
          } else {
            setLvPowerFactor({
              max_lv_rn: "-",
              max_lv_yn: "-",
              max_lv_bn: "-",
              min_lv_rn: "-",
              min_lv_yn: "-",
              min_lv_bn: "-",
            });
            // showFlashMessage("Data Not Available For LV Power Factor", "danger");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setLvPowerFactor({
        max_lv_rn: "-",
        max_lv_yn: "-",
        max_lv_bn: "-",
        min_lv_rn: "-",
        min_lv_yn: "-",
        min_lv_bn: "-",
      });
      // showFlashMessage("Data Not Available For LV Power Factor", "danger");
    }
  };

  const onLoadHandleSubmitData = async (props) => {
    // setIsLoading(true)
    const { location, site } = props;
    // setHeader({ ...header, asset: selectedAsset.label })
    // setHeader({ location: location.label, asset: site.label });
    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location);
    setOnloadAsset(site);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };
      try {
        await average_hv_power_factor(data);
        await average_lv_power_factor(data);
        await hv_power_factor(data);
        await lv_power_factor(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const handleSubmitData = async (props) => {
    const { fromDate, toDate, location, site } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    // setHeader({ location: location.label, asset: site.label });
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      try {
        await average_hv_power_factor(data);
        await average_lv_power_factor(data);
        await hv_power_factor(data);
        await lv_power_factor(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  // ajax api for all locations
  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  // ajax api for all assets depend upon location
  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer",
            }
          );

          setAssetData([]);

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));


            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // showFlashMessage("Assets are Not Found");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        setOnloadLoc(new_location);
        // setSelectedLocation(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  function hasDecimal(number) {
    // Compare the rounded version with the original number

    return number !== Math.floor(number);
  }

  function isPropertyPresent(obj, propertyPath) {
    if (!obj || typeof obj !== "object") {
      return "-";
    }

    const properties = propertyPath.split(".");
    let currentObject = obj;

    for (let prop of properties) {
      if (currentObject && currentObject.hasOwnProperty(prop)) {
        currentObject = currentObject[prop];
      } else {
        return "-";
      }
    }
    if (Number.isInteger(currentObject)) {
      return currentObject;
    } else if (typeof currentObject === "number") {
      return parseFloat(currentObject.toFixed(2));
    } else {
      return currentObject;
    }
  }

  // hooking of location and asset then hit data api

  // if (isLoading) {
  //     return (
  //         <div className="loaderWpr">
  //             <div className="loader"></div>
  //         </div>
  //     );
  // }

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const updateFromDate = (newFromDate) => {
    // alert(fromDate(newFromDate));
    // setNewStartDate(formatDate(newFromDate));
    // alert(formatDate(newFromDate));
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    // setNewEndDate(formatDate(newToDate));
    setToDate(newToDate);
  };

 function formatDate(date) {
    if (!date) return ""; // Handle null/undefined
  
    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);
  
    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check
  
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const checkHvAverage = (data) => {
    let result = "-";
    if (data.hasOwnProperty("data") && typeof data["data"] === "object") {
      // Check if 'hv_average_pf' key is present and its value is a number
      if (
        data["data"].hasOwnProperty("hv_average_pf") &&
        typeof data["data"]["hv_average_pf"] === "number"
      ) {
        const hvAveragePf = data["data"]["hv_average_pf"];
        setLeadOrLagHv(hvAveragePf);

        if (hvAveragePf > 0) {
          result = parseFloat(hvAveragePf).toFixed(2);
        } else if (hvAveragePf < 0) {
          result = Math.abs(hvAveragePf);
          result = parseFloat(result).toFixed(2);
        } else {
          result = parseFloat(hvAveragePf).toFixed(2);
        }
      }
    } else {
      setLeadOrLagHv("-");
    }
    return result;
  };

  const checkLvAverage = (data) => {
    let result = "-";
    if (data.hasOwnProperty("data") && typeof data["data"] === "object") {
      // Check if 'hv_average_pf' key is present and its value is a number
      if (
        data["data"].hasOwnProperty("lv_average_pf") &&
        typeof data["data"]["lv_average_pf"] === "number"
      ) {
        const hvAveragePf = data["data"]["lv_average_pf"];
        setLeadOrLagLv(hvAveragePf);
        if (hvAveragePf > 0) {
          result = parseFloat(hvAveragePf).toFixed(2);
        } else if (hvAveragePf < 0) {
          result = Math.abs(hvAveragePf);
          result = parseFloat(result).toFixed(2);
        } else {
           result = parseFloat(hvAveragePf).toFixed(2);
        }
      }
    } else {
      setLeadOrLagLv("-");
    }
    return result;
  };

  const checkDates = (data) => {
    let result = "";
    if (data.hasOwnProperty("data") && typeof data["data"] === "object") {
      // Check if 'hv_average_pf' key is present and its value is a number
      if (data["data"].hasOwnProperty("datetime")) {
        result = data["data"]["datetime"];
      }
    } else {
      setHvDates("-");
      setLvDates("-");
    }
    return result;
  };

  const leadOrLag = (data) => {
    let result = " ";
    if (typeof data === "number") {
      if (data > 0) {
        result = "(Lead)";
      } else if (data < 0) {
        result = "(Lag)";
      } else {
        result = "(Lead)";
      }
    } else {
      result = " ";
    }

    return result;
  };

  return (
    <>
      {isLoading && (
        <PowerFactorSkeleton/>
      )}
     
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">{selectedAsset.label}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">{selectedLocation.label}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">
                          {" "}
                          {newStartDate ? (
                            <a href="#">{newStartDate}</a>
                          ) : (
                            <a href="#">{formatDate(fromDate)}</a>
                          )}
                          &nbsp;-&nbsp;
                          {newEndDate ? (
                            <a href="#">{newEndDate}</a>
                          ) : (
                            <a href="#">{formatDate(toDate)}</a>
                          )}
                        </a>
                      </li>
                    </ol>
                  </div>
                  <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={handleSubmitData}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                  <div className="pwrFtrPg mdlContent card-body">
                  
         
                    
                 
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="card pr-card mb20">
                          <div className="inrCardBody text-center">
                            <div className="avgBox powerFctBox">
                              <div className="inrCardHdr">
                                <h5>Average HV Power Factor</h5>
                              </div>
                              <div className="inrCardBody text-center">
                                <h2>
                                  {average_hv_factor}
                                  <span> {leadOrLag(leadOrLagHv)}</span>
                                </h2>
                                <p className="no-data">{hv_dates}</p>
                              </div>
                            </div>
                            <div className="row mb20">
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="rnBg card-header">
                                    Max - HV RN
                                  </div>
                                  <div className="card-body">
                                    {/* <h3>1.0</h3>
                                                                        <p>28/6/2023 12:30:56</p> */}
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_rn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_rn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="ynBg card-header">
                                    Max - HV YN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_yn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_yn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="bnBg card-header">
                                    Max - HV BN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_bn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "max_hv_bn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="rnBg card-header">
                                    Min - HV RN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_rn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_rn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="ynBg card-header">
                                    Min - HV YN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_yn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_yn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="bnBg card-header">
                                    Min - HV BN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_bn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        hvPowerFactor,
                                        "min_hv_bn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-6">
                        <div className="card pr-card mb20">
                          <div className="inrCardBody text-center">
                           
                            <div className="avgBox powerFctBox">
                              <div className="inrCardHdr">
                                <h5>Average LV Power Factor</h5>
                              </div>
                              <div className="inrCardBody text-center">
                                <h2>
                                  {average_lv_factor}{" "}
                                  <span>{leadOrLag(leadOrLagLv)}</span>
                                </h2>
                                <p className="no-data">{lv_dates}</p>
                              </div>
                            </div>
                            <div className="row mb20">
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="rnBg card-header">
                                    Max - LV RN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_rn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_rn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="ynBg card-header">
                                    Max - LV YN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_yn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_yn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="bnBg card-header">
                                    Max - LV BN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_bn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "max_lv_bn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="rnBg card-header">
                                    Min - LV RN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_rn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_rn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="ynBg card-header">
                                    Min - LV YN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_yn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_yn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="powerFctBox">
                                  <div className="bnBg card-header">
                                    Min - LV BN
                                  </div>
                                  <div className="card-body">
                                    <h3>
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_bn.value"
                                      )}
                                    </h3>
                                    <p className="no-data">
                                      {isPropertyPresent(
                                        lvPowerFactor,
                                        "min_lv_bn.datetime"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

  
    </>
  );
}

export default PowerFactor;
