import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactApexChart from "react-apexcharts";
import AssetWiseSummary from "./AssetWiseSummary";
import { FormGroup } from "react-bootstrap";
import GaugeComponent from "react-gauge-component";
import Cookies from "js-cookie";
import axios from "axios";

function Fleet() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [summaryData, setSummaryData] = useState([]);
  const [currentValue, setCurrentValue] = useState(50);
  const [arcs, setArcs] = useState([{}]);
  const [gaugeData, setGaugeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOption, setSortOption] = useState("Sort");

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCurrentValue(Math.floor(Math.random() * 100)); // Generates an integer from 0-99
  //   }, 3000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // });
  // const gaugeData = [
  //   { id: 1, value: 42, assetName: 'Asset' },

  // ];

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchGaugeData();
      fetchSummary();
    }
  }, [token]);

  const fetchGaugeData = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/fleet_view/asset_wise_gauge`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            setGaugeData(response.data.data);
          } else {
            console.log("Eroor while fetching api");
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      // showFlashMessage("Locations are Not Found", "danger");
      setIsLoading(false);
    }
  };
  const fetchSummary = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/fleet_view/asset_wise_summary`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            setSummaryData(response.data.data);
          } else {
            console.log("Eroor while fetching api");
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      // showFlashMessage("Locations are Not Found", "danger");
      setIsLoading(false);
    }
  };

  const handleSortChange = (e) => {
    const option = e.target.value;
    setSortOption(option);
    let sortedData = [...summaryData];

    switch (option) {
      case "Asset A-Z":
        sortedData.sort((a, b) => a.asset.localeCompare(b.asset));
        break;
      case "Asset Z-A":
        sortedData.sort((a, b) => b.asset.localeCompare(a.asset));
        break;
      case "Value Asc.":
        sortedData.sort(
          (a, b) =>
            (a["Temperature °C"] !== null ? a["Temperature °C"] : -Infinity) -
            (b["Temperature °C"] !== null ? b["Temperature °C"] : -Infinity)
        );
        break;
      case "Value Desc.":
        sortedData.sort(
          (a, b) =>
            (b["Temperature °C"] !== null ? b["Temperature °C"] : -Infinity) -
            (a["Temperature °C"] !== null ? a["Temperature °C"] : -Infinity)
        );
        break;
      default:
        break;
    }
    setSummaryData(sortedData);
  };

  return (
    <>
      <div className="busbarOverviewPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <Row>
              <Col md={12} lg={12}>
                <Card className="mdlContent pr-card mb30">
                  <Card.Body className="mdlContent">
                  <Card className="pr-card mb30">
                    <div className="inrCardHdr mb10">
                      <h5>Highest Temperatures Record</h5>
                    </div>
                    <Card.Body className="inrCardBody">
                      {gaugeData.length > 0 ? (
                        <Row className="d-flex flex-wrap">
                          {gaugeData.map((gauge, index) => (
                            <Col
                              key={index}
                              md={4}
                              lg={4}
                              className="d-flex align-items-stretch"
                            >
                              <Card
                                className="pr-card mb30 w-100"
                                style={{ height: "100%" }}
                              >
                                <div>
                                  <p className="floats-left mt-3">
                                    <strong>{`Max ${gauge.max_temp}ºC`}</strong>
                                  </p>
                                  <GaugeComponent
                                    id={`gauge-${index}`}
                                    maxValue={
                                      gauge.temperature_max_limit || 100
                                    } // Default to 100 if missing
                                    value={
                                      gauge["Temperature °C"] !== null && !isNaN(gauge["Temperature °C"])
                                        ? gauge["Temperature °C"]
                                        : ""
                                    }
                                    type="radial"
                                    arc={{
                                      width: 0.2,
                                      nbSubArcs: 20,
                                      colorArray: ["#00FF15", "#CE1F1F"],
                                    }}
                                    labels={{
                                      valueLabel: {
                                        formatTextValue: (value) =>
                                          value + "ºC",
                                        style: { fill: "black" },
                                      },
                                      tickLabels: {
                                        type: "inner",
                                        defaultTickValueConfig: {
                                          formatTextValue: (value) =>
                                            value + "ºC",
                                          style: { fill: "black" },
                                        },
                                        ticks: Array.from(
                                          {
                                            length:
                                              Math.ceil(
                                                gauge.temperature_max_limit / 20
                                              ) + 1,
                                          },
                                          (_, i) => ({ value: i * 20 })
                                        ),
                                      },
                                    }}
                                    pointer={{ length: 0.5 }}
                                  />

                                  <p style={{ textAlign: "center" }}>
                                    <strong>{gauge.asset}</strong>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <div
                          className="text-center"
                          style={{ padding: "50px", color: "#999" }}
                        >
                          <h5>No Data Available</h5>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                  <Card className="AssetSmryCard pr-card mb30">
                    <div className="inrCardHdr mb10">
                      <h5>Asset Wise Summary</h5>
                      <FormGroup className="selectWpr mr10">
                        <select
                          className="minimal form-control"
                          style={{
                            width: "100%",
                            height: "30px",
                            padding: "2px 5px",
                            fontSize: "14px",
                          }}
                          onChange={handleSortChange}
                          value={sortOption}
                        >
                          <option>Sort</option>
                          <option>Asset A-Z</option>
                          <option>Asset Z-A</option>
                          <option>Value Asc.</option>
                          <option>Value Desc.  <i class="fa fa-chevron-down"></i></option>
                        </select>
                      </FormGroup>
                    </div>
                    <Card.Body className="inrCardBody">
                      {isLoading ? (
                        <p>Loading data...</p>
                      ) : summaryData.length === 0 ? (
                        <div
                          className="text-center"
                          style={{ padding: "50px", color: "#999" }}
                        >
                          <h5>No Data Available</h5>
                        </div>
                      ) : (
                        <Row>
                          {summaryData.map((item, index) => (
                            <AssetWiseSummary
                              key={index}
                              title={item.asset}
                              value={`${
                                item["Temperature °C"] !== null
                                  ? item["Temperature °C"]
                                  : "0"
                              }°C`}
                              color={item.color}
                            />
                          ))}
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default Fleet;
