import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { CardHeader, ToggleButton } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Asset() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isCriticalVisible, setCriticalVisible] = useState(false);
    const [usernameRole, setnameUserRole] = useState("");
  
  // const [selectedAssetId, setSelectedAssetId] = useState(null);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      const name = tokenData.name;
      const role = Cookies.get("role");
      setUserName(role);
      setnameUserRole(`${name} | ${role}`);
    }
  }, []);

  ////// message //////
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  ///// table api ////
  const getAssets = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/fetch_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setAssetData(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("No Data Available For Assets");
      setIsLoading(false);
    }
  };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",  
    textOverflow: "ellipsis",
    // maxWidth: "150px",    
  };

  const columns = [
    {
      name: "Asset",
      selector: (row) => row.asset,
      sortable: true,
      cell: (row) => <div title={row.asset} style={ellipsisStyle}>{row.asset}</div>,
    },
    {
      name: "Category",
      selector: (row) => row.categories,
      sortable: true,
      cell: (row) => (<div title={row.categories} style={ellipsisStyle}>{row.categories}</div>),
    },
    {
      name: "Subaccount",
      selector: (row) => row.subaccount,
      sortable: true,
      cell: (row) => <div title={row.subaccount} style={ellipsisStyle}>{row.subaccount}</div>,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      cell: (row) => <div title={row.location} style={ellipsisStyle}>{row.location}</div>,
    },
    {
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
      cell: (row) => <div title={row.department} style={ellipsisStyle}>{row.department}</div>,
    },
    {
      name: "Non Critical /Critical",
      cell: (row) => (
        <div className="datalabel">
          <label className="switchSmall">
            <input
              type="checkbox"
              checked={row.critical === 1}
              onChange={() => handleCritToggle(row._id, row.critical)}
            />
            <small></small>
          </label>
        </div>
      ),
    },
    // ...(userName === "account_admin"
    //   ? [
    //       {
    //         name: "Active / Inactive",
    //         cell: (row) => (
    //           <div className="datalabel">
    //             <label className="switchSmall">
    //               <input
    //                 type="checkbox"
    //                 checked={row.status === "disable"}
    //                 onChange={() => handleStatusToggle(row._id, row.status)}
    //               />
    //               <small></small>
    //             </label>
    //           </div>
    //         ),
    //       },
    //     ]
    //   : []),
  ];

  ///// excel config //////
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const filteredDataExcel = assetData.map((row) => {
      const dataRow = {
        Asset: row.asset || "-",
        Category: row.categories || "-",
        Subaccount: row.subaccount || "-",
        Location: row.location || "-",
        Department: row.department || "-",
        "Critical / Non Critical": row.critical === 1 ? "Critical" : row.critical === 0 ? "Non Critical" : "-",      };

      // Include "Active / Inactive" only if the user is admin
      if (userName === "account_admin") {
        dataRow["Active / Inactive"] = row.status || "-";
      }

      return dataRow;
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "Asset Data" + fileExtension);
  };

  /////Critical/no critical /////
  const handleCritToggle = (id, currentCrit) => {
    if (currentCrit === 1) {
      setSelectedAssetId(id);
      setCriticalVisible(true);
    } else if (currentCrit === 0) {
      // Non-Critical → Critical
      setSelectedAssetId(id);
      setCriticalVisible(true);
    }
  };

  const handleCriticalToggle = async (id, newCritical) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setAssetData((prevData) =>
      prevData.map((asset) =>
        asset._id === id ? { ...asset, critical: newCritical } : asset
      )
    );

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.patch(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/update_asset`,
            {
              access_token: token,
              assetId: id,  
              user_log_id: userLogId,
              critical: newCritical,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            showFlashMessage("Status Updated Successfully", "success");
            success = true;
            // setIsLoading(false);
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Not Able To Update Critical Status");
      setIsLoading(false);
    }
  };

  const handleCriticalConfirm = () => {
    if (selectedAssetId) {
      const currentAsset = assetData.find(
        (asset) => asset._id === selectedAssetId
      );
      const newCritical = currentAsset.critical === 1 ? 0 : 1;
      handleCriticalToggle(selectedAssetId, newCritical);
    }
    setCriticalVisible(false);
  };

  const handleCriticalCancel = () => {
    setCriticalVisible(false);
    setSelectedAssetId(null);
  };

  /////// Active/Inactive///////
  const handleStatusToggle = (id, currentStatus) => {
    if (currentStatus === "enable") {
      setSelectedAssetId(id);
      setIsModalVisible(true);
    } else {
      updateStatus(id, "enable");
    }
  };

  const updateStatus = async (id, newStatus) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setAssetData((prevData) =>
      prevData.map((asset) =>
        asset._id === id ? { ...asset, status: newStatus } : asset
      )
    );
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.patch(
            `${process.env.REACT_APP_VARIABLE}/iotrms/asset_management/update_asset`,
            {
              access_token: token,
              assetId: id,
              user_log_id: userLogId,
              status: newStatus,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            showFlashMessage("Asset Status Updated Successfully", "success");
            success = true;
            setIsLoading(false);
            getAssets();
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Not Able To Update Asset Status");
      setIsLoading(false);
    }
  };

  const handleModalConfirm = () => {
    if (selectedAssetId) {
      updateStatus(selectedAssetId, "disable");
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedAssetId(null);
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getAssets();
    }
  }, [token]);

  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Assets</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      {/* active/inactive button  */}
      {isModalVisible && (
        <div
          className="inactiveModal modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5>Asset status</h5>
              </div>
              <div className="modal-body">
                <p>Please confirm if you want to {
                assetData.find((asset) => asset._id === selectedAssetId)
                ?.status === "enable"
                      ? "Inactive"
                      : "Active"
              } 
               {/* Active / Inactive the */}
                <> {
                assetData.find((asset) => asset._id === selectedAssetId)
                  ?.asset
              }
            </>?</p>
                <button
                  type="button"
                  className="btn btn-primary mr10"
                  onClick={handleModalConfirm}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleModalCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* critical toggle button  */}
      {isCriticalVisible && (
        <div
          className="inactiveModal modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5>Asset status</h5>
              </div>
              <div className="modal-body">
                <p>
                Please confirm if you want to change  <>
              {
                assetData.find((asset) => asset._id === selectedAssetId)
                  ?.asset
              }
            </> to {" "}
                  <>
                    {assetData.find((asset) => asset._id === selectedAssetId)
                      ?.critical === 1
                      ? "Non-Critical"
                      : "Critical"}
                  </>
                  ?
                </p>
                <button
                  type="button"
                  className="btn btn-primary mr10"
                  onClick={handleCriticalConfirm}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCriticalCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="historicalPage content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="historicalpg mdlContent card pr-card mb30">
                  <CardHeader className="breadcrumbHdr">
                    <Breadcrumb>
                    <Breadcrumb.Item href="#">My Account</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Asset</Breadcrumb.Item>
                    <p className="float-right">{usernameRole}</p>
                    </Breadcrumb>
                  </CardHeader>
                  <Card.Body className="mdlContent">
                    <Card style={{}} className="assetTbl">
                      <Card.Body>
                      <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="form-inline dataTables_filter">
                                <div className="input-group pr-form-group" >
                        <a className="mt10">
                          {Object.keys(assetData).length === 0 ? (
                            <a alt="Download XLS">
                              <i className="fa fa-file-excel-o"></i>
                            </a>
                          ) : (
                            <a
                              className=""
                              alt="Download XLS"
                              onClick={exportToCSV}
                            >
                              <i className="fa fa-file-excel-o"></i>
                            </a>
                          )}
                        </a>
                        </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                          columns={columns}
                          data={assetData}
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}
                          pagination
                          highlightOnHover
                          responsive
                        />
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Asset;
