import React, { Component } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';

const data = [
  { user: "Megha Dharankar", emailId: "dharankar.megha@big-testdata.com", mobile: "9823456107"},
  { user: "Nikita Korade", emailId: "korade.nikita@big-testdata.com", mobile: "9375624221"},
  { user: "Bhavin Bhagat", emailId: "bhagat.bhavin@big-testdata.com", mobile: "9144738920"},
  { user: "Akshay Shirsat ", emailId: "shirsat.akshay@big-testdata.com", mobile: "8729013467"},
];

export class UserTester extends Component {
  render() {
    return (
        <div className="historicalPage content-wrapper">
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="historicalpg mdlContent card pr-card mb30">
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">User</Breadcrumb.Item>
                            </Breadcrumb>
                            <Card.Body>
                            <Card style={{  }}>
                                <Card.Body>
                                    <DataTable
                                        columns={[
                                        {
                                            name: "User",
                                            selector: (row) => row.user,
                                            sortable: true,
                                        },
                                        {
                                            name: "Email Id",
                                            selector: (row) => row.emailId,
                                            sortable: true,
                                        },
                                        {
                                            name: "Mobile",
                                            selector: (row) => row.mobile,
                                            sortable: true,
                                        },
                                        
                                        {
                                            name: "Email (20/100)",
                                            cell: (row) => (
                                              <div className='datalabel'>
                                              <label class="switchSmall">
                                                <input type="checkbox"/>
                                                <small></small>
                                              </label>
                                              </div>
                                            ),
                                        },
                                        {
                                            name: "SMS (50/1000)",
                                            cell: (row) => (
                                              <div className='datalabel'>
                                              <label class="switchSmall">
                                                <input type="checkbox"/>
                                                <small></small>
                                              </label>
                                              </div>
                                            ),
                                        },
                                        {
                                            name: "Whatsapp (20/100)",
                                            cell: (row) => (
                                              <div className='datalabel'>
                                              <label class="switchSmall">
                                                <input type="checkbox"/>
                                                <small></small>
                                              </label>
                                              </div>
                                            ),
                                        },
                                        
                                        ]}
                                        data={data}
                                        pagination
                                        highlightOnHover
                                        responsive
                                        />
                                </Card.Body>
                            </Card>
                            </Card.Body>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
    )
  }
}

export default UserTester