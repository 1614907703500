import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from "moment";
import LineChart from "./LineChart";
import Table from "./Table";
import { date } from "yup";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import TrendSkeleton from "../../Ems/Trend/TrendSkeleton";
import dayjs from "dayjs";

const Trend = () => {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pielabels, setPielabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onloadParameter, setOnloadParameter] = useState("");
  const [selectedParameter, setSelectedParameter] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [onload, setOnLoad] = useState(true);
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [isParam, setIsParam] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOption, setChartOption] = useState({
    colors: [
      "#2A73B9",
      "#8EC5EC",
      "#F15A2A",
      "#F7941E",
      "#39B54A",
      "#D7DF24",
      "#92278F",
      "#EC008D",
      "#FF0000",
      " #FF33CC",
    ],
    chart: {
      toolbar: {
        export: {
          csv: {
            filename: "Selco Trend",
            headerCategory: "Date Time",
            categoryFormatter: function (timestamp) {
              let date = new Date(timestamp).toUTCString();
              const originalDate = new Date(date);

              // Add 5 hours and 30 minutes to the original date
              originalDate.setHours(originalDate.getHours() - 5);
              originalDate.setMinutes(originalDate.getMinutes() - 30);

              // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
              const formattedDate =
                ("0" + originalDate.getDate()).slice(-2) +
                "-" +
                ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                "-" +
                originalDate.getFullYear() +
                " " +
                ("0" + originalDate.getHours()).slice(-2) +
                ":" +
                ("0" + originalDate.getMinutes()).slice(-2) +
                ":" +
                ("0" + originalDate.getSeconds()).slice(-2);
              return formattedDate;
            },
          },
          png: {
            filename: "Selco Trend",
          },
          svg: {
            filename: "Selco Trend",
          },
        },
      },
      animations: {
        enabled: false,
      },
      height: "350px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    responsive: [{ breakpoint: 1367, options: { chart: { height: 270 } } }],
    legend: {
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "center",
      style: {
        fontFamily: "Montserrat", // Set font family for X-axis title
        fontSize: "14px", // Set font size for X-axis title
        fontWeight: 500,
      },
      // fontSize: '14px'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      min: minValue,
      max: maxValue,
      title: {
        text: "values", // Set your Y-axis title here
        style: {
          fontFamily: "Montserrat", // Set font family for X-axis title
          fontSize: "14px", // Set font size for X-axis title
          fontWeight: 500,
        },
      },
      labels: {
        formatter: function (value) {
          if (value !== null && typeof value !== "undefined" && !isNaN(value)) {
            return value.toFixed(2); // Format y-axis labels to 2 decimal places
          } else {
            return ""; // Return an empty string for undefined or NaN values
          }
        },
        style: {
          fontFamily: "Montserrat, sans-serif",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Datetime", // Set your X-axis title here
        style: {
          fontFamily: "Montserrat", // Set font family for X-axis title
          fontSize: "14px", // Set font size for X-axis title
          fontWeight: 500,
        },
      },

      labels: {
        datetimeFormatter: {
          day: "dd-MM-yyyy",
          month: "MM-yyyy",
          year: "yyyy",
          hour: "dd-MM-yyyy HH:mm:ss",
        },
        style: {
          fontFamily: "Montserrat", // Set font family for X-axis title
          fontSize: "14px", // Set font size for X-axis title
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      x: {
        format: "dd-MM-yyyy HH:mm:ss",
      },
      cssClass: "custom-tooltip",
      style: {
        fontFamily: "Montserrat, sans-serif", // Set font family for tooltip
        fontSize: "14px", // Set font size for tooltip
      },
      // y: {
      //   // Use the ' { value }' format to display decimal values on hover
      //   formatter: function (value) {
      //     if (typeof value !== 'undefined' && !isNaN(value)) {
      //       // Check if the value is an integer or a decimal
      //       if (Number.isInteger(value)) {
      //         return value.toFixed(0); // Format integers with 0 decimal places
      //       } else {
      //         return value.toFixed(4); // Format decimals with 2 decimal places
      //       }
      //     } else {
      //       return ''; // Return an empty string for undefined or NaN values
      //     }
      //   },
      // },
    },
    title: {
      text: " ",
      align: "left",
    },
  });
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const [allParams, setAllParams] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterTableData, setFilterTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [stringParameters, setStringParameters] = useState([]);
  const [initializationComplete, setInitializationComplete] = useState(false);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageParameter();
    }
  }, [parameterData]);
  useEffect(() => {
    if (initializationComplete) {
      let data = {
        fromDate: fromDate,
        toDate: toDate,
        location: onloadLoc,
        site: onloadAsset,
      };
      const storedParameter = JSON.parse(localStorage.getItem("parameter"));
      const updatedParameter = storedParameter || onloadParameter;
      data =
        isParam === false
          ? Object.assign({}, data, { parameter: updatedParameter })
          : data;
      handleSubmitData(data);
      setOnLoad(false); // Set onload to false after handling submit data
    }
  }, [initializationComplete]);
  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
    if (solution === null) {
      setSolution(localStorage.getItem("solution"));
      // update_solution(localStorage.getItem("solution"));
    }
  }, []);
  // for loader
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      try {
        let sensor_param = allParams
          ? parameterData.map((d) => d.value)
          : onloadParameter.map((d) => d.value);
        if (allParams) {
          sensor_param = sensor_param.concat(stringParameters);
        }
        const data = {
          access_token: token,
          user_log_id: userLogId,
          location_id: selectedLocation.value,
          assset_id: selectedAsset.value,
          start_date: convertDateCloneApi(fromDate),
          end_date: convertDateCloneApi(toDate),
          sensor_parameters: sensor_param,
        };
        if (onload === true) {
          data.start_date = breadcrum_to_api(newStartDate);
          data.end_date = breadcrum_to_api(newEndDate);
        }
        getDatableData(data);
      } catch (error) {}
    }
  }, [allParams]);
  useEffect(() => {
    searchTable();
  }, [searchText]);
  const localStorageLocation = () => {
    if (param_asset && param_location) {
      const flag = locationData.some((item) => item.value === param_location);

      if (flag) {
        let location = locationData.filter(
          (item) => item.value === param_location
        );
        location = location[0];
        setHeader({ location: location.label, asset: "" });
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        return;
      }
    } else {
      const storedLocation = localStorage.getItem("location");
      if (
        storedLocation &&
        storedLocation !== "undefined" &&
        storedLocation !== "null"
      ) {
        let location = JSON.parse(storedLocation);
        const flag = locationData.some((item) => item.value === location.value);
        if (flag) {
          setSelectedLocation(location);
          setOnloadLoc(location);
          getAsset(location.value);
          return;
        }
      } else {
        localStorage.removeItem("asset");
        if (locationData.length > 0) {
          const new_location = locationData[0];
          setOnloadLoc(new_location);
          getAsset(new_location.value);
        } else {
          showFlashMessage("Please select Location and Asset to display data.");
        }
      }
    }
  };

  const localStorageAsset = () => {
    if (param_asset && param_location) {
      const flag = assetData.some((item) => item.value === param_asset);

      if (flag) {
        let asset = assetData.filter((item) => item.value === param_asset);
        asset = asset[0];
        setHeader({ asset: asset.label, location: selectedLocation.label });
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        getParameter(asset);
        return;
      }
    } else {
      const storedAsset = localStorage.getItem("asset");
      if (
        storedAsset &&
        storedAsset !== "undefined" &&
        storedAsset !== "null"
      ) {
        let asset = JSON.parse(storedAsset);
        const flag = assetData.some((item) => item.value === asset.value);
        if (flag) {
          setHeader({ asset: asset.label, location: selectedLocation.label });
          setSelectedAsset(asset);
          setOnloadAsset(asset);
          getParameter(asset);
        } else {
          getGroupName(asset);
        }
      } else {
        if (assetData.length > 0) {
          const new_asset = assetData[0];
          setOnloadAsset(new_asset);
          if (onload) {
            getParameter(new_asset);
          }
        }
      }
    }
  };

  const localStorageParameter = () => {
    if (onload && parameterData.length > 0) {
      const firstParameter = [parameterData[0]];
      const storedParameter = JSON.parse(localStorage.getItem("parameter"));
      const updatedParameter = storedParameter || firstParameter;
      setOnloadParameter(updatedParameter);
      setOnLoad(true);
      setInitializationComplete(true); // Signal that initialization is complete
    } else if (parameterData.length === 0) {
      setOnLoad(false);
      setInitializationComplete(false);
    } else {
      const storedParameter = localStorage.getItem("parameter");
      if (
        storedParameter &&
        storedParameter !== "undefined" &&
        storedParameter !== "null" &&
        storedParameter.length > 3
      ) {
        let parameter = JSON.parse(storedParameter);
        const flag = parameter.every((item) => {
          return parameterData.some(
            (arr1Item) => arr1Item.value === item.value
          );
        });

        if (flag && !isParam) {
          setSelectedParameter(parameter);
          setOnloadParameter(parameter);
          setOnLoad(true);
          setInitializationComplete(true); // Signal that initialization is complete
        }
      }
    }
  };
  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props);

    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };
  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
      setOnloadAsset(props);
      if (!isParam) {
        getParameter(props);
      } else {
        //get group_name
        getGroupName(props);
      }
      // alert(props.value);
    }
  };
  const changeParameter = (props) => {
    // localStorage.setItem("parameter", JSON.stringify(props));
    setSelectedParameter(props);
  };
  const changeIsParam = (props) => {
    // alert(props)
    setIsParam(props);

    if (props) {
      // getGroupName();
      getGroupName(selectedAsset);
    } else {
      getParameter(selectedAsset);
    }
  };
  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };
  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };
  const getParameter = async (props) => {
    // alert(props.value);
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/trend/get_parameters_list`,
            {
              access_token: token,
              user_log_id: userLogId,
              // location_id: selectedLocation.value,
              location_id: onloadLoc.value,
              iot_sol: "Transformer",
              assset_id: props.value,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.length === 0) {
              setParameterData([]);
              // showFlashMessage("Parameters Not Found");
            } else {
              const data = response.data.data.map((data) => {
                return { label: data.replace(/_/g, " "), value: data };
              });
              setParameterData(data);
              success = true;
              setStringParameters(response.data.string_para_names);
            }
          } else {
            setParameterData([]);
            // showFlashMessage("Parameters Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setParameterData([]);
      showFlashMessage("Parameters Not Found");
    }
  };
  const getGroupName = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/trend/get_groups`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              //"6204d1cc12756f0c4a0e22db"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.length === 0) {
              // showFlashMessage("Groups Not Found");
            } else {
              const data = response.data.data.map((data) => {
                return { label: data.name, value: data._id };
              });
              setGroupData(data);
              success = true;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Groups Not Found");
    }
  };
  const handleAddGroup = (props) => {
    addGroup(props);
  };
  const addGroup = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/trend/add_group_parameters`,
            {
              access_token: token,
              user_log_id: userLogId,
              assset_id: selectedAsset.value,
              iot_sol: "Transformer",
              parameters: selectedParameter.map((param) => param.value),
              name: props,
              status: "enable",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            showFlashMessage("Group Added Successfully", "success");
            success = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
  };
  const getGraphData = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      const data = props;

      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/trend/graph_trend`,
            data
          );
          // if (response.data.data.length === 0) {
          //   let result = Array.isArray(selectedParameter) ? selectedParameter.map((res) => ({ "name": res.label, "data": [] })) : [];
          //   setChartSeries(result)
          //   setIsLoading(false);
          //   return;
          // }
          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.length > 0) {
              let currentMinValue = null;
              let currentMaxValue = null;
              const chartData = response.data.data.map((map) => {
                return {
                  data: map.data
                    .filter(([timestamp, value]) => value !== null)
                    .map(([timestamp, value]) => {
                      const truncatedValue = value;

                      //adjust minValue
                      if (typeof truncatedValue === "number") {
                        const adjustedValue = truncatedValue - 0.1;

                        // Set minValue if it's the first match or the smallest found
                        if (
                          currentMinValue === null ||
                          adjustedValue < currentMinValue
                        ) {
                          currentMinValue = adjustedValue;
                        }

                        // Update the maxValue if necessary
                        if (
                          currentMaxValue === null ||
                          value > currentMaxValue
                        ) {
                          currentMaxValue = value;
                        }
                      }
                      return [timestamp, truncatedValue];
                    }),
                };
              });

              // If a valid min value was found, update the state
              if (currentMinValue !== null) {
                setMinValue(currentMinValue);
              }

              // If a valid max value was found, update the state by adding 0.1 after decimal
              if (currentMaxValue !== null) {
                setMaxValue(currentMaxValue + 0.1);
              }

              setChartSeries(response.data.data);
              success = true;
              setHeader({
                location: selectedLocation.label,
                title: selectedAsset.label,
              });
              // showFlashMessage("Data F");
              setIsLoading(false);
            } else {
              setIsLoading(false);
              // showFlashMessage("Data not Available");
              let result = Array.isArray(selectedParameter)
                ? selectedParameter.map((res) => ({
                    name: res.label,
                    data: [],
                  }))
                : [];
              setChartSeries(result);
            }
          } else {
            let result = Array.isArray(selectedParameter)
              ? selectedParameter.map((res) => ({ name: res.label, data: [] }))
              : [];
            setChartSeries(result);
            setIsLoading(false);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available", "danger");
      let result = Array.isArray(selectedParameter)
        ? selectedParameter.map((res) => ({ name: res.label, data: [] }))
        : [];
      setChartSeries(result);
      setIsLoading(false);
    }
  };

  // render the graph late until minValue comes
  useEffect(() => {
    if (minValue !== null && maxValue !== null) {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: minValue,
          max: maxValue,
        },
      }));
    } else if (minValue !== null) {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: minValue,
          max: undefined, // Keep max undefined if only minValue is available
        },
      }));
    } else {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: undefined,
          max: undefined, // Reset both if minValue is undefined
        },
      }));
    }
  }, [minValue, maxValue]);

  const api_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [day, month, year] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = new Date(year, month - 1, day, hours, minutes);
    return myDate;
  };
  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const getDatableData = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      // setIsLoading(true)
      const data = props;
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/trend/trend_table`,
            data
          );
          // if (response.data.data.length === 0) {
          //   setTableData({ columns: [], data: [] });
          //   setFilterTableData({ columns: [], data: [] });
          //   showFlashMessage("Data Not Available", "danger");
          //   return;
          // }
          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.data.length > 0) {
              let data = response.data.data;
              // decending sorting
              for (let i = 0; i < data.data.length - 1; i++) {
                for (let j = 0; j < data.data.length - 1 - i; j++) {
                  if (data.data[j][0] < data.data[j + 1][0]) {
                    // Swap the elements if they are in the wrong order
                    let temp = data.data[j];
                    data.data[j] = data.data[j + 1];
                    data.data[j + 1] = temp;
                  }
                }
              }
              const newData = formatTableData(response.data.data);
              setTableData(newData);
              setFilterTableData(newData);
              success = true;
              if (response.data.start_date && response.data.end_date) {
                setNewStartDate(response.data.start_date);
                setNewEndDate(response.data.end_date);
                // setToDate(api_date_converter(response.data.end_date));
                // setFromDate(api_date_converter(response.data.start_date));
              }
            }
          } else {
            // showFlashMessage("Data Not Available");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setIsLoading(false);
      setTableData({ columns: [], data: [] });
      setFilterTableData({ columns: [], data: [] });
      showFlashMessage("Data Not Available", "danger");
    }
  };
  function removeSlash(dateTimeString) {
    const [day, month, year] = dateTimeString.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  function UTCFormat(dateTime) {
    const year = dateTime.getUTCFullYear();
    // JavaScript months are 0-based, so we add 1 to get the correct month
    const month = (dateTime.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getUTCDate()).padStart(2, "0");

    // Format the UTC date as "YYYY-MM-DD"
    const formattedUTCDate = `${year}-${month}-${day}`;
  }
  const formatTableData = (props) => {
    const responseData = props;
    const fields = props.fields;

    const columns = fields.map((value, index) => {
      return {
        name: index === 0 ? "Date Time" : value.replace(/_/g, " "),
        selector: (row) => {
          if (index === 0) {
            return row.date_time;
          }
          const cellValue = row[value.toLowerCase()];
          // Check if the cell value is a valid number
          return isNaN(parseFloat(cellValue))
            ? cellValue
            : parseFloat(cellValue);
        },
        sortable: true,
        cell: (row) => {
          const cellValue =
            index === 0 ? row["date_time"] : row[value.toLowerCase()];
          return <div className="custom-cell">{cellValue}</div>;
        },
      };
    });

    const newData = responseData.data.map((row) => {
      const object = {};
      row.forEach((item, index) => {
        if (index === 0) {
          const milliseconds = parseInt(item);
          if (isNaN(milliseconds)) {
            console.error(`Invalid milliseconds value: ${item}`);
            return;
          }
          const dateWithTime = new Date(milliseconds);
          const formattedDate = `${dateWithTime
            .getUTCDate()
            .toString()
            .padStart(2, "0")}-${(dateWithTime.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}-${dateWithTime.getUTCFullYear()} ${dateWithTime
            .getUTCHours()
            .toString()
            .padStart(2, "0")}:${dateWithTime
            .getUTCMinutes()
            .toString()
            .padStart(2, "0")}:${dateWithTime
            .getUTCSeconds()
            .toString()
            .padStart(2, "0")}`;
          object["date_time"] = formattedDate;
        } else {
          object[fields[index].toLowerCase()] = item.toString();
        }
      });
      return object;
    });

    return { columns, data: newData };
  };
  const searchTable = () => {
    if (
      searchText === undefined ||
      searchText === null ||
      searchText === "" ||
      !searchText
    ) {
      setFilterTableData(tableData);
    } else if (tableData && tableData.data) {
      const filteredResult = tableData.data.filter((row) => {
        row = Object.values(row);
        const rowValues = row.map((item) =>
          typeof item === "string" ? item.toLowerCase() : item.toString()
        );

        const searchTerm = searchText.toLowerCase();

        return rowValues.some((value) => value.includes(searchTerm));
      });

      setFilterTableData((prevOptions) => ({
        ...prevOptions,
        data: filteredResult,
      }));
    }
  };
  const convertDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const convertDateClone = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };
  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const breadcrum_to_api = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [day, month, year] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    return myDate;
  };

  const handleSubmitData = (props) => {
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    setSelectedLocation(location);
    setSelectedAsset(site);
    setSelectedParameter(parameter);
    setOnloadLoc(location);
    setOnloadAsset(site);
    setOnloadParameter(parameter);
    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
      };
      try {
        if (isParam === false) {
          data = Object.assign({}, data, {
            sensor_parameters: onloadParameter.map((d) => d.value),
          });
          getGraphData(data);
          getDatableData(data);
        } else {
          data = Object.assign({}, data, {
            group_id: group.value,
            iot_sol: "Transformer",
          });
          getGraphData(data);
          getDatableData(data);
        }
      } catch (error) {}
      // data = (isParam === false) ? Object.assign({}, data, { sensor_parameters: parameter }) : data;
      // alert("done");
    }
  };

  const SubmitDataWithDate = (props) => {
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    localStorage.setItem("parameter", JSON.stringify(parameter));
    setOnLoad(false);
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);
    setSelectedParameter(parameter);
    setAllParams(false);
    if (props.fromDate && props.toDate) {
      setNewStartDate(
        breadcrum_date_converter(convertDateCloneApi(props.fromDate))
      );
      setNewEndDate(
        breadcrum_date_converter(convertDateCloneApi(props.toDate))
      );
    }

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
      };
      try {
        if (isParam === false) {
          data = Object.assign({}, data, {
            sensor_parameters: selectedParameter.map((d) => d.value),
          });
          getGraphData(data);
          getDatableData(data);
        } else {
          data = Object.assign({}, data, {
            group_id: group.value,
            iot_sol: "Transformer",
          });
          getGraphData(data);
          getDatableData(data);
        }
      } catch (error) {}
      // data = (isParam === false) ? Object.assign({}, data, { sensor_parameters: parameter }) : data;
      // alert("done");
    }
  };
  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  const png = (pngId) => {
    const id = `#${pngId}`;
    const svg = document.querySelector(`${id} svg`);
    svg.style.backgroundColor = "white";

    // create a temporary canvas element
    const canvas = document.createElement("canvas");
    // set the canvas dimensions to match the chart dimensions
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;
    canvas.backgroundColor = svg.style.backgroundColor;
    // get the canvas context
    const context = canvas.getContext("2d");
    // create a new Image object with the SVG data
    const image = new Image();
    // wait for the Image to load
    image.onload = function () {
      // draw the Image onto the canvas
      context.drawImage(image, 0, 0);
      // convert the canvas to a data URL
      const dataURL = canvas.toDataURL("image/jpeg", 2.0);
      // create a temporary link element
      const link = document.createElement("a");
      link.download = "chart.png";
      link.href = dataURL;
      // trigger a click event on the link element to initiate the download
      link.click();
      // cleanup the link element
      link.remove();
    };

    image.src =
      "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg.outerHTML);
  };
  const handleParamsChange = async (data) => {
    setOnLoad(true);
    setInitializationComplete(false);
    setAllParams(data);
    setTimeout(() => {
      setOnLoad(false);
    }, 0);
  };
  const changeAllParams = (newParams) => {
    handleParamsChange(newParams);
    setInitializationComplete(true);
  };
  function formatDate(date) {
    if (!date) return ""; // Handle null/undefined

    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);

    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };
  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const [fieldValue, setFieldValue] = useState(false);

  const handleSwitchChange = (checked) => {
    setFieldValue(checked);
    changeIsParam(checked);
  };

  return (
    <>
      {isLoading && <TrendSkeleton />}

      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="trendPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <div className="row">
                      <ol className="breadcrumb col-md-10">
                        <li className="breadcrumb-item">
                          <a href="#">{selectedAsset.label}</a>
                        </li>
                        <li className="breadcrumb-item ">
                          <a href="#">{selectedLocation.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">
                            {" "}
                            {newStartDate ? (
                              <a href="#">{newStartDate}</a>
                            ) : (
                              <a href="#">{formatDate(fromDate)}</a>
                            )}
                            &nbsp;-&nbsp;
                            {newEndDate ? (
                              <a href="#">{newEndDate}</a>
                            ) : (
                              <a href="#">{formatDate(toDate)}</a>
                            )}
                          </a>
                        </li>
                      </ol>
                      <div className="switchWpr col-md-2">
                        <div className="form-group switch float-right">
                          <label className="switch-light">
                            <input
                              type="checkbox"
                              checked={isParam}
                              onChange={(e) =>
                                handleSwitchChange(e.target.checked)
                              }
                              name="isGroupSwitchOn"
                            />
                            <span id="toggleParamColor">
                              <span
                                style={{
                                  color: isParam ? "black" : "white",
                                }}
                              >
                                Parameter
                              </span>
                              <span
                                style={{
                                  color: !isParam ? "black" : "white",
                                }}
                              >
                                Group
                              </span>
                            </span>
                            <a className="btn btn-primary"></a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Sidebar
                    locationData={locationData}
                    assetData={assetData}
                    parameterData={parameterData}
                    changeLocation={changeLocation}
                    changeAsset={changeAsset}
                    changeParameter={changeParameter}
                    handleSubmitData={SubmitDataWithDate}
                    selectedLocation={selectedLocation}
                    selectedAsset={selectedAsset}
                    selectedParameter={selectedParameter}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    isParam={isParam}
                    changeIsParam={changeIsParam}
                    handleAddGroup={handleAddGroup}
                    groupData={groupData}
                    getGroupName={getGroupName}
                    updateFromDate={updateFromDate}
                    updateToDate={updateToDate}
                    fieldValue={fieldValue}
                  />
                  <div className="mdlContent card-body">
                    <div className="col-md-12 col-lg-12"></div>
                    {/* <LineChart
                      chartSeries={chartSeries}
                      chartOption={chartOption}
                    /> */}
                    <Table
                      allParams={allParams}
                      changeAllParams={changeAllParams}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      filterTableData={filterTableData}
                      tableData={tableData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Trend;
