import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

function Summary() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCritical, setIsCritical] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);
  useEffect(() => {
    if (token && selectedDate) {
      busbarSummry();
    }
  }, [token, selectedDate]);

  const exportToCSV = () => {
    if (filteredData.length === 0) {
      alert("No data available to export.");
      return;
    }

    // Transform data into a flat format
    const formattedData = filteredData.map((item) => ({
      location: item.location || "",
      asset: item.asset || "",
      critical: item.critical || 0,
      last_value: item.last_value || "",
      last_datetime: item.last_datetime || "",
      color: item.color || "",
      today_range: item.today?.range || "",
      today_min: item.today?.min || "",
      today_avg: item.today?.avg || "",
      today_max: item.today?.max || "",
      prev_day_range: item.previous_day?.range || "",
      prev_day_min: item.previous_day?.min || "",
      prev_day_avg: item.previous_day?.avg || "",
      prev_day_max: item.previous_day?.max || "",
      last_7_days_range: item.last_7_days?.range || "",
      last_7_days_min: item.last_7_days?.min_temp || "",
      last_7_days_avg: item.last_7_days?.avg_temp || "",
      last_7_days_max: item.last_7_days?.max_temp || "",
      last_30_days_range: item.last_30_days?.range || "",
      last_30_days_min: item.last_30_days?.min_temp || "",
      last_30_days_avg: item.last_30_days?.avg_temp || "",
      last_30_days_max: item.last_30_days?.max_temp || "",
      last_90_days_range: item.last_90_days?.range || "",
      last_90_days_min: item.last_90_days?.min_temp || "",
      last_90_days_avg: item.last_90_days?.avg_temp || "",
      last_90_days_max: item.last_90_days?.max_temp || "",
    }));

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Summary Report");

    // Save as an Excel file
    XLSX.writeFile(
      workbook,
      `Summary_Report_${new Date().toISOString().split("T")[0]}.xlsx`
    );
  };
  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const busbarSummry = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/summary/view_busbar_summary`,
            {
              access_token: token,
              user_log_id: userLogId,
              date: selectedDate,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            
            setData(response.data.data);
          } else {
            console.log("Eroor while fetching api");
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      // showFlashMessage("Locations are Not Found", "danger");
      setIsLoading(false);
    }
  };

  const dataa = [
    {
      location: "Mumbai",
      asset: "Distribution Transformer",
      last_value: "33 (°C)",
      last_datetime: "2025-2-19 04:15:11",
      critical: 1,
      today: { min: 23, avg: 34.72, max: 13 },
      previous: { min_temp: 25, avg_temp: 35.5, max_temp: 14 },
      last_7_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
      last_30_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
      last_90_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
    },
    {
      location: "Nashik",
      asset: "9000 KVA Distribution Transformer",
      last_value: "33 (°C)",
      last_datetime: "2025-2-19 04:15:11",
      critical: 1,
      today: { min: 23, avg: 34.72, max: 13 },
      previous: { min_temp: 25, avg_temp: 35.5, max_temp: 14 },
      last_7_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
      last_30_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
      last_90_days: { min_temp: 23, avg_temp: 34.72, max_temp: 13 },
    },
    {
      location: "Mahape",
      asset: "2.1 MVA Delta 1",
      last_value: "33 (°C)",
      last_datetime: "2025-02-19 09:10:00",
      critical: 0,
      today: { min: 1, avg: 342, max: 3 },
      previous: { min_temp: 25, avg_temp: 35.5, max_temp: 14 },
      last_7_days: { min_temp: 1, avg_temp: 342, max_temp: 3 },
      last_30_days: { min_temp: 1, avg_temp: 342, max_temp: 3 },
      last_90_days: { min_temp: 1, avg_temp: 342, max_temp: 3 },
    },
  ];

  const columnss = [
    { name: "Location", selector: (row) => row.location, sortable: true },
    { name: "Asset", selector: (row) => row.asset, sortable: true },
    {
      name: "Last Value",
      selector: (row) => row.last_value,
      sortable: true,
      cell: (row) =>
        row.last_value && (
          <b style={{ color: row.color.toLowerCase() }}>{row.last_value}</b>
        ),
    },
    {
      name: "Last Datetime",
      selector: (row) => row.last_datetime,
      sortable: true,
    },
  ];

  const ExpandedComponent = ({ data }) => {
    const tempColumns = [
      { name: "", selector: (row) => row.type, sortable: false },
      {
        name: `Today\n(${data.today.range})`,
        selector: (row) => row.today,
        sortable: false,
      },
      {
        name: `Previous Day\n(${data.previous_day.range})`,
        selector: (row) => row.previous,
        sortable: false,
      },
      {
        name: `Last 7 Days\n(${data.last_7_days.range})`,
        selector: (row) => row.last7Days,
        sortable: false,
      },
      {
        name: `Last 30 Days\n(${data.last_30_days.range})`,
        selector: (row) => row.last30Days,
        sortable: false,
      },
      {
        name: `Last 90 Days\n(${data.last_90_days.range})`,
        selector: (row) => row.last90Days,
        sortable: false,
      },
    ];
    const formatValue = (value) => {
      if (value == null || value === "" || isNaN(Number(value))) return "-"; // Handle missing/invalid values
      const num = Number(value);
      return Number.isInteger(num) ? num.toString() : num.toFixed(2);
    };
    
    
    // Convert Asset Temperature Data into Table Format
    const tempData = [
      {
        type: "Min (°C)",
        previous: formatValue(data.previous?.min_temp),
        today: formatValue(data.today?.min),
        last7Days: formatValue(data.last_7_days?.min_temp),
        last30Days: formatValue(data.last_30_days?.min_temp),
        last90Days: formatValue(data.last_90_days?.min_temp),
      },
      {
        type: "Avg (°C)",
        previous: formatValue(data.previous?.avg_temp),
        today: formatValue(data.today?.avg),
        last7Days: formatValue(data.last_7_days?.avg_temp),
        last30Days: formatValue(data.last_30_days?.avg_temp),
        last90Days: formatValue(data.last_90_days?.avg_temp),
      },
      {
        type: "Max (°C)",
        previous: formatValue(data.previous?.max_temp),
        today: formatValue(data.today?.max),
        last7Days: formatValue(data.last_7_days?.max_temp),
        last30Days: formatValue(data.last_30_days?.max_temp),
        last90Days: formatValue(data.last_90_days?.max_temp),
      },
    ];
    

    return (
      <div className="collapsTbl">
        <DataTable
          columns={tempColumns}
          data={tempData}
          dense
          noHeader
          customStyles={{
            headCells: {
              style: {
                whiteSpace: "pre-wrap", // Enables line breaks
                //textAlign: "center",
              },
            },
          }}
        />
      </div>
    );
  };

  const filteredData = data
    .filter((item) => (isCritical ? item.critical === 1 : item.critical === 0)) // Filter by criticality
    .filter(
      (item) =>
        item.location.toLowerCase().includes(searchText.toLowerCase()) || // Search in location
        item.asset.toLowerCase().includes(searchText.toLowerCase()) || // Search in asset
        item.last_value.toString().includes(searchText) || // Search in last_value
        item.last_datetime.toString().includes(searchText)
    );

  return (
    <div className="busbarSmryPg content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <Row>
            <Col md={12} lg={12}>
              <Card className="mdlContent pr-card mb30">
                <div className="breadcrumbHdr card-header ">
                  <ol className="breadcrumb  float-left">
                      <li className="breadcrumb-item">
                        <a>Summary Report</a>
                      </li>
                    </ol>

                    <div className="float-right form-inline dataTables_filter">
                      <div className="sldSwitchWpr switch float-right  form-check form-switch">
                        <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isCritical}
                              onChange={() => setIsCritical(!isCritical)}
                            />
                            <small></small>
                          </label>
                      </div>
                      <div className="mr10">Critical</div>
                    </div>
                  </div>
                <Card.Body className="mdlContent">
                  
                  <Card className="pr-card mb30">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="form-inline dataTables_filter">
                          <div className="input-group pr-form-group">
                            <DatePicker
                              selected={
                                selectedDate ? new Date(selectedDate) : null
                              }
                              onChange={handleDateChange}
                              className="form-control custom-datepicker mr-2"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>
                          <div className="mr10">
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)} // Store input in state
                              />
                            </label>
                          </div>
                          <div className="input-group pr-form-group" id="">
                            <a
                              className=""
                              alt="Download XLS"
                              onClick={exportToCSV}
                            >
                              <i className="fa fa-file-excel-o"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    {/* <div className="inrCardHdr">
                      <h5>Daily Summary Report</h5>
                    </div> */}
                    <Card.Body className="">
                      {isLoading ? (
                        <p className="text-center mt-3">Loading data...</p>
                      ) : filteredData.length > 0 ? (
                        <DataTable
                          columns={columnss}
                          data={filteredData}
                          expandableRowExpanded={() => true}
                          expandableRows
                          expandableRowsComponent={ExpandedComponent}
                          pagination
                          highlightOnHover
                        />
                      ) : (
                        <p className="text-center mt-3">No data available.</p>
                      )}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Summary;
