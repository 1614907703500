import React from "react";
import ReactApexChart from "react-apexcharts";

const EnergyLossChart = (chartData) => {
  // Extracting labels and dataset values

  const chartDataArray = chartData?.chartData || [];

  const formatDate = (dateString) => {
    const dateParts = dateString.split('-');
    
    // Expecting dateString in format YYYY-MM-DD
    const [year, month, day] = dateParts;
  
    // Format the date in "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
  };

  const labels = chartDataArray.map((item) => formatDate(item.created_at));

  const benchmarkValues = chartDataArray.map(
    (item) => item.benchmark_energy_loss_kwh
  );

  const actualValues = chartDataArray.map(
    (item) => item.actual_energy_loss_kwh
  );
  const chartDataMain = {
    options: {
        chart: {
            toolbar: {
                export: {
                    csv: {
                        filename: "Energy Loss (kWh)",
                        headerCategory: 'Date',
                        categoryFormatter: (value) => value,
                    },
                    png: {
                        filename: "Energy Loss (kWh)",
                    },
                    svg: {
                        filename: "Energy Loss (kWh)",
                    }
                }
            },
            animations: {
                enabled: false
            },
            type: "bar",
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["#1B75BB", "#7BBFE9"],
        },
        xaxis: {
            categories: labels,
            title: {
                // text: "Date",
                style: {
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "500",
                },
            },
            labels: {
                show: labels.length > 0,
                style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "500",
                },
                formatter: (value) => value,
            },
        },
        yaxis: {
            title: {
                text: "Energy Loss (kWh)",
                style: {
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "500",
                },
            },
            labels: {
                style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "500",
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ["#1B75BB", "#7BBFE9"], // Add your desired column colors here
            type: ["solid", "solid"], // Set legend marker type to 'solid'
            markers: {
                colors: ["#1B75BB", "#7BBFE9"], // Set legend marker colors
            },
        },
        tooltip: {
            y: {
                style: {
                    fontSize: "14px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "500",
                },
                formatter: function (val) {
                    return val;
                },
            },
        },
        legend: {
            position: "top", // Place legends at the top
            markers: {
                fillColors: ["#1B75BB", "#7BBFE9"], // Set legend marker fill colors
            },
            labels: {
                style: {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '14px',
                    fontWeight: '500',
                }
            }
        },
        grid: {
            padding: {
                bottom: 20, // Adjust bottom padding to ensure x-axis title is not cut off
            }
        }
    },
    series: [
        {
            name: "Benchmark",
            data: benchmarkValues,
            markers: {
                colors: ["#1B75BB"], // Set legend marker color for this series
            },
        },
        {
            name: "Actual",
            data: actualValues,
            markers: {
                colors: ["#7BBFE9"], // Set legend marker color for this series
            },
        },
    ],
};



  return (
    <div className="card pr-card">
      <div className="cardHdr">
        <h5>Energy Loss (kWh)</h5>
      </div>
      {/* <div className="inrCardBody"> */}
      <div className="card-body text-center mt20">
        <ReactApexChart
          options={chartDataMain.options}
          series={chartDataMain.series}
          type="bar"
          height={350}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default EnergyLossChart;
