import React from "react";
import ReactApexChart from "react-apexcharts";

const EnergyChart = (chartData) => {
  // Extracting labels and dataset values

  const chartDataArray = chartData?.chartData || [];

  const formatDate = (dateString) => {
    const dateParts = dateString.split('-');
    
    // Expecting dateString in format YYYY-MM-DD
    const [year, month, day] = dateParts;
  
    // Format the date in "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
  };

  const labels = chartDataArray.map((item) => formatDate(item.created_at));

  const benchmarkValues = chartDataArray.map((item) =>
    item.benchmark_energy_delivered_kwh.toFixed(1)
  );

  const actualValues = chartDataArray.map((item) =>
    item.actual_energy_delivered_kwh.toFixed(1)
  );

  const chartDataMain = {
    options: {
      chart: {
        toolbar: {
          export: {
            csv: {
              filename: "Energy Transferred (kWh)",
              headerCategory: 'Date',
              categoryFormatter: (value) => value,
            },
            png: {
              filename: "Energy Transferred (kWh)",
            },
            svg: {
              filename: "Energy Transferred (kWh)",
            },
          },
        },
        animations: {
          enabled: false,
        },
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#1B75BB", "#7BBFE9"],
        
      },
      xaxis: {
        categories: labels,
        title: {
          // text: "Date",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis title
            fontSize: '14px',          // Set the font size
            fontWeight: '500',         // Adjust font weight
          },
        },
        labels: {
          show: labels.length > 0,
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis labels
            fontSize: '14px',
            fontWeight: '500',
          },
          formatter: (value) => value,
        },
      },
      yaxis: {
        title: {
          text: "Energy Transferred (kWh)",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for y-axis title
            fontSize: '14px',
            fontWeight: '500',
            color: '#000000',
          },
          
        },
        
       
      },
      fill: {
        
        opacity: 1,
        colors: ["#1B75BB", "#7BBFE9"],
        type: ["solid", "solid"],
        markers: {
          colors: ["#1B75BB", "#7BBFE9"],
        },
      },
  
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for tooltip
            fontSize: '14px',
            fontWeight: '500',
          },
        },
      },
      legend: {
        position: "top",  // Legends at the top
        labels: {
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for legend
            fontSize: '14px',
            fontWeight: '500',
          },
        },
        markers: {
          fillColors: ["#1B75BB", "#7BBFE9"],
        },
      },
      grid: {
        padding: {
          bottom: 20, // Padding to prevent cut-off
        },
      },
    },
    series: [
      {
        name: "Benchmark",
        data: benchmarkValues,
        style: {
          fontFamily: "Montserrat",  // Apply Montserrat font to series names
          fontSize: '14px',
          fontWeight: '500',
        },
        markers: {
          colors: ["#1B75BB"],
        },
      },
      {
        name: "Actual",
        data: actualValues,
        style: {
          fontFamily: "Montserrat",  // Apply Montserrat font to series names
          fontSize: '14px',
          fontWeight: '500',
          color: '#000000',
        },
        markers: {
          colors: ["#7BBFE9"],
        },
      },
    ],
  };
  


  return (
    <div className="card pr-card">
      <div className="inrCardHdr">
        <h5>Energy Transferred (kWh)</h5>
      </div>
      <div className="inrCardBody">
        <div className="card-body text-center mt20">

          <ReactApexChart
            options={chartDataMain.options}
            series={chartDataMain.series}
            type="bar"
            height={350}
          />

        </div>
      </div>
    </div>
  );
};

export default EnergyChart;
