import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function DrumReport() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [servReport, setServReport] = useState();

  const location = useLocation();
  const rowData = location.state;
  const { serviceOrderNo, service_order_Id } = location.state;

  const drumserialnumber = rowData?.id.assset_id;

  // getting Token
  useEffect(() => {
    const elementsToRemove = document.querySelectorAll(".sidebarRight");
    elementsToRemove.forEach((element) => {
      element.remove();
    });
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  //////// service order api /////////
  const getServiceReport = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/drums/view_drum_test_report`,
            {
              access_token: token,
              user_log_id: userLogId,
              service_order_Id: service_order_Id,
              service_order_no: serviceOrderNo,
              drum_serial_number: drumserialnumber,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setServReport(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setServReport([]);
      showFlashMessage("Drum Report Not Found");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getServiceReport();
    }
  }, [token]);

  // truncate to two decimal limt
  const truncateToTwoDigits = (value) => {
    if (!isNaN(value)) {
      return Math.trunc(value * 100) / 100;
    }
    return value;
  };

  const downloadPDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF

    // Loop through each drum and capture its content
    for (let i = 0; i < servReport?.[0]?.drum_info.length; i++) {
      const input = document.getElementById(`drum-1`);

      // Temporarily adjust the height of the container to fit all content
      const originalHeight = input.style.height;
      input.style.height = "auto";

      // Capture the drum's content as an image
      const canvas = await html2canvas(input, {
        scale: 2, // Increase scale for better quality
        useCORS: true, // Allow cross-origin images
        allowTaint: true, // Allow tainted images
        logging: true, // Enable logging for debugging
      });

      // Restore the original height
      input.style.height = originalHeight;

      const imgData = canvas.toDataURL("image/png");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = imgWidth / imgHeight;

      let width = pageWidth;
      let height = width / ratio;

      if (height > pageHeight) {
        height = pageHeight;
        width = height * ratio;
      }

      // Add the drum's content to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, width, height);

      // Add a new page for the next drum (except for the last drum)
      //   if (i < servReport?.[0]?.drum_info.length - 1) {
      //     pdf.addPage();
      //   }
    }

    // Save the PDF
    pdf.save("Drum Report.pdf");
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="reportPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div id="content-to-download">
              <Row>
                <Col md={12} lg={12}>
                  <Card className="mdlContent pr-card mb30">
                    <Card.Header className="breadcrumbHdr">
                      <Row>
                        <Breadcrumb className="col-md-10">
                          <Breadcrumb.Item>
                            <Link to={`/cable Service Order`}>
                              Service Order
                            </Link>
                          </Breadcrumb.Item>{" "}
                          <Breadcrumb.Item href="#">
                            {servReport?.[0]?.service_order_no}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                        <Col className="switchWpr col-md-2">
                          <button
                            type="button"
                            class="btn btn-primary float-right"
                            onClick={downloadPDF}
                          >
                            Download PDF
                          </button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body className="mdlContent">
                      <div className="">
                        <table
                          id=""
                          className="table table-bordered dtr-inline"
                          style={{ width: "100%" }}
                        >
                          <tbody id="drum-1">
                            <tr>
                              <td colspan="4" style={{ padding: "0" }}>
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td
                                      rowspan="3"
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <img
                                        src="assets/images/motware_logo2.png"
                                        alt="oil test Logo"
                                        width="40px"
                                      />
                                    </td>
                                    <td>
                                      <h1 className="reportHead">{servReport?.[0]?.customerName}</h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                    {servReport?.[0]?.address} |{" "}
                                    {servReport?.[0]?.contact_no}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                    {servReport?.[0]?.service_order_no} |{" "}
                                    {servReport?.[0]?.purchase_order_no}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            {servReport?.[0]?.drum_info.map((drum, index) => (
                              <React.Fragment key={index}>
                                <tr id={`drum-${index + 1}`}>
                              <td style={{ padding: "0" }}>
                                <table
                                  className="mt20"
                                  style={{ width: "100%" }}
                                >
                                  <tr>
                                    <th>Drum Serial Number</th>
                                    <th>Conductor Type</th>
                                    <th>Conductor Diameter (m)</th>
                                    <th>Conductor Length (m)</th>
                                    <th>Weight (kg)</th>
                                  </tr>
                                  <tr>
                                    <td>
                                    {drum.drum_serial_number}
                                    </td>
                                    <td>{drum.conductor_type}</td>
                                    <td>
                                    {truncateToTwoDigits(drum.conductor_diameter_mm)}
                                    </td>
                                    <td>
                                    {truncateToTwoDigits(drum.drum_length_m)}
                                    </td>
                                    <td>
                                    {truncateToTwoDigits(drum.drum_weight)}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            {/* drum 1 report start */}
                            <tr>
                              <td style={{ padding: "0" }}>
                                <table
                                  className="mt20"
                                  style={{ width: "100%" }}
                                >
                                  <tr>
                                    <th>Total Test</th>
                                    <th>Pass Test</th>
                                    <th>Fail Test</th>
                                    <th>Line</th>
                                  </tr>
                                  <tr>
                                    <td>{drum.total_test}</td>
                                    <td>{drum.pass_count}</td>
                                    <td>{drum.fail_count}</td>
                                    <td>{drum.line_number}</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            {/* test 1 start */}
                            {drum.test_data.map((test, testIndex) => (
                                  <React.Fragment key={testIndex}>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "10px",
                                        }}
                                      >
                                        <h5>
                                          {testIndex + 1}. {test.test_type}{" "}
                                          <span
                                            className={
                                              test.test_data.test_result ===
                                              "Pass"
                                                ? "passTest"
                                                : "failTest"
                                            }
                                          >
                                            {test.test_data.test_result}
                                          </span>
                                        </h5>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0" }}>
                                        <table
                                          className="mb20"
                                          style={{ width: "100%" }}
                                        >
                                          {/* Dynamically generate table headers */}
                                          <thead>
                                            <tr>
                                              {Object.keys(test.test_data)
                                                .filter(
                                                  (key) =>
                                                    key !== "test_result" &&
                                                    key !== "test_remark"
                                                )
                                                .map((key) => (
                                                  <th key={key}>{key}</th>
                                                ))}
                                            </tr>
                                          </thead>
                                          {/* Dynamically generate table rows */}
                                          <tbody>
                                            <tr>
                                              {Object.entries(test.test_data)
                                                .filter(
                                                  ([key]) =>
                                                    key !== "test_result" &&
                                                    key !== "test_remark"
                                                )
                                                .map(([key, value], index) => (
                                                  <td key={index}>{!isNaN(value) ? truncateToTwoDigits(value) : value}</td>
                                                ))}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "10px",
                                        }}
                                      >
                                        <h6>Remark:</h6>
                                        <ol>
                                          {test.test_data.test_remark ? (
                                            <li>
                                              {test.test_data.test_remark}
                                            </li>
                                          ) : (
                                            <li>-</li>
                                          )}
                                        </ol>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            {/* test 1 end */}
                            </React.Fragment>
                        ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DrumReport;
