/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";

const Sidebar = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let {
    locationData,
    assetData,
    changeLocation,
    changeAsset,
    handleSubmitData,
    selectedLocation,
    selectedAsset,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    updateFromDate,
    updateToDate,
  } = props;
  // const [showFirstModal, setShowFirstModal] = useState(false);
  // const [fromTime, setFromTime] = useState("00:00");
  // const [fromHours, setFromHours] = useState(
  //   fromDate.getHours().toString().padStart(2, "0")
  // );
  // const [fromMinutes, setFromMinutes] = useState(
  //   fromDate.getMinutes().toString().padStart(2, "0")
  // );
  // const [fromSeconds, setFromSeconds] = useState("00");
  // const [toTime, setToTime] = useState("00:00");
  // const [toHours, setToHours] = useState(
  //   toDate.getHours().toString().padStart(2, "0")
  // );
  // const [toMinutes, setToMinutes] = useState(
  //   toDate.getMinutes().toString().padStart(2, "0")
  // );
  // const [toSeconds, setToSeconds] = useState("00");
  const [isFromDateOpen, setFromDateOpen] = useState(false);
  const [isToDateOpen, setToDateOpen] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // Custom input component to disable manual typing
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      value={value}
      onClick={onClick}
      ref={ref}
      readOnly
    />
  ));

  const handleModalConfirm = () => {
    // if (selectedRow) {
    //   const newStatus = selectedRow.status === "Open" ? "Close" : "Open";
    //   handleUserUpdate(selectedRow, "status", newStatus);
    // }
    setIsModalVisible(false);
    // setSelectedRow(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    // setSelectedRow(null);
  };

  const validationSchema = Yup.object().shape({
    // fromDate: Yup.date()
    //   .required('From Date is required')
    //   .max(new Date(moment(toDate).add(1, 'days')), 'From Date should be less than or equal to To Date')
    //   .min(new Date(moment(fromDate).subtract(30, 'days')), 'From Date is exceeding the minimum From Date'),
    // .max(new Date(), 'From Date should be less than or equal to the current date'),
    // toDate: Yup.date()
    //   .required('To Date is required')
    //   .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
    //   .max(new Date(moment(fromDate).add(30, 'days')), 'To Date is exceeding the maximum To Date')
    // ,
    // .max(),
    // .max(new Date(), 'To Date should be less than or equal to the current date'),
    location: Yup.object().shape({
      value: Yup.string().required("Location is required"),
    }),
    site: Yup.object().shape({
      value: Yup.string().required("Site is required"),
    }),
  });

  const formik = useFormik({
    // enableReinitialize: true, // Enable reinitialization when initialValues change
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
      location: selectedLocation,
      site: selectedAsset,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      // fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      // toDateWithHours.setHours(toHours, toMinutes, toSeconds);

      // Check if To Date is less than From Date
      if (toDateWithHours < fromDateWithHours) {
        formik.setFieldError(
          "toDate",
          "To Date cannot be less than From Date."
        );
        return;
      }

      // Check the difference between fromDate and toDate
      const diffInDays = moment(toDateWithHours).diff(
        moment(fromDateWithHours),
        "days"
      );

      // If difference exceeds 30 days, show error message
      if (diffInDays > 30) {
        formik.setFieldError("toDate", "Maximum allowed date range is 30 days");
      } else {
        const data = {
          fromDate: fromDateWithHours,
          toDate: toDateWithHours,
          // toDate: new Date(toDate),
          location: selectedLocation,
          site: selectedAsset,
        };
        props.handleSubmitData(data);
        handleCloseFirstModal();

        props.updateFromDate(fromDateWithHours);
        props.updateToDate(toDateWithHours);
      }
    },
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleCloseFirstModal = () => {
    // formik.setErrors({
    //   combinedDate: "",
    //   fromDate: "",
    //   toDate: "",
    //   location: "",
    //   site: "",
    // });
    setShowFirstModal(false);
  };
  const handleShowFirstModal = () => {
    formik.setFieldValue("location", selectedLocation);
    formik.setFieldValue("site", selectedAsset);
    formik.setFieldValue("toDate", toDate);
    formik.setFieldValue("fromDate", fromDate);

    setShowFirstModal(true);
  };

  const handleLocationChange = (selectedLocation) => {
    localStorage.removeItem("asset");
    props.changeLocation(selectedLocation);
  };
  // Update formik values when props change
  useEffect(() => {
    formik.setErrors({
      fromDate: "",
      toDate: "",
      location: "",
      site: "",
      parameter: "",
    });
    formik.setFieldValue("fromDate", fromDate);
    formik.setFieldValue("location", selectedLocation);
    formik.setFieldValue("site", selectedAsset);
  }, [selectedLocation, selectedAsset, fromDate]);

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
  };
  const handleShowSecondModal = () => {
    setShowSecondModal(true);
  };

  const handleCloseTitleGroupForm = () => {
    setShowSecondModal(false);
  };

  return (
    <>
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
              data-tooltip-id="tooltip"
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip
              id="tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              style={{
                position: "absolute",
                marginLeft: "-10px",
                padding: "10% 20% 10% 20%",
              }}
            />
          </li>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
              data-tooltip-content="Filter"
              data-tooltip-id="tooltip"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
          </li> */}
        </ul>
      </div>

      <Card className="filterCard submitbtnWpr">
        <Card.Body>
          <Form className="">
            <Row>
              <Form.Group className="form-group col-md-3 col-lg-3" controlId="">
                <Form.Label>Location *</Form.Label>.
                <Select
                  options={locationData}
                  placeholder="Select Location"
                  onChange={(e) => {
                    handleLocationChange(e);
                    formik.setFieldValue("site", "");
                    // changeLocation(e);
                    formik.setFieldTouched("location", true);
                    formik.setFieldValue("location", e);
                  }}
                  value={"formik.values.location"}
                  onBlur={() => {
                    // formik.handleBlur('location');
                    formik.setFieldTouched("location", true);
                  }}
                />
                {formik.errors.location && formik.touched.location && (
                  <div className="error-message text-danger">
                    {formik.errors.location.value}
                  </div>
                )}
                {/* <Form.Select>
                              <option>Select Location</option>
                            </Form.Select> */}
              </Form.Group>
              <Form.Group className="form-group col-md-3 col-lg-3" controlId="">
                <Form.Label>Asset *</Form.Label>
                <Select
                  options={assetData}
                  placeholder="Select Asset"
                  value={formik.values.site}
                  onChange={(e) => {
                    formik.setFieldValue("parameter", []);
                    changeAsset(e);
                    formik.setFieldTouched("site", true);
                    formik.setFieldValue("site", e);
                  }}
                  onBlur={() => {
                    // formik.handleBlur('site');
                    formik.setFieldTouched("site", true);
                  }}
                />
                {formik.errors.site && formik.touched.site && (
                  <div className="error-message text-danger">
                    {formik.errors.site.value}
                  </div>
                )}
                {/* <Form.Select>
                              <option>Select Asset</option>
                            </Form.Select> */}
              </Form.Group>

              <Form.Group className="form-group col-md-3 col-lg-3" controlId="">
                <Form.Label>Rating </Form.Label>
                <Select
                  options={assetData}
                  placeholder="Select Rating"
                  value={formik.values.site}
                  onChange={(e) => {
                    formik.setFieldValue("parameter", []);
                    changeAsset(e);
                    formik.setFieldTouched("site", true);
                    formik.setFieldValue("site", e);
                  }}
                  onBlur={() => {
                    // formik.handleBlur('site');
                    formik.setFieldTouched("site", true);
                  }}
                />
                {formik.errors.site && formik.touched.site && (
                  <div className="error-message text-danger">
                    {formik.errors.site.value}
                  </div>
                )}
                {/* <Form.Select>
                              <option>Select Asset</option>
                            </Form.Select> */}
              </Form.Group>
              <Form.Group className="form-group col-md-3 col-lg-3" controlId="">
                <Form.Label>Test Name </Form.Label>
                <Select
                  options={assetData}
                  placeholder="Select Test Name"
                  value={formik.values.site}
                  onChange={(e) => {
                    formik.setFieldValue("parameter", []);
                    changeAsset(e);
                    formik.setFieldTouched("site", true);
                    formik.setFieldValue("site", e);
                  }}
                  onBlur={() => {
                    // formik.handleBlur('site');
                    formik.setFieldTouched("site", true);
                  }}
                />
                {formik.errors.site && formik.touched.site && (
                  <div className="error-message text-danger">
                    {formik.errors.site.value}
                  </div>
                )}
                {/* <Form.Select>
                              <option>Select Asset</option>
                            </Form.Select> */}
              </Form.Group>
              <Form.Group className="form-group col-md-3 col-lg-3" controlId="">
                <Form.Label>Test Result </Form.Label>
                <Select
                  options={assetData}
                  placeholder="Select Test Result"
                  value={formik.values.site}
                  onChange={(e) => {
                    formik.setFieldValue("parameter", []);
                    changeAsset(e);
                    formik.setFieldTouched("site", true);
                    formik.setFieldValue("site", e);
                  }}
                  onBlur={() => {
                    // formik.handleBlur('site');
                    formik.setFieldTouched("site", true);
                  }}
                />
                {formik.errors.site && formik.touched.site && (
                  <div className="error-message text-danger">
                    {formik.errors.site.value}
                  </div>
                )}
                {/* <Form.Select>
                              <option>Select Asset</option>
                            </Form.Select> */}
              </Form.Group>
              <Form.Group
                className="form-group col-auto"
                controlId=""
              >
                <Form.Label>Year</Form.Label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en"
                >
                  <DateTimePicker
                    disabled={!formik.values.location}
                    className="form-control"
                    onChange={(date) => {
                      formik.setFieldValue("fromDate", date);
                      formik.setFieldTouched("fromDate", true);
                      updateFromDate(date);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 37,
                        cursor: "pointer",
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      },
                      "& .MuiInputAdornment-root button": {
                        display: "none", // Hide the button
                      },
                    }} // Adjust height
                    defaultValue={dayjs()} // Set default value to current date-time
                    format="YYYY" // 24-hour format
                    timeSteps={{ minutes: 1 }} // Set minute step to 1
                    ampm={false} // Ensures 24-hour format
                    maxDate={dayjs()} // Max date as today
                    minDate={dayjs().subtract(3, "month")} // Min date 3 months ago
                    slots={{ openPickerIcon: () => null }} // Remove date-time icon
                    open={isFromDateOpen} // Control picker state
                    onClose={() => setFromDateOpen(false)}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        onClick: () => setFromDateOpen(true), // Open picker on input click
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>

                <Form.Group className="col-auto">
                  <button
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </Form.Group>
             
                <Form.Group className="col-auto">
                  <button
                    // onClick={formik.handleSubmit}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Upload Report
                  </button>
                </Form.Group>
              
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {/* active/inactive button  */}
      {isModalVisible && (
        <div
          className="right modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5>Upload Document</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleModalCancel}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <Form>
                  {/* Upload Document Field */}
                  <Form.Group className="form-group">
                    {/* <Form.Label>Upload Document </Form.Label> */}
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="documentUpload"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="documentUpload"
                      >
                        {selectedFile ? selectedFile.name : "Choose file"}
                      </label>
                    </div>
                  </Form.Group>
                  
                  {/* File List
                  {selectedFile && (
                    <div className="file-list mt-2">
                      <p>
                        <strong>Selected File:</strong> {selectedFile.name}
                      </p>
                    </div>
                  )} */}

                  <Form.Group className="form-group">
                    <Form.Label>Date *</Form.Label>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en"
                    >
                      <DateTimePicker
                        className="form-control"
                        format="DD-MM-YYYY " // 24-hour format
                        maxDate={dayjs()}
                        minDate={dayjs().subtract(3, "month")}
                        onChange={() => {}}
                      />
                    </LocalizationProvider>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Location </Form.Label>
                    <Select
                      options={locationData}
                      placeholder="Select Location"
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Asset </Form.Label>
                    <Select options={assetData} placeholder="Select Asset" />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Test Name</Form.Label>
                    <Select
                      options={assetData}
                      placeholder="Select Test Type"
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Test Result</Form.Label>
                    <Select
                      options={assetData}
                      placeholder="Select Test Result"
                    />
                  </Form.Group>

                  {/* <Form.Group className="form-group">
                    <Form.Label>Rating *</Form.Label>
                    <Select options={assetData} placeholder="Select Rating" />
                  </Form.Group> */}

                  


                  

                  {/* <div className="text-center mt-3">
                    <Button variant="primary" onClick={handleModalConfirm}>
                      Upload
                    </Button>
                    <Button
                      variant="secondary"
                      className="ml-2"
                      onClick={handleModalCancel}
                    >
                      Cancel
                    </Button>
                  </div> */}

                  <div id="" class="text-center">
                    <div class="ftrBtn">
                      <div class="form-group">
                        <button type="" name="" value="Submit" class="btn btn-primary btn-block" data-disable-with="">Submit</button>
                      </div>
                    </div>
                  </div>

                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
