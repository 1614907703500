import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CardBody, CardHeader, Table } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import NameplateSkeleton from "./NameplateSkeleton";
import { useFormik } from "formik";
import * as Yup from "yup";

function Nameplate() {
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [userLogId, setUserLogId] = useState("");
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      const role = Cookies.get("role");
      setUserName(role);
    }
  }, []);

  const [alerts, setAlerts] = React.useState([]);
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          location: onloadLoc,
          site: onloadAsset,
        };
        handleSubmitData(data);
      } catch (error) {}
    }
  }, [onload]);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        setOnloadLoc(new_location);
        getAsset(new_location.value);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
    }
  };

  const getLocation = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  //////// tx iq cards ////////
  const [txIq, setTxIq] = useState([]);

  const getTxIq = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/nameplate/view_txiq_details`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setTxIq(data);
            success = true;
            setIsLoading(false);
          } else {
            setTxIq([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setTxIq([]);
      showFlashMessage("No Data Available For Cards");
      setIsLoading(false);
    }
  };

  //////// nameplate api/////
  const [nameplate, setNameplate] = useState([]);

  const getNameplate = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/nameplate/view_txiq_nameplate`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setNameplate(data);
            success = true;
            setIsLoading(false);
          } else {
            setNameplate([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setNameplate([]);
      showFlashMessage("No Data Available For Nameplate");
      setIsLoading(false);
    }
  };

  const formatKey = (key) => {
    if (key === "No Of Phases") return "No. Of Phases";
    if (key === "Max Temperature Rise Of Oil")
      return "Max. Temperature Rise Of Oil";
    if (key === "Max Temperature Rise Of Winding")
      return "Max. Temperature Rise Of Winding";
    return key;
  };

  /////// nameplate table ///////
  const [nameplatetable, setNameplateTable] = useState([]);

  const getNameplateTable = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/nameplate/view_txiq_nameplate_table`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setNameplateTable(data);
            success = true;
            setIsLoading(false);
          } else {
            setNameplateTable([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setNameplateTable([]);
      showFlashMessage("No Data Available For Nameplate Table");
      setIsLoading(false);
    }
  };

  // Extract headers dynamically from the first object in nameplatetable (excluding "Type")
  const headers =
    nameplatetable.length > 0
      ? Object.keys(nameplatetable[0]).filter((key) => key !== "Type")
      : [];

  ///////// get and add nameplate///////
  const [nameplateView, setNameplateView] = useState([]);

  const getNameplateView = async (props) => {
    if (!token) {
      return;
    }
    setNameplateView([])
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/nameplate/get_nameplate_for_update_view`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation.value,
              assset_id: selectedAsset.value,
              iot_sol: "Transformer",
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setNameplateView(data);
            success = true;
            setIsLoading(false);
          } else {
            setNameplateView([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setNameplateView([]);
      showFlashMessage("No Data Available For Nameplate View");
      setIsLoading(false);
    }
  };

  const [formData, setFormData] = useState(() => {
    return nameplateView.reduce((acc, field) => {
      acc[field.database_name] = field.value;
      return acc;
    }, {});
  });

  // const handleChange = (e, field) => {
  //   const { value } = e.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     [field.database_name]:
  //       field.data_type === "number" ? Number(value) : value,
  //   }));
  // };

  const handleChange = (e, field) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [field.database_name]: value, // Store as text
    }));
  };

  const handleNameplateSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }

     // Convert values to number only if required
  const formattedData = Object.keys(formData).reduce((acc, key) => {
    const field = nameplateView.find((f) => f.database_name === key);
    acc[key] = field.data_type === "number" ? parseFloat(formData[key]) || 0 : formData[key];
    return acc;
  }, {});

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/nameplate/update_txiq_nameplate`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation.value,
              assset_id: selectedAsset.value,
              iot_sol: "Transformer",
              nameplate: formattedData,
            }
          );
          if (response.status === 200) {
            message = response.data.msg;
            if (response.status === 200 && response.data.success === true) {
              success = true;
              setIsLoading(false);
              handleCloseNameplate();
              getNameplate({
                access_token: token,
                user_log_id: userLogId,
                location_id: selectedLocation?.value,
                assset_id: selectedAsset?.value,
                iot_sol: "Transformer",
              })
              getNameplateTable({
                access_token: token,
                user_log_id: userLogId,
                location_id: selectedLocation?.value,
                assset_id: selectedAsset?.value,
                iot_sol: "Transformer",
              })
              showFlashMessage(message, "success");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      handleCloseNameplate();
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  ////////// TOD Table api ////////
  const [hideActionColumn, setHideActionColumn] = useState(false);

  const [tapsTod, setTod] = useState([]);

  const getTod = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/TOD/get_tod_parameters`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setTod(data);
            success = true;
            setIsLoading(false);
          } else {
            setTod([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setTod([]);
      showFlashMessage("No Data Available For TOD");
      setIsLoading(false);
    }
  };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // maxWidth: "150px",
  };

  const columnsTod =
    tapsTod.length > 0
      ? Object.keys(tapsTod[0]).map((key) => ({
          name: key.replace(/_/g, " "),
          selector: (row) => row[key],
          sortable: true,
          cell: (row) => (
            <div title={row[key]} style={ellipsisStyle}>
              {row[key]}
            </div>
          ),
        }))
      : [];

  if (userName === "account_admin" && !hideActionColumn) {
    columnsTod.push({
      name: "",
      cell: (row) => (
        <>
          <Link title="Edit" onClick={() => getEditTod(row)}>
            <i className="fa fa-edit" onClick={handleShowEditTOD}></i>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link title="Delete" onClick={() => handleDeleteClickTOD(row)}>
            <i className="fa fa-trash"></i>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    });
  }
  //// no data available columns ////
  const NoDataComponentTod = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {/* {columns.map((column) => (
                   <th key={column.name}>{column.name}</th>
                 ))} */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For TOD</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  /////////// add TOD //////////

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    start_time: Yup.string().required("Start Time is required"),
    end_time: Yup.string().required("End Time is required"),
    energy_rate: Yup.number().required("Energy Rate is required"),
    // .positive("Energy Rate must be a positive number"),
  });

  const [isStartTimeOpen, setStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setEndTimeOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      start_time: "", // Default to current time
      end_time: "", // Default to current time
      energy_rate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!token) return;

      let attempts = 0;
      const maxAttempts = 3;
      let success = false;
      let message = "";

    // Convert energy_rate to a number
    const numericEnergyRate = Number(values.energy_rate);

      while (attempts < maxAttempts && !success) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/TOD/add_tod_parameters`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
              start_time: values.start_time,
              end_time: values.end_time,
              energy_rate: numericEnergyRate,
            }
          );
          if (response.status === 200) {
            message = response.data.msg;
            if (response.status === 200 && response.data.success === true) {
              success = true;
              setIsLoading(false);
              handleCloseAddTOD();
              showFlashMessage(message, "success");
              getTod({
                access_token: token,
                user_log_id: userLogId,
                location_id: selectedLocation?.value,
                assset_id: selectedAsset?.value,
                iot_sol: "Transformer",
              });
              resetForm(); // Reset the form after successful submission
            }
          }
        } catch (error) {
          console.error(error);
        }
        attempts++;
      }
      if (!success) {
        handleCloseAddTOD();
        showFlashMessage(message);
        setIsLoading(false);
        resetForm();
      }
    },
  });
  
  ////// edit TOD //////

  const [selectedTod, setSelectedTod] = useState({
    start_time: "",
    end_time: "",
    energy_rate: "",
  });

  const [selectedTodTime, setSelectedTodTime] = useState({
    start_time: "",
    end_time: "",
  });

  const editvalidationSchema = Yup.object().shape({
    start_time: Yup.string().required("Start Time is required"),
    end_time: Yup.string().required("End Time is required"),
    energy_rate: Yup.number().required("Energy Rate is required"),
    // .positive("Energy Rate must be a positive number"),
  });

  const getEditTod = async (row) => {
    // e.preventDefault();
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/TOD/get_edit_tod_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            start_time: row.start_time,
            end_time: row.end_time,
          }
        );
        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            setSelectedTod({
              start_time: response.data.data[0].start_time,
              end_time: response.data.data[0].end_time,
              energy_rate: response.data.data[0].energy_rate,
            });
            setSelectedTodTime({
              start_time: response.data.data[0].start_time,
              end_time: response.data.data[0].end_time,
            });
            success = true;
            setIsLoading(false);
            // showFlashMessage(message, "success" );
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  const formikEditTod = useFormik({
    initialValues: {
      start_time: selectedTod.start_time || "",
      end_time: selectedTod.end_time || "",
      energy_rate: selectedTod.energy_rate || "",
    },
    validationSchema: editvalidationSchema,
    enableReinitialize: true, // Reinitialize when `selectedTod` changes
    onSubmit: async (values, { resetForm }) => {
      if (!token) return;
   // Convert energy_rate to a number
   const numericEnergyRate = Number(values.energy_rate);
      let attempts = 0;
      const maxAttempts = 3;
      let success = false;
      let message = "";

      while (attempts < maxAttempts && !success) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/TOD/edit_tod_parameters`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
              find_time: {
                start_time: selectedTodTime.start_time,
                end_time: selectedTodTime.end_time,
              },
              update_tod: {
                start_time: values.start_time,
                end_time: values.end_time,
                energy_rate: numericEnergyRate,
              },
            }
          );
          if (response.status === 200) {
            message = response.data.msg;
            if (response.status === 200 && response.data.success === true) {
              success = true;
              setIsLoading(false);
              handleCloseEditTOD();
              showFlashMessage(message, "success");
              getTod({
                access_token: token,
                user_log_id: userLogId,
                location_id: selectedLocation?.value,
                assset_id: selectedAsset?.value,
                iot_sol: "Transformer",
              });
              resetForm(); // Reset the form after successful submission
            }
          }
        } catch (error) {
          console.error(error);
        }
        attempts++;
      }
      if (!success) {
        handleCloseEditTOD();
        showFlashMessage(message);
        setIsLoading(false);
        resetForm();
      }
    },
  });

  ///////// delete tod //////////
  const deleteTod = async (row) => {
    // e.preventDefault();
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/TOD/delete_tod_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            start_time: row.start_time,
            end_time: row.end_time,
          }
        );
        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            success = true;
            setIsLoading(false);
            showFlashMessage(message, "success");
            getTod({
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  const [showDeleteModalTOD, setShowDeleteModalTOD] = useState(false);

  const handleDeleteClickTOD = (row) => {
    setSelectedTod(row);
    setShowDeleteModalTOD(true);
  };

  const handleConfirmDeleteTOD = () => {
    if (selectedTod) {
      deleteTod(selectedTod);
      setShowDeleteModalTOD(false);
    }
  };

  ///////// Taps table api ////////
  const [tapsTable, setTapsTable] = useState([]);

  const getTaps = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/get_taps_parameters`,
            data
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setTapsTable(data);
            success = true;
            setIsLoading(false);
          } else {
            setTapsTable([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setTapsTable([]);
      showFlashMessage("No Data Available For Taps");
      setIsLoading(false);
    }
  };

  const columns =
    tapsTable.length > 0
      ? Object.keys(tapsTable[0]).map((key) => ({
          name: key.replace(/_/g, " ").toUpperCase(),
          selector: (row) => row[key],
          sortable: true,
          cell: (row) => (
            <div title={row[key]} style={ellipsisStyle}>
              {row[key]}
            </div>
          ),
        }))
      : [];

  if (userName === "account_admin" && !hideActionColumn) {
    columns.push({
      name: "",
      cell: (row) => (
        <>
          <Link title="Edit" onClick={() => getEditTap(row)}>
            <i className="fa fa-edit" onClick={handleShowEditTap}></i>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link title="Delete" onClick={() => handleDeleteClick(row)}>
            <i className="fa fa-trash"></i>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    });
  }
  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {/* {columns.map((column) => (
             <th key={column.name}>{column.name}</th>
           ))} */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Taps</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        //zIndex: 2,
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontWeight: "bold",
        whiteSpace: "normal",
      },
    },
  };

  ////// add tap ////////

  const [tapView, setTapView] = useState([]);

  const geTapView = async (props) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/get_list_of_tap_parameters`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setTapView(data);
            success = true;
            setIsLoading(false);
          } else {
            setTapView([]); // Clear data on API success: false
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setTapView([]);
      showFlashMessage("No Data Available For Tap View");
      setIsLoading(false);
    }
  };

  const [showAddTap, setShowAddTap] = useState(false);

  const handleCloseAddTap = () => {
    setShowAddTap(false);
  };
  const handleShowAddTap = () => {
    geTapView();
    setShowAddTap(true);
  };

  const [tapFormData, setTapFormData] = useState({});

  // Handle input change
  const handleTapChange = (e, key) => {
    const value = e.target.value;
  
    // Allow only numbers and optional decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setTapFormData((prevData) => ({
        ...prevData,
        [key]: value, // Store as string (or convert to number if needed)
      }));
    }
  };

  // Handle form submit
  const handletapSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

     // Convert string values to numbers
 const numericAddTapData = Object.keys(tapFormData).reduce((acc, key) => {
  acc[key] = Number(tapFormData[key]);
  return acc;
}, {});

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/add_tap_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            tap: numericAddTapData,
          }
        );

        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            success = true;
            setIsLoading(false);
            handleCloseAddTap();
            getTaps({
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
            });
            showFlashMessage(message, "success");
            setTapFormData({});
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      handleCloseAddTap();
      showFlashMessage(message);
      setIsLoading(false);
      setTapFormData({});
    }
  };

  ///////// edit tap //////////

  const [showEditTap, setShowEditTap] = useState(false);
  const [editTapData, setEditTapData] = useState({});
  const [selectedTap, setSelectedTap] = useState({});

  // Handle input change
  const handleEditInputChange = (e, key) => {
    const value = e.target.value;
  
    // Allow only numbers and optional decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setEditTapData((prevData) => ({
        ...prevData,
        [key]: value, // Store as string (or convert to number if needed)
      }));
    }
  };

  const getEditTap = async (row) => {
    // e.preventDefault();
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/get_edit_tap_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            tap_no: row.tap_no,
          }
        );
        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            setEditTapData(response.data.data[0]);
            setSelectedTap(row.tap_no);
            success = true;
            setIsLoading(false);
            // showFlashMessage(message, "success" );
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  const SubmitEditTap = async (e, row) => {
    e.preventDefault();
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

 // Convert string values to numbers
 const numericEditTapData = Object.keys(editTapData).reduce((acc, key) => {
  acc[key] = Number(editTapData[key]);
  return acc;
}, {});

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/edit_tap_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            tap_no: selectedTap,
            tap: numericEditTapData,
          }
        );
        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            success = true;
            setIsLoading(false);
            handleCloseEditTap();
            showFlashMessage(message, "success");
            getTaps({
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      handleCloseEditTap();
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  const handleCloseEditTap = () => {
    setEditTapData({});
    setShowEditTap(false);
  };
  const handleShowEditTap = () => {
    setEditTapData({});
    setShowEditTap(true);
  };

  ///// delete tap //////
  const deleteTap = async (row) => {
    // e.preventDefault();
    if (!token) return;

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    let message = "";

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/txiq/taps/delete_tap_parameters`,
          {
            access_token: token,
            user_log_id: userLogId,
            location_id: selectedLocation?.value,
            assset_id: selectedAsset?.value,
            iot_sol: "Transformer",
            tap_no: row.tap_no,
          }
        );
        if (response.status === 200) {
          message = response.data.msg;
          if (response.status === 200 && response.data.success === true) {
            success = true;
            setIsLoading(false);
            showFlashMessage(message, "success");
            getTaps({
              access_token: token,
              user_log_id: userLogId,
              location_id: selectedLocation?.value,
              assset_id: selectedAsset?.value,
              iot_sol: "Transformer",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage(message);
      setIsLoading(false);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (row) => {
    setSelectedTap(row);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedTap) {
      deleteTap(selectedTap);
      setShowDeleteModal(false);
    }
  };

  ///// end taps //////

  const handleSubmitData = async (props) => {
    const { location, site } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);
    setOnloadLoc(location);
    setOnloadAsset(site);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };

      try {
        await getTxIq(data);
        await getNameplate(data);
        await getNameplateTable(data);
        await getTod(data);
        await getTaps(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { location, site } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      try {
        await getTxIq(data);
        await getNameplate(data);
        await getNameplateTable(data);
        await getTod(data);
        await getTaps(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  //////// show pupups //////

  //////// add nameplate ///////
  const [showNameplate, setShowNameplate] = useState(false);
  const handleCloseNameplate = () => setShowNameplate(false);
  const handleShowNameplate = () => {
    getNameplateView();
    setShowNameplate(true);
  };

  /////// add tod ////////////
  const [showAddTOD, setShowAddTOD] = useState(false);
  const handleCloseAddTOD = () => {
    setShowAddTOD(false);
  };
  const handleShowAddTOD = () => {
    setShowAddTOD(true);
  };

  ///////// edit tod //////////
  const [showEditTOD, setShowEditTOD] = useState(false);
  const handleCloseEditTOD = () => {
    setSelectedTod({
      start_time: "",
      end_time: "",
      energy_rate: "",
    });
    setShowEditTOD(false);
  };
  const handleShowEditTOD = () => {
    setSelectedTod({
      start_time: "",
      end_time: "",
      energy_rate: "",
    });
    setShowEditTOD(true);
  };

  //////////// generate pdf ///////////

  const [showPDFHeader, setShowPDFHeader] = useState(false);
  const downloadPDF = async () => {
    const input = document.getElementById("content-to-download");
  
    if (!input) {
      console.error("Content not found");
      return;
    }
    setShowPDFHeader(true);
    // Hide action columns & buttons
    setHideActionColumn(true);
    document.querySelectorAll(".hide-in-pdf").forEach((el) => (el.style.display = "none"));
  
    // Expand content height
    input.style.height = "auto";
    input.style.overflow = "visible";
  
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for reflow
  
    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      let position = 10; // Initial Y position
  
      // **Capture HTML Content as Image**
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      position += imgHeight + 2; // Move down for tables

      // **Generate TOD Table**
      // autoTable(pdf, {
      //   startY: position,
      //   head: [Object.keys(tapsTod[0] || {}).map((key) => key.replace(/_/g, " "))],
      //   body: tapsTod.map((row) => Object.values(row)),
      //   styles: { fontSize: 5 },
      //   headStyles: { fillColor: false,textColor: [0, 0, 0] },
      //   // margin: { top: 10 },
      // })

  
      // position = pdf.lastAutoTable.finalY + 10; // Update position
  
// **Taps Header (Styled)**
// **Set position after TOD Table**
// position = pdf.lastAutoTable.finalY + 5; // Small gap after TOD Table

// **Taps Header (Styled)**
// pdf.setFillColor(101, 119, 144); // Background color: #657790
// pdf.rect(10, position, 190, 5, "F"); // Small height (8px)

// pdf.setFontSize(7);
// pdf.setFont("helvetica", "bold");
// pdf.setTextColor(255, 255, 255); // White text
// pdf.text("Taps", 13, position + 3.5); // Adjusted for padding

// position += 5; // Move position below header

// **Generate Taps Table**
autoTable(pdf, {
  startY: position,
  head: [Object.keys(tapsTable[0] || {}).map((key) => key.replace(/_/g, " ").toUpperCase())],
  body: tapsTable.map((row) => Object.values(row)),
  styles: { fontSize: 5, textColor: [0, 0, 0] },
  headStyles: { fillColor: false, textColor: [0, 0, 0] }, // No header background
  bodyStyles: { fillColor: false }, // Remove row highlighting
  alternateRowStyles: { fillColor: false }, // Disable zebra striping
  // theme: "grid", // Enables borders
  // tableLineWidth: 0.1, // Show only table border
  // tableLineColor: [0, 0, 0], // Black border color
  // horizontalLineWidth: () => 0.1, // Show only horizontal lines
});

  
      // Restore hidden elements
      document.querySelectorAll(".hide-in-pdf").forEach((el) => (el.style.display = ""));
      setHideActionColumn(false);
      setShowPDFHeader(false);
      pdf.save("Nameplate.pdf");
    });
  };
  
  

  // const downloadPDF = () => {
  //   // Check if there's data in the table
  //   if (tapsTable.length === 0) {
  //     console.error("No data available to generate PDF");
  //     return;
  //   }
  
  //   // Initialize PDF
  //   const doc = new jsPDF("p", "mm", "a4");
  
  //   // Register the autoTable plugin
  //   autoTable(doc, {
  //     head: [Object.keys(tapsTable[0]).map((key) => key.replace(/_/g, " ").toUpperCase())], // Column headers
  //     body: tapsTable.map((row) => Object.values(row)), // Row data
  //     startY: 20, // Start position (in mm)
  //     margin: { top: 20 }, // Margin
  //     styles: { overflow: "linebreak", fontSize: 10 }, // Cell styles
  //     columnStyles: {
  //       // Adjust column widths if needed
  //       0: { cellWidth: "auto" },
  //       1: { cellWidth: "auto" },
  //       // Add more column styles as needed
  //     },
  //   });
  
  //   // Save the PDF
  //   doc.save("Nameplate.pdf");
  // };

  return (
    <>
      {isLoading && <NameplateSkeleton />}
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="nameplatePg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <Row>
              <Col md={12} lg={12}>
                <div
                  className="mdlContent card pr-card mb30"
                  style={{ border: "0" }}
                >
                  <CardHeader className="nameplateHdr breadcrumbHdr">
                    <Row>
                      <Breadcrumb className="col-md-10">
                        <Breadcrumb.Item href="#">
                          {selectedAsset.label}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                          {selectedLocation.label}
                        </Breadcrumb.Item>
                      </Breadcrumb>
                      <div className="topBtnWpr col-md-2">
                        <div className="form-inline dataTables_filter pr-form-inline float-right">
                          <div className="input-group pr-form-group" id="">
                            {userName === "account_admin" ? (
                              <a
                                className="btn btn-c-outline-primary mr10"
                                onClick={handleShowNameplate}
                              >
                                <b>Update Nameplate</b>
                              </a>
                            ) : (
                              <></>
                            )}
                            <Link>
                              <i
                                className="fa fa-file-pdf-o"
                                onClick={downloadPDF}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <Sidebar
                    locationData={locationData}
                    assetData={assetData}
                    changeLocation={changeLocation}
                    changeAsset={changeAsset}
                    handleSubmitData={SubmitDataWithDate}
                    selectedLocation={selectedLocation}
                    selectedAsset={selectedAsset}
                  />
                  <Card.Body
                    className="mdlContent mt10"
                  >
<div  id="content-to-download">
                    <Row>
                      {txIq.length > 0 && txIq[0] ? (
                        Object.entries(txIq[0]).map(([key, value], index) => (
                          <Col md={4} lg={4} key={index}>
                            <div className="enrgConsBox mb-3">
                              <div className="inrCardHdr">
                                <h5>{key}</h5>
                              </div>
                              <div className="inrCardBody">
                                <h3 className="mb0">{value}</h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <Col md={12}>
                          <div className="enrgConsBox mb-3">
                            <div className="inrCardHdr">
                              {/* <h5>{key}</h5> */}
                            </div>
                            <div className="inrCardBody">
                              <h3 className="mb0">
                                No Data Available For Cards
                              </h3>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>

                    <Card className="pr-card alerttblCard mb30">
                      <div className="card-header">
                        <h5>Nameplate</h5>
                      </div>
                      <CardBody>
                        <Table className="nameplateTbl table table-bordered mt20">
                          <tbody>
                            {nameplate.length > 0 && nameplate[0] ? (
                              // Split the data into two columns
                              Object.entries(nameplate[0]).reduce(
                                (rows, [key, value], index, array) => {
                                  // Create a new row for every two key-value pairs
                                  if (index % 2 === 0) {
                                    rows.push(
                                      <tr key={index}>
                                        {/* First column */}
                                        <th>{formatKey(key)}</th>
                                        <td>{value}</td>
                                        {/* Second column */}
                                        {array[index + 1] && (
                                          <>
                                            <th>
                                              {formatKey(array[index + 1][0])}
                                            </th>
                                            <td>{array[index + 1][1]}</td>
                                          </>
                                        )}
                                      </tr>
                                    );
                                  }
                                  return rows;
                                },
                                []
                              )
                            ) : (
                              // Fallback UI if no data is available
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <p>No Data Available</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>

                        {nameplatetable.length > 0 ? (
                          <Table className="table table-bordered mt20">
                            <thead>
                              <tr>
                                <th></th>{" "}
                                {/* Empty header for the "Type" column */}
                                {headers.map((header, index) => (
                                  <th key={index} className="text-center">
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {nameplatetable.map((row, index) => (
                                <tr key={index}>
                                  <th className="text-center">{row.Type}</th>
                                  {headers.map((header, idx) => (
                                    <td key={idx} className="text-center">
                                      {row[header]}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <>
                            {/* <Table className="table mt20">
                            <thead>
                              <tr>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <p>No Data Available For Nameplate Table</p>
                                </td>
                              </tr>
                            </tbody>
                          </Table> */}
                          </>
                        )}
                      </CardBody>
                    </Card>


                    <Card className="pr-card mb30 todtblCard alerttblCard">
                      <div className="card-header">
                        <h5>TOD</h5>
                        {userName === "account_admin" ? (
                          <div className="form-inline pr-form-inline float-right hide-in-pdf">
                            <div className="input-group pr-form-group">
                              <a onClick={handleShowAddTOD}>Add</a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <CardBody className="todTbl">
                        <DataTable
                          columns={columnsTod}
                          data={tapsTod}
                          noDataComponent={<NoDataComponentTod />}
                          customStyles={customStyles}
                          className="headerColor"
                          // pagination
                          highlightOnHover
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="400px"
                        />
                      </CardBody>
                    </Card>
                    {showPDFHeader && (
        <Card className="pr-card mb30 alerttblCard">
          <div className="card-header no-margin-bottom">
            <h5>Taps</h5>
          </div>
        </Card>
      )}
</div>

                    <Card className="pr-card mb30 alerttblCard">
                      <div className="card-header">
                        <h5>Taps</h5>
                        {userName === "account_admin" ? (
                          <div className="form-inline pr-form-inline float-right hide-in-pdf">
                            <div className="input-group pr-form-group">
                              <a onClick={handleShowAddTap}>Add</a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <CardBody>
                        <DataTable
                          columns={columns}
                          data={tapsTable}
                          noDataComponent={<NoDataComponent />}
                          customStyles={customStyles}
                          className="headerColor"
                          // pagination
                          highlightOnHover
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="400px" // Set the height of the scrollable table
                        />
                      </CardBody>
                    </Card>
                    
                  </Card.Body>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>

      {/* right side modal Nameplate */}
      <Modal
        show={showNameplate}
        className="right"
        onHide={handleCloseNameplate}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Update Nameplate</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNameplateSubmit}>
            {nameplateView.map((field) => (
              <Form.Group key={field.database_name} className="form-group">
                <Form.Label>{field.display_name}</Form.Label>
                {field.dropdown === "Yes" ? (
                  <Form.Select
                    defaultValue={field.value}
                    onChange={(e) => handleChange(e, field)}
                  >
                    <option value="">Select One</option>
                    {field.dropdown_values.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Control
                    // step="any"
                    // type={field.data_type === "number" ? "number" : "text"}
                    type="text" 
                    defaultValue={field.value}
                    onChange={(e) => handleChange(e, field)}
                    onInput={(e) => {
    if (field.data_type === "number") {
      e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers and decimals
    }
  }}
                  />
                )}
              </Form.Group>
            ))}
            <div className="ftrBtn">
              <Form.Group className="form-group">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary btn-block"
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* right side modal Add TOD */}
      <Modal show={showAddTOD} className="right" onHide={handleCloseAddTOD}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Add TOD</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              {/* Start Time */}
              <Form.Group className="form-group">
                <Form.Label>Start Time *</Form.Label>
                <TimePicker
                  className="form-control"
                  // value={dayjs(formik.values.start_time, "HH:mm")}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "start_time",
                      dayjs(value).format("HH:mm")
                    );
                  }}
                  slots={{ openPickerIcon: () => null }} // Remove icon
                  open={isStartTimeOpen}
                  onClose={() => setStartTimeOpen(false)}
                  slotProps={{
                    textField: {
                      readOnly: true,
                      onClick: () => setStartTimeOpen(true), // Open on click
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 37,
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    },
                    "& .MuiInputAdornment-root button": {
                      display: "none", // Hide the button
                    },
                  }}
                  // defaultValue={dayjs()}
                  format="HH:mm" // 24-hour format
                  timeSteps={{ minutes: 1 }}
                  ampm={false}
                />
                {formik.touched.start_time && formik.errors.start_time && (
                  <div className="text-danger">{formik.errors.start_time}</div>
                )}
              </Form.Group>

              {/* End Time */}
              <Form.Group className="form-group">
                <Form.Label>End Time *</Form.Label>
                <TimePicker
                  className="form-control"
                  // value={dayjs(formik.values.end_time, "HH:mm")}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "end_time",
                      dayjs(value).format("HH:mm")
                    );
                  }}
                  slots={{ openPickerIcon: () => null }}
                  open={isEndTimeOpen}
                  onClose={() => setEndTimeOpen(false)}
                  slotProps={{
                    textField: {
                      readOnly: true,
                      onClick: () => setEndTimeOpen(true),
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 37,
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    },
                    "& .MuiInputAdornment-root button": {
                      display: "none",
                    },
                  }}
                  // defaultValue={dayjs().subtract(1, "hour")}
                  format="HH:mm"
                  timeSteps={{ minutes: 1 }}
                  ampm={false}
                />
                {formik.touched.end_time && formik.errors.end_time && (
                  <div className="text-danger">{formik.errors.end_time}</div>
                )}
              </Form.Group>
            </LocalizationProvider>

            {/* Energy Rate */}
            <Form.Group className="form-group">
              <Form.Label>Energy Rate *</Form.Label>
              <Form.Control
                // step="any"
                type="text"
                placeholder="Enter Energy Rate"
                name="energy_rate"
                value={formik.values.energy_rate}
                onChange={(e) => {
    const value = e.target.value;
    // Allow only numbers and optional decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      formik.setFieldValue("energy_rate", value); // Store as string
    }
  }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.energy_rate && formik.errors.energy_rate && (
                <div className="text-danger">{formik.errors.energy_rate}</div>
              )}
            </Form.Group>

            <div className="ftrBtn">
              <Form.Group className="form-group">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary btn-block"
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* right side modal edit TOD */}
      <Modal show={showEditTOD} className="right" onHide={handleCloseEditTOD}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Edit TOD</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formikEditTod.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              {/* Start Time */}
              <Form.Group className="form-group">
                <Form.Label>Start Time *</Form.Label>
                <TimePicker
                  className="form-control"
                  value={
                    formikEditTod.values.start_time
                      ? dayjs(formikEditTod.values.start_time, "HH:mm")
                      : null
                  }
                  onChange={(value) => {
                    formikEditTod.setFieldValue(
                      "start_time",
                      dayjs(value).format("HH:mm")
                    );
                  }}
                  slots={{ openPickerIcon: () => null }} // Remove icon
                  open={isStartTimeOpen}
                  onClose={() => setStartTimeOpen(false)}
                  slotProps={{
                    textField: {
                      readOnly: true,
                      onClick: () => setStartTimeOpen(true),
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 37,
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    },
                    "& .MuiInputAdornment-root button": {
                      display: "none", // Hide the button
                    },
                  }}
                  format="HH:mm" // 24-hour format
                  timeSteps={{ minutes: 1 }}
                  ampm={false}
                />
                {formikEditTod.touched.start_time &&
                  formikEditTod.errors.start_time && (
                    <div className="text-danger">
                      {formikEditTod.errors.start_time}
                    </div>
                  )}
              </Form.Group>

              {/* End Time */}
              <Form.Group className="form-group">
                <Form.Label>End Time *</Form.Label>
                <TimePicker
                  className="form-control"
                  value={
                    formikEditTod.values.end_time
                      ? dayjs(formikEditTod.values.end_time, "HH:mm")
                      : null
                  }
                  onChange={(value) => {
                    formikEditTod.setFieldValue(
                      "end_time",
                      dayjs(value).format("HH:mm")
                    );
                  }}
                  slots={{ openPickerIcon: () => null }}
                  open={isEndTimeOpen}
                  onClose={() => setEndTimeOpen(false)}
                  slotProps={{
                    textField: {
                      readOnly: true,
                      onClick: () => setEndTimeOpen(true),
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 37,
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    },
                    "& .MuiInputAdornment-root button": {
                      display: "none",
                    },
                  }}
                  format="HH:mm"
                  timeSteps={{ minutes: 1 }}
                  ampm={false}
                />
                {formikEditTod.touched.end_time &&
                  formikEditTod.errors.end_time && (
                    <div className="text-danger">
                      {formikEditTod.errors.end_time}
                    </div>
                  )}
              </Form.Group>
            </LocalizationProvider>

            {/* Energy Rate */}
            <Form.Group className="form-group">
              <Form.Label>Energy Rate *</Form.Label>
              <Form.Control
                type="text"
                step="any"
                name="energy_rate"
                value={formikEditTod.values.energy_rate}
                onChange={(e) => {
    const value = e.target.value;
    // Allow only numbers and optional decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      formikEditTod.setFieldValue("energy_rate", value); // Store as string
    }
  }}
                onBlur={formikEditTod.handleBlur}
              />
              {formikEditTod.touched.energy_rate &&
                formikEditTod.errors.energy_rate && (
                  <div className="text-danger">
                    {formikEditTod.errors.energy_rate}
                  </div>
                )}
            </Form.Group>

            <div className="ftrBtn">
              <Form.Group className="form-group">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* delete tod  */}
      <Modal
        show={showDeleteModalTOD}
        onHide={() => setShowDeleteModalTOD(false)}
      >
        <Modal.Header closeButton>
          <img
            src="assets/images/motware_logo.png"
            alt="motware Logo"
            width="40px"
            className="brand-image"
          />
          <h5 className="modal-title w-100 text-center">TOD</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            Please confirm if you want to delete the TOD?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModalTOD(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmDeleteTOD}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      {/* right side modal Add Tap */}
      <Modal show={showAddTap} className="right" onHide={handleCloseAddTap}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Add Tap</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handletapSubmit}>
            {/* Energy Rate */}
            {tapView.map((label, index) => (
              <Form.Group className="form-group" key={index}>
                <Form.Label>
                  {label.replace(/_/g, " ").toUpperCase()}
                </Form.Label>
                <Form.Control
                  // step="any"
                  type="text"
                  placeholder={`Enter ${label.replace(/_/g, " ")}`}
                  value={tapFormData[label] ?? ""}
                  onChange={(e) => handleTapChange(e, label)}
                />
              </Form.Group>
            ))}

            <div className="ftrBtn">
              <Form.Group className="form-group">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary btn-block"
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* right side modal Edit Tap */}
      <Modal show={showEditTap} className="right" onHide={handleCloseEditTap}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Edit Tap</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={SubmitEditTap}>
            {/* Energy Rate */}
            {Object.keys(editTapData).map((key, index) => (
              <Form.Group className="form-group" key={index}>
                <Form.Label>{key.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  // step="any"
                  type="text"
                  placeholder={`Enter ${key.replace(/_/g, " ")}`}
                  value={editTapData[key] ?? ""}
                  onChange={(e) => handleEditInputChange(e, key)}
                />
              </Form.Group>
            ))}

            <div className="ftrBtn">
              <Form.Group className="form-group">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary btn-block"
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* delete tap  */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <img
            src="assets/images/motware_logo.png"
            alt="motware Logo"
            width="40px"
            className="brand-image"
          />
          <h5 className="modal-title w-100 text-center">Taps</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            Please confirm if you want to delete the Tap?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmDelete}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Nameplate;
