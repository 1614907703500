import React from 'react';
import { Card, Row, Col } from "react-bootstrap";

const AssetWiseSummary = ({ title, value, color }) => {
    return (
      <Col md={3} lg={3}>
        <Card className="busbarCard pr-card mt10">
            <div className="inrCardHdr">
                <h5>{title}</h5>
            </div>
            <div className="inrCardBody text-center" style={{ backgroundColor: color, padding: "10px", borderRadius: "5px" }}>
                <h3 className="mb0" style={{ color: "#fff" }}>{value}</h3>
            </div>
        </Card>
      </Col>
    );
};

export default AssetWiseSummary;
