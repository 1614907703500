import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from 'moment';
import EnergyConsumptionSkeleton from "./EnergyConsumptionSkeleton";
import dayjs from "dayjs";


function EnergyConsum() {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pielabels, setPielabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [onload, setOnLoad] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [header, setHeader] = useState({ location: "", asset: "" });



  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {

      }

    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    }
    else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset)
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };
  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "SELCO"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          }
          else {
            // showFlashMessage("Locations are Not Found");
          }

        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {

        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "SELCO"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          }
          else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const convertDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const convertDateClone = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };


  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  const handleSubmitData = (props) => {
    const { location, site } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
      };
      energy_consumption_data(data);
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        // iot_sol: "SELCO"
      };
      try {
        await energy_consumption_data(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }


  const energy_consumption_data = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      setIsLoading(true);
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        if (!token) {
          // Return early if token is not available
          return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/summary/summary_of_parameters`,
          data
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const energyData = response.data;

          if (energyData.success === true) {
            const result = energyData.data;
            // for the Pi chart value and key arrays
            // removing the last date from the object
            const selectedKeysObject = {
              Grid_Input_to_PCU_kWh:
                Math.round(result["Grid_Input_to_PCU_kWh"] * 10000) / 10000,
              Actual_Solar_Generation_kWh:
                Math.round(result["Actual_Solar_Generation_kWh"] * 10000) / 10000,
            };
            const pieobject = Object.fromEntries(
              Object.entries(selectedKeysObject).filter(
                ([key, value]) => value !== "-"
              )
            );
            const keysArray = Object.keys(selectedKeysObject).map((key) =>
              key.replace(/_/g, " ")
            );
            const valuesArray = Object.values(selectedKeysObject);


            setPielabels(keysArray);
            setpieData(valuesArray);

            setIsLoading(false);
            // setting the entire data as  for showing inside the cards
            setenergyData(result);
            success = true;
            if (response.data.start_date && response.data.end_date) {
              setNewStartDate(response.data.start_date);
              setNewEndDate(response.data.end_date);
            }
          }
        }
        else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : `Energy Consumption Data Not Available for ${selectedLocation.label
              } and ${selectedAsset.label} on ${convertDateClone(
                fromDate
              )} - ${convertDateClone(toDate)}`;
          //
          setIsLoading(false);
          setenergyData([]);
          setPielabels([]);
          setpieData([]);
          // showFlashMessage("Data Not Available for Energy Consumption", "danger");
        }
      } catch (error) {
        // console.error("Error fetching sidebar data:", error);
        showFlashMessage("Error fetching energy data", "danger");
        setIsLoading(false);
        setenergyData([]);
        setPielabels([]);
        setpieData([]);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      setIsLoading(false);
      setenergyData([]);
      setPielabels([]);
      setpieData([]);
      showFlashMessage("Data Not Available for Energy Consumption", "danger");
    }
  };


  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  if (isLoading) {
    return (
      <EnergyConsumptionSkeleton/>
    );
  }

  // checkk if the data is empty
  const isPieDataEmpty = pieData.every((value) => value === 0);

const pieoptions = {
  chart: {
    width: "400px",
    type: "pie",
  },
  responsive: [
    {
      breakpoint: 1601,
      options: {
        chart: {
          height: 300,
        },
      },
      breakpoint: 1367,
      options: {
        chart: {
          height: 280,
        },
      },
      breakpoint: 1281,
      options: {
        chart: {
          height: 300,
        },
      },
    },
  ],
  legend: {
    position: "top",
 
  },
  dataLabels: {
    style: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
    },
    formatter: function (val, opts) {
      return opts.w.config.series[opts.seriesIndex];
    },

  },
  labels: pielabels,
  colors: ['#FF6873', '#92D050'],
};
  

function formatDate(date) {
  if (!date) return ""; // Handle null/undefined

  // Convert dayjs object or string to Date
  const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);

  if (isNaN(parsedDate.getTime())) return ""; // Invalid date check

  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const year = parsedDate.getFullYear();
  const hours = String(parsedDate.getHours()).padStart(2, "0");
  const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

  return `${day}-${month}-${year} ${hours}:${minutes}`;
}


  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };


  const truncateToThreeDigits = (value) => {
    if (value !== undefined && value !== "" && value !== null) {
      const truncatedValue = parseFloat(Number(value).toFixed(3));
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "-";
  };

  return (
    <>
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">

                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {newStartDate ? (
                          <a href="#">{newStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {newEndDate ? (
                          <a href="#">{newEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li>
                  </ol>
                </div>
                <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                <div className="mdlContent card-body">
                  <div className="col-md-12 col-lg-12">
                    <div className="row">
                      <div className="paramGraph col-md-6 col-lg-6">
                        <div className="chartWpr card pr-card mb30">

                          <div className="card-body">
                            <p>Grid/Solar Utilization (kWh)</p>
                            <div className="noDataTbl">

                              <Chart
                                options={pieoptions}
                                series={pieData}
                                type="pie"
                                height="400"
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="paramWpr col-md-6 col-lg-6">
                        <div className="row">
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Potential Solar Generation kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Potential_Solar_Generation_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Actual Solar Generation kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Actual_Solar_Generation_kWh)}
                                  
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Grid Input to PCU kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Grid_Input_to_PCU_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>PCU Output kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.PCU_Output_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Energy Consumption kWh - Solar</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Total_Energy_Consumption_on_Solar_Loads_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Battery Charging Energy kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Battery_Charging_Energy_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Battery Discharging Energy kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Battery_Discharging_Energy_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Energy Consumption kWh - Non Solar</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Total_Energy_Consumption_on_Non_Solar_Loads_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>
                                  Total Energy Consumption kWh
                                </p>
                                <h3>
                                {truncateToThreeDigits(energyData.Total_Energy_Consumption_on_the_Health_Centre_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="paramBox col-md-6 col-lg-6">
                            <div className="card pr-card mb30">
                              <div className="card-body">
                                <p>Overall Grid Utilization kWh</p>
                                <h3>
                                {truncateToThreeDigits(energyData.Overall_Grid_Utilization_kWh)}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     
    </>
  );
}

export default EnergyConsum;
