import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Alert ,Form} from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as ExcelJS from 'exceljs';  
import {Tooltip } from 'react-tooltip';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const Sidebar = (props) => {
  let { handleSubmitData, fromDate, toDate, setFromDate, setToDate, updateFromDate, updateToDate, handleParametersAdded } = props;
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showProdModal, setShowProdModal] = useState(false)
  const [isFromDateOpen, setFromDateOpen] = useState(false);
  const [isToDateOpen, setToDateOpen] = useState(false);
  // const [disabled, setDisabled] = useState(false);
  // const [fromTime, setFromTime] = useState("00:00");
  // const [fromHours, setFromHours] = useState(fromDate.getHours().toString().padStart(2, '0'));
  // const [fromMinutes, setFromMinutes] = useState(fromDate.getMinutes().toString().padStart(2, '0'));
  // const [fromSeconds, setFromSeconds] = 
  // useState('00');
  // const [toHours, setToHours] = useState(toDate.getHours().toString().padStart(2, '0'));
  // const [toMinutes, setToMinutes] = useState(toDate.getMinutes().toString().padStart(2, '0'));
  // const [toSeconds, setToSeconds] = 
  // useState('00');
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [subdomain,setSubdomain]= useState("")
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");

  const [prodDate, setProdDate] = useState(new Date(moment().subtract(1, 'days').toDate()))
  const [prodHours, setProdHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [prodMinutes, setProdMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [prodSeconds, setProdSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [inputs, setInputs] = useState([
    { label: 'TOTAL CONVERTER HEATS', value: '' },
    { label: 'LHF-1 (HEATS)', value: '' },
    { label: 'LHF-2 (HEATS)', value: '' },
    { label: 'LHF-3 (HEATS)', value: '' },
    { label: 'BOF PRODUCTION (TON)', value: '' },
    { label: 'LHF-1 PRODUCTION (TON)', value: '' },
    { label: 'LHF-2 PRODUCTION (TON)', value: '' },
    { label: 'LHF-3 PRODUCTION (TON)', value: '' },
    { label: 'CCP-1 PRODUCTION (TON)', value: '' },
    { label: 'CCP-2 PRODUCTION (TON)', value: '' },
    { label: 'CCP-3 PRODUCTION (TON)', value: '' },
    { label: 'CCP-4 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-1 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-2 PRODUCTION (TON)', value: '' },
    { label: 'CONVERTER-3 PRODUCTION (TON)', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-1', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-2', value: '' },
    { label: 'NUMBER OF HEATS IN CONVERTER-3', value: '' },
    { label: 'LHF 1 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 1 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 1 NUMBER OF HEAT ARC >20 MIN', value: '' },
    { label: 'LHF 2 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 2 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 2 NUMBER OF HEAT ARC >20 MIN', value: '' },
    { label: 'LHF 3 SUM OF ARC TIME IN MIN', value: '' },
    { label: 'LHF 3 AVERAGE ARC TIME >20 MIN', value: '' },
    { label: 'LHF 3 NUMBER OF HEAT ARC >20 MIN', value: '' }
  ]);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control confirmation modal

  const [excelDate, setExcelDate] = useState(new Date(moment().subtract(1, 'days').toDate()));
  const [excelHours, setExcelHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [excelMinutes, setExcelMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [excelSeconds, setExcelSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [excelToDate, setExcelToDate] = useState(new Date());
  const [excelToHours, setExcelToHours] = useState(prodDate.getHours().toString().padStart(2, '0'));
  const [excelToMinutes, setExcelToMinutes] = useState(prodDate.getMinutes().toString().padStart(2, '0'));
  const [excelToSeconds, setExcelToSeconds] = useState(prodDate.getSeconds().toString().padStart(2, '0'));
  const [showExcel, setShowExcel] = useState(false);
  const [reportList,setReportList]=useState([])
  const [selectedReportName, setSelectedReportName] = useState("");
  const [selectedReportId, setSelectedReportId] = useState("");
  const[selectedReportLink,setSelectedReportLink]= useState("")
  const [reportExcel,setReportExcel] = useState({ columns: [], data: [] })

   // Custom input component to disable manual typing
   const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      value={value}
      onClick={onClick}
      ref={ref}
      readOnly
    />
  ));

  // const validationSchema = Yup.object().shape({
  //   toDate: Yup.date()
  //     .required('To Date is required')
  //     .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
  //     .max(new Date(moment(fromDate).add(30, 'days')), 'To Date is exceeding the maximum To Date')
  //   ,
  // });

 

  const formik = useFormik({
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      // fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      // toDateWithHours.setHours(toHours, toMinutes, toSeconds);

         // Check if To Date is less than From Date
    if (toDateWithHours < fromDateWithHours) {
      formik.setFieldError('toDate', 'To Date cannot be less than From Date.');
      return;
    }

    // Check the difference between fromDate and toDate
    const diffInDays = moment(toDateWithHours).diff(moment(fromDateWithHours), 'days');
    
    // If difference exceeds 30 days, show error message
    if (diffInDays > 30) {
      formik.setFieldError('toDate', 'Maximum allowed date range is 30 days');
    } else {

      const data = {
        fromDate: fromDateWithHours,
        toDate: toDateWithHours,
        // toDate: new Date(toDate),
      };

      handleSubmitData(data);
      updateFromDate(fromDateWithHours);
      updateToDate(toDateWithHours);
      handleCloseFirstModal();
    }
    },
  });

  const handleCloseFirstModal = () => {
    setShowFirstModal(false)
  };
  const handleShowFirstModal = () => {
    formik.setErrors({
      fromDate: '',
      toDate: '',
    });
    formik.setFieldValue("fromDate", fromDate)
    setShowFirstModal(true);
  }

   // Update formik values when props change
 useEffect(() => {
  formik.setErrors({
    fromDate: "",
    toDate: "",
    
  });
  formik.setFieldValue("fromDate", fromDate);

}, [fromDate]);

  /////// for adding components ///////////
  const handleCloseProdModal = () => {
    setShowProdModal(false)
  };
  const handleShowProdModal = () => {
    setShowProdModal(true);
  }

  const combineDateTime = (date, hours, minutes, seconds) => {
    const formattedDate = formatDate(date);
    const formattedTime = formatTime(hours, minutes, seconds);
    return `${formattedDate} ${formattedTime}`;
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const formatTime = (hours, minutes, seconds) => {
    return `${hours}:${minutes}:${seconds}`;
  };


  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  useEffect(() => {
    const tkData = Cookies.get('tkData');
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      setSubdomain(tokenData.subdomain)
    }

    const fetchReportNames = async () => {
      await reportNames();
    };

    fetchReportNames();
  }, []);

  useEffect(() => {
    if (reportList.length > 0) {
      setSelectedReportName(reportList[0].report_name);
      setSelectedReportId(reportList[0]._id);
      setSelectedReportLink(reportList[0].api_url)
    }
  }, [reportList]);

  const handleSubmit = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    const datetime = combineDateTime(prodDate, prodHours, prodMinutes, prodSeconds);

    // const datetime = moment(prodDate).format("YYYY-MM-DD");
    const parameters = inputs.reduce((acc, cur) => {
      acc[cur.label] = cur.value.trim() !== '' ? parseFloat(cur.value) : 0
      return acc;
    }, {});

    const dataToSend = {
      access_token: token,
      user_log_id: userLogId,
      parameters: {
        datetime,
        ...parameters
      }
    };
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/production_jsw/add_JSW_parameters`,
          dataToSend
        );

        if (response.status === 200 && response.data.success === true) {
          showFlashMessage(response.data.msg, "success");
          setInputs(inputs.map(input => ({ ...input, value: '' })));
          // handleParametersAdded()
          success = true;
        } else if (response.data.success === false) {
          console.error('Failed to add parameter');
          setInputs(inputs.map(input => ({ ...input, value: '' })));
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to add parameter');
        }
      } catch (error) {
        console.error("Error:", error);
        showFlashMessage("Error sending data", "danger");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Parameter Not Added Successfully.", "danger");
    }
  };

  const energy_summary_report  = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    const combineDate = (date) => {
      const formattedDate = formatDate(date);
      return `${formattedDate}`
    };
    const datetime = combineDate(prodDate);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/energy_summary_report?start_date=${datetime}&end_date=${datetime}`,
        );

        if (response.status === 200 && response.data.success === true) {
        
        } else if (response.data.success === false) {
          console.error('Failed to send');
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to send');
        }
      } catch (error) {
        console.error("Error:", error);
      }
  };

  const custom_calculations  = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    const combineDate = (date) => {
      const formattedDate = formatDate(date);
      return `${formattedDate}`
    };

    const datetime = combineDate(prodDate);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_VARIABLE}/iotrms/custom_calculations?start_date=${datetime}&end_date=${datetime}`,
        );

        if (response.status === 200 && response.data.success === true) {

        } else if (response.data.success === false) {
          console.error('Failed to send');
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Failed to send');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    
  };

  const handleChange = (index, event) => {
    const value = event.target.value;
    // Check if the value is numeric (optional: allow empty string as well)
    if (!isNaN(value) || value === '') {
      const values = [...inputs];
      values[index].value = value;
      setInputs(values);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowConfirmation(true); // Show confirmation modal instead of window.confirm
  };

  const handleConfirm = () => {
    handleSubmit();
    energy_summary_report()
    custom_calculations()
    handleCloseProdModal(); // Close the add production modal
    setShowConfirmation(false); // Close the confirmation modal
  };

  ///////// downloading report excel////////

  const reportNames = async (event) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/list_of_energy_summary_reports`,
          {access_token: token,
      user_log_id: userLogId,}
        );
        if ( response.data.success === true) {
          setReportList(response.data.data)
          success = true;
        } else if (response.data.success === false) {
          console.error("Reports are Not Found");
        }
        else {
          console.error("Reports are Not Found");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Reports are Not Found","danger")
    }
  };

  const handleExcelData = async (event) => {
    
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    // with time
    // const datetime = combineDateTime(excelDate, excelHours, excelMinutes, excelSeconds, excelToDate, excelToHours, excelToMinutes, excelToSeconds);

    // without time
    const startDate = formatDate(excelDate) 
    const endDate = formatDate(excelToDate)
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}${selectedReportLink}`,
          { access_token: token,
            user_log_id: userLogId,
            "start_date":startDate,
            "end_date": endDate,
            "iot_sol":"EMS"
          }
        );
        // const responseData = { "success": true, "data": { "columns": ["Date", "A 03"], "rows": [["2024-03-20", 1], ["2024-03-19", 10.257683], ["2024-03-18", 5.678948], ["2024-03-16", 22], ["2024-03-15", 2], ["2024-03-14", 2]] } }
        if ( response.data.success === true) {

          setReportExcel(response.data.data)

          //excel
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet1');
        
          // Add columns
          worksheet.columns = response.data.data.columns.map(column => ({ header: column, key: column }));
        
          // Add rows
          response.data.data.rows.forEach(row => {
            worksheet.addRow(row);
          });
        
          // Generate buffer
          const buffer = await workbook.xlsx.writeBuffer();
        
          // Save file
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          FileSaver.saveAs(blob, `${selectedReportName}.xlsx`);
          showFlashMessage("Report Downloaded Successfully", "success");
          success = true;
        } else if (response.data.success === false) {
          console.error('Report Not Downloaded Successfully', "danger");
        }
        else {
          // showFlashMessage("Data Not Available");
          console.error('Report Not Downloaded Successfully', "danger");
        }
      } catch (error) {
        console.error("Error:", error);
        showFlashMessage("Error sending data", "danger");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Report Not Downloaded Successfully", "danger");
    }
  };

const handleExcel = () => {
  reportNames()
  setShowExcel(true);
};

const handleExcelSubmit = async (event) => {
  event.preventDefault();
  await handleExcelData();
  setShowExcel(false);
};

  // Calculate the last two dates
  const lastDate = new Date(moment().subtract(1, 'days').toDate());
  const secondLastDate = new Date(moment().subtract(2, 'days').toDate()); 
   return (
    <>

     
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}


      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
              data-tooltip-id="Alert-tooltip"  
              data-tooltip-content="Alert"  
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip id="Alert-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
              data-tooltip-id="filter-tooltip"  
              data-tooltip-content="Filter"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
            <Tooltip id="filter-tooltip" place="bottom"  type="dark" effect="solid"style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li> */}
          {['jswsms1bay', 'test1'].includes(subdomain) && (
            <>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowProdModal}
              data-tooltip-id="Add-tooltip"  
              data-tooltip-content="Add"
            >
              <i className="fa fa-plus nav-icon"></i>
            </a>
            <Tooltip id="Add-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleExcel}
              data-tooltip-id="Excel-tooltip"  
              data-tooltip-content="Excel"
            >
              <i className="fa fa-file-excel-o"></i>
            </a>
            <Tooltip id="Excel-tooltip" place="bottom"  type="dark" effect="solid" style={{position:"absolute",marginLeft:"-10px",padding:"10% 20% 10% 20%", }}/>
          </li>
          </>
        )}
        </ul>
      </div>

       {/* top modal */}
       <Card className="filterCard">
        <Card.Body>
          <Form className="">
            <Row>
            
              <Form.Group
                className="dateWidth form-group col-auto"
                controlId=""
              >
                <Form.Label>From Date</Form.Label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en"
                >
                  <DateTimePicker
                    className="form-control"
                    onChange={(date) => {
                      formik.setFieldValue("fromDate", date);
                      formik.setFieldTouched("fromDate", true);
                      updateFromDate(date);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 37,
                        cursor: "pointer",
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      },
                      "& .MuiInputAdornment-root button": {
                        display: "none", // Hide the button
                      },
                    }}
                    defaultValue={dayjs().subtract(1, "day")} // Set default value to current date-time
                    format="DD-MM-YYYY HH:mm" // 24-hour format
                    timeSteps={{ minutes: 1 }} // Set minute step to 1
                    ampm={false} // Ensures 24-hour format
                    maxDate={dayjs()} // Max date as today
                    minDate={dayjs().subtract(3, "month")} // Min date 3 months ago
                    slots={{ openPickerIcon: () => null }} // Remove date-time icon
                    open={isFromDateOpen} // Control picker state
                    onClose={() => setFromDateOpen(false)}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        onClick: () => setFromDateOpen(true), // Open picker on input click
                      },
                    }}
                  />
                </LocalizationProvider>
              </Form.Group>
              <Form.Group
                className="dateWidth form-group col-auto"
                controlId=""
              >
                <Form.Label>To Date</Form.Label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en"
                >
                  <DateTimePicker
                    className="form-control"
                    onChange={(date) => {
                      formik.setFieldValue("toDate", date);
                      formik.setFieldTouched("toDate", true);
                      updateToDate(date);
                    }}
                    includeDateIntervals={[
                      {
                        start: new Date(
                          moment(formik.values.fromDate).subtract(3, "months")
                        ),
                        end: new Date(
                          moment(formik.values.fromDate).add(3, "months")
                        ),
                      },
                    ]}
                    onBlur={(e) => {
                      try {
                        if (
                          moment(
                            e.target.value,
                            "DD-MM-YYYY HH:mm",
                            true
                          ).isValid()
                        ) {
                          const date = new Date(
                            moment(e.target.value, "DD-MM-YYYY HH:mm").format(
                              "YYYY-MM-DD HH:mm"
                            )
                          );
                          formik.setFieldValue("toDate", date);
                          formik.setFieldTouched("toDate", true);
                          setToDate(date);
                        }
                      } catch (error) {}
                    }}
                    slots={{ openPickerIcon: () => null }} // Remove icon
                    open={isToDateOpen} // Control picker state
                    onClose={() => setToDateOpen(false)}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        onClick: () => setToDateOpen(true), // Open on click
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 37,
                        cursor: "pointer",
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      },
                      "& .MuiInputAdornment-root button": {
                        display: "none", // Hide the button
                      },
                    }}
                    defaultValue={dayjs()} // Set default value to current date-time
                    format="DD-MM-YYYY HH:mm" // 24-hour format
                    timeSteps={{ minutes: 1 }} // Set minute step to 1
                    ampm={false} // Ensures 24-hour format
                    maxDate={dayjs()}
                    minDate={dayjs().subtract(3, "month")}
                  />
                </LocalizationProvider>

                {formik.errors.toDate && formik.touched.toDate && (
                  <div className="error-message text-danger text-center">
                    {formik.errors.toDate}
                  </div>
                )}
                {/* <Form.Select>
                  <option>DD-MM-YYYY HH:MM</option>
                </Form.Select> */}
              </Form.Group>
              <div className="col-md-2 col-lg-2 d-flex float-right">
              
                <Form.Group className="col-auto">
                  <button
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </Form.Group>
              
              </div>
            </Row>

          </Form>
        </Card.Body>
      </Card>

      {/* filter modal */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Filter</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
            <div className="form-group">
              <div className='row'>
                <div className='col-8'>
                  <label>From Date *</label>
                  <DatePicker
                    selected={formik.values.fromDate}
                    onChange={(date) => {
                      if (moment(date, "DD-MM-YYYY", true).isValid()) {
                        formik.setFieldValue('fromDate', date);
                        formik.setFieldTouched('fromDate', true);
                        setFromDate(date);
                        // const lastDate = new Date(moment(date).add(30, 'days'));
                        // formik.setFieldValue('toDate', lastDate);
                        // formik.setFieldTouched('toDate', true);
                        // setToDate(lastDate);
                      }
                    }}

                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    maxDate={new Date()} // Maximum date as today
                    minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                    customInput={<CustomDateInput />}
                  />
                </div>
                <div className='col-2'>
                  <label>HH</label>
                  <select
                    className="form-control"
                    // value={fromHours}
                    // onChange={(e) => setFromHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2'>
                  <label>MM</label>
                  <select
                    className="form-control"
                    // value={fromMinutes}
                    // onChange={(e) => setFromMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2' style={{ display: 'none' }}>
                  <label>SS</label>
                  <select
                    className="form-control"
                    // value={fromSeconds}
                    // onChange={(e) => setFromSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>
                </div>
                {/* {formik.errors.fromDate && formik.touched.fromDate && (
                  <div className="error-message text-danger">
                    {formik.errors.fromDate}
                  </div>
                )} */}
              </div>
            </div>

            <div className="form-group">

              <div className='row'>
                <div className='col-8'>
                  <label>To Date *</label>

                  <DatePicker
                    selected={formik.values.toDate}
                    onChange={(date) => {
                      if (moment(date, "DD-MM-YYYY", true).isValid()) {
                        formik.setFieldValue("toDate", date);
                        formik.setFieldTouched("toDate", true);
                        setToDate(date);
                      }
                    }}

                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    includeDateIntervals={[
                      {
                        start: new Date(
                          moment(fromDate).subtract(3, "months")
                        ),
                        end: new Date(moment(fromDate).add(3, "months")),
                      },
                    ]}
                    onBlur={(e) => {
                      try {
                        if (
                          moment(
                            e.target.value,
                            "DD-MM-YYYY",
                            true
                          ).isValid()
                        ) {
                          const date = new Date(
                            moment(e.target.value, "DD-MM-YYYY").format(
                              "YYYY-MM-DD"
                            )
                          );
                          formik.setFieldValue("toDate", date);
                          formik.setFieldTouched("toDate", true);
                          setToDate(date);
                        }
                      } catch (error) { }
                    }}
                    maxDate={new Date()} // Adjust if you want a different maximum date logic
                    minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                    customInput={<CustomDateInput />}
                  />
                </div>
                <div className='col-2'>
                  <label>HH</label>
                  <select
                    className="form-control"
                    // value={toHours}
                    // onChange={(e) => setToHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2'>
                  <label>MM</label>
                  <select
                    className="form-control"
                    // value={toMinutes}
                    // onChange={(e) => setToMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className='col-2' style={{ display: 'none' }}>
                  <label>SS</label>
                  <select
                    className="form-control"
                    // value={toSeconds}
                    // onChange={(e) => setToSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>
                </div>
                {formik.errors.toDate && formik.touched.toDate && (
    <div className="error-message text-danger text-center">
      {formik.errors.toDate}
    </div>
  )}
              </div>
            </div>
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* add production  */}
      <Modal
        show={showProdModal}
        onHide={handleCloseProdModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Production</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit} id="energy_consumtion_form">
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="prodDate">Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    minDate={secondLastDate}
                    maxDate={lastDate}
                    selected={prodDate}
                    onChange={(date) => setProdDate(date)}
                    className="form-control"
                    id="prodDate"
                  />
                </div>
                <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setProdHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setProdMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                {/* <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setProdSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
            <div className="row">
              {inputs.map((input, index) => (
                <div className="col-md-12" key={index}>
                  <div className="form-group">
                    <label>{input.label}</label>
                    <input
                      type="text"
                      inputmode="numeric"
                      className="form-control"
                      value={input.value}
                      onChange={(event) => handleChange(index, event)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <img
            src="assets/images/motware_logo.png"
            alt="motware Logo"
            width="40px"
            className="brand-image"
          />
          <h5 className="modal-title w-100 text-center">Production</h5>
        </Modal.Header>
        <Modal.Body><div className="text-center">Are you sure you want to add Parameters?</div></Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

 {/* excel Modal */}
 <Modal
        show={showExcel}
        onHide={() => setShowExcel(false)}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5"><b>Reports</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleExcelSubmit} id="energy_consumtion_form">
          <div className="row">
              <div className="col-md-12" >
                <div className="form-group">
                  <label>Report Name</label>
                  <Form.Select 
  value={selectedReportId}
  onChange={(e) => {
    const selectedReport = reportList.find(report => report._id === e.target.value);
    setSelectedReportName(selectedReport.report_name);
    setSelectedReportId(selectedReport._id);
    setSelectedReportLink(selectedReport.api_url)
  }}
>
  {reportList.map(report => (
    <option key={report._id} value={report._id}>
      {report.report_name}
    </option>
  ))}
</Form.Select>

                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="prodDate">From Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={excelDate}
                    onChange={(date) => setExcelDate(date)}
                    className="form-control"
                    id="excelDate"
                  />
                </div>
                {/* <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setExcelHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setExcelMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setExcelSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="prodDate">To Date</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={excelToDate}
                    onChange={(date) => setExcelToDate(date)}
                    className="form-control"
                    id="excelToDate"
                  />
                </div>
                {/* <div className="col-2">
                  <label>HH</label>
                  <select
                    className="form-control"
                    value={prodHours}
                    onChange={(e) => setExcelToHours(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => {
                      const hour = i.toString().padStart(2, '0');
                      return <option key={hour} value={hour}>{hour}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>MM</label>
                  <select
                    className="form-control"
                    value={prodMinutes}
                    onChange={(e) => setExcelToMinutes(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const minute = i.toString().padStart(2, '0');
                      return <option key={minute} value={minute}>{minute}</option>;
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>SS</label>
                  <select
                    className="form-control"
                    value={prodSeconds}
                    onChange={(e) => setExcelToSeconds(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => {
                      const second = i.toString().padStart(2, '0');
                      return <option key={second} value={second}>{second}</option>;
                    })}
                  </select>

                </div> */}
              </div>
            </div>
           
            {/* <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => setShowExcel(false)}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleExcelSubmit}>
              Confirm
            </button>
          </Modal.Footer> */}
            <div className="ftrBtn">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  className="btn btn-primary btn-block"
                  data-disable-with="Submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sidebar