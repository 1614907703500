/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useFormik } from 'formik';
import * as Yup from "yup";

const ConducotrOverview = () => {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [servOrder, setServOrder] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  // getting Token
  useEffect(() => {
    const elementsToRemove = document.querySelectorAll(".sidebarRight");
    elementsToRemove.forEach((element) => {
      element.remove();
    });
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // truncate to two decimal limt
  const truncateToTwoDigits = (value) => {
    if (!isNaN(value)) {
      return Math.trunc(value * 100) / 100;
    }
    return value;
  };

  //////// service order api /////////
  const getServiceOrders = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/view_service_orders`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setServOrder(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setServOrder([]);
      showFlashMessage("Service Order are Not Found");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getServiceOrders();
    }
  }, [token]);

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",  
    textOverflow: "ellipsis",
    // maxWidth: "150px",    
  };

  const columns = [
    {
      name: "Service Order (SO)",
      selector: (row) => row.service_order_no,
      cell: (row) => (<div title={row.service_order_no} style={ellipsisStyle}>{row.service_order_no}</div>),
      sortable: true,
    },
    {
      name: "Purchase Order (PO)",
      selector: (row) => row.purchase_order_no,
      cell: (row) => (<div title={row.purchase_order_no} style={ellipsisStyle}>{row.purchase_order_no}</div>),
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      cell: (row) => (<div title={row.customerName} style={ellipsisStyle}>{row.customerName}</div>),
      sortable: true,
    },
    { name: "Type", 
      selector: (row) => row.conductor_type, 
      cell: (row) => (<div title={row.conductor_type} style={ellipsisStyle}>{row.conductor_type}</div>),
      sortable: true },
    {
      name: "Diameter (m)",
      selector: (row) => truncateToTwoDigits(row.conductor_diameter_mm),
      cell: (row) => (<div title={row.conductor_diameter_mm} style={ellipsisStyle}>{row.conductor_diameter_mm}</div>),
      sortable: true,
    },
    {
      name: "Length (m)",
      selector: (row) => truncateToTwoDigits(row.conductor_length_m),
      cell: (row) => (<div title={row.conductor_length_m} style={ellipsisStyle}>{row.conductor_length_m}</div>),
      sortable: true,
    },
    {
      name: "No. Of Drums",
      selector: (row) => truncateToTwoDigits(row.number_of_drums),
      cell: (row) => (<div title={row.number_of_drums} style={ellipsisStyle}>{row.number_of_drums}</div>),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        // <Form className="statusTgl">
        <div className="form-group switch float-right">
          <label className="switch-light">
            <input
              type="checkbox"
              checked={row.status === "Close"} // If status is "Close", checkbox should be checked
              onChange={() => {
                setSelectedRow(row);
                setIsModalVisible(true);
              }}
            />
            <span id="toggleParamColor">
              <span
                style={{ color: row.status === "Open" ? "white" : "black" }}
              >
                Open
              </span>
              <span
                style={{ color: row.status === "Close" ? "white" : "black" }}
              >
                Close
              </span>
            </span>
            <a className="btn btn-primary"></a>
          </label>
        </div>
        // </Form>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Link to="/cable Drum" state={{ id: row }}>
            Drum
          </Link>{" "}
          |{" "}
          <Link to="/cable service report" state={{ id: row._id }}>
            Report
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  /////// Status ///////
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalConfirm = () => {
    if (selectedRow) {
      const newStatus = selectedRow.status === "Open" ? "Close" : "Open";
      handleUserUpdate(selectedRow, "status", newStatus);
    }
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleUserUpdate = async (row, field, value) => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const payload = {
            access_token: token,
            user_log_id: userLogId,
            service_order_Id: row._id || "",
            status: field === "status" ? value : row.status,
          };
          const response = await axios.patch(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/update_service_status`,
            payload
          );
          if (response.status === 200 && response.data.success) {
            showFlashMessage("Status Updated Successfully", "success");
            success = true;
            setIsLoading(false);
            getServiceOrders();
          }
        } else {
        }
      } catch (error) {
        console.error(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Not Able To Update Status");
      setIsLoading(false);
    }
  };

  /////// search /////////
  const filteredData = servOrder.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Service Order</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  ////////  Add Service Order //////////

  const [customerList, setCustomerList] = useState([]);

  const getCustomerList = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/get_subaccounts`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setCustomerList(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setCustomerList([]);
      showFlashMessage("Service Order are Not Found");
      setIsLoading(false);
    }
  };

  const [customerIndustry, setCustomerIndustry] = useState([]);

  const getCustomerIndustry = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/get_repo_industries`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setCustomerIndustry(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setCustomerIndustry([]);
      showFlashMessage("Service Order are Not Found");
      setIsLoading(false);
    }
  };

  const [testTypeList, setTestTypeList] = useState([]);

  const getTestTypeList = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/get_tests`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setTestTypeList(data);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setTestTypeList([]);
      showFlashMessage("Test Type are Not Found");
      setIsLoading(false);
    }
  };

  const addServiceorder = () => {
    getCustomerList();
    getCustomerIndustry();
    getTestTypeList();
  };


  ///// add customer ///////
  const [showAddCustomer, setShowAddCustomer] = useState(false);

// Formik validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Customer Name is required'),
  address: Yup.string().required('Address is required'),
  // address: Yup.string().notRequired(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  contact_no: Yup.string().required('Contact No is required'),
  repo_industry_id: Yup.string().required('Industry is required'),
});

// Formik setup
const formik = useFormik({
  initialValues: {
    name: '',
    address: '',
    email: '',
    contact_no: '',
    repo_industry_id: '',
  },
  validationSchema: validationSchema,
  onSubmit: async (values,{resetForm}) => {
    if (!token) {
      return;
    }

    const data = {
      access_token: token,
      user_log_id: userLogId,
      ...values,
    };

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== '') {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/add_subaccount`,
            data
          );
          if (response.status === 200 && response.data.success) {
            success = true;
            setShowAddCustomer(false);
            getCustomerList();
            showFlashMessage("Customer added Successfully", "success");
            resetForm()
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }

    if (!success) {
      showFlashMessage('Failed to add customer');
    }
  },
});

/////// add servie order ///////
const [testInput, setTestInput] = useState({
  testName: "",
  numberOfTests: "",
});

// Formik validation schema
const validationservieSchema = Yup.object().shape({
  subaccount_id: Yup.string().required('Customer is required'),
  purchase_order_no: Yup.string().required('Purchase Order Number is required'),
  service_order_no: Yup.string().required('Service Order Number is required'),
  conductor_type: Yup.string().required('Type is required'),
  conductor_diameter_mm: Yup.number()
    .typeError('Diameter must be a number')
    .required('Diameter is required')
    .positive('Diameter must be positive')
    ,
  conductor_length_m: Yup.number()
    .typeError('Length must be a number')
    .required('Length is required')
    .positive('Length must be positive')
    ,
  number_of_drums: Yup.number()
    .typeError('Number of Drums must be a number')
    .required('Number of Drums is required')
    .positive('Number of Drums must be positive')
    ,
  tests: Yup.object().test(
    'atLeastOneTest',
    'At least one test is required',
    (value) => Object.keys(value).length > 0
  ),
});

// Formik setup
const servieformik = useFormik({
  initialValues: {
    subaccount_id: "",
    purchase_order_no: "",
    service_order_no: "",
    conductor_type: "",
    conductor_diameter_mm: "",
    conductor_length_m: "",
    number_of_drums: "",
    tests: {},
  },
  validationSchema: validationservieSchema,
  onSubmit: async (values, { resetForm }) => {
    if (!token) {
      return;
    }

    const data = {
      access_token: token,
      user_log_id: userLogId,
      ...values,
      conductor_diameter_mm: parseFloat(values.conductor_diameter_mm),
      conductor_length_m: parseFloat(values.conductor_length_m),
      number_of_drums: parseInt(values.number_of_drums, 10),
    };

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/add_service_order`,
            data
          );
          if (response.status === 200 && response.data.success) {
            success = true;
            getServiceOrders();
            showFlashMessage("Service Order added Successfully", "success");
            resetForm();
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }

    if (!success) {
      showFlashMessage("Failed to add service order");
    }
  },
});

const addTest = () => {
  const { testName, numberOfTests } = testInput;
  if (testName && numberOfTests) {
    servieformik.setFieldValue('tests', {
      ...servieformik.values.tests,
      [testName]: parseInt(numberOfTests, 10),
    });
    setTestInput({ testName: "", numberOfTests: "" });
  }
};

const removeTest = (testName) => {
  const updatedTests = { ...servieformik.values.tests };
  delete updatedTests[testName];
  servieformik.setFieldValue('tests', updatedTests);
};

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <Row>
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <Card.Body className="mdlContent">
                    
                    <Card style={{}} className="assetAloTbl">
                      <Card.Header>
                        <div className="form-inline dataTables_filter">
                          <div
                            className="input-group pr-form-group"
                            onClick={addServiceorder}
                          >
                            <a
                              className="btn btn-c-outline-primary mr10"
                              data-bs-toggle="modal"
                              data-bs-target="#addServiceorder"
                              style={{ padding: "0px 20px" }}
                            >
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "14px" }}
                              ></i>{" "}
                              Service Order
                            </a>
                          </div>
                          <div className="mr10">
                            <label>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </label>
                          </div>
                          {/* <div
                                  className="input-group pr-form-group"
                                  id=""
                                >
                                  <a
                                    className=""
                                    alt="Download XLS"
                                  >
                                    <i className="fa fa-file-excel-o"></i>
                                  </a>
                                </div> */}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div className="soTbl table-responsive">
                          <DataTable
                            columns={columns}
                            data={filteredData}
                            noDataComponent={<NoDataComponent />}
                            customStyles={customStyles}
                            pagination
                            highlightOnHover
                            responsive
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>

      {/* add service order  */}
      <div
        className="modal fade"
        id="addServiceorder"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addServiceorder"
        aria-hidden="true"
      >
        <Modal.Dialog
          className="modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <Modal.Header className="">
              <img
                src="assets/images/motware_logo.png"
                alt="Motware Logo"
                width="40px"
                className="brand-image"
              />
              <Modal.Title className="h5 w-100 text-center">
                Add Service Order
              </Modal.Title>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>

            <Modal.Body>
              <Form className="add_so">
                <div className="col-md-12">
                  <Row>
                    {/* Select Customer */}

                    <Form.Group className="form-group">
                      <Form.Label>Select Customer *</Form.Label>
                      <Row>
                        <Form.Select
                          className=" col-md-11 col-lg-11"
                          name="subaccount_id"
                          value={servieformik.values.subaccount_id}
                        onChange={servieformik.handleChange}
                        onBlur={servieformik.handleBlur}
                          onClick={() => setShowAddCustomer(false)}
                        >
                          <option value="">Select Customer</option>
                          {customerList.map((customer) => (
                            <option key={customer._id} value={customer._id}>
                              {customer.name}
                            </option>
                          ))}
                        </Form.Select>
                        
                        <div className="col-sm-1 col-md-1 col-lg-1">
                          <div
                            className="input-group pr-form-group"
                            onClick={() => setShowAddCustomer(!showAddCustomer)}
                          >
                            <button
                              type="button"
                              className="btnAdd btn btn-c-outline-primary"
                            >
                              {/* <b>+</b>  */}
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "14px" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                        {servieformik.touched.subaccount_id && servieformik.errors.subaccount_id && (
                        <div className="text-danger">{servieformik.errors.subaccount_id}</div>
                      )}
                      </Row>
                    </Form.Group>
                  </Row>
                  {showAddCustomer && (
                    <Card>
                      <Card.Body>
                        <Row className="addCustomer">
                          {/* Customer Name */}
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <Form.Group className="form-group">
                              <Form.Label>Customer Name *</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter Customer Name"
                                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                              />
                                {formik.touched.name && formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
                            </Form.Group>
                            <Form.Group className="form-group">
                              {/* Address */}
                              <Form.Label>Address *</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={5}
                                name="address"
                                placeholder="Enter Address"
                                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                              />
                               {formik.touched.address && formik.errors.address && (
                <div className="text-danger">{formik.errors.address}</div>
              )}
                            </Form.Group>
                          </div>

                          {/* Industry */}
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <Form.Group className="form-group">
                              <Form.Label>Industry *</Form.Label>
                              <Form.Select
                                name="repo_industry_id"
                                value={formik.values.repo_industry_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                              >
                                <option value="">Select Industry *</option>
                                {customerIndustry.map((industry) => (
                                  <option
                                    key={industry._id}
                                    value={industry._id}
                                  >
                                    {industry.name}
                                  </option>
                                ))}
                              </Form.Select>
                              {formik.touched.repo_industry_id && formik.errors.repo_industry_id && (
                <div className="text-danger">{formik.errors.repo_industry_id}</div>
              )}
                            </Form.Group>

                            {/* Email */}
                            <Form.Group className="form-group">
                              <Form.Label>Email *</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter Email ID"
                                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                              />
                               {formik.touched.email && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
                            </Form.Group>

                            {/* Contact Number */}
                            <Form.Group className="form-group">
                              <Form.Label>Contact No. *</Form.Label>
                              <Form.Control
                                type="text"
                                name="contact_no"
                                placeholder="Enter Number"
                                value={formik.values.contact_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                              />
                               {formik.touched.contact_no && formik.errors.contact_no && (
                <div className="text-danger">{formik.errors.contact_no}</div>
              )}
                            </Form.Group>
                          </div>
                        </Row>

                        {/* Submit Button */}
                        <div className="col-12 text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={formik.handleSubmit}
                             data-disable-with="Submit"
                          >
                            Submit
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                  <Row>
                    {/* Service Order Number */}
                    <Form.Group className="form-group col-sm-6 col-md-6 col-lg-6">
                      <Form.Label className="">
                        Service Order Number *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="service_order_no"
                        placeholder="Enter Number"
                        value={servieformik.values.service_order_no}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      />
                       {servieformik.touched.service_order_no && servieformik.errors.service_order_no && (
                      <div className="text-danger">{servieformik.errors.service_order_no}</div>
                    )}
                    </Form.Group>

                    {/* Purchase Order Number */}
                    <Form.Group className="form-group col-sm-6 col-md-6 col-lg-6">
                      <Form.Label className="">
                        Purchase Order Number *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="purchase_order_no"
                        placeholder="Enter Number"
                        value={servieformik.values.purchase_order_no}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      />
                      {servieformik.touched.purchase_order_no && servieformik.errors.purchase_order_no && (
                      <div className="text-danger">{servieformik.errors.purchase_order_no}</div>
                    )}
                    </Form.Group>
                  </Row>
                  <Row>
                    {/* Type */}
                    <Form.Group className="form-group col-sm-3 col-md-3 col-lg-3">
                      <Form.Label className="">Type *</Form.Label>
                      <Form.Select
                        className=""
                        name="conductor_type"
                        value={servieformik.values.conductor_type}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      >
                       <option value="">Select Type</option>
                        <option value="Copper">Copper</option>
                        <option value="Aluminium">Aluminium</option>
                        <option value="Metal Alloys">Metal Alloys</option>
                      </Form.Select>
                      {servieformik.touched.conductor_type && servieformik.errors.conductor_type && (
                      <div className="text-danger">{servieformik.errors.conductor_type}</div>
                    )}
                    </Form.Group>

                    {/* Diameter */}
                    <Form.Group className="form-group col-sm-3 col-md-3 col-lg-3">
                      <Form.Label className="">Diameter (m) *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Diameter"
                        name="conductor_diameter_mm"
                        value={servieformik.values.conductor_diameter_mm}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      />
                       {servieformik.touched.conductor_diameter_mm && servieformik.errors.conductor_diameter_mm && (
                      <div className="text-danger">{servieformik.errors.conductor_diameter_mm}</div>
                    )}
                    </Form.Group>

                    {/* Length */}
                    <Form.Group className="form-group col-sm-3 col-md-3 col-lg-3">
                      <Form.Label className="">Length (m) *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Length"
                        name="conductor_length_m"
                        value={servieformik.values.conductor_length_m}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      />
                       {servieformik.touched.conductor_length_m && servieformik.errors.conductor_length_m && (
                      <div className="text-danger">{servieformik.errors.conductor_length_m}</div>
                    )}
                    </Form.Group>

                    {/* No. of Drums */}
                    <Form.Group className="form-group col-sm-3 col-md-3 col-lg-3">
                      <Form.Label className="">No of Drums *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Number of Drums"
                        name="number_of_drums"
                        value={servieformik.values.number_of_drums}
                      onChange={servieformik.handleChange}
                      onBlur={servieformik.handleBlur}
                      />
                        {servieformik.touched.number_of_drums && servieformik.errors.number_of_drums && (
                      <div className="text-danger">{servieformik.errors.number_of_drums}</div>
                    )}
                    </Form.Group>
                  </Row>
                  <Row>
                    {/* Select Test */}
                    <Form.Group className="form-group col-sm-6 col-md-6 col-lg-6">
                      <Form.Label className="">Select Test</Form.Label>
                      <Form.Select
                        name="testName"
                        value={testInput.testName}
                        onChange={(e) => setTestInput({ ...testInput, testName: e.target.value })}
                      >
                        <option value="">Select Test</option>
                        {testTypeList.map((test) => (
                          <option key={test} value={test}>
                            {test}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {/* Number of Tests */}
                    <Form.Group className="col-sm-6 col-md-6 col-lg-6">
                      <Form.Label className="">No. Of Test</Form.Label>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Row>
                          <Form.Control
                            className="col-sm-8 col-md-8 col-lg-8"
                            type="text"
                            name="numberOfTests"
                            placeholder="Number of Tests"
                            value={testInput.numberOfTests}
                            onChange={(e) => setTestInput({ ...testInput, numberOfTests: e.target.value })}
                          />
                          <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="input-group pr-form-group">
                              <button
                                type="button"
                                className="btnAdd btn btn-c-outline-primary"
                                onClick={addTest}
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ fontSize: "14px" }}
                                ></i>{" "}
                                <b>Add Test</b>{" "}
                              </button>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Form.Group>

                    {/* Selected Tests */}
                    <Form.Group className="selectedTestWpr form-group">
                    <Form.Label className="">Selected Test</Form.Label>
                    <div className="form-control d-flex flex-wrap gap-2 align-items-center">
                      {Object.entries(servieformik.values.tests).map(([testName, numberOfTests]) => (
                        <div key={testName} className="selectedTest">
                          <span>
                            {testName} | {numberOfTests}
                          </span>
                          <button
                            type="button"
                            className="btn p-0"
                            onClick={() => removeTest(testName)}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                    {servieformik.touched.tests && servieformik.errors.tests && (
                      <div className="text-danger">{servieformik.errors.tests}</div>
                    )}
                  </Form.Group>


                    {/* Submit Button */}
                    <div className="col-12 text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        // data-bs-dismiss="modal"
                        onClick={servieformik.handleSubmit}
                        data-bs-dismiss={
                          servieformik.values.subaccount_id &&
                          servieformik.values.purchase_order_no &&
                          servieformik.values.service_order_no &&
                          servieformik.values.conductor_type &&
                          servieformik.values.conductor_diameter_mm &&
                          servieformik.values.conductor_length_m &&
                          servieformik.values.number_of_drums &&
                          Object.keys(servieformik.values.tests).length > 0
                            ? "modal"
                            : ""
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </Row>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </div>

      {/* active/inactive button  */}
      {isModalVisible && (
        <div
          className="inactiveModal modal fade show"
          role="dialog"
          aria-labelledby="inactivePop"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5>Status</h5>
              </div>
              <div className="modal-body">
                <p>
                  please confirm if you want to change production status
                  {/* Please confirm if you want to{" "}
                  {selectedRow?.status === "Open" ? "Close" : "Open"} Status */}
                </p>
                <button
                  type="button"
                  className="btn btn-primary mr10"
                  onClick={handleModalConfirm}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleModalCancel}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConducotrOverview;
