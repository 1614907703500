/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from "moment";
import { auto } from "@popperjs/core";
import InsightsSkeleton from "./InsightsSkeleton";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
// import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";

function Insights() {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [relayLoad, setRelaLoad] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {}
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        setOnloadLoc(new_location);
        // setSelectedLocation(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    setOnloadLoc(props);
    // setSelectedLocation(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));
            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmitData = async (props) => {
    const { location, site } = props;

    setOnloadLoc(location);
    setOnloadAsset(site);

    setSelectedLocation(location);
    setSelectedAsset(site);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };
      try {
        await getChart(data);
        await getLoadProfile(data);
        await relay_opearated_count(data);
        await count_insights(data);
        await getCurrUnbalance(data);
        await getVoltUnbalance(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };

      try {
        // await getChart(data);
        // await sleep(1000);
        // await getLoadProfile(data);
        // await sleep(1000);
        // await relay_opearated_count(data);
        // await sleep(1000);
        // await count_insights(data);
        // await sleep(1000);
        // await getCurrUnbalance(data);
        // await sleep(1000);
        // await getVoltUnbalance(data);
        await getChart(data);
        await getLoadProfile(data);
        await relay_opearated_count(data);
        await count_insights(data);
        await getCurrUnbalance(data);
        await getVoltUnbalance(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [relay_opearated_count_insights, setRelay_opearated_count_insights] =
    useState();
  const energyDatas =
    relay_opearated_count_insights && relay_opearated_count_insights.length > 0
      ? relay_opearated_count_insights[0]
      : null;
  const relay_opearated_count = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      // setIsLoading(true);
      const data = props;
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/relay_opearated_count_insights`,
          data
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setRelay_opearated_count_insights(response.data.data);
          success = true;
          if (response.data.start_date && response.data.end_date) {
            setNewStartDate(response.data.start_date);
            setNewEndDate(response.data.end_date);
          }
        } else {
          setRelay_opearated_count_insights([]);
          // showFlashMessage("Data Not Available For Relay Operated Count", "danger")
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setRelay_opearated_count_insights([]);
      showFlashMessage("Data Not Available For Relay Operated Count", "danger");
    }
    // setIsLoading(false);
  };

  const [oltc_operated_count_insights, setOltc_operated_count_insights] =
    useState();
  const count_insightsDatas =
    oltc_operated_count_insights && oltc_operated_count_insights.length > 0
      ? oltc_operated_count_insights[0]
      : null;

  const count_insights = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/oltc_operated_count_insights`,
          data
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setOltc_operated_count_insights(response.data.data);
          success = true;
        } else {
          setOltc_operated_count_insights([]);
          // showFlashMessage("Data Not Available For OLTC Operated Count", "danger")
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    } // setIsLoading(false);
    if (!success) {
      setOltc_operated_count_insights([]);
      showFlashMessage("Data Not Available For OLTC Operated Count", "danger");
    }
  };

  const [chartOptions, setChartOptions] = useState({});
  const truncateToTwoDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = parseFloat(Number(value).toFixed(2));
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };
  const getResponsiveDataLabels = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      return {
        fontSize: "8px",
        offsetY: -5,
      };
    } else if (screenWidth < 1024) {
      return {
        fontSize: "8px",
        offsetY: -10,
      };
    } else if (screenWidth <= 1366) {
      // For screen sizes like 1366x768
      return {
        fontSize: "8px",
        offsetY: -12,
      };
    } else {
      return {
        fontSize: "14px",
        offsetY: -15,
      };
    }
  };
  // chart
  const getChart = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/efficiency_vs_load_band_insights`,
          props,
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );

        const chartData = response.data.data.map((item) => ({
          x: item.loadband,
          y: truncateToTwoDigits(item.efficiency),
        }));
        const minYValue = Math.min(...chartData.map((data) => data.y));

        const options = {
          chart: {
            toolbar: {
              export: {
                csv: {
                  filename: "Average Efficiency (%) vs Load Band (%)",
                  headerCategory: "Load Band %",
                  dateFormatter: function (timestamp) {
                    let date = new Date(timestamp).toUTCString();
                    const originalDate = new Date(date);

                    // Adjust time zone
                    originalDate.setHours(originalDate.getHours() - 5);
                    originalDate.setMinutes(originalDate.getMinutes() - 30);

                    // Format date and time
                    const formattedDate =
                      originalDate.getFullYear() +
                      "-" +
                      ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + originalDate.getDate()).slice(-2) +
                      " " +
                      ("0" + originalDate.getHours()).slice(-2) +
                      ":" +
                      ("0" + originalDate.getMinutes()).slice(-2) +
                      ":" +
                      ("0" + originalDate.getSeconds()).slice(-2);
                    return formattedDate;
                  },
                },
                png: {
                  filename: "Average Efficiency (%) vs Load Band (%)",
                },
                svg: {
                  filename: "Average Efficiency (%) vs Load Band (%)",
                },
              },
            },
            type: "bar",
            zoom: {
              enabled: true,
            },
          },
          colors: ["#1B75BB"],
          xaxis: {
            categories: chartData.map((data) => data.x),
            title: {
              text: "Load Band %",
              style: {
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "600",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "500",
              },
            },
          },
          yaxis: {
            min: minYValue - 1,
            title: {
              text: "Average Efficiency %",
              style: {
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: "600",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "500",
              },
            },
          },
          series: [
            {
              name: "Efficiency",
              data: chartData.map((data) => data.y),
            },
          ],
          dataLabels: {
            enabled: true,
            position: "top", // Position labels on top of bars
            offsetY: -20, // Adjust the offset to place the label higher
            style: {
              fontFamily: "Montserrat, sans-serif",
              fontSize: getResponsiveDataLabels().fontSize,
              fontWeight: "500",
            },
            formatter: function (val) {
              return val;
            },
            dropShadow: {
              enabled: false,
            },
            background: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 1401,
              options: {
                chart: {
                  height: 260,
                },
              },
            },
            {
              breakpoint: 913,
              options: {
                chart: {
                  height: 202,
                },
              },
            },
          ],
          tooltip: {
            x: {
              format: "dd-MM-yyyy HH:mm:ss",
            },
            cssClass: "custom-tooltip",
            style: {
              fontFamily: "Montserrat, sans-serif",
              fontSize: "14px",
            },
          },
        };

        // setChartOptions(options);
        // setIsLoading(false);
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setChartOptions(options);
          success = true;
          setIsLoading(false);
        } else {
          setChartOptions([]);
          setIsLoading(false);
          // showFlashMessage("Data Not Available For Average Efficiency (%) vs Load Band (%)", "danger");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
      attempts++;
    }
    if (!success) {
      setChartOptions([]);
      setIsLoading(false);
      showFlashMessage(
        "Data Not Available For Average Efficiency (%) vs Load Band (%)",
        "danger"
      );
    }
  };

  // load profile dognut chart
  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };

  const [loadData, setLoadData] = useState([]);

  const getLoadProfile = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/load_profiles_insights`,
          props
        );
        const data = response.data.data[0]?.load_profile || [];
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setLoadData(response.data.data[0]?.load_profile);
          success = true;
        } else {
          setLoadData([]);
          // showFlashMessage("Data Not Available For Load Profile (%)", "danger");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
      attempts++;
    }
    if (!success) {
      setLoadData([]);
      showFlashMessage("Data Not Available For Load Profile (%)", "danger");
    }
  };

  const getColor = (status) => {
    const lowercaseStatus = status.toLowerCase();
    switch (lowercaseStatus) {
      case "healthy loading":
        return "#00B050";
      case "under loading":
        return "#FF9900";
      case "over loading":
        return "#FF0000";
      default:
        return "";
    }
  };

  const chartData = {
    series: loadData.map((item) => truncateToOneDigits(Object.values(item)[0])),
    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Load Profile (%)",
              headerCategory: "Name",
              dateFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Load Profile (%)",
            },
            svg: {
              filename: "Load Profile (%)",
            },
          },
        },
        type: "donut",
      },
      labels: loadData.map((item) => Object.keys(item)[0]),
      colors: loadData.map((item) => getColor(Object.keys(item)[0])), // Assigning colors based on status
      style: {
        fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
        fontSize: "14px", // Set font size for Y-axis title
        fontWeight: 500,
      },
      responsive: [
        {
          breakpoint: 1601, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "9px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1401,
          options: {
            chart: {
              height: 230,
            },
          },
        },
        {
          breakpoint: 1367, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "8px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 913,
          options: {
            chart: {
              height: 215,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: getResponsiveDataLabels().fontSize,
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        background: {
          enabled: false,
        },

        dropShadow: {
          enabled: false,
        },
      },
      
      legend: {
        position: "top",
        horizontalAlign: "center",
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: "14px", // Set font size for Y-axis title
          fontWeight: 500,
        },
      },
    },
  };

  // current unbalance donuts
  const [currData, setCurrData] = useState([]);
  const getCurrUnbalance = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/current_unbalance_insights`,
          props,
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        const data = response.data.data[0]?.current_unbalance || [];
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setCurrData(response.data.data[0]?.current_unbalance);
          success = true;
        } else {
          setCurrData([]);
          // showFlashMessage("Data Not Available For Current Unbalance", "danger");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
      attempts++;
    }
    if (!success) {
      setCurrData([]);
      showFlashMessage("Data Not Available For Current Unbalance", "danger");
    }
  };
  const currColor = (status) => {
    if (typeof status === "string") {
      const lowercaseStatus = status.toLowerCase();
      switch (lowercaseStatus) {
        case "br":
          return "#7BBFE9";
        case "ry":
          return "#FF6873";
        case "yb":
          return "#FDC163";
        default:
          return "";
      }
    }
  };

  //    hv chart data
  const firstArray =
    currData.length > 1 && Array.isArray(currData[0]) ? currData[0] : [];
  // Extracting data and labels from the first array
  const seriesData = firstArray.map((item) =>
    truncateToOneDigits(Object.values(item)[0])
  );
  const labels = firstArray.map((item) => Object.keys(item)[0].toUpperCase());
  const colors = firstArray.map((item) => currColor(Object.keys(item)[0]));

  const HVChartData = {
    series: seriesData,
    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Current Unbalance HV (%)",
              headerCategory: "Name",
              dateFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Current Unbalance HV (%)",
            },
            svg: {
              filename: "Current Unbalance HV (%)",
            },
          },
        },
        type: "donut",
      },
      labels: labels,
      colors: colors,
      responsive: [
        {
          breakpoint: 1601,
          options: {
            dataLabels: {
              style: {
                fontSize: "9px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1367, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "8px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1281,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 912,
          options: {
            chart: {
              height: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: getResponsiveDataLabels().fontSize,
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        background: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
    },
  };

  // lv chart data
  const secondArray =
    currData.length > 1 && Array.isArray(currData[1]) ? currData[1] : [];
  // Extracting data and labels from the second array
  const seriesLvData = secondArray.map((item) =>
    truncateToOneDigits(Object.values(item)[0])
  );
  const labelsLv = secondArray.map((item) =>
    Object.keys(item)[0].toUpperCase()
  );
  const colorsLv = secondArray.map((item) => currColor(Object.keys(item)[0]));

  const LVChartData = {
    series: seriesLvData,
    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Current Unbalance LV (%)",
              headerCategory: "Name",
              dateFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Current Unbalance LV (%)",
            },
            svg: {
              filename: "Current Unbalance LV (%)",
            },
          },
        },
        type: "donut",
      },
      labels: labelsLv,
      colors: colorsLv,
      responsive: [
        {
          breakpoint: 1601,
          options: {
            dataLabels: {
              style: {
                fontSize: "9px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1367, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "8px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1280,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 912,
          options: {
            chart: {
              height: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: getResponsiveDataLabels().fontSize,
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        background: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
    },
  };

  // current unbalance donuts
  const [voltData, setVoltData] = useState([]);
  const getVoltUnbalance = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/voltage_unbalance_insights`,
          props,
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
        const data = response.data.data[0]?.voltage_unbalance || [];
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setVoltData(response.data.data[0]?.voltage_unbalance);
          success = true;
        } else {
          setVoltData([]);
          // showFlashMessage("Data Not Available For Voltage Unbalance", "danger");
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }

      attempts++;
    }
    if (!success) {
      setVoltData([]);
      showFlashMessage("Data Not Available For Voltage Unbalance", "danger");
    }
  };
  const filteredVoltData = voltData.filter(
    (item) => Object.keys(item).length !== 0
  );

  //    hv chart data
  const voltHvArray =
    voltData.length > 1 && Array.isArray(voltData[0]) ? voltData[0] : [];
  // Extracting data and labels from the first array
  const voltHvData = voltHvArray.map((item) =>
    truncateToOneDigits(Object.values(item)[0])
  );
  const voltHvlabels = voltHvArray.map((item) =>
    Object.keys(item)[0].toUpperCase()
  );
  const voltHvcolors = voltHvArray.map((item) =>
    currColor(Object.keys(item)[0])
  );

  const HvvoltageData = {
    series: voltHvData,

    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Voltage Unbalance HV (%)",
              headerCategory: "Name",
              dateFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Voltage Unbalance HV (%)",
            },
            svg: {
              filename: "Voltage Unbalance HV (%)",
            },
          },
        },
        type: "donut",
      },
      labels: voltHvlabels,
      colors: voltHvcolors,
      responsive: [
        {
          breakpoint: 1601,
          options: {
            dataLabels: {
              style: {
                fontSize: "9px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1367, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "8px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1280,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 912,
          options: {
            chart: {
              height: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: getResponsiveDataLabels().fontSize,
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        background: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
    },
  };

  //    lv chart data

  const voltLvArray =
    voltData.length > 1 && Array.isArray(voltData[1]) ? voltData[1] : [];
  // Extracting data and labels from the first array
  const voltLvData = voltLvArray.map((item) =>
    truncateToOneDigits(Object.values(item)[0])
  );
  const voltLvlabels = voltLvArray.map((item) =>
    Object.keys(item)[0].toUpperCase()
  );
  const voltLvcolors = voltLvArray.map((item) =>
    currColor(Object.keys(item)[0])
  );

  const LvvoltageData = {
    series: voltLvData,
    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Voltage Unbalance LV (%)",
              headerCategory: "Name",
              dateFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Voltage Unbalance LV (%)",
            },
            svg: {
              filename: "Voltage Unbalance LV (%)",
            },
          },
        },
        type: "donut",
      },
      labels: voltLvlabels,
      colors: voltLvcolors,
     
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: getResponsiveDataLabels().fontSize,
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        background: {
          enabled: false,
          style: {
            fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
            fontSize: "12px", // Set font size for Y-axis title
            fontWeight: 500,
          },
        },
        dropShadow: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 1601, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "9px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1367, // Apply settings for 1600x900 screen
          options: {
            dataLabels: {
              style: {
                fontSize: "8px", // Reduce font size for donut values
              },
            },
          },
        },
        {
          breakpoint: 1281,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 913,
          options: {
            chart: {
              height: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        position: "top",
        horizontalAlign: "center",
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: "12px", // Set font size for Y-axis title
          fontWeight: 500,
        },
      },
    },
  };
  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // if (isLoading) {
  //     return (
  //         <div className="loaderWpr">
  //             <div className="loader"></div>
  //         </div>
  //     );
  // }

  // date format for breadcrums
  function formatDate(date) {
    if (!date) return ""; // Handle null/undefined
  
    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);
  
    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check
  
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  return (
    <>
      {isLoading && (
        <InsightsSkeleton/>
      )}

      {alerts.map((alert, index) => (
        <Alert
          key={alert.id}
          id={`flash-message-${alert.id}`}
          variant={alert.variant === "success" ? "success" : "danger"}
          onClose={() =>
            setAlerts((prevAlerts) =>
              prevAlerts.filter((a) => a.id !== alert.id)
            )
          }
          dismissible
          style={{ width: "35%", marginBottom: `${index * 25 * 2}px` }}
        >
          {alert.message}
        </Alert>
      ))}
      <div>
        <div className="wrapper">
          {/* <!-- Content Wrapper. Contains page content --> */}
          <div className="content-wrapper">
            <section className="content">
              <div className="insightPg container-fluid">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="mdlContent card pr-card mb30">
                      <div className="breadcrumbHdr card-header">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="#">{selectedAsset.label}</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">{selectedLocation.label}</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">
                              {" "}
                              {newStartDate ? (
                                <a href="#">{newStartDate}</a>
                              ) : (
                                <a href="#">{formatDate(fromDate)}</a>
                              )}
                              &nbsp;-&nbsp;
                              {newEndDate ? (
                                <a href="#">{newEndDate}</a>
                              ) : (
                                <a href="#">{formatDate(toDate)}</a>
                              )}
                            </a>
                          </li>
                        </ol>
                      </div>
                      <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
                      <div className="mdlContent card-body">
                  
          
                    
             
                        <div className="row">
                          <div className="loadBar col-md-5 col-lg-5">
                            <div className="card pr-card mb30">
                              <div className="inrCardHdr">
                                <h5>Average Efficiency (%) vs Load Band (%)</h5>
                              </div>
                              <div className="inrCardBody">
                                {Object.keys(chartOptions).length > 0 &&
                                chartOptions.series &&
                                chartOptions.series.length > 0 ? (
                                  <Chart
                                    options={chartOptions}
                                    series={chartOptions.series}
                                    type="bar"
                                    width="100%"
                                  />
                                ) : (
                                  <p class="no-datacard">
                                    Data Not Available For Average Efficiency
                                    (%) vs Load Band (%)
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="loadProfile col-md-4 col-lg-4">
                            <div className="card pr-card mb30">
                              <div className="inrCardHdr">
                                <h5>Load Profile (%)</h5>
                              </div>
                              <div className="inrCardBody">
                                {Object.keys(chartData).length > 0 &&
                                chartData.series &&
                                chartData.series.length > 0 ? (
                                  <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="donut"
                                    width="100%"
                                  />
                                ) : (
                                  <p class="no-datacard">Data Not Available For Load Profile (%) </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="relayCnt col-md-3 col-lg-3">
                            <div className="card pr-card mb30">
                              <div className="relayOprCnt">
                                <div className="inrCardHdr">
                                  <h5 className="text-center">
                                    Relay Operated Count
                                  </h5>
                                </div>
                                <div className="inrCardBody">
                                  <div className="">
                                    <table className="table table-bordered">
                                      <div className="relayTblscroll">
                                        {energyDatas &&
                                        energyDatas.relay_status &&
                                        Object.keys(energyDatas.relay_status)
                                          .length > 0 ? (
                                          <tbody>
                                            {Object.entries(
                                              energyDatas.relay_status
                                            ).map(([field, value]) => {
                                              const formattedField = field;
                                              return (
                                                <tr key={field}>
                                                  <th>{formattedField}</th>
                                                  <td className="valueFont">
                                                    {value}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        ) : (
                                          <p class="no-datacard">Relay is not configured</p>
                                        )}
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="oltcCnt card pr-card mb30">
                              <div className="oltc_card text-center">
                                <div className="inrCardHdr">
                                  <h5>OLTC Operated Count</h5>
                                </div>
                                <div className="inrCardBody">
                                  {relayLoad ? (
                                    {
                                      /* <p class="no-data">Loading...</p> */
                                    }
                                  ) : count_insightsDatas ? (
                                    <>
                                      <h3>
                                        {
                                          count_insightsDatas.oltc_operated_count
                                        }
                                      </h3>
                                    </>
                                  ) : (
                                    <>
                                      <h3>-</h3>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-lg-6 text-center">
                            <div className="card pr-card mb30 unbalaWpr">
                              <div className="unbalaMidl">
                                <div className="inrCardHdr">
                                  <h5>Current Unbalance (%)</h5>
                                </div>
                                <div className="inrCardBody">
                                  <div className="row text-center">
                                    <div className="separator col-md-6 col-lg-6">
                                      <h6>HV</h6>
                                      {Object.keys(HVChartData).length > 0 &&
                                      HVChartData.series &&
                                      HVChartData.series.length > 0 ? (
                                        <ReactApexChart
                                          options={HVChartData.options}
                                          series={HVChartData.series}
                                          type="donut"
                                          width="100%"
                                        />
                                      ) : (
                                        <p class="no-data">Data Not Available</p>
                                      )}
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                      <h6>LV</h6>
                                      {Object.keys(LVChartData).length > 0 &&
                                      LVChartData.series &&
                                      LVChartData.series.length > 0 ? (
                                        <ReactApexChart
                                          options={LVChartData.options}
                                          series={LVChartData.series}
                                          type="donut"
                                          width="100%"
                                        />
                                      ) : (
                                        <p class="no-data">Data Not Available</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 text-center">
                            <div className="card pr-card mb30 unbalaWpr">
                              <div className="unbalaMidl">
                                <div className="inrCardHdr">
                                  <h5>Voltage Unbalance (%)</h5>
                                </div>
                                <div className="inrCardBody">
                                  <div className="row text-center">
                                    <div className="separator col-md-6 col-lg-6">
                                      <h6>HV</h6>
                                      {Object.keys(HvvoltageData).length > 0 &&
                                      HvvoltageData.series &&
                                      HvvoltageData.series.length > 0 ? (
                                        <ReactApexChart
                                          options={HvvoltageData.options}
                                          series={HvvoltageData.series}
                                          type="donut"
                                          width="100%"
                                        />
                                      ) : (
                                        <p class="no-data">Data Not Available</p>
                                      )}
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                      <h6>LV</h6>
                                      {Object.keys(LvvoltageData).length > 0 &&
                                        LvvoltageData.series &&
                                      LvvoltageData.series.length > 0 ? (
                                        <ReactApexChart
                                          options={LvvoltageData.options}
                                          series={LvvoltageData.series}
                                          type="donut"
                                          width="100%"
                                        />
                                      ) : (
                                        <p class="no-data">Data Not Available</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
 
    </>
  );
}

export default Insights;
