import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import InfoBox from "./InfoBox";
import Col from "react-bootstrap/Col";
import ReactFlow, { MiniMap, Controls, Background } from "reactflow";
import "reactflow/dist/style.css";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import dayjs from "dayjs";
// import "./styles.css";
import Tree, { withStyles } from "react-vertical-tree";

// const data = [
//   {
//     id: 0,
//     name: "location",
//     parent: null,
//     color: "#657790",
//     children: [

//     ],
//   },
//   {
//     id: 1,
//     name: "Hydrabad",
//     parent: null,
//     color: "#657790",
//     children: [
//       { id: 2, color: "red", name: "HDAN 1", temp: "45 °C", children: [] },
//       {
//         id: 3, color: "green", name: "HDAN 2", temp: "25 °C", children: [
//           { id: 4, color: "red", name: "HDAN 3", temp: "50 °C", children: [] }
//         ]
//       },
//     ],
//   },
//   {
//     id: 5,
//     name: "Mumbai",
//     parent: null,
//     color: "#657790",
//     children: [
//       { id: 6, color: "green", name: "HDAN 4", temp: "45 °C", children: [] },
//       {
//         id: 7, color: "red", name: "HDAN 5", temp: "5 °C", children: [

//         ]
//       },
//     ],
//   },
// ];

const generateFlowData = (data) => {
  const nodes = [];
  const edges = [];
  let idCounter = 0;

  const traverse = (node, parent = null, x = 350, y = 100, level = 0) => {
    const nodeId = node.name ? node.name : `node-${idCounter++}`;
    const childCount = node.children?.length || 1;
    const baseOffset = 250;
    const childXOffset = Math.max(baseOffset, childCount * 10);
    const childYOffset = Math.max(baseOffset, childCount * 10);

    nodes.push({
      id: nodeId,
      data: {
        label: (
          <div className="busbarCard pr-card mt-10 mb-30 card">
            <div className="inrCardHdr text-white fs-6">
              <strong>{node.name || "Unnamed"}</strong>
            </div>
            <div
              className="inrCardBody text-center text-white fs-6"
              style={{ backgroundColor: node.color || "#657790" }}
            >
              <strong>{node.temp ? `${node.temp}°C` : ""}</strong>
            </div>
          </div>
        ),
      },
      position: { x, y },
      style: {
        width: 190,
        border: "1px solid #ccc",
        borderRadius: "5px",
        background: "#f9f9f9",
      },
    });

    if (parent) {
      edges.push({
        id: `e${parent.id}-${nodeId}`,
        source: parent.id,
        target: nodeId,
        animated: true,
      });
    }

    node.children?.forEach((child, index) => {
      const adjustedX =
        x +
        index * childXOffset -
        (node.children.length - 1) * (childXOffset / 2);
      traverse(child, { id: nodeId }, adjustedX, y + childYOffset, level + 1);
    });
  };

  data.forEach((root, index) => traverse(root, null, 600 * index, 60));

  return { nodes, edges };
};

function Overview() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [alertHeaders, setAlertHeaders] = useState([]);
  const [alertRows, setAlertRows] = useState([]);
  const[astCount, setAstcount]=useState([{ total: 0, active: 0, inactive: 0 }]);

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  useEffect(() => {
    if (apiData.length > 0) {
      const { nodes, edges } = generateFlowData(apiData);
      setNodes(nodes);
      setEdges(edges);
    }
  }, [apiData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      tempSld();
      getLatestAlerts();
      assetCount();
    }
  }, [token]);
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // Extract date components
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Extract time components
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, 0 becomes 12
    hours = String(hours).padStart(2, "0");

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const getLatestAlerts = async () => {
    if (!token) {
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/overview/busbar_last_alerts_data`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            if (response.data.success === true) {
              setAlertHeaders(response.data.data.headers);
              setAlertRows(response.data.data.data);
              success = true;
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setAlertHeaders([]);
              setAlertRows([]);
              // showFlashMessage("Data Not Available");
              // setLatestAlerts(result)
            }
          } else {
            // setLatestAlerts(result)
            setIsLoading(false);
            setAlertHeaders([]);
            setAlertRows([]);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      // showFlashMessage("Data Not Available For Latest Alerts", "danger");
      // setChartSeries(result)
      setAlertHeaders([]);
      setAlertRows([]);
      setIsLoading(false);
    }
  };
  // Define the new sequence order
  const reorderedHeaders = [
    "datetime",
    "location",
    "assset",
    "parameter_name",
    "parameter_value",
    "message",
  ];

  // Header display names
  const headerNames = [
    "Date Time",
    "Location",
    "Asset",
    "Parameter",
    "Value",
    "Alert",
  ];

  // Reorder the rows based on the new sequence
  const reorderedRows = alertRows.map((row) =>
    reorderedHeaders.map((header) => row[alertHeaders.indexOf(header)])
  );
  const tempSld = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/overview/assetwise_with_temp_sld`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            setApiData(response.data.data);
          } else {
            console.log("Eroor while fetching api");
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      // showFlashMessage("Locations are Not Found", "danger");
      setIsLoading(false);
    }
  };

  const assetCount = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/overview/total_assets_count`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            setAstcount(response.data.data)
          } else {
            console.log("Eroor while fetching api");
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      // showFlashMessage("Locations are Not Found", "danger");
      setIsLoading(false);
    }
  };

  const styles = {
    lines: {
      color: "#1890ff",
      height: "30px",
    },
    node: {
      border: "1px solid #1890ff",
      padding: "8px",
      borderRadius: "4px",
      cursor: "pointer",
      display: "inline-block",
      textAlign: "center",
      minWidth: "80px",
    },
    circle: {
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "8px",
    },
    temp: {
      fontSize: "12px",
      marginTop: "4px",
      // color:"gray"
    },
  };

  const StyledTree = withStyles(styles)(Tree);

  // Function to find the main parent (root location) of a node
  const findMainParent = (node, assetId, mainParent = null) => {
    if (node.id === assetId) {
      return mainParent ? mainParent.name : null; // Return the main parent name if found
    }
    if (node.children) {
      for (let child of node.children) {
        const result = findMainParent(
          child,
          assetId,
          node.parent === null ? node : mainParent
        );
        if (result) return result; // Return the main parent if found
      }
    }
    return null;
  };

  // Handle click event
  // const handleClick = (asset) => {
  //   const mainParentName = findMainParent({ children: data }, asset.id);
  //   console.log(`Main Parent: ${mainParentName}, Asset: ${asset.name}`);
  // };

  return (
    <>
      <div className="busbarOverviewPg content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <Row>
              <Col md={12} lg={12}>
                <Card className="mdlContent pr-card mb30">
                  <Card.Body className="mdlContent">
                    <Row className="align-items-center">
                      <InfoBox title="Total" value={astCount.total} />
                      <InfoBox title="Active" value={astCount.active} />
                      <InfoBox title="Inactive" value={astCount.inactive} />
                    </Row>

                    <Card className="latestArtTbl pr-card mb30 tableCard">
                      <div className="inrCardHdr mb10">
                        <h5>Latest Alerts</h5>
                      </div>
                      <Card.Body className="inrCardBody">
                        <div className="table-responsive">
                          <table className="table table-hover dtr-inline">
                            <thead>
                              <tr>
                                {headerNames.map((header, index) => (
                                  <th key={index}>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {reorderedRows.length > 0 ? (
                                reorderedRows.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                      <td key={cellIndex}>
                                        {reorderedHeaders[cellIndex] ===
                                        "datetime" ? (
                                          <a href="#">
                                            {convertTimestamp(cell)}
                                          </a>
                                        ) : (
                                          <a href="#">{cell}</a>
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={headerNames.length}
                                    className="text-center"
                                  >
                                    No Data Available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card className="pr-card mb30">
                      <div className="inrCardHdr mb10">
                        <h5>Flow Chart</h5>
                      </div>
                      <Card.Body className="inrCardBody">
                        <div
                          style={{
                            width: "100%",
                            height: "300px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            overflow: "auto",
                          }}
                        >
                           {nodes.length > 0 && edges.length > 0 ? (
                          <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            fitView
                            zoomOnScroll
                            panOnScroll
                            panOnDrag
                            zoomOnDoubleClick
                            minZoom={0.2}
                            maxZoom={2}
                            defaultViewport={{ x: 0, y: 0, zoom: 1 }}
                            proOptions={{ hideAttribution: true }}
                          >
                            {/* <MiniMap /> */}
                            <Controls showInteractive={false} />
                            <Background
                              color="#0000" // Change this to your desired color
                              gap={16} // Optional: Adjust grid spacing
                              variant="dots" // Can be "dots", "lines", or "cross"
                            />
                          </ReactFlow>
                          ) : (
                            <div className="text-center" style={{ padding: "100px", color: "#999" }}>
                              <h5>No Data Available</h5>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default Overview;
