import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import Chart from "react-apexcharts";
import Plot from "react-plotly.js";
import Plotly from "plotly.js/lib/core";
import { Alert } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useResetAxes } from "../../context/ReactAxesContext";
import HotspotSkeleton from "./HotspotSkeleton";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
// import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

function Hotspot(props) {
  const plotRef = useRef(null);
  const { resetAxes } = useResetAxes();
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);
  const [max_hotspot, setmax_hotspot] = useState();
  const [hotspotcount, sethotspotcount] = useState();
  const [hotspotTime, sethotspotTime] = useState();
  const [hotspotTrend, sethotspotTrend] = useState();
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: "Hotspot",
              headerCategory: "Date Time",
              categoryFormatter: function (timestamp) {
                let date = new Date(timestamp).toUTCString();
                const originalDate = new Date(date);

                // Add 5 hours and 30 minutes to the original date
                originalDate.setHours(originalDate.getHours() - 5);
                originalDate.setMinutes(originalDate.getMinutes() - 30);

                // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                const formattedDate =
                  ("0" + originalDate.getDate()).slice(-2) +
                  " " +
                  ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                  "-" +
                  originalDate.getFullYear() +
                  "-" +
                  ("0" + originalDate.getHours()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + originalDate.getSeconds()).slice(-2);
                return formattedDate;
              },
            },
            png: {
              filename: "Hotspot",
            },
            svg: {
              filename: "Hotspot",
            },
          },
        },
        type: "bar",
        height: 400,
      },
      xaxis: {
        categories: [], // Empty initially until data is fetched
      },
      // Rest of your options remain unchanged
    },
    series: [
      {
        name: "Hotspot",
        data: [],
      },
    ],
  });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [thresholdValue, setThresholdValue] = useState("");

  const [isChartDataAvailable, setIsChartDataAvailable] = useState(true);
  const emptyChartData = {
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: "Hotspot Temperature (°C)", // Set your y-axis title here
          font: {
            family: "Montserrat",
            size: 12, // Set your desired font size here
            color: "#000000", // Optional: Set the color of the title
          },
          standoff: 20,
        },
      },
      xaxis: {
        type: "category",
        title: {
          text: "Datetime", // X-axis title
          style: {
            fontFamily: "Montserrat", // Set the font family to Montserrat
            fontSize: "13px", // Set the font size here
          },
        },
        labels: {
          show: false, // Disable labels
        },
        categories: [],
      },
    },
    series: [
      {
        name: "Hotspot",
        data: [],
      },
    ],
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);
  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {}
    }
  }, [onload]);
  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  // for chart responsive
  useEffect(() => {
    const handleModeBarButtonClick = (buttonName) => {
      if (plotRef.current) {
        setTimeout(() => {
          const button = plotRef.current.querySelector(
            `.modebar-btn[data-title="${buttonName}"]`
          );
          if (button) {
            button.click();
          } else {
            console.warn(`Button with title "${buttonName}" not found.`);
          }
        }, 500);
      }
    };

    handleModeBarButtonClick("Zoom");
  }, [resetAxes]);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };
  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };
  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData([]);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData([]);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };
  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };
  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  // if (isLoading) {
  //   return (
  //     <div className="loaderWpr">
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }
  // date format for breadcrums
  function formatDate(date) {
    if (!date) return ""; // Handle null/undefined

    // Convert dayjs object or string to Date
    const parsedDate = dayjs.isDayjs(date) ? date.toDate() : new Date(date);

    if (isNaN(parsedDate.getTime())) return ""; // Invalid date check

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };
  const updateToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  //max hotspot api
  const max_hotspot_fun = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      setIsLoading(true);

      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/hotspot/max_hotspot`,
          data
        );
        // if (response.data.data.length === 0) {
        //   setmax_hotspot();
        //   setIsLoading(false);
        //   showFlashMessage("Data Not Available For Max Of Hotspot (oC)", "danger");
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setmax_hotspot(response.data.data);
          success = true;
          if (response.data.start_date && response.data.end_date) {
            setNewStartDate(response.data.start_date);
            setNewEndDate(response.data.end_date);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        setIsLoading(false);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      setmax_hotspot();
      setIsLoading(false);
      // showFlashMessage("Data Not Available For Max Of Hotspot (oC)", "danger");
    }
  };
  // hotspot count
  const hotspotcount_fun = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/hotspot/hotspot_count`,
          data
        );
        // if (response.data.data.length === 0) {
        //   sethotspotcount();
        //   showFlashMessage("Data Not Available For Hotspot Count", "danger");
        //   // setIsLoading(false);
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          sethotspotcount(response.data.data);
          success = true;
        } else {
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      sethotspotcount();
      // showFlashMessage("Data Not Available For Hotspot Count", "danger");
    }
    // setIsLoading(false);
  };
  // hotspot time
  const hotspotTime_fun = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/hotspot/hotspot_time_in_min`,
          data
        );
        // if (response.data.data.length === 0) {
        //   sethotspotTime();
        //   // setIsLoading(false);
        //   showFlashMessage("Data Not Available For Hotspot Time (Min)", "danger");
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          sethotspotTime(response.data.data);
          success = true;
        } else {
          // setIsLoading(false);
        }
      } catch (error) {
        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      sethotspotTime();
      // setIsLoading(false);
      // showFlashMessage("Data Not Available For Hotspot Time (Min)", "danger");
    }
    // setIsLoading(false);
  };

  // bar graph
  const [hotspotDates, setHotspotDates] = useState([]);
  const [hotspotValues, setHotspotValues] = useState([]);
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataTableData, setOriginalDataTableData] = useState([]);
  const [servOrder, setServOrder] = useState([]);

  //////// service order api /////////
  const getServiceOrders = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/conductor/serviceOrder/view_service_orders`,
            {
              access_token: token,
              user_log_id: userLogId,
            }
          );
          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            setServOrder([
              {
                date: "2025-03-03",
                Asset_Name: "MISS Power Transformer",
                Location: "ARIYALUR",
                Rating: "50 MVA",
                Serial: "-",
                Test: "Winding Resistance test",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Power Transformer",
                Location: "REDDIPALAYAM CEMENT WORKS",
                Rating: "128 MVA",
                Serial: "SR32198",
                Test: "Turns Ratio Test",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Distribution Transformer",
                Location: "GUJARAT CEMENT WORKS",
                Rating: "33 KVA",
                Serial: "TD1289",
                Test: "Insulation Resistance",
                Result: "Fail",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Power Transformer",
                Location: "REDDIPALAYAM CEMENT WORKS",
                Rating: "128 MVA",
                Serial: "SR32198",
                Test: "SFRA",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Distribution Transformer",
                Location: "GUJARAT CEMENT WORKS",
                Rating: "33 KVA",
                Serial: "TD1289",
                Test: "Mangetic Balance Test",
                Result: "Fail",
              },
             
            ]);
            success = true;
            setIsLoading(false);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setServOrder([{ date: 21 }]);
      showFlashMessage("Service Order are Not Found");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getServiceOrders();
    }
  }, [token]);
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Asset Name",
      selector: (row) => row.Asset_Name,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.Rating,
      sortable: true,
    },
    {
      name: "Serial No.",
      selector: (row) => row.Serial,
      sortable: true,
    },
    {
      name: "Test Name",
      selector: (row) => row.Test,
      sortable: true,
    },
    {
      name: "Test Result",
      selector: (row) => row.Result,
      sortable: true,
    },
    {
      name: "View",
      cell: (row) => (
        <div className="actionWidth">
          <Link to="/Conductor Drum" state={{ id: row }}>
            Report
          </Link>{" "}
          |{" "}
          <Link to="/conductor service report" state={{ id: row._id }}>
            Document
          </Link>
        </div>
      ),
      // ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  /////// search /////////
  const filteredData = servOrder.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const hotspotTrendGraph_fun = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
      setFilteredDataTableData([]);
      setOriginalDataTableData([]);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/hotspot/hotspot_trend_graph`,
          data
        );

        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const apiData = response.data.data;
          // if (apiData.created_at.length === 0) {
          //   showFlashMessage("Data Not Available", "danger");
          //   setIsChartDataAvailable(false);
          //   // setIsLoading(false);
          //   return;
          // }
          // Convert timestamps to date strings for x-axis categories
          const dates = apiData.created_at.map((timestamp) => {
            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");
            const utcFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            return utcFormattedDate;
          });

          const hotspotValues = apiData.hotspot.map(truncateToOneDigits);
          const thresholdValue = truncateToOneDigits(apiData.rated_hotspot);
          setHotspotValues(hotspotValues);
          setHotspotDates(dates);
          setThresholdValue(thresholdValue);

          // Filter hotspotValues and dates based on whether they are above thresholdValue
          const filteredData = hotspotValues.reduce((acc, value, index) => {
            if (value > thresholdValue) {
              const date = new Date(dates[index]);
              const formattedDate = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${date.getFullYear()} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${date
                .getSeconds()
                .toString()
                .padStart(2, "0")}`;

              if (
                formattedDate.toLowerCase().includes(searchText.toLowerCase())
              ) {
                // Check if the formattedDate contains the search text
                acc.push({
                  date: formattedDate,
                  value: truncateToOneDigits(value),
                });
              }
            }
            return acc;
          }, []);

          // Sort the filtered data in descending order based on the value
          const sortedFilteredData = [...filteredData].sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });

          // Set filtered data for DataTable
          setFilteredDataTableData(sortedFilteredData);
          setOriginalDataTableData(sortedFilteredData);
          // setResultant(transformData(filteredData))

          const updatedChartData = {
            options: {
              chart: {
                toolbar: {
                  export: {
                    csv: {
                      filename: "Hotspot",
                      headerCategory: "Date Time",
                      categoryFormatter: function (timestamp) {
                        let date = new Date(timestamp).toUTCString();
                        const originalDate = new Date(date);

                        // Add 5 hours and 30 minutes to the original date
                        // originalDate.setHours(originalDate.getHours() - 5);
                        // originalDate.setMinutes(originalDate.getMinutes() - 30);

                        // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
                        const formattedDate =
                          ("0" + originalDate.getDate()).slice(-2) +
                          "-" +
                          ("0" + (originalDate.getMonth() + 1)).slice(-2) +
                          "-" +
                          originalDate.getFullYear() +
                          " " +
                          ("0" + originalDate.getHours()).slice(-2) +
                          ":" +
                          ("0" + originalDate.getMinutes()).slice(-2) +
                          ":" +
                          ("0" + originalDate.getSeconds()).slice(-2);
                        return formattedDate;
                      },
                    },
                  },
                },
                animations: {
                  enabled: false,
                },
                type: "bar",
                height: 400,
                zoom: {
                  enabled: true,
                },
              },
              xaxis: {
                type: "datetime",
                categories: dates,
                automargin: true,
                labels: {
                  datetimeFormatter: {
                    day: "dd-MM-yyyy",
                    month: "MM-yyyy",
                    year: "yyyy",
                    hour: "dd-MM-yyyy HH:mm",
                  },
                  style: {
                    fontSize: "14px", // Adjust the font size as needed
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                  },
                  // formatter: function (value) {
                  //   return new Date(value).toLocaleString();
                  // },
                },
                title: {
                  text: "Datetime",
                  style: {
                    fontSize: "14px", // Adjust the font size as needed
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                  },
                },
              },
              annotations: {
                yaxis: [
                  {
                    automargin: true,
                    title: {
                      text: "Hotspot Temperature (\u00B0C)",
                      style: {
                        fontSize: "14px", // Adjust the font size as needed
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                      },
                    },
                    y: thresholdValue,
                    borderColor: "#000",
                    borderWidth: 1,
                    label: {
                      borderColor: "#000",
                      style: {
                        color: "#000",
                        background: "#fff",
                      },
                      text: "Rated Hotspot Limit: " + thresholdValue.toString(), // Label for the threshold value
                      // offsetX: -1200, // Adjust the horizontal offset to position closer to x-axis
                      style: {
                        fontSize: "12px", // Adjust the font size as needed
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                      },
                    },
                  },
                ],
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        from: -Infinity,
                        to: thresholdValue,
                        color: "#0095DA",
                      },
                      {
                        from: thresholdValue,
                        to: Infinity,
                        color: "#FF6873",
                      },
                    ],
                  },
                },
              },
              yaxis: {
                title: {
                  text: "Hotspot Temperature (\u00B0C)",
                  style: {
                    fontSize: "14px", // Adjust the font size as needed
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                  },
                },
                y: thresholdValue,
                borderColor: "#000",
                borderWidth: 1,
                label: {
                  borderColor: "#000",
                  style: {
                    color: "#000",
                    background: "#fff",
                  },
                  text: "Rated Hotspot Limit: " + thresholdValue.toString(), // Label for the threshold value
                  // offsetX: -1200, // Adjust the horizontal offset to position closer to x-axis
                },
              },
              dataLabels: {
                enabled: false, // This disables data labels for all series
              },
              tooltip: {
                x: {
                  format: "dd-MM-yyyy HH:mm",
                },
              },
            },
            series: [
              {
                name: "Hotspot",
                data: hotspotValues,
              },
            ],
          };
          setChartData(updatedChartData); // Set the updated chart data state
          success = true;
          setIsChartDataAvailable(true);
          // setIsLoading(false);
        } else {
          // showFlashMessage("Data Not Available", "danger");
          setIsChartDataAvailable(false);
          // setIsLoading(false);
        }
      } catch (error) {
        // showFlashMessage("Data Not Available", "danger");
        setIsChartDataAvailable(false);
        showFlashMessage("Error fetching hotspot data", "danger");
        // setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data Not Available", "danger");
      setIsChartDataAvailable(false);
    }
  };
  const handleSearch = (searchText) => {
    // Convert search text to lowercase for case-insensitive comparison
    const searchTextLower = searchText.toLowerCase();

    // Filter the original data based on the search text
    const filteredData = originalDataTableData.filter((item) => {
      return (
        item.date.toLowerCase().includes(searchTextLower) ||
        item.value.toString().includes(searchTextLower)
      );
    });

    // Update the filtered data state
    setFilteredDataTableData(filteredData);
  };
  const exportToCSV = () => {
    const customHeadings = columns.map((column) => column.name);
    const filteredDataExcel = filteredDataTableData.map((row) => {
      row = Object.values(row);
      const filteredItem = {};
      row.forEach((item, index) => {
        const heading = customHeadings[index];
        filteredItem[heading] = item;
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "Hotspot Recorded" + fileExtension);
  };

  var excelIcon = {};
  const formatDateTime = (timestamp) => {
    let date = new Date(timestamp).toUTCString();
    const originalDate = new Date(date);
    const formattedDate =
      ("0" + originalDate.getDate()).slice(-2) +
      "-" +
      ("0" + (originalDate.getMonth() + 1)).slice(-2) +
      "-" +
      originalDate.getFullYear() +
      " " +
      ("0" + originalDate.getHours()).slice(-2) +
      ":" +
      ("0" + originalDate.getMinutes()).slice(-2) +
      ":" +
      ("0" + originalDate.getSeconds()).slice(-2);
    return formattedDate;
  };

  if (hotspotDates.length > 0 && hotspotValues.length > 0) {
    var iExcel = {
      width: 400,
      height: 500,
      path: "M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z",
    };

    excelIcon = {
      name: "excel-icon",
      icon: iExcel,
      click: function (gd) {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          "Date Time,Hotspot\n" +
          hotspotDates
            .map(
              (date, index) => `${formatDateTime(date)},${hotspotValues[index]}`
            )
            .join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "hotspot.csv");
        document.body.appendChild(link);
        link.click();
      },
      title: "Export to Excel",
    };
  }

  const handleSubmitData = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location);
    setOnloadAsset(site);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer",
      };
      try {
        await max_hotspot_fun(data);
        await hotspotcount_fun(data);
        await hotspotTime_fun(data);
        await hotspotTrendGraph_fun(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
      };
      try {
        await max_hotspot_fun(data);
        await hotspotcount_fun(data);
        await hotspotTime_fun(data);
        await hotspotTrendGraph_fun(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  };

  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };

  // Custom styles for DataTable
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal", // Custom font weight for the table header
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  //// no data available columns ////
  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center">
                <p>No Data Available For Service Order</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  return (
    <>
      {isLoading && <HotspotSkeleton />}
      <div className="wrapper">
        {alerts.map((alert, index) => (
          <Alert
            key={alert.id}
            id={`flash-message-${alert.id}`}
            variant={alert.variant === "success" ? "success" : "danger"}
            onClose={() =>
              setAlerts((prevAlerts) =>
                prevAlerts.filter((a) => a.id !== alert.id)
              )
            }
            dismissible
            style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
          >
            {alert.message}
          </Alert>
        ))}
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                 <a>Periodic Test Report</a> 
                    </li>
                    {/* <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {newStartDate ? (
                          <a href="#">{newStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {newEndDate ? (
                          <a href="#">{newEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li> */}
                  </ol>
                </div>
                <Sidebar
                  locationData={locationData}
                  assetData={assetData}
                  changeLocation={changeLocation}
                  changeAsset={changeAsset}
                  handleSubmitData={SubmitDataWithDate}
                  selectedLocation={selectedLocation}
                  selectedAsset={selectedAsset}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  updateFromDate={updateFromDate}
                  updateToDate={updateToDate}
                />

                <div className="mdlContent card-body mt20">
                  {/* <div className="card-body col-md-12 col-lg-12 mt-2"> */}
                  <div className="card pr-card mt-2">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-md-6 col-lg-6 col-xl-6"></div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="form-inline dataTables_filter">
                            <div id="" className="mr10">
                              <label>
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search..."
                                  value={searchText}
                                  onChange={(e) => {
                                    const searchText = e.target.value;
                                    setSearchText(searchText);
                                    // Call search function with updated search text
                                    handleSearch(searchText);
                                  }}
                                />
                              </label>
                            </div>
                            <div className="input-group pr-form-group">
                              <a
                                className=""
                                alt="Download XLS"
                                onClick={exportToCSV}
                              >
                                <i className="fa fa-file-excel-o"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                    <div className='table-responsive'>
                      <DataTable
                        columns={columns}
                        data={[
              {
                date: "2025-03-03",
                Asset_Name: "MISS Power Transformer",
                Location: "REDDIPALAYAM CEMENT WORKS",
                Rating: "50 MVA",
                Serial: "A4532",
                Test: "Winding Resistance test",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Power Transformer",
                Location: "REDDIPALAYAM CEMENT WORKS",
                Rating: "128 MVA",
                Serial: "SR32198",
                Test: "Turns Ratio Test",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Distribution Transformer",
                Location: "GUJARAT CEMENT WORKS",
                Rating: "33 KVA",
                Serial: "TD1289",
                Test: "Insulation Resistance",
                Result: "Fail",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Power Transformer",
                Location: "REDDIPALAYAM CEMENT WORKS",
                Rating: "128 MVA",
                Serial: "SR32198",
                Test: "SFRA",
                Result: "Pass",
              },
              {
                date: "2025-03-03",
                Asset_Name: "Distribution Transformer",
                Location: "GUJARAT CEMENT WORKS",
                Rating: "33 KVA",
                Serial: "TD1289",
                Test: "Mangetic Balance Test",
                Result: "Fail",
              },
             
            ]}
                        noDataComponent={<NoDataComponent />}
                        customStyles={customStyles}
                        pagination
                        highlightOnHover
                        responsive
                      />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default Hotspot;
